import React from "react";
import UserService from "../../services/UserService";
import { MeasurementConverter } from "../../app/measurementConverter";
import { Measurement as DataMeasurement } from "../../data/entities/Measurement";
import { MeasurementUnit } from "../../data/entities/MeasurementUnit";

interface MeasurementProps {
  value: number;
  unit: MeasurementUnit;
  showValue?: boolean;
  showUnit?: boolean;
}

export function Measurement(props: MeasurementProps) {
  const system = UserService.getPreferredMeasurement();
  const measurement = {
    value: props.value,
    system: system,
    unit: props.unit,
  } as DataMeasurement;
  const converter = new MeasurementConverter();
  const convertedValue = converter.toFriendlyString(measurement, props.showValue, props.showUnit);

  return <span className="fw-bold measurement">{convertedValue}</span>;
}

export function millimetersToInches(millimeters: number): number {
  return millimeters / 25.4;
}

export function inchesToMillimeters(inches: number): number {
  return inches * 25.4;
}

Measurement.defaultProps = {
  showValue: true,
  showUnit: true,
};

export default Measurement;
