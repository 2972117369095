import { MeasurementConverter } from "../app/measurementConverter";
import { Measurement, MeasurementUnit, NoteImageModel, NoteModel, NoteValueModel, ODataResponse } from "../data/entities";
import { NoteRepo } from "../data/repo";
import UserService from "./UserService";

export class NoteService {

  async addNote(note: NoteModel): Promise<NoteModel | null> {
    return await new NoteRepo().addNote(note);
  }

  async editNote(note: NoteModel): Promise<boolean> {
    return await new NoteRepo().editNote(note);
  }

  async deleteNote(id: number): Promise<boolean> {
    return await new NoteRepo().deleteNoteById(id);
  }

  async getNoteById(inspectionId: number, id: number): Promise<NoteModel | null> {
    return await new NoteRepo().getNoteById(inspectionId, id);
  }

  async getNoteValues(noteId: number): Promise<NoteValueModel[] | null> {
    const odataValues = await new NoteRepo().getNoteValues(noteId);
    if (!odataValues) return null;
    const valuesList = odataValues.value.map((value) => { return { ...value } as NoteValueModel; });
    return valuesList;
  }

  async addNoteValue(noteValue: NoteValueModel): Promise<boolean> {
    return await new NoteRepo().addNoteValue(noteValue);
  }

  async updateNoteValue(noteValue: NoteValueModel): Promise<boolean> {
    return await new NoteRepo().updateNoteValue(noteValue);
  }

  async deleteNoteValue(id: number): Promise<boolean> {
    return await new NoteRepo().deleteNoteValue(id);
  }

  async calculateOvality(noteId: number): Promise<ODataResponse<NoteValueModel> | null> {
    return await new NoteRepo().calculateOvality(noteId);
  }

  async getImages(inspectionId: number, noteId: number): Promise<string[] | null> {
    return await new NoteRepo().getImages(inspectionId, noteId);
  }

  async updateNoteImage(noteImage: NoteImageModel): Promise<boolean> {
    return await new NoteRepo().updateNoteImage(noteImage);
  }

  async deleteNoteImage(id: number): Promise<boolean> {
    return await new NoteRepo().deleteNoteImage(id);
  }

  async reversePositions(id: number): Promise<boolean> {
    return await new NoteRepo().reversePositions(id);
  }

  static convertNotePositions(notes: NoteModel[]) {
    if (UserService.getDefaultMeasurement() === UserService.getPreferredMeasurement())
      return notes;
  
    const converter = new MeasurementConverter();
    const defaultSystem = UserService.getDefaultMeasurement();
    const targetSystem = UserService.getPreferredMeasurement();

    const measurementFactory = (value: number, unit: MeasurementUnit = MeasurementUnit.distanceTiny) => {
      return {
        value: value,
        unit: unit,
        system: defaultSystem
      } as Measurement;
    };

    const result = notes.map((note) => {
      const mStart = measurementFactory(note.startPosition, MeasurementUnit.distanceLarge);
      const mEnd = measurementFactory(note.endPosition ?? 0, MeasurementUnit.distanceLarge);
      const convertedStart = converter.convert(mStart, targetSystem);
      const convertedEnd = converter.convert(mEnd, targetSystem);
      note.startPosition = Number(convertedStart?.value.toFixed(2));
      note.endPosition = Number(convertedEnd?.value.toFixed(2));
      return note;
    });

    return result;
  }
}

export default NoteService; 
