import InvitationsModel from "../entities/InvitationsModel";
import InviteInsertModel from "../entities/InviteInsertModel";
import BaseRepository from "./BaseRepository";

export class InviteRepo extends BaseRepository {

  async getInvites(): Promise<InvitationsModel> {
    const url = `/Invite`;
    const response = await this.client.get(url);
    return this.handleResponse<InvitationsModel>(response)!;
  }

  async deleteInvite(id: number): Promise<boolean> {
    console.log("deleteInvite repo", id)
    const url = `/Invite/Delete/${id}`;
    const response = await this.client.post(url);
    return this.handleResponse<boolean>(response)!;
  }

  async insertInvite(invite: InviteInsertModel): Promise<boolean | undefined> {
    const url = `/Invite/Insert`;
    const response = await this.client.post(url, invite);
    return this.handleResponse<boolean>(response)!;
  }


}

export default InviteRepo;
