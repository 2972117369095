import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Footer,
  AlertMessage,
  Breadcrumbs,
  Breadcrumb,
  LinkLooksLike,
  Icon,
  IconDefinitions
} from "../components";
import { useLocation } from "react-router";

function PageTitle(props: any) {
  if (props.title && props.title !== "") {
    return <h2 className="mb-1">{props.title}</h2>;
  }
  return null;
}

export interface PageBreadcrumbProps {
  showHome?: boolean;
  values: Breadcrumb[];
}

export function PageBreadcrumbs(props?: PageBreadcrumbProps) {
  if (!props?.values?.length) return null;

  let breadcrumbs = [...props.values];
  if (props.showHome || props.showHome === undefined) {
    breadcrumbs.unshift({
      label: "Home",
      url: "/",
    });
  }
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

interface PageContentProps extends React.PropsWithChildren {
  title?: string;
  breadcrumbs?: PageBreadcrumbProps;
  submenu?: React.ReactElement;
  sidemenu?: React.ReactElement;
  contentCssClass?: string;
  footerless?: boolean;
}

export const PageActionsContext = createContext({
  actions: undefined as JSX.Element | undefined,
  setActions: (() => undefined) as React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >
});

export function PageContextActions() {
  const actionsContext = useContext(PageActionsContext);
  return <>{actionsContext.actions}</>;
}

export function usePageActions(component: JSX.Element) {
  let actionsContext = useContext(PageActionsContext);
  return useEffect(() => {
    actionsContext.setActions(component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function PageContent(props: PageContentProps) {
  const [actions, setActions] = useState<JSX.Element | undefined>(undefined);
  const [showSidemenu, setShowSidemenu] = useState(false);
  const location = useLocation();

  // Reset actions when location changes
  useEffect(() => {
    setShowSidemenu(false);
    return () => setActions(undefined);
  }, [location]);

  const sidemenuToggle = useCallback((e: any) => {
    setShowSidemenu((v) => !v);
  }, []);

  const footer = props.footerless ? null : <Footer></Footer>;

  return (
    <PageActionsContext.Provider
      value={useMemo(() => ({ actions, setActions }), [actions, setActions])}
    >
      {props.submenu}
      <AlertMessage></AlertMessage>
      <div role="article" className={`container-fluid ${props.contentCssClass ?? ""}`}>
        <div className="row align-items-center pt-3">
          <div className="col">
            <PageTitle title={props.title} />
            {PageBreadcrumbs(props.breadcrumbs)}
          </div>
          <div className="col-auto">
            <span id="main-actions" className="d-none d-lg-inline-block">
              {actions}
            </span>
            <span className="d-lg-none">
              {!props.sidemenu && (!actions || actions === <></>) ? <></> :
                <LinkLooksLike
                  id="main-sidemenu-toggle"
                  onClick={sidemenuToggle}
                >
                  <Icon iconName={IconDefinitions.ellipsisVertical} className="ms-2" />
                </LinkLooksLike>
              }
            </span>
          </div>
        </div>
        <div className="row flex-lg-nowrap">
          <div className={`${props.sidemenu ? "col-12 col-lg-auto" : "d-none"}`}>
            <div id="main-sidemenu" className={showSidemenu ? "show" : ""}>
              <div id="main-sidemenu-actions" className="d-lg-none mb-2">
                {actions}
              </div>
              <div>
                {!props.sidemenu ? <></> :
                  <div className="card bg-white mb-2 my-lg-0">
                    <div className="card-title bg-secondary-dark p-3 m-0">
                      <h6 className="text-light m-0">Menu</h6>
                    </div>
                    {props.sidemenu}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className={"col"}>
            {props.children}
          </div>
        </div>
      </div>
      {footer}
    </PageActionsContext.Provider>
  );
}

export default PageContent;
