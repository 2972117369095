import MaintenanceRepo from "../data/repo/MaintenanceRepo";
export class MaintenanceService {

  async getSpaceUsed(): Promise<number | null> {
    const response = await new MaintenanceRepo().getSpaceUsed();

    return response.fileCacheUsedBytes;
  }

  async emptyFileCache(): Promise<boolean | null> {
    const response = await new MaintenanceRepo().emptyFileCache();

    return response;
  }

  async defragmentDatabase(): Promise<boolean | null> {
    const response = await new MaintenanceRepo().defragmentDatabase();

    return response;
  }

  async cleanUpValues(olderThanDays: number) {
    return await new MaintenanceRepo().cleanUpValues(olderThanDays);
  }

  async compressInspections(olderThanDays: number, ratio: number) {
    return await new MaintenanceRepo().compressInspections(olderThanDays, ratio);
  }

  async compressImages(compressImagesOlderThanDays: number) {
    return await new MaintenanceRepo().compressImages(compressImagesOlderThanDays);
  }
}

export default MaintenanceService;
