import "./ExploreCard.scss";
import LinkLooksLike from "../../components/link/LinkLooksLike";
import { CardTitle } from "../../components/card/CardTitle";
import { Card, CardBody, SimpleCard } from "../../components/card/Card";
import SpinnerDefault from "../../components/spinners/SpinnerDefault";
import { useEffect, useState } from "react";
import ExploreCardChart from "./ExploreCardChart";
import { InspectionChartModel } from "../../data/entities";
import { AreaExport } from "../../services";
import FileExporter from "./FileExporter";

interface ViewStructure {
  label: string;
  value: string;
  title: string;
}

const views: ViewStructure[] = [
  {
    label: "Combined",
    value: "value",
    title: "View Cobined (Sum of all Values)",
  },
  {
    label: "Surface Flaw",
    value: "flawValue",
    title: "View Surface Flaw (Flaw)",
  },
  {
    label: "Mid-Wall Flaw",
    value: "wallValue",
    title: "View Mid-Wall Flaw (Max of Wall1 and Wall2)",
  },
];

export interface ExploreCardProps extends React.PropsWithChildren {
  chartData: InspectionChartModel;
  tubing: string;
  reference: string;
  inspectionDate: Date;
  overallThreshold: number;
}

export function ExploreCard(props: Readonly<ExploreCardProps>) {
  const { tubing, reference, inspectionDate } = props;
  const [chartSeries, setChartSeries] = useState("value");
  const chartData = props.chartData;

  useEffect(() => {
  }, [props.chartData])

  const getContent = () => {
    if (chartData?.points.length === 0) return <em>No data to display.</em>;

    return (
      <>
        <div className="list-group list-group-flush">
          <div className="list-group-item px-3">
            <label className="text-sm font-weight-bold mb-0 mr-3 d-none d-lg-inline">
              View:{" "}
            </label>
            {views.map((view) => (
              <LinkLooksLike
                key={view.label.replaceAll(" ", "")}
                title={view.title}
                className={`card-link text-sm clickable ${
                  chartSeries === view.value ? "active" : ""
                }`}
                onClick={() => setChartSeries(view.value)}
              >
                {view.label}
              </LinkLooksLike>
            ))}
          </div>
          <ExploreCardChart
            chartData={chartData}
            chartSeries={chartSeries}
            overallThreshold={props.overallThreshold}
          ></ExploreCardChart>
          <div className="row">
            <div className="col-6 text-start text-sm text-soft"> </div>
            <div className="col-6 text-end text-sm text-soft">tail</div>
          </div>
        </div>
        <FileExporter
          type={AreaExport.inspection}
          id={chartData.id}
          pdfFileName={`${tubing}-${new Date(inspectionDate)
            .toISOString()
            .slice(0, 10)}`}
        />
      </>
    );
  };

  if (chartData === undefined) return <SpinnerDefault />;
  if (chartData === null)
    return <SimpleCard title="Failed to load data."></SimpleCard>;

  return (
    <Card className="mb-3">
      <CardTitle>
        <h6 className="text-light m-0">
          Explore {tubing} : {reference}
        </h6>
      </CardTitle>
      <CardBody>{getContent()}</CardBody>
    </Card>
  );
}

