import { GridColumn, GridRowClickEvent, } from "@progress/kendo-react-grid";
import { UserService } from "../../services";
import { ODataGrid, TitlelessCard } from "../../components";
import { router, routes } from "../../app/routes";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";

export function UsersGrid() {

  const getUsers = async (oDataQuery: string) => {
    const users = await new UserService().getUsers(oDataQuery);
    return users;
  }

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(routes.userDetails, new Map([
          ['id', dataItem.id]
        ]));
        break;
      case "opentab":
        router.navigateWithParams(routes.userDetails, new Map([
          ['id', dataItem.id]
        ]), true);
        break;
    }
  };


  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      routes.userDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  return (
    <TitlelessCard className="p-0 m-0" bodyClassName="p-0">
      <ODataGrid
        getData={getUsers}
        onRowMenuSelect={onRowMenuSelect}
        onRowClick={onRowClick}
        sort={[{ field: "userName", dir: "asc" }]}
        filter={{ logic: "and", filters: [{field:"isDeleted", operator: "eq", value: false}] }}
        scrollable="scrollable"
        fullHeight={true}
      >
        <GridColumn 
          title="User" 
          field="userName" 
          sortable={true} 
          filter="text" 
        />
        <GridColumn 
          title="Company" 
          field="company.name" 
          sortable={true} 
          filter="text" 
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem 
          text="Open Tab" 
          data={{ action: "opentab" }} 
          icon="windows" 
        />
      </ODataGrid>
    </TitlelessCard>
  )
}

export default UsersGrid
