import React from "react";

export interface sharedProperty<T> {
  value: T,
  setter: React.Dispatch<React.SetStateAction<T>>
}

export function useSharedState<T>(initialValue: T): sharedProperty<T> {
  const [v, s] = React.useState(initialValue);

  return {
    value: v,
    setter: s
  };
};

export default sharedProperty;
