import { useState } from "react";
import { SimpleCard } from "../../components/card/Card";
import Form from "react-bootstrap/Form";
import { BlockButton, TextWithValidation } from "../../components";
import MaintenanceService from "../../services/MaintenanceService";
import { showErrorMessage, showSuccessMessage } from "../../app/tools";

export default function CleanUpOldValuesCard() {
  const [olderThanDays, setOlderThanDays] = useState(14);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleChange = (input: string) => {
    setOlderThanDays(parseInt(input));
  };

  async function cleanUpOldValuesButtonClick() {
    setShowSpinner(true);
    const ok = await new MaintenanceService().cleanUpValues(olderThanDays);
    setShowSpinner(false);
    if(!ok) {
      showErrorMessage("Procedure to clean up of old inspection values failed to execute.");
      return;
    }
    showSuccessMessage("Cleaned up old inspection values started, check activity logs for progress.");
  }

  return (
    <SimpleCard className="mb-3 p-0" title="Clean Up Old Values">
      <Form>
        <div className="row">
          <div className="col-12">
            <p>
              The system stores two copies of the data for every point, the
              "original" and the "modified" current version. For older
              inspections, there is probably no need to keep two copies of every
              data point.
            </p>
            <p>
              This process will clean up all inspections older than the number
              of days that you set, it will remove the "original" values and
              keep only the current versions. This can reduce the amount of data
              stored over time.
            </p>
            <strong>
              Doing big data cleanup operations can fragment the database, so be
              sure to always run the "Defragment Database" after you do this
              cleanup.
            </strong>
          </div>
          <div className="col-12 mt-3">
            <TextWithValidation
              label="Older than this many days"
              id=""
              required={false}
              initialValue={olderThanDays.toString()}
              onChangeText={(input) => {
                handleChange(input);
              }}
              type="number"
            ></TextWithValidation>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-5 col-xl-3 mt-3">
            <BlockButton
              label="Clean Up Old Values"
              onClick={cleanUpOldValuesButtonClick}
              disabled={false}
              showSpinner={showSpinner}
            ></BlockButton>
          </div>
        </div>
      </Form>
    </SimpleCard>
  );
}

