import ProvinceModel from "../data/entities/ProvinceModel";
import AddressRepo from "../data/repo/AddressRepo";

export class AddressService {

  async getAllProvinces(): Promise<ProvinceModel[] | null> {
    const response = await new AddressRepo().getAllProvinces();

    return response;
  }
} 

export default AddressService;
