import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  IconAndText,
  Link,
  SpinnerDefault,
} from "../../components";
import { TubingModel } from "../../data/entities";
import { TubingService, UrlService } from "../../services";
import { useParams, useLocation } from "react-router-dom";
import { routes } from "../../app/routes";
import { usePageActions } from "../PageContent";

export function TubingInspectionsCard() {
  const urlParams = useParams();
  const location = useLocation();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [data, setData] = useState<TubingModel>();

  usePageActions(
    <Link
      className="btn btn-primary"
      url={`${routes.inspectionsInsert}?returnUrl=${location.pathname}&tubingId=${id}`}
    >
      <IconAndText iconName="plus" text="Add New Inspection" />
    </Link>
  );

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new TubingService().getTubingDetails(
          id.toString()
        );
        setData(loadedData);
      } catch (e) {
        console.error("Error loading tubing details", e);
      }
    };
    fetchData();
  }, [id]);

  if (data === undefined) return <SpinnerDefault />

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">View tubing inspections.</h6>
      </CardTitle>
      <CardBody>
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th scope="col">Inspection Date</th>
            </tr>
          </thead>
          <tbody>
            {data.inspections?.map((inspection) => {
              const inspectionDate = inspection?.inspectionDate
                ? new Date(inspection.inspectionDate).toLocaleDateString()
                : null;
              return (
                <tr key={inspection.id}>
                  <td>
                    <Link
                      url={routes.myInspectionsDetails}
                      params={new Map([["id", inspection.id!.toString()]])}
                    >
                      {inspectionDate}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default TubingInspectionsCard;

