import { useOutletContext, useParams } from "react-router-dom";
import { BlockButton, SimpleCard, TextWithValidation } from "../../../components";
import { useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import ProcessResetModel from "../../../data/entities/ProcessResetModel";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsProcessReset() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Reset Data");

  const params = useParams();
  const id = params["id"] ?? "";

  const [resetModel, setResetModel] = useState<ProcessResetModel | null>(null);
  const [resetIsValid, setResetIsValid] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadData = async (id: number) => {
    await new ProcessService()
    .getProcessReset(id)
    .then((data) => {
      setResetModel(data);
    })
    .catch((error) => {
      console.error("Failed to fetch process reset data: ", error);
    });
  }

  useEffect(() => {
    loadData(parseInt(id));
  }, [id])

  async function resetDataButtonClick() {
    if(!resetIsValid) return;
    setShowSpinner(true);
    const ok = await new ProcessService().postProcessReset(parseInt(id), resetModel?.confirm ?? "");
    setShowSpinner(false);

    if(!ok) return;
    
    showSuccessMessage("Inspection successfully reset.");
    // Reload the reset history data.
    loadData(parseInt(id));
  }

  return (
    <>
    <SimpleCard className="mb-3 p-0" title="Reset all data point values.">
      <div className="row">
        <div className="col-12">
          <p className="fst-italic">WARNING! This cannot be undone, it is permanent.</p>
          <p>This will reset all of the data point values for the entire inspection. It will not erase the calculated positions, or reset your splits or reference points. This just resets the following values: FLW, FLWBaseline, Flaw, Wall1, and Wall2.</p>
          <p>To confirm you definitely want to reset the modified/normalized data point values, you need to type the word RESET into the field below.</p>
        </div>
        <TextWithValidation
          label="Type RESET"
          id=""
          validationFunction={(input) => {
            const valid = (input === "RESET");
            setResetIsValid(valid);
            return valid;
          }}
          onChangeText={(input) => {
            setResetModel((prevState) => ({
              ...prevState as ProcessResetModel,
              confirm: input,
            }));
          }}
        ></TextWithValidation>
      <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
        <BlockButton
          label="Reset Data"
          onClick={resetDataButtonClick}
          disabled={false}
          showSpinner={showSpinner}
        ></BlockButton>
      </div>
    </div>
    </SimpleCard>
    <SimpleCard className="mb-3 p-0" title="Data reset history.">
    <table className="table hover-row mt-3">
        <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
          {resetModel?.processes.map((processDataHistory) => (
            <tr className="clickable" key={getUuid()}>
              <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
              <td>{processDataHistory.description}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </SimpleCard>
    </>
    
  );
}

export default InspectionsProcessReset;

