import React, { useEffect } from "react";
import "./SubmenuSideBar.scss";
import { useLocation } from "react-router-dom";
import SubmenuSideBarNavItemModel from "../../models/SubmenuSideBarNavItemModel";
import { v4 as uuidv4 } from "uuid";
import { SubmenuSideBarNavItem } from ".";

interface SubmenuSideBarProps {
  title?: string;
  menuItems: SubmenuSideBarNavItemModel[];
}

interface SubmenuSideBarProps {
  title?: string,
  menuItems: SubmenuSideBarNavItemModel[];
}

interface SubmenuSideBarProps {
  title?: string,
  menuItems: SubmenuSideBarNavItemModel[];
}
export function SubmenuSideBar({title, menuItems}:  SubmenuSideBarProps) {
  const location = useLocation();

  useEffect(() => {
    return () => { };
  }, [location]);

  return (
    <div className="">
      {title && title !== "" ? <h6>{title}</h6> : <></>}
      <ul className="nav flex-column">
        {menuItems.map((menuItem, index) => (
          <SubmenuSideBarNavItem
            key={uuidv4()}
            text={menuItem.text}
            url={menuItem.url}
            additionalClass={`submenu-sidebar-nav-item ${menuItem.additionalClass}`}
            activeMatchUrl={menuItem.activeMatchUrl ?? menuItem.url}
            activeMatchExact={menuItem.activeMatchExact}
            clickHandler={menuItem.clickHandler}
          />
        ))}
      </ul>
    </div>
  );
}

export default SubmenuSideBar;




