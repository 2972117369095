import { router, routes } from "../../app/routes";
import { BlockButton, IconAndText } from "../../components";

export function RegisterConfirmationPage() {
  return (
    <>
      <h2 className="mb-4">Registration completed</h2>
      <hr />
      <section>
        <h6>Your new account is ready</h6>
        <p>
          Save your password somewhere that you will remember it.
        </p>
        <div className="row">
          <div className="col col-4">
            <BlockButton 
              onClick={() => {
                router.navigate(routes.login).catch((error) => {});
              }} 
              disabled={false}>
              <IconAndText iconName="key" text="Sign in now" />
            </BlockButton>
          </div>
        </div>
      </section>
    </>
  );
}
export default RegisterConfirmationPage;
