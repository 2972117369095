import React, { useState } from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../../components';
import sharedProperty from '../../../../app/sharedProperty';


interface ModalStatesModel {
  detailsState: sharedProperty<boolean>;
  imagesState: sharedProperty<boolean>;
  valuesState: sharedProperty<boolean>;

}

interface InspectionNotesSubmenuProps {
  onChangeTab: (tab: string) => void;
  initialTab: 'Details' | 'Images' | 'Values' ;
  modalStates?: ModalStatesModel;
}

export const InspectionNotesSubmenu: React.FC<InspectionNotesSubmenuProps> = ({ onChangeTab, initialTab, modalStates }) => {
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  function setModalState(key: keyof ModalStatesModel, value: boolean) {
    if (modalStates) {
      modalStates[key].setter(value);
    }
  }

  const handleTabChange = (tab: keyof ModalStatesModel) => {
    if (modalStates) {
      Object.keys(modalStates).forEach(key => {
        setModalState(key as keyof ModalStatesModel, false);
      });
      setModalState(tab, true);
    }
    
  };

  const items: SubmenuNavigationItem[] = [
    {
      label: 'Details',
      url: '',
      clickHandler: () => handleTabChange('detailsState'),
      ifActive: activeTab === 'Details' ? 'active' : '',
    },
    {
      label: 'Values',
      url: '',
      clickHandler: () => handleTabChange('valuesState'),
      ifActive: activeTab === 'Values' ? 'active' : '',
    },
    {
      label: 'Images',
      url: '',
      clickHandler: () => handleTabChange('imagesState'),
      ifActive: activeTab === 'Images' ? 'active' : '',
    },
  ]

  return (
    <Submenu items={items} />
  );
};

export default InspectionNotesSubmenu;
