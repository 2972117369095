import { useEffect, useState } from "react";
import { InspectionsPageContext } from "../InspectionsPage";
import { useOutletContext, useParams } from "react-router-dom";
import {
  Icon,
  IconDefinitions,
  SimpleCard,
  SpinnerDefault,
} from "../../../components";
import { InspectionModel } from "../../../data/entities";
import Gravatar from "react-gravatar";
import { InspectionService } from "../../../services";
import "../../../components/inspections/InspectorList.scss";
import InsertInspectorModal from "./components/InsertInspectorModal";
import { useSharedState } from "../../../app/sharedProperty";
import { usePageActions } from "../../PageContent";
import { Button } from "react-bootstrap";
import {
  getUuid,
  showErrorMessage,
  showSuccessMessage,
} from "../../../app/tools";

export function InspectionsInspectors() {
  const params = useParams();
  const id = params["id"] ?? "";
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Inspectors");

  const [inspection, setInspection] = useState<InspectionModel | null>(null);
  const showInsertState = useSharedState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const insertInspector = async (id: number, userId: number) => {
    try {
      showInsertState.setter(false);
      setShowSpinner(true);
      const ok = await new InspectionService().addInspector(id, userId);

      if (!ok) return;

      showSuccessMessage("Inspector successfully inserted.");
      // Reload data after inserting an inspector
      loadData(id);
    } catch (error) {
      showErrorMessage("Error occurred while inserting Inspector.");
      throw new Error(`Unable to insert new inspector, ${error}`);
    } finally {
      setShowSpinner(false);
    }
  };

  async function deleteHandler(userId: number): Promise<void> {
    const ok = await new InspectionService().removeInspector(
      parseInt(id),
      userId
    );

    if (!ok) return;

    showSuccessMessage("Inspector successfully removed from this Inspection.");
    // Reload data after deleting an inspector
    loadData(parseInt(id));
  }

  const loadData = async (inspectionId: number) => {
    await new InspectionService().getInspectors(inspectionId).then((data) => {
      setInspection(data);
    });
  };

  useEffect(() => {
    loadData(parseInt(id));
  }, [id]);

  const content = (
    <>
      {inspection?.inspectors?.map((inspector) => {
        const displayName = inspector.displayName;
        const signature =
          inspector.profile?.signatureDataUri ?? false ? (
            <img
              className="signature"
              src={inspector.profile?.signatureDataUri}
              alt="Inspector Signature"
              width={40}
              height={20}
            />
          ) : null;

        return (
          <div
            className="row d-flex align-items-center flex-nowrap py-2"
            key={getUuid()}
          >
            <div className="col-auto">
              <Gravatar
                email={inspector.email}
                size={40}
                default="identicon"
                alt={`${inspector.profile?.userName} Gravatar`}
                title={displayName}
                className="avatar rounded-circle"
              />
            </div>
            <div className="col line-height-1">
              <div>{inspector.displayName}</div>
              <div className="text-muted small">
                {signature ?? inspector.email}
              </div>
            </div>
            <div className="col-auto text-end">
              <Icon
                className="text-danger cursor-pointer"
                iconName={IconDefinitions.delete}
                onClick={() => deleteHandler(inspector.profileId!)}
              />
            </div>
          </div>
        );
      })}
    </>
  );

  usePageActions(
    <>
      <Button onClick={() => showInsertState.setter(true)}>
        <Icon iconName="plus" />
        Add Inspector
      </Button>
    </>
  );

  return (
    <>
      <SpinnerDefault show={showSpinner} fullscreen={true} />
      <SimpleCard title="Modify inspectors on this inspection.">
        {content}
      </SimpleCard>
      <InsertInspectorModal
        showState={showInsertState}
        onSubmit={insertInspector}
        inspectionId={parseInt(id)}
      />
    </>
  );
}

export default InspectionsInspectors;

