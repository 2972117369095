import { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { router, routes } from '../../app/routes';
import PageContent from '../PageContent';
import { CompanyModel } from '../../data/entities';
import CompanyService from '../../services/CompanyService';
import UrlService from '../../services/UrlService';
import CompanySubmenuSideBar from './CompanySubmenuSideBar';
import { SlideOutModal, SpinnerDefault } from '../../components';
import { Button } from 'react-bootstrap';
import { useSharedState } from '../../app/sharedProperty';
import GetCompanyBreadcrumbs from './GetCompanyBreadcrumbs';
import CompanySubmenu from './CompanySubmenu';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

export interface CompaniesPageContext {
  title?: string;
  setTitle: (title?: string) => void;
  sidemenu?: ReactElement;
  setSidemenu: (sidemenu?: ReactElement) => void;
}

export function CompanySubpagesWrapper() {

  const location = useLocation();
  const urlParams = useParams();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const params = new Map<string, string>();

  params.set("id", companyId.toString() ?? "");

  const [company, setCompany] = useState<CompanyModel | null>(null);
  const title = company?.name ?? "Manage company";

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await new CompanyService().deleteCompany(companyId);
      if(!ok) return;
      showSuccessMessage("Company: " + title + " was successfully deleted.");
      router.navigate(routes.company)
      .catch((error) => {
        showErrorMessage("Unable to delete company: " + title + ", an error occurred: " + error);
      });
  };

  useEffect(() => {
    new CompanyService()
      .getDetails(companyId)
      .then((data) => {
        setCompany(data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, [companyId]);

  if (company === null) return <SpinnerDefault />;

  return (
    <PageContent
      title={title ?? company?.name ?? "Manage company"}
      breadcrumbs={GetCompanyBreadcrumbs(location, company)}
      submenu={<CompanySubmenu id={companyId.toString()} showSelectModalState={showSelectModalState} />}
      sidemenu={<CompanySubmenuSideBar title={title} />}
    >
      <Outlet />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Company"}
        footer={
          <div className="row m-0 w-100">
            <div className="col-6 ps-0">
              <Button
                onClick={selectModalCancelClick}
                variant="cancel"
                className="w-100"
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 pe-0">
              <Button
                onClick={deleteSelectClick}
                variant="primary"
                className="w-100"
              >
                Delete
              </Button>
            </div>
          </div>
        }
        >
        Are you sure you want to delete this company?
      </SlideOutModal>
    </PageContent>
  );
}

export default CompanySubpagesWrapper;
