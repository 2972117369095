import { useState } from 'react'
import { SimpleCard } from '../../components/card/Card';
import Form from "react-bootstrap/Form";
import { BlockButton } from '../../components';
import MaintenanceService from '../../services/MaintenanceService';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

export default function DefragmentDatabaseCard() {

  const [showSpinner, setShowSpinner] = useState(false);

  async function defragmentDatabaseButtonClick() {
    setShowSpinner(true);
    const ok = new MaintenanceService().defragmentDatabase().then(() => {});
    setShowSpinner(false);
    if(!ok) {
      showErrorMessage("De-fragmentation of database failed to execute.");
      return;
    }
    showSuccessMessage("Database de-fragmented started. Please monitor activity logs to ensure it has finished.");
  }

  return (
      <SimpleCard className="mb-3 p-0" title="Defragment Database">
        <Form>
          <div className="row">
            <div className="col-12">
              <p>As data files are uploaded, the database will get full of a lot of individual points that aren't always stored in order.</p>
              <p>The data points being stored randomly can slow down the system as the inspection data gets "fragmented". Use this maintenance procedure to de-fragment the database and re-order the data points for faster performance.</p>
              <p>This maintenance procedure can take time to run, so only click this button once and then monitor the "activity logs" to make sure the process stops before clicking it again. <strong>Try and run this when nobody else is in the system, the database won't be able to de-fragment the data if it's in use.</strong></p>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-5 col-xl-3 mt-3">
              <BlockButton
                label="Defragment Database"
                onClick={defragmentDatabaseButtonClick}
                disabled={false}
                showSpinner={showSpinner}
              ></BlockButton>
            </div>
          </div>
        </Form>
      </SimpleCard>
  );
}
