import React from "react";

export interface HeadingBarProps extends React.PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  h?: number;
  right?: React.ReactElement;
}

export function HeadingBar(props: HeadingBarProps) {
  return (
    <div
      className="heading-bar pt-3"
      {...props}>
      <div className="row align-items-end mb-2">
        {props.title ? <div className="col text-start"><h2 className={`m-0 h${props.h ? props.h : "2"}`}>{props.title}</h2></div> : <></>}
        {props.right ? <div className="col-auto text-end">{props.right}</div> : <></>}
      </div>
      {props.children}
    </div>
  );
}

export default HeadingBar;
