import { OvalityDataPointViewModel, OvalityInsertModel } from "../entities";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";

export class OvalityDataPointViewRepo extends BaseRepository {
  async getDetails(id: string, oDataQuery: string = ""): Promise<OvalityDataPointViewModel> {
    const url = `${this.getBaseUri()}(${id})`;
    const response = await this.client.get(url);
    return this.handleResponse<OvalityDataPointViewModel>(response)!;
  }

  async getOvalityDataPointViews(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<OvalityDataPointViewModel>> {
    const url = `${this.getBaseUri(inspectionId)}?$count=true&${oDataQuery}`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<OvalityDataPointViewModel>>(response)!;
  }

  async update(model: OvalityDataPointViewModel): Promise<OvalityDataPointViewModel> {
    const url = `${this.getBaseUri()}(${model.id})`;
    const response = await this.client.put(url, model);
    return this.handleResponse<OvalityDataPointViewModel>(response) || model;
  }

  async delete(id: number): Promise<boolean> {
    const url = `${this.getBaseUri()}(${id})`;
    const response = await this.client.delete(url);
    return response.success;
  }

  async reset(id: number): Promise<OvalityDataPointViewModel> {
    const url = `${this.getBaseUri()}(${id})/Default.ResetCurrentValue`;
    const response = await this.client.post(url);
    return this.handleResponse<OvalityDataPointViewModel>(response)!;
  }

  async insert(inspectionId: number, data: OvalityInsertModel): Promise<boolean | undefined> {
    const url = `/Inspection/${inspectionId}/Ovality/Insert`;
    const response = await this.client.post(url, data);
    return this.handleResponse(response);
  }

  private getBaseUri(inspectionId?: number): string {
    if (inspectionId !== undefined && inspectionId != null) {
      return `/OData/Inspections(${inspectionId})/OvalityDataPointViews`;
    }
    return '/OData/OvalityDataPointViews';
  }
}

export default OvalityDataPointViewRepo;
