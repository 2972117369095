import { useCallback, useEffect, useState } from "react";
import { CalibrationModel, EquipmentCalibrationModel } from "../../../data/entities/CalibrationModel";
import { useOutletContext, useParams } from "react-router";
import { InspectionsPageContext } from "../InspectionsPage";
import {
  BlockButton,
  MultiLineTextWithValidation,
  SimpleCard,
  SpinnerDefault,
  Card,
  CardTitle,
  CardBody,
} from "../../../components";
import { InspectionService, UserService } from "../../../services";
import InspectionCalibrationImage from "./InspectionCalibrationImage";
import { Form } from "react-bootstrap";
import {
  Upload,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import { showErrorMessage, showSuccessMessage } from "../../../app/tools";

export function InspectionsCalibration() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Calibration");

  const { id } = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState<CalibrationModel>();
  const [preCalibrationImage, setPreCalibrationImage] = useState(<></>);
  const [postCalibrationImage, setPostCalibrationImage] = useState(<></>);
  const [equipmentCalibration, setEquipmentCalibration] = useState<
    string | null
  >();

  const getImages = useCallback(async () => {
    const inspectionService = new InspectionService();
    const loadedData = await inspectionService.getCalibrationData(Number(id));
    if (loadedData) {
      setData(loadedData);
    }
  }, [id]);

  const onStatusChange = useCallback(
    (event: UploadOnStatusChangeEvent) => {
      if (event.response?.status === 201) {
        getImages();
      }
    },
    [getImages]
  );

  const addCalibrationImageBtn = useCallback(
    (isPreCalibration: boolean) => (
      <Upload
        batch={false}
        multiple={false}
        defaultFiles={[]}
        withCredentials={false}
        autoUpload={true}
        saveHeaders={{
          Authorization: `Bearer ${UserService.getUserToken()}`,
        }}
        saveUrl={`${process.env.REACT_APP_API_URL}Inspection/${Number(
          id
        )}/AddCalibrationImages?isPreCalibration=${isPreCalibration}`}
        onStatusChange={onStatusChange}
      />
    ),
    [id, onStatusChange]
  );

  useEffect(() => {
    if (!data) return;

    setEquipmentCalibration(data.equipmentCalibrations);

    const preCalibrationImage = data.calibrationImages!.find(
      (i) => i.isPreCalibration === true
    );
    const postCalibrationImage = data.calibrationImages!.find(
      (i) => i.isPostCalibration === true
    );

    preCalibrationImage
      ? setPreCalibrationImage(
          <InspectionCalibrationImage
            onChange={getImages}
            image={preCalibrationImage}
          />
        )
      : setPreCalibrationImage(addCalibrationImageBtn(true));

    postCalibrationImage
      ? setPostCalibrationImage(
          <InspectionCalibrationImage
            onChange={getImages}
            image={postCalibrationImage}
          />
        )
      : setPostCalibrationImage(addCalibrationImageBtn(false));
  }, [addCalibrationImageBtn, data, getImages]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getImages();
      } catch (e) {
        console.error("Error loading calibration data", e);
      }
    };
    fetchData();
  }, [getImages]);

  const handleSubmit = async () => {
    setShowSpinner(true);
    try {
      const body: EquipmentCalibrationModel = {
        reference: data?.reference!,
        equipmentCalibrations: equipmentCalibration ?? "",
        id: Number(id),
      };
      const result = await new InspectionService().updateCalibrationData(
        Number(id),
        body
      );

      result
        ? showSuccessMessage(`Calibration data updated for inspection ${id}`)
        : showErrorMessage(
            `Error updating calibration data for inspection ${id}`
          );
    } catch (e) {
      console.error("Error saving calibration data", e);
    }
    setShowSpinner(false);
  };

  if (data === undefined) {
    return (
      <SimpleCard title="Update calibration settings">
        <SpinnerDefault />
      </SimpleCard>
    );
  }
  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Update calibration settings</h6>
      </CardTitle>
      <CardBody>
        <div className="row">
          <div className="col-12 col-lg-6 mb-5">
            <h6 className="text-center">Pre-Calibration Image</h6>
            {preCalibrationImage}
          </div>
          <div className="col-12 col-lg-6 mb-5">
            <h6 className="text-center">Post-Calibration Image</h6>
            {postCalibrationImage}
          </div>
        </div>
        <Form>
          <div className="row">
            <div className="col-12">
              <MultiLineTextWithValidation
                id="equipmentCalibration"
                label="Equipment Calibration"
                initialValue={data?.equipmentCalibrations}
                onChangeText={(value) => {
                  setEquipmentCalibration(value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <BlockButton
                label="Save"
                onClick={handleSubmit}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default InspectionsCalibration;

