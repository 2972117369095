import { createSlice } from "@reduxjs/toolkit";
import { AlertLevel } from "../../models";

interface AlertSlice {
  message: string | null;
  show: boolean;
  level: AlertLevel | null;
}

const initialState: AlertSlice = {
  message: null,
  show: false,
  level: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setInformation: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.level = AlertLevel.information;
    },
    setWarning: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.level = AlertLevel.warning;
    },
    setError: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.level = AlertLevel.error;
    },
    setSuccess: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.level = AlertLevel.success;
    },
    clearAlert: (state) => {
      state.message = null;
      state.show = false;
      state.level = null;
    },
  },
});

export const { setInformation, setWarning, setError, clearAlert, setSuccess } = alertSlice.actions;
export default alertSlice.reducer;
