import { routes } from "../../app/routes";
import { MenuSelectEvent } from "@progress/kendo-react-layout";
import {
  Breakpoints,
  ODataGrid,
  ResponsiveGridColumn,
  Submenu,
  TitlelessCard,
} from "../../components";
import LogService from "../../services/LogService";
import PageContent from "../PageContent";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { toYearMonthDayTime } from "../../app/dateHelpers";

export function LogPage() {
  const title: string = "Activity Logs";

  const submenus = [
    {
      label: "Activity Logs",
      url: routes.log,
      activeMatch: routes.log,
      activeMatchExact: true,
    },
  ];

  const dateCellBuilder = (props: GridCellProps) => {
    return <td>{toYearMonthDayTime(props.dataItem.createdDate)}</td>;
  };

  const getEventLogs = (oDataQuery: string) =>
    new LogService().getEventLogsOData(`${oDataQuery}`);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    // Do nothing
  };

  return (
    <PageContent
      footerless
      title={title}
      submenu={<Submenu items={submenus} />}
    >
      <TitlelessCard className="p-0" bodyClassName="p-0">
        <ODataGrid
          getData={getEventLogs}
          onRowMenuSelect={onRowMenuSelect}
          scrollable="scrollable"
          take={30}
          sort={[{ field: "createdDate", dir: "desc" }]}
          pageSize={30}
          style={{ height: "calc(95vh - 130px)" }}
        >
          <GridColumn
            className="column-date"
            field="createdDate"
            title="Date"
            sortable={true}
            filter="date"
            cell={dateCellBuilder}
          />
          <ResponsiveGridColumn
            className="column-type"
            field="eventType"
            title="Type"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.xxl}
          />
          <ResponsiveGridColumn
            className="column-level"
            field="logLevel"
            title="Level"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.xxl}
          />
          <ResponsiveGridColumn
            className="column-user"
            field="userName"
            title="User"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.lg}
          />
          <ResponsiveGridColumn
            className="column-message"
            field="message"
            title="Message"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.xs}
          />
        </ODataGrid>
      </TitlelessCard>
    </PageContent>
  );
}

export default LogPage;

