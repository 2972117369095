import MiniChartDataModel from "../entities/MiniChartDataModel";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";

export class MiniChartRepo extends BaseRepository {

  async getInspectionNoteMiniChartData(inspectionId: number, startPosition: number, endPosition: number): Promise<MiniChartDataModel[] | null> {
    const url = `OData/DashboardItems/Default.MiniChart`;
    const body = {
      "Id": inspectionId,
      "StartPosition": startPosition,
      "EndPosition": endPosition
    };

    const response = await this.client.post(url, body);
    const handledData = this.handleResponse<ODataResponse<MiniChartDataModel>>(response);
    return handledData?.value ?? null;
  }
}