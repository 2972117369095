import React, { useState } from "react";
import { Collapse as RbCollapse } from "react-bootstrap";
import "./Collapse.scss";
import { Icon, IconDefinitions } from "../icons";

export interface CollapseProps extends React.PropsWithChildren {
  text: string;
  textClassName?: string;
  startOpen?: boolean;
}

export function Collapse(props: CollapseProps) {
  const { text, textClassName } = props;
  const [open, setOpen] = useState(props.startOpen);

  return (
    <>
      <h4 className={`d-flex justify-content-between align-items-center px-3 mt-4 mb-0 ${textClassName}`}>
        <span>{text}</span>
        <span
          className="clickable collapse-icon"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
        >
          {open ? (
            <Icon iconName={IconDefinitions.angleUp} className="collapse-hide fa-fw"/>
          ) : (
            <Icon iconName={IconDefinitions.angleDown} className="collapse-hide fa-fw"/>
          )}
        </span>
      </h4>

      <RbCollapse in={open}>
        <div id="example-collapse-text">{props.children}</div>
      </RbCollapse>
    </>
  );
}

Collapse.defaultProps = {
  startOpen: false,
};

export default Collapse;
