import { useOutletContext, useParams } from "react-router-dom";
import { BlockButton, SimpleCard, TextWithValidation } from "../../../components";
import { useEffect, useState } from "react";
import { router, routes } from "../../../app/routes";
import ProcessService from "../../../services/ProcessService";
import { ProcessSplitModel } from "../../../data/entities";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsProcessSplit() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Split Segment");

  const params = useParams();
  const id = params["id"] ?? "";

  const defaultFormValue = "0";

  const [splitModel, setSplitModel] = useState<ProcessSplitModel | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  async function splitDataButtonClick() {

    if(splitModel === null) return;

    setShowSpinner(true);
    const ok = await new ProcessService().postProcessSplit(parseInt(id), splitModel);
    setShowSpinner(false);

    if(!ok) return;

    showSuccessMessage("Inspection data successfully split.");
    router.navigateWithParams(routes.inspectionsDataSegment, new Map([["id", id]]));
  }

  useEffect(() => {
    new ProcessService()
    .getProcessSplit(parseInt(id))
    .then((data) => {
      setSplitModel(data);
    })
    .catch((error) => {
      console.error("Failed to fetch process split data: ", error);
    });
  }, [id])

  const handleChange = (field: string, input: string) => {
    setSplitModel((prevState) => ({
      ...prevState as ProcessSplitModel,
      [field]: parseInt(input),
    }));
  }

  return (
    <>
    <SimpleCard className="mb-3 p-0" title="Create a reference point.">
      <div className="row">
        <div className="col-12">
              <p>This data process allows you to split a segment in two, specifying the exact data point number and what position (in metres) that data point is at. For example, if you know that data point 1234 has a spike that was at exactly 302m, you can split the data at that point to increase the accuracy of the "Positions" data processing</p>
              <p>After you split the data, you should always do the "Positions" data processing again to recalculate the estimated position of the data points on either side of the split.</p>
          <div className="row">
            <div className="col-12 col-md-6">
            <TextWithValidation
              label="Data Point Index"
              id=""
              required={false}
              initialValue={defaultFormValue}
              onChangeText={(input) => {
                handleChange("dataPointIndexByInspection", input);
              }}
              type="number"
            ></TextWithValidation>
            </div>
            <div className="col-12 col-md-6">
            <TextWithValidation
              label="Position (m)"
              id=""
              required={false}
              initialValue={defaultFormValue}
              onChangeText={(input) => {
                handleChange("position", input);
              }}
              type="number"
            ></TextWithValidation>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
              <BlockButton
                label="Split Data"
                onClick={splitDataButtonClick}
                disabled={false}
                showSpinner={showSpinner}
              ></BlockButton>
            </div>
          </div>
        </div>
      </div>
    </SimpleCard>
    <SimpleCard className="mb-3 p-0" title="Data splitting history.">
    <table className="table hover-row mt-3">
        <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
        {splitModel?.processes.map((processDataHistory) => (
            <tr className="clickable" key={getUuid()}>
              <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
              <td>{processDataHistory.description}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </SimpleCard>
    </>
  );
}

export default InspectionsProcessSplit;

