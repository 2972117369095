import { routes } from "../../app/routes";
import { Submenu, IconDefinitions, SubmenuNavigationItem } from "../../components";
import { AreaExport, InspectionService } from "../../services";
import { FileExportType } from "../../models";

interface InspectionSubmenuProps {
  id: string
};

export const InspectionSubmenu = (props: InspectionSubmenuProps) => {

  const downloadPdf = () => {
    const inspectionService = new InspectionService();
    const sources = inspectionService.getExportUrls(
      Number(props.id ?? "0"),
      AreaExport.inspection,
      `${props.id}`
    );

    const source = sources.find((s) => s.type === FileExportType.Report);
    try {
      inspectionService
        .exportFile(source!.url)
        .then((file) => {
          if (!file) return;
  
          const binaryData = [];
          binaryData.push(file.data);
          const blob = new Blob(binaryData, { type: source!.contentType });
          const objUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download =
            file.name ??
            `${props.id}.${source!.fileExtension}`;
          link.target = "_blank";
          link.href = objUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(objUrl);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const submenus: SubmenuNavigationItem[] = [
    {
      label: "Back",
      icon: IconDefinitions.arrowLeft,
      url: routes.inspections,
    },
    {
      label: "Details",
      url: "",
      params: new Map([["id", props.id]]),
      activeMatch: [
        routes.inspectionsDetailsBase,
        routes.inspectionsDetails,
        routes.inspectionsNote,
        routes.inspectionsInspectors,
        routes.inspectionsScale,
        routes.inspectionsCalibration,
        routes.inspectionsAccess,
      ],
      innerItems: [
        {
          label: "Summary",
          url: routes.inspectionsDetails,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Notes",
          url: routes.inspectionsNote,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Inspectors",
          url: routes.inspectionsInspectors,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Scale",
          url: routes.inspectionsScale,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Calibration",
          url: routes.inspectionsCalibration,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Access",
          url: routes.inspectionsAccess,
          params: new Map([["id", props.id]]),
        },
      ],
    },
    {
      label: "Data",
      url: "",
      activeMatch: routes.inspectionsData,
      activeMatchExact: false,
      innerItems: [
        {
          label: "Segments",
          url: routes.inspectionsDataSegment,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Data Points",
          url: routes.inspectionsDataPoint,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Ovality",
          url: routes.inspectionsDataOvality,
          params: new Map([["id", props.id]]),
        },
      ],
    },
    {
      label: "Process",
      url: "",
      activeMatch: routes.inspectionsProcess,
      activeMatchExact: false,
      innerItems: [
        {
          label: "Positions",
          url: routes.inspectionsProcessPositions,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Normalize",
          url: routes.inspectionsProcessNormalize,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Adjust",
          url: routes.inspectionsProcessAdjust,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Randomize",
          url: routes.inspectionsProcessRandomize,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Split",
          url: routes.inspectionsProcessSplit,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Merge",
          url: routes.inspectionsProcessMerge,
          params: new Map([["id", props.id]]),
        },
        {
          label: "FLW Baseline",
          url: routes.inspectionsProcessFlwBaseline,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Copy",
          url: routes.inspectionsProcessCopy,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Reset",
          url: routes.inspectionsProcessReset,
          params: new Map([["id", props.id]]),
        },
        {
          label: "Reverse",
          url: routes.inspectionsProcessReversePositions,
          params: new Map([["id", props.id]]),
        },
      ],
    },
    {
      label: "Reports",
      params: new Map([["id", props.id]]),
      url: "",
      innerItems: [
        {
          label: "Summary",
          params: new Map([["id", props.id]]),
          url: "#",
          clickHandler: downloadPdf
        },
      ],
    }
  ];

  return <Submenu items={submenus}></Submenu>;
};

export default InspectionSubmenu;
