import { useEffect, useState } from "react";
import {
  Dropdown,
  Option,
  MultiLineTextWithValidation,
  SlideOutModal,
  TextWithValidation,
} from "../../../../components";
import sharedProperty from "../../../../app/sharedProperty";
import { Button, FloatingLabel } from "react-bootstrap";
import {
  InspectionModel,
  NoteContextModelContext,
  NoteImageModel,
  NoteModel,
  NoteValueModel,
  UserModel,
} from "../../../../data/entities";
import { Severity, getSeverityDescriptor } from "../../../../models";
import { NoteService } from "../../../../services";
import InspectionNotesSubmenu from "./InspectionNotesSubmenu";

export interface ModalStatesModel {
  detailsState: sharedProperty<boolean>;
  imagesState: sharedProperty<boolean>;
  valuesState: sharedProperty<boolean>;

}

interface InspectionsNotesAddModalProps {
  modalState: sharedProperty<boolean>;
  noteContext: NoteContextModelContext;
  inspectionId: number;
  note?: NoteModel;
  onSaved?: (note: NoteModel) => void;
  modalStates?: ModalStatesModel;
}

class NoteModelFactory implements NoteModel {
  id?: number;
  createdDate: Date;
  startPosition: number;
  endPosition?: number;
  inspectionId?: number;
  userId?: number;
  description: string;
  severity?: string | null;
  inspection?: InspectionModel;
  user?: UserModel;
  images?: NoteImageModel[] | null;
  values?: NoteValueModel[] | null;

  constructor(inspectionId: number) {
    this.createdDate = new Date();
    this.startPosition = 0;
    this.endPosition = 0;
    this.description = "";
    this.severity = Severity.Comment;
    this.inspectionId = inspectionId;
  }
}

function InspectionsNotesModal(props: InspectionsNotesAddModalProps) {
  const { isReversed, direction, length } = props.noteContext;

  const [allowSave, setAllowSave] = useState(false);
  // const [noteState, setNoteState] = useState<NoteModel>();
  const [noteModel, setNoteModel] = useState<NoteModel>(
    props.note ?? new NoteModelFactory(props.inspectionId)
  );

  useEffect(() => {
    if (props.note) {
      setNoteModel(props.note);
    }
    else {
      setNoteModel(new NoteModelFactory(props.inspectionId));
    }
  }, [props.note]);

  const title = props.note ? "Details" : "Add Note";

  useEffect(() => {
    setAllowSave(noteModel.description.length > 0);
    return () => {};
  }, [noteModel]);

  const closeClickHandler = () => {
    props.modalState.setter(false);
  };
  const saveClickHandler = async () => {
    const noteService = new NoteService();
    if (!noteModel.id) {
      const newNote = await noteService.addNote(noteModel);
      if (newNote) {
        setNoteModel(new NoteModelFactory(props.inspectionId));
        props.onSaved?.(newNote);
      }
    } else {
      const saved = await noteService.editNote(noteModel);
      if (saved) props.onSaved?.(noteModel);
    }


    props.modalState.setter(false);
  };

  const commands = (
    <>
      <Button variant="secondary" onClick={closeClickHandler}>
        Close
      </Button>
      <Button
        variant="primary"
        onClick={saveClickHandler}
        disabled={!allowSave}
      >
        Save
      </Button>
    </>
  );

  const startValue = isReversed
    ? length - (noteModel?.endPosition ?? 0)
    : noteModel?.startPosition;
  const endValue = isReversed
    ? length - (noteModel?.startPosition ?? 0)
    : noteModel?.endPosition;
  const options = Object.keys(Severity).map((value, idx) => {
    return {
      value: idx.toString(),
      text: getSeverityDescriptor(value as Severity),
    } as Option;
  });

  return (
    <SlideOutModal 
      showState={props.modalState} 
      title={title} 
      footer={commands}
      submenu={props.note ?
        <InspectionNotesSubmenu
          initialTab={"Details"}
          onChangeTab={() => {
            console.log("change tab");
          }}
          modalStates={props.modalStates}
        />
        :
        <></>
      }>
      <div className="row bg-light p-1 mb-2">
        <div className="col-12">
          <strong className="text-sm">Inspected {direction}</strong>1
          <div className="text-sm distance-from-core">
            Distance from core:&nbsp;
            <span className="distance-from-core-start">{`${startValue}m`}</span>
            <span>{` - `}</span>
            <span className="distance-from-core-end">{`${endValue}m`}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FloatingLabel
            controlId={"cmbSeverity"}
            label={"Severity"}
            className="mb-3"
            aria-label={"Severity"}
            title={"Severity"}
          >
            <Dropdown
              id={"cmbSeverity"}
              label={"Severity"}
              options={options}
              selectedOption={Object.values(Severity).indexOf(
                noteModel.severity as Severity
              )}
              onChangeSelected={(value) => {
                const severity =
                  Object.values(Severity)[parseInt(value as string)];
                console.log(severity);
                setNoteModel((current) => ({
                  ...current,
                  severity: severity,
                }));
              }}
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <TextWithValidation
            autoFocus={true}
            id="txtStart"
            type="number"
            label="Start Position(m)"
            initialValue={noteModel.startPosition.toString()}
            onChangeText={(value) => {
              setNoteModel((current) => ({
                ...current,
                startPosition: parseInt(value),
              }));
            }}
          />
        </div>
        <div className="col-6">
          <TextWithValidation
            id="txtEnd"
            type="number"
            label="End Position(m)"
            initialValue={noteModel.endPosition?.toString()}
            onChangeText={(value) => {
              setNoteModel((current) => ({
                ...current,
                endPosition: parseInt(value),
              }));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <MultiLineTextWithValidation
            id="txtDescription"
            label="Description"
            initialValue={noteModel.description}
            required={true}
            onChangeText={(value) => {
              setNoteModel((current) => ({
                ...current,
                description: value,
              }));
            }}
          />
        </div>
      </div>
    </SlideOutModal>
  );
}

export default InspectionsNotesModal;
