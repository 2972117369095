import { Outlet, useOutletContext } from "react-router-dom";
import { InviteSidebar, UsersPageContext } from "./";
import { useEffect } from "react";

export function UserInvitePage() {
  const context: UsersPageContext = useOutletContext();

  useEffect(() => {
    context.setSidemenu(<InviteSidebar />);
  }, []);

  return (
    <Outlet />
  )
}

export default UserInvitePage
