import PageContent from "../PageContent";
import PageLayout from "../PageLayout";

export function ErrorPage() {
  return (
    <PageLayout>
      <PageContent>
        <h1>Ooops... An error!</h1>
      </PageContent>
    </PageLayout>
  );
}

export default ErrorPage;
