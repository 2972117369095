import ODataResponse from "../data/entities/ODataResponse";
import DataPointViewModel from "../data/entities/DataPointViewModel";
import DataPointViewRepo from "../data/repo/DataPointViewRepo";

export class DataPointViewService {
  async getAll(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<DataPointViewModel>> {
    const response = await new DataPointViewRepo().getDataPointViews(inspectionId, oDataQuery);
    return response;
  }

  async get(id: string): Promise<DataPointViewModel> {
    const response = await new DataPointViewRepo().getDetails(id);
    return response;
  }

  async update(model: DataPointViewModel): Promise<DataPointViewModel> {
    const updated = await new DataPointViewRepo().update(model);
    return updated;
  }

  async delete(id: number): Promise<boolean> {
    const deleted = await new DataPointViewRepo().delete(id);
    return deleted;
  }

  async reset(id: number): Promise<DataPointViewModel> {
    const resetItem = await new DataPointViewRepo().reset(id);
    return resetItem;
  }

  async getList(inspectionId?: number): Promise<DataPointViewModel[]> {
    const oDataQuery = await this.getAll(inspectionId);
    if (!oDataQuery) return [];
    const count = oDataQuery["@odata.count"] ?? 0;
    return count > 0 ? oDataQuery.value : [];
  }
}

export default DataPointViewService;
