import { useState, useEffect } from 'react'
import { Card, CardTitle, CardBody, TextWithValidation, SpinnerDefault } from '../../components'
import { Form } from 'react-bootstrap'
import { SettingsService } from '../../services/SettingsService'
import { PageContent, PageBreadcrumbProps } from '../PageContent'
import { SettingsSubmenu } from '.'
import SettingsModel from '../../data/entities/SettingsModel'

export function SettingsGeneralCard() {

const title: string = "Settings";
const breadcrumbs: PageBreadcrumbProps = {
  showHome: true,
  values: [{ label: title }]
};
  
const [pageData, setPageData] = useState<SettingsModel | undefined | null>(undefined);

  /**
  * Fetches the page data when the component mounts.
  */
  useEffect(() => {
    async function fetchData() {
      try {
        const loadedData = await new SettingsService().getAllAsync();
        setPageData(loadedData);
      }
      catch (e) {
        console.error("Error fetching settings: ", e);
      }
    }
    fetchData();
  }, []);


  if (pageData === undefined) {
    return (
      <PageContent title={title} breadcrumbs={breadcrumbs} submenu={<SettingsSubmenu />}>
        <div className="position-relative">
          <SpinnerDefault />
        </div>
      </PageContent>
    )
  }

  return (
    <PageContent title={title} breadcrumbs={breadcrumbs} submenu={<SettingsSubmenu />}>
      <Card>
        <CardTitle><h6 className="text-light m-0">Site information.</h6></CardTitle>
        <CardBody>
          <Form>
            <div className="row mt-2">
              <div className="col-12 col-lg-6 mb-2">
                <TextWithValidation
                  id="company"
                  label="Site Name"
                  required={true}
                  disabled={true}
                  initialValue={pageData?.siteInfo.name ?? ''}

                />
              </div>
              <div className="col-12 col-lg-6">
                <TextWithValidation
                  id="company"
                  label="Site Name"
                  required={true}
                  disabled={true}
                  initialValue={pageData?.siteInfo.company ?? ''}
                />
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </PageContent>
  )
}

export default SettingsGeneralCard
