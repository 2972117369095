import LoggedUser from "../../models/LoggedUser";
import { AddressModel, CompanyAddModel } from "../entities";
import CompanyModel from "../entities/CompanyModel";
import ODataResponse from "../entities/ODataResponse";
import ProvinceModel from "../entities/ProvinceModel";
import BaseRepository from "./BaseRepository";

export class CompanyRepo extends BaseRepository {

  async getDetails(id: number) {
    const url = `Company/${id}`

    const response = await this.client.get(url);
    return this.handleResponse<CompanyModel>(response);
  }

  async getAddress(id: number) {
    const url = `Company/${id}/Address`

    const response = await this.client.get(url);
    return this.handleResponse<CompanyModel>(response);
  }

  async getCompanies(): Promise<ODataResponse<CompanyModel>> {
    const url = "/OData/Companies";

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async getUsers(id: number): Promise<ODataResponse<LoggedUser>> {
    const url = `/OData/Companies(${id})/Users`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async getCompaniesOData(oDataQuery: string = ""): Promise<ODataResponse<CompanyModel>> {
    const url = `/OData/Companies/?$count=true&${oDataQuery}`;

    const response = await this.client.get(url);
    return this.handleResponse(response)!;
  }

  async updateAddress(id: number, name: string, address: AddressModel): Promise<boolean | undefined> {
    const url = `Company/${id}/Address`;
    const body = {
      id: id,
      name: name,
      address: address
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async addCompany(model: CompanyAddModel): Promise<number | null> {
    const url = `Company/Insert`;
    const body = {
        name: model.name,
        address: {
          streetAddress: model?.streetAddress,
          streetAddress2: model?.streetAddress2,
          city: model?.city,
          postalCode: model?.postalCode,
          provinceId: model?.provinceId,
        }
    };
      const response = await this.client.post<number>(url, body);
      const handled = this.handleResponse(response);
      return handled;
  }

  async updateCompany(id: number, model: CompanyModel): Promise<boolean | undefined> {
    const url = `Company/${id}`;
    const body = {
        id: id,
        name: model.name,
        defaultMeasurement: model.defaultMeasurement
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async deleteCompany(id: number): Promise<boolean | undefined> {
    const url = `Company/Delete?id=${id}`;

    const response = await this.client.post(url);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getAllProvinces() {
    const url = `Company/Regions`

    const response = await this.client.get(url);
    return this.handleResponse<ProvinceModel>(response);
  }

  async getTubings(id: number) {
    const url = `Company/${id}/Tubings`

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }
}

export default CompanyRepo;
