import SegmentModel from "../entities/SegmentModel";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";
import SegmentInsertModel from "../entities/SegmentInsertModel";
import SegmentInsertEmptyModel from "../entities/SegmentInsertEmptyModel";

export class SegmentRepo extends BaseRepository {
  async getDetails(id: string, oDataQuery: string = ""): Promise<SegmentModel> {
    const url = `${this.getBaseUri()}(${id})`;
    const response = await this.client.get(url);
    return this.handleResponse<SegmentModel>(response)!;
  }

  async getSegments(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<SegmentModel>> {
    const url = `${this.getBaseUri(inspectionId)}?$count=true&${oDataQuery}`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<SegmentModel>>(response)!;
  }

  async addSegment(inspectionId: number, data: SegmentInsertModel): Promise<boolean | undefined> {
    const url = `/Inspection/${inspectionId}/Segment/Insert`;
    const response = await this.client.post(url, data);
    return this.handleResponse(response);
  }

  async addEmptySegment(inspectionId: number, data: SegmentInsertEmptyModel): Promise<boolean | undefined> {
    const url = `/Inspection/${inspectionId}/Segment/InsertEmpty`;
    const response = await this.client.post(url, data);
    return this.handleResponse(response);
  }

  async delete(inspectionId: number, segmentId: number): Promise<boolean> {
    const url = `/Inspection/${inspectionId}/Segment/${segmentId}/Delete`;
    const response = await this.client.post(url);
    return response.success;
  }

  private getBaseUri(inspectionId?: number): string {
    if (inspectionId !== undefined && inspectionId != null) {
      return `/OData/Inspections(${inspectionId})/Segments`;
    }
    return '/OData/Segments';
  }
}

export default SegmentRepo;
