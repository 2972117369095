import ProvinceModel from "../entities/ProvinceModel";
import BaseRepository from "./BaseRepository";

export class AddressRepo extends BaseRepository {

  async getAllProvinces() {
    const url = `Address/Regions`

    const response = await this.client.get(url);
    return this.handleResponse<ProvinceModel[]>(response);
  }
}

export default AddressRepo;
