import BaseRepository from "./BaseRepository";

export class ReportRepo extends BaseRepository {

  async cacheReport(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/report/Cache`;
    const response = await this.client.post(url);
    this.handleResponse(response);
    return response.success;
  }
}

export default ReportRepo;