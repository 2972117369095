import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  EditInspectionModel,
  NoteContextModelContext,
  NoteModel,
} from "../../../data/entities";
import { useSelector } from "react-redux";
import { InspectionService } from "../../../services";
import { IconAndText, SpinnerDefault } from "../../../components";
import InspectionsNotesEditCard from "./components/InspectionsNotesEditCard";
import { Button } from "react-bootstrap";
import InspectionsNotesModal from "./components/InspectionsNotesModal";
import { useSharedState } from "../../../app/sharedProperty";
import InspectionsNotesReverseModal from "./components/InspectionsNotesReverseModal";
import { InspectionsPageContext } from "../InspectionsPage";
import { useOutletContext } from "react-router-dom";

interface CardNavigationRef {
  noteId: number;
  ref: MutableRefObject<HTMLDivElement>;
}

export function InspectionsNotes() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Notes");

  const inspection = useSelector(
    (state: any) => state.inspectionManagement.inspection as EditInspectionModel
  );

  const [noteContext, setNoteContext] = useState<NoteContextModelContext>({} as NoteContextModelContext);
  const [notes, setNotes] = useState<NoteModel[] | null>(null);
  const [cardRef, setCardRef] = useState<CardNavigationRef>();
  const addModalState = useSharedState(false);
  const reverseModalState = useSharedState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const fetchNotes = useCallback(async () => {
    const data = await new InspectionService().getNotesWithContext(
      inspection.id
    );
    const notes = [...(data?.notes ?? [])]
      .sort((a, b) => a.startPosition - b.startPosition);

    if (data?.noteContext) {
      setNoteContext(data?.noteContext);
    }
    setNotes(notes);
  }, [inspection.id]);

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [fetchNotes]);

  const applyRef = (currentId: number) => {
    if (currentId === cardRef?.noteId) {

      setTimeout(() => {
        const element = document.getElementById(`note-${cardRef?.noteId}`);
        element?.scrollIntoView();

        if (cardRef?.ref?.current && (cardRef?.ref?.current as any).show) {
          const curr = cardRef.ref.current as any;
          curr.show();

          setCardRef(undefined);
        }
      }, 500);

      return cardRef?.ref;
    }
    return undefined;
  };

  // Define how to show the result of the API call
  const notesCards =
    notes == null ? (
      <em>There are no notes for this inspection.</em>
    ) : (
      <>
        {notes?.map((note) => (
          <InspectionsNotesEditCard
            ref={applyRef(note.id!)}
            key={note.id}
            note={note}
            onChanged={() => fetchNotes()}
            noteContext={noteContext}
          />
        ))}
      </>
    );

  // Define the event handlers for the default buttons
  const addNoteClickHandler = () => addModalState.setter(true);
  const reversePositionsClickHandler = () => reverseModalState.setter(true);
  const noteAddedHandler = async (note: NoteModel) => {
    // instead of refetching the notes, we can just add the new note to the list
    const newNotes = notes ? [...notes, note] : [note];
    setNotes(newNotes);

    const refObj = React.createRef<HTMLDivElement>();
    const newRef = {
      noteId: note.id!,
      ref: refObj,
    } as CardNavigationRef;
    setCardRef(newRef);
  };

  return (
    <>
      <SpinnerDefault fullscreen={true} show={showSpinner} />
      <div className="row">
        <div className="col-12 p-3 d-flex gap-3">
          <Button className="" variant="primary" onClick={addNoteClickHandler}>
            <IconAndText iconName={"add"} text="Add Note" />
          </Button>
          <Button
            className="btn"
            variant="secondary"
            onClick={reversePositionsClickHandler}
          >
            <IconAndText iconName={"add"} text="Reverse Positions" />
          </Button>
        </div>
      </div>
      {notesCards}
      <InspectionsNotesModal
        modalState={addModalState}
        noteContext={noteContext}
        inspectionId={inspection.id}
        note={undefined}
        onSaved={noteAddedHandler}
      />
      <InspectionsNotesReverseModal modalState={reverseModalState} inspectionId={inspection.id} onSubmit={fetchNotes} setShowSpinner={setShowSpinner}/>
    </>
  );
}

export default InspectionsNotes;


