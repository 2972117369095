import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "./";

interface IconProps {
  iconName: string;
  className?: string;
  onClick?: () => void;
}

export function Icon(props: IconProps) {
  const { iconName } = props;
  const iconObject = getFontAwesomeIcon(iconName);
  return (
    <FontAwesomeIcon
      icon={iconObject}
      className={`fa fad fa-fw me-1 ${props.className}`}
      onClick={props.onClick}
    />
  );
}

export default Icon;

