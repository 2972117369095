import { router, routes } from "../../app/routes";
import { isNumber, toTitleCase } from "../../app/tools";
import { EditInspectionModel } from "../../data/entities";
import { PageBreadcrumbProps } from "../PageContent";

export const GetInspectionBreadcrumbs = (
  location: any,
  inspection: EditInspectionModel | undefined | null
): PageBreadcrumbProps => {
  const breadcrumbs: PageBreadcrumbProps = {
    values: [{ label: "Inspections", url: routes.inspections }],
  }

  const currentArea = location.pathname.split("/").pop()!;
  if (isNumber(currentArea) || currentArea === "details") {
    // Just the inspection root
    breadcrumbs.values.push({
      label: inspection?.reference ?? "--",
      url: null,
    });
  } else {
    const id = inspection?.id ?? 0;

    // Make the inspection id a link
    breadcrumbs.values.push({
      label: inspection?.reference ?? "--",
      url: router.getUrl(
        routes.inspectionsDetails,
        new Map([["id", `${id}`]])
      ),
    });

    // The last page name
    breadcrumbs.values.push({
      label: toTitleCase(currentArea) ?? "--",
      url: null,
    });
  }

  return breadcrumbs;
};

export default GetInspectionBreadcrumbs;
