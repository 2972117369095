import { useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import sharedProperty from "../../../../app/sharedProperty";
import { HeadingBar, SlideOutModal, SlideOutModalProps } from "../../../../components";
import { OvalityDataPointViewModel } from "../../../../data/entities";
import "../../../../components/form/FormStyle.scss";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { propsFilter } from "../../../../app/tools";
import * as yup from 'yup';
import { Formik } from 'formik';

export interface OvalityFormProps extends SlideOutModalProps {
  dataItemState: sharedProperty<OvalityDataPointViewModel>;
  onSubmit: (data: OvalityDataPointViewModel) => void;
}

export function OvalityForm(props: Readonly<OvalityFormProps>) {
  
  const schema = yup.object().shape({
    odMin: yup.number().required(),
    odMax: yup.number().required(),
    odAvg: yup.number().required(),
    ovality: yup.number().required(),
  });

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const patchDataItem = (patch: Object) => {
    props.dataItemState.setter({ ...props.dataItemState.value, ...patch });
    console.debug("Patched data item: ", props.dataItemState.value)
  }

  propsFilter(props, ["title", "footer", "onShow"]);

  return (
    <SlideOutModal {...props}
      title={`Edit Data Point ${props.dataItemState.value.dataPointIndexByInspection}`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Save
            </Button>
          </div>
        </div>
      </div>}
    >
      <HeadingBar h={5} title={`Position: ${props.dataItemState.value.startPosition}m`} />
      <Formik
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit.call(null, props.dataItemState.value);
          setSubmitting(false);
        }}
        initialValues={{
          odMin: props.dataItemState.value.diameterMinimum,
          odMax: props.dataItemState.value.diameterMaximum,
          odAvg: props.dataItemState.value.diameterAverage,
          ovality: props.dataItemState.value.ovalityPercent,
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => {

          // Custom change handler that also patches data item state
          const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;

            handleChange(e);

            const patch: Partial<OvalityDataPointViewModel> = {};
            const floatValue = parseFloat(value);
            switch (name) {
              case "odMin":
                patch.diameterMinimum = floatValue;
                break;
              case "odMax":
                patch.diameterMaximum = floatValue;
                
                break;
              case "odAvg":
                patch.diameterAverage = floatValue;
                break;
              case "ovality":
                patch.ovalityPercent = floatValue;
                break;
              default:
                break;
            }

            patchDataItem(patch);
          };

          return (
            <Form 
              ref={formRef} 
              noValidate 
              onSubmit={handleSubmit}
            >
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="OD Min" className="mb-3">
                    <Form.Control
                      autoFocus={true}
                      type="number"
                      name="odMin"
                      placeholder="OD Min"
                      value={values.odMin}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.odMin}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.odMin}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="OD Max" className="mb-3">
                    <Form.Control
                      type="number"
                      name="odMax"
                      placeholder="OD Max"
                      value={values.odMax}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.odMax}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.odMax}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                    <InputGroup hasValidation>
                      <FloatingLabel label="OD Avg" className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="OD Avg"
                          name="odAvg"
                          value={values.odAvg}
                          onChange={handleCustomChange}
                          isInvalid={!!errors.odAvg}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.odAvg}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="Ovality" className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="Ovality"
                      name="ovality"
                      value={values.ovality}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.ovality}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ovality}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              
              <Button ref={formSubmitRef} type="submit" className="d-none" />
            </Form>
          )
        }}
      </Formik>
      {props.children}
    </SlideOutModal>
  );
}

export default OvalityForm;

