import { routes, router } from "../../app/routes";
import { useParams } from "react-router-dom";
import SubmenuSideBar from "../../components/sideBar/SubmenuSideBar";
import { TubingService, UrlService } from "../../services";
import { Button } from "react-bootstrap";
import { useSharedState } from "../../app/sharedProperty";
import { SlideOutModal } from "../../components/modals/SlideOutModal";
import { showSuccessMessage } from "../../app/tools";

export function TubingSidebar() {

  const urlParams = useParams();
  const tubingId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await deleteTubing(tubingId);
    console.log(ok);
    if(!ok){return}
    else{
      showSuccessMessage(`Tubing deleted`);
    };
    router.navigate(routes.tubings).catch((error) => {});
  };

  async function deleteTubing(id: number) {
    await new TubingService().deleteTubing(id);
    return true;
  }


  const menuItems = [
    {
      text: 'Details',
      url: router.getUrl(routes.tubingDetails, new Map([
        ['Id', tubingId.toString()]
      ])),
      activeMatchUrl: routes.tubingDetails,
      additionalClass: "submenu-sidebar-nav-item",
      isTubingsMenu: true
    },
    {
      text: 'Inspections',
      url: router.getUrl(routes.tubingInspections, new Map([
        ['Id', tubingId.toString()]
      ])),
      activeMatchUrl: routes.tubingInspections,
      additionalClass: "submenu-sidebar-nav-item",
      isTubingsMenu: true
    },
    {
      text: 'Delete',
      url: "",
      additionalClass: "submenu-sidebar-nav-item",
      clickHandler: () => showSelectModalState.setter(true),
    }
  ];

  return (
    <>
      <SubmenuSideBar menuItems={menuItems} />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Tubing"}
        footer={<>
          <Button onClick={selectModalCancelClick} variant="primary" className="w-100">
            Close
          </Button>
          <Button onClick={deleteSelectClick} variant="secondary" className="w-100">
            Delete
          </Button>
        </>}
      >
        Are you sure you want to delete this tubing?
      </SlideOutModal>
    </>
  );
}

export default TubingSidebar;
