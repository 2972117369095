import * as React from "react";
import { ContextMenu, MenuSelectEvent } from "@progress/kendo-react-layout";
import { IconDefinitions, Icon } from "../icons";

export interface GridRowContextMenuCellProps {
  onClick?: React.MouseEventHandler
  menuRef: any,
  dataItem: any
}

export const GridRowContextMenuCell = (props: GridRowContextMenuCellProps) => {
  const onClickDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    props.menuRef.current.open(
      e.pageX,
      e.pageY,
      props.dataItem
    );
  };

  return (
    <td className="p-0" style={{ verticalAlign: "middle", textAlign: "center" }}>
      <div onClick={props.onClick ?? onClickDefault} className="cursor-pointer px-1 py-2">
        <Icon iconName={IconDefinitions.rowMenu}></Icon>
      </div>
    </td>
  );
}

export type GridRowContextMenuSelectFunction = (event: MenuSelectEvent, dataItem: any) => void

export interface GridRowContextMenuProps extends React.PropsWithChildren {
  children: any,
  onSelect: GridRowContextMenuSelectFunction
}

export const GridRowContextMenu = React.forwardRef((props: GridRowContextMenuProps, ref: any) => {
  const [show, setShow] = React.useState(false);
  const [dataItem, setDataItem] = React.useState<any>();

  const offset = React.useRef({
    left: 50,
    top: 50,
  });

  const onClose = () => {
    setShow(false);
  };

  const onSelect = (e: MenuSelectEvent) => {
    props.onSelect(e, dataItem!);
    setShow(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(left: number, top: number, dataItem: any) {
      offset.current = {
        left: left,
        top: top
      };

      setDataItem(dataItem);
      setShow(true);
    }
  }));

  return (
    <ContextMenu
      show={show}
      offset={offset.current}
      onSelect={onSelect}
      onClose={onClose} 
    >
      {props.children}
    </ContextMenu>
  );
});

export default GridRowContextMenu;
