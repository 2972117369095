import React, { useEffect, useState } from "react";
import Submenu from "../../components/submenu/Submenu";
import { routes } from "../../app/routes";
import { useParams } from "react-router-dom";
import UrlService from "../../services/UrlService";
import { HeadingBar, SimpleCard, SpinnerDefault } from "../../components";
import { InspectionOvalityViewModel } from "../../data/entities";
import { AreaExport, InspectionService } from "../../services";
import FileExporter from "./FileExporter";
import PageContent from "../PageContent";
import { Alert } from "react-bootstrap";
import ODChart from "./ODChart";

export function MyInspectionODPage() {
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);
  const params = new Map<string, string>();
  params.set("id", id ?? "");

  const submenus = [
    { label: "Back", url: routes.myInspections, icon: "arrow-left" },
    {
      label: "Details",
      url: routes.myInspectionsDetails,
      params,
      activeMatch: routes.myInspectionsDetails,
    },
    {
      label: "Ovality",
      url: routes.myInspectionsOvality,
      params,
      activeMatch: routes.myInspectionsOvality,
    },
    {
      label: "OD",
      url: routes.myInspectionsOD,
      params,
      activeMatch: routes.myInspectionsOD,
    },
  ];

  ////////////////////////////////////////////////////////
  const [pageData, setPageData] = useState<
    InspectionOvalityViewModel | null | undefined
  >(undefined);

  useEffect(() => {
    const numberId = Number.parseInt(id ?? "0");

    const fetchData = async () => {
      const loadedData = await new InspectionService().getInspectionOvality(
        numberId
      );

      setPageData(loadedData);
    };

    fetchData().catch((error) => {
      console.error("Error fetching Ovality data.", error);
      setPageData(null);
    });

    return () => {};
  }, [id]);

  const alertMessage = pageData?.uiMessage ? (
    <Alert variant="warning" dismissible={false}>
      <p className="mb-0">{pageData?.uiMessage}</p>
    </Alert>
  ) : (
    <></>
  );

  let content: React.JSX.Element;

  if (pageData === undefined) {
    content = <SpinnerDefault />;
  } else if (pageData === null) {
    content = (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>No Ovality data to show.</em>
        </div>
      </SimpleCard>
    );
  } else {
    content = (
      <>
        {alertMessage}
        <HeadingBar className="mt-1 d-flex justify-content-start" title="Outside Diameter With Threshold" />
        <SimpleCard title={`${pageData?.tubing} - ${pageData?.reference}`}>
          <ODChart chart={pageData?.chart} threshold={pageData.ovalityThreshold} odLow={pageData.odLow} odHigh={pageData.odHigh}/>
          <div className="row">
            <div className="col-6 text-start text-sm text-soft">core</div>
            <div className="col-6 text-end text-sm text-soft">tail</div>
          </div>
          <FileExporter
            type={AreaExport.ovality}
            id={pageData.id}
            pdfFileName={`${pageData.tubing}-${new Date(pageData?.inspectionDate)
              .toISOString()
              .slice(0, 10)}`}
          />
        </SimpleCard>
      </>
    );
  }
  return (
    <PageContent submenu={<Submenu items={submenus}></Submenu>}>
      {content}
    </PageContent>
  );
}

export default MyInspectionODPage;
