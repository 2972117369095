import { useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { InspectionsPageContext } from "../InspectionsPage";
import { useSharedState } from "../../../app/sharedProperty";
import { DataPointViewModel } from "../../../data/entities";
import { ConfirmationModal, Icon, IconDefinitions, ODataGrid, SpinnerDefault, TitlelessCard } from "../../../components";
import { DataPointViewService } from "../../../services";
import EditDataPointViewModal from "./components/EditDataPointViewModal";

export function InspectionsDataPoints() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Data Points");

  const { id } = useParams();
  const inspectionId = Number(id);
  const gridData = useSharedState<Array<DataPointViewModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const editItemState = useSharedState<DataPointViewModel>({} as DataPointViewModel);
  const showEditState = useSharedState(false);

  const resetItemState = useSharedState<DataPointViewModel>({} as DataPointViewModel);
  const showResetState = useSharedState(false);

  const pointService = new DataPointViewService();

  const getDataPointViews = async (oDataQuery: string) =>
    await pointService.getAll(inspectionId, oDataQuery);

  const onRowMenuSelect = async (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "edit":
        showEdit(dataItem);
        break;
      case "reset":
        showReset(dataItem);
        break;
    }
  };

  const showEdit = (dataItem: DataPointViewModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  };

  const showReset = (dataItem: DataPointViewModel) => {
    console.debug('Showing reset modal for data item', dataItem);
    resetItemState.setter(dataItem);
    showResetState.setter(true);
  };

  const update = async (newItem: DataPointViewModel) => {
    setShowSpinner(true);
    try {
      showEditState.setter(false);
      let updatedItem = await pointService.update(newItem);
      gridData.setter((c) => c.map(i => i.id === updatedItem.id ? updatedItem : i));
    } catch (err) {
      throw new Error(`Unable to edit data point: ${err}`);
    } finally {
      setShowSpinner(false);
    }
  };

  const reset = async (dataItem: DataPointViewModel) => {
    setShowSpinner(true);
    try {
      showEditState.setter(false);
      let resetItem = await pointService.reset(dataItem.id);
      gridData.setter((c) => c.map(i => i.id === resetItem.id ? resetItem : i));
    } catch (err) {
      throw new Error(`Unable to reset data point: ${err}`);
    } finally {
      setShowSpinner(false);
    }
  };

  const IndexCell = (props: GridCellProps) =>
    <td valign="middle" style={{lineHeight:1}}>
      {props.dataItem.dataPointIndexByInspection}
      {!props.dataItem.normalizedDate ? <></> :
        <Icon onClick={() => showReset(props.dataItem)} iconName={IconDefinitions.normalize} className="float-right" />
      }
      {!props.dataItem.manuallyAdjustedDate ? <></> :
        <Icon onClick={() => showReset(props.dataItem)} iconName={IconDefinitions.edit} className="float-right" />
      }
    </td>;

  return (
    <>
      <TitlelessCard className="p-0 m-0" bodyClassName="p-0">
        <SpinnerDefault show={showSpinner} fullscreen={true} />
        <ODataGrid
          ref={gridRef}
          fullHeight={true}
          getData={getDataPointViews}
          dataState={gridData}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={(e) => showEdit(e.dataItem)}
          sort={[{ field: "dataPointIndexByInspection", dir: "asc" }]}
          scrollable="scrollable"
          pageSize={100}
        >
          <GridColumn title="Index" field="dataPointIndexByInspection" cell={IndexCell} filter="numeric" />
          <GridColumn title="Pos" field="startPosition" filter="numeric" />
          <GridColumn title="FLW" field="flw" filter="numeric" />
          <GridColumn title="Flaw" field="flaw" filter="numeric" />
          <GridColumn title="Wall1" field="wall1" filter="numeric" />
          <GridColumn title="Wall2" field="wall2" filter="numeric" />
          <MenuItem text="Edit" data={{ action: "edit" }} icon={IconDefinitions.edit} />
          <MenuItem text="Reset" data={{ action: "reset" }} icon={IconDefinitions.refresh} />
        </ODataGrid>
      </TitlelessCard>
      <EditDataPointViewModal
        showState={showEditState}
        dataItemState={editItemState}
        onSubmit={update}
      />
      <ConfirmationModal
        onConfirm={() => reset(resetItemState.value)}
        showState={showResetState}
        title={`Reset point #${resetItemState.value.dataPointIndexByInspection}`}
        message={`You are about to reset data point #${resetItemState.value.dataPointIndexByInspection} to its original values. Do you want to continue?`}
        confirmText="Reset"
      />
    </>
  );
}

export default InspectionsDataPoints;

