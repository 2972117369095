import ODataResponse from "../data/entities/ODataResponse";
import SegmentInsertModel from "../data/entities/SegmentInsertModel";
import SegmentModel from "../data/entities/SegmentModel";
import SegmentRepo from "../data/repo/SegmentRepo";

export class SegmentService {
  async getAllSegments(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<SegmentModel>> {
    const response = await new SegmentRepo().getSegments(inspectionId, oDataQuery);
    return response;
  }

  async getSegmentDetails(id: string): Promise<SegmentModel> {
    const response = await new SegmentRepo().getDetails(id);
    return response;
  }

  async addSegment(inspectionId: number, data: SegmentInsertModel): Promise<boolean | undefined> {
    return await new SegmentRepo().addSegment(inspectionId, data);
  }

  async addEmptySegment(inspectionId: number, length: number): Promise<boolean | undefined> {
    return await new SegmentRepo().addEmptySegment(inspectionId, {
      length: length
    });
  }

  async deleteSegment(inspectionId: number, segmentId: number): Promise<boolean> {
    const deleted = await new SegmentRepo().delete(inspectionId, segmentId);
    return deleted;
  }

  async getSegmentsList(inspectionId?: number): Promise<SegmentModel[]> {
    const oDataQuery = await this.getAllSegments(inspectionId);
    if (!oDataQuery) return [];
    const count = oDataQuery["@odata.count"] ?? 0;
    return count > 0 ? oDataQuery.value : [];
  }
}

export default SegmentService;
