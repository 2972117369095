import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import BlockButton from "../../components/form/buttons/BlockButton";
import UserService from "../../services/UserService";
import { router, routes } from "../../app/routes";
import TextWithValidation from "../../components/form/input/TextWithValidation";

export function RegistrationRequest() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFirsNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isCompanyValid, setIsCompanyValid] = useState(false);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  async function register() {
    setShowSpinner(true);
    const ok = await new UserService().register(
      email,
      firstName,
      lastName,
      company
    );
    setShowSpinner(false);

    if (!ok) return;
    router.navigate(routes.requestAccessConfirmation);
  }

  enum fields {
    email,
    firstName,
    lastName,
    company,
  }

  const validateField = (field: fields, input: string, result: boolean) => {
    switch (field) {
      case fields.email:
        setEmail(input);
        setIsEmailValid(result);
        break;
      case fields.firstName:
        setFirstName(input);
        setIsFirstNameValid(result);
        break;
      case fields.lastName:
        setLastName(input);
        setIsLastNameValid(result);
        break;
      case fields.company:
        setCompany(input);
        setIsCompanyValid(result);
        break;
    }
  };

  useEffect(() => {
    setButtonEnabled(
      isEmailValid && isFirsNameValid && isLastNameValid && isCompanyValid
    );
  }, [isEmailValid, isFirsNameValid, isLastNameValid, isCompanyValid]);

  return (
    <>
      <h2>Request Access</h2>
      <section>
        <h4 className="mb-4">Request a new account.</h4>
        <Form>
          <EmailWithValidation
            id="email"
            validationResult={(input, result) => {
              validateField(fields.email, input, result);
            }}
          ></EmailWithValidation>
          <Row>
            <Col>
              <TextWithValidation
                id="firstName"
                label="First Name"
                required={true}
                validationResult={(input, result) => {
                  validateField(fields.firstName, input, result);
                }}
              ></TextWithValidation>
            </Col>
            <Col>
              <TextWithValidation
                id="lastName"
                label="Last Name"
                required={true}
                validationResult={(input, result) => {
                  validateField(fields.lastName, input, result);
                }}
              ></TextWithValidation>
            </Col>
          </Row>
          <TextWithValidation
            id="company"
            label="Company"
            required={true}
            validationResult={(input, result) => {
              validateField(fields.company, input, result);
            }}
          ></TextWithValidation>
          <BlockButton
            label="Register"
            onClick={register}
            disabled={!isButtonEnabled}
            showSpinner={showSpinner}
          ></BlockButton>
        </Form>
      </section>
    </>
  );
}

export default RegistrationRequest;