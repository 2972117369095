import { createSlice } from "@reduxjs/toolkit";
import InspectionDetails from "../../data/entities/InspectionDetailsModel";
import { InspectionOvalityViewModel } from "../../data/entities";

interface ActiveInspectionSlice {
  inspectionId: number | undefined;
  inspectionCompareToId: number | undefined;
  details: InspectionDetails | undefined | null;
  ovality: InspectionOvalityViewModel | null;
}

const initialState: ActiveInspectionSlice = {
  inspectionId: undefined,
  inspectionCompareToId: undefined,
  details: undefined,
  ovality: null,
};


const activeInspectionSlice = createSlice({
  name: "activeInspection",
  initialState,
  reducers: {
    setActiveInspectionId: (state, action) => {
      state.inspectionId = Number.parseInt(action.payload);
    },

    setActiveInspectionDetails: (state, action) => {
      state.details = action.payload == null ? null : { ...action.payload };
    },

    setActiveInspectionToCompareId: (state, action) => {
      state.inspectionCompareToId = action.payload == null
        || action.payload === undefined ? undefined : Number.parseInt(action.payload);
    },

    setActiveInspectionOvality: (state, action) => { 
      state.ovality = action.payload;
    },
  },
});

export const {
  setActiveInspectionId,
  setActiveInspectionDetails,
  setActiveInspectionToCompareId,
  setActiveInspectionOvality,
} = activeInspectionSlice.actions;
export default activeInspectionSlice.reducer;