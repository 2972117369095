import { useParams } from "react-router-dom";
import { routes, router } from "../../app/routes";
import { SlideOutModal, Submenu } from "../../components";
import { TubingService, UrlService } from "../../services";
import { useSharedState } from "../../app/sharedProperty";
import { Button } from "react-bootstrap";
import { showSuccessMessage } from "../../app/tools";

export const TubingDetailsSubmenu = (props: any) => {
  const urlParams = useParams();
  const tubingId = UrlService.getParamValue("id", urlParams) ?? "0";
  const params = new Map<string, string>();
  params.set("id", tubingId ?? "");

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  async function deleteTubing(id: number) {
    await new TubingService().deleteTubing(id);
    return true;
  }

  const deleteSelectClick = async () => {
    const ok = await deleteTubing(parseInt(tubingId));
    if (!ok) {
      return;
    } else {
      showSuccessMessage(`Tubing deleted`);
    }
    router.navigate(routes.tubings).catch((error) => {});
  };

  const submenu = [
    {
      label: "Back",
      url: routes.tubings,
      icon: "arrow-left",
    },
    {
      label: "Details",
      url: router.getUrl(routes.tubingDetails, new Map([["Id", tubingId]])),
      params,
      activeMatch: routes.tubingDetails,
    },
    {
      label: "Inspections",
      url: router.getUrl(routes.tubingInspections, new Map([["Id", tubingId]])),
      params,
      activeMatch: routes.tubingInspections,
    },
    {
      label: "Delete",
      url: "",
      clickHandler: () => showSelectModalState.setter(true),
    },
  ];

  return (
    <>
      <Submenu items={submenu} />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Tubing"}
        footer={
          <div className="row p-0 w-100">
            <div className="col-6 ps-0">
              <Button
                onClick={selectModalCancelClick}
                variant="cancel"
                className="w-100"
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 pe-0">
              <Button
                onClick={deleteSelectClick}
                variant="primary"
                className="w-100"
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        Are you sure you want to delete this tubing?
      </SlideOutModal>
    </>
  );
};

export default TubingDetailsSubmenu;

