import SettingsModel from "../data/entities/SettingsModel";
import SettingsRepo from "../data/repo/SettingsRepo";
import SiteInfoModel from "../data/entities/SiteInfoModel";
import InviteModel from "../data/entities/InviteModel";
import { ScaleModel } from "../data/entities";

export class SettingsService {

  async getAllAsync(): Promise<SettingsModel | null> {
    return await new SettingsRepo().getSettingsAsync();
  }

  async changeSiteInfoAsync(siteInfo: SiteInfoModel): Promise<boolean | undefined> {
    return await new SettingsRepo().changeSiteInfoAsync(siteInfo);
  }

  async changeAccountsAsync(accounts: InviteModel): Promise<boolean | undefined> {
    return await new SettingsRepo().changeAccountsAsync(accounts);
  }

  async changeScaleAsync(scale: ScaleModel): Promise<boolean | undefined> {
    return await new SettingsRepo().changeScaleAsync(scale);
  }

}

export default SettingsService;
