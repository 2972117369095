import {
  SlideOutModal,
  SlideOutModalProps,
  TextWithValidation,
  Toggle,
} from "../../../../components";
import { Button, Form } from "react-bootstrap";
import { NormalizeVarianceModel } from "../../../../data/entities/NormalizeVarianceModel";
import { useEffect, useState } from "react";
import ProcessService from "../../../../services/ProcessService";
import { useParams } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "../../../../app/tools";
import { InspectionProcessModel } from "../../../../data/entities";
import ProcessRepo from "../../../../data/repo/ProcessRepo";

export interface NormalizeModalProps extends SlideOutModalProps {
  message?: string;
  confirmText?: string;
  range: { startPosition: number; endPosition: number };
  setProcesses: React.Dispatch<React.SetStateAction<InspectionProcessModel[] | undefined>>;
  id: number;
}

export function NormalizeModal(props: NormalizeModalProps) {
  const params = useParams();
  const inspectionId = params["id"] ?? "";

  const [formData, setFormData] = useState<NormalizeVarianceModel>({
    reduceChatter: false,
    flwVariance: 0,
    flawVariance: 0,
    wall1Variance: 0,
    wall2Variance: 0,
    normalizeFlaw: false,
    normalizeFlw: false,
    normalizeWall1: false,
    normalizeWall2: false,
    startPosition: props.range.startPosition,
    endPosition: props.range.endPosition,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      startPosition: props.range.startPosition,
      endPosition: props.range.endPosition,
    });
  }, [props.range]);

  const onCancel = () => {
    props.showState.setter(false);
  };

  const onConfirm = (e: any) => {
    if (
      window.confirm(
        "Are you sure you want to execute this normalization process?"
      )
    ) {
      console.log("onConfirm", formData);
      props.showState.setter(false);
      handleConfirm();
    }
  };

  const handleConfirm = async () => {
    try {
      const result = await new ProcessService().postProcessNormalize(
        Number(inspectionId),
        formData
      );
      if (result) {
        showSuccessMessage(
          `Normalized data between ${formData.startPosition} and ${formData.endPosition}`
        );
        try {
          const loadedData = await new ProcessRepo().getProcessNormalize(props.id);
          props.setProcesses(loadedData.processes);
        } catch (e) {
          console.error("Error loading data points", e);
        }
      } else {
        showErrorMessage(`Error normalizing data. `);
      }
    } catch (error) {
      console.error("Error trying to normalize", error);
    }
  };

  const renderFooter = () => (
    <div className="container p-0">
      <div className="row g-0">
        <div className="col-6 pe-2">
          <Button onClick={onCancel} variant="cancel" className="w-100">
            Cancel
          </Button>
        </div>
        <div className="col-6 ps-2">
          <Button onClick={onConfirm} variant="primary" className="w-100">
            {props.confirmText ?? "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );

  const toggleContainer = (id: string) => {
    const element = document.querySelector(`.${id}-container`);
    if (element) {
      element.classList.toggle("invisible");
    }
  };

  const renderToggle = (description: string, id: string, containerId: string) => (
    <div className="col-12 col-md-6 d-flex align-items-center">
      <Toggle
        description={description}
        id={id}
        className="mb-3"
        onChange={(value) => {
          setFormData({
            ...formData,
            [id]: value,
          });
          toggleContainer(containerId);
        }}
      />
    </div>
  );

  const renderTextWithValidation = (
    id: string,
    label: string,
    initialValue: number
  ) => (
    <div className={`col-12 col-md-6 ${id}-container invisible`}>
      <TextWithValidation
        id={id}
        label={label}
        type="number"
        initialValue={initialValue}
        onChangeText={(value) => {
          setFormData({
            ...formData,
            [id]: Number(value),
          });
        }}
      />
    </div>
  );

  return (
    <SlideOutModal {...props} footer={renderFooter()}>
      <p>
        The next step is to pick a variance range to fluctuate the data
        randomly. This data processor will use the baseline settings for the
        inspection to normalize the data between points{" "}
        {props.range.startPosition} and {props.range.endPosition}, varying
        randomly by whatever settings you choose below.
      </p>
      <table className="table">
        <tbody>
          <tr>
            <td>Range: </td>
            <td>
              {props.range.startPosition} to {props.range.endPosition}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Length: </td>
            <td>{props.range.endPosition - props.range.startPosition}</td>
          </tr>
        </tfoot>
      </table>
      <Form>
        {/* Reduce Chatter */}
        <div className="row pt-3 bg-light">
          <div className="col-12">
            <p>
              When 'reducing chatter', the system will leave all values alone
              that are outside of the allowed variance. It will normalize
              everything within the allowed variance range.
            </p>
            <p>
              When 'reduce chatter' is turned off, the system will do the
              opposite. It will normalize everything outside of the allowed
              variance range.
            </p>
            {renderToggle("Reduce Chatter", "reduceChatter", "")}
          </div>
        </div>
        {/* Normalize FLW */}
        <div className="row pt-3">
          {renderToggle("Normalize FLW", "normalizeFlw", "flwVariance")}
          {renderTextWithValidation("flwVariance", "FLW Variance", 0)}
        </div>
        {/* Normalize Flaw */}
        <div className="row pt-3 bg-light">
          {renderToggle("Normalize Flaw", "normalizeFlaw", "flawVariance")}
          {renderTextWithValidation("flawVariance", "Flaw Variance", 0)}
        </div>
        {/* Normalize Wall1 */}
        <div className="row pt-3">
          {renderToggle("Normalize Wall1", "normalizeWall1", "wall1Variance")}
          {renderTextWithValidation("wall1Variance", "Wall1 Variance", 0)}
        </div>
        {/* Normalize Wall2 */}
        <div className="row pt-3 bg-light">
          {renderToggle("Normalize Wall2", "normalizeWall2", "wall2Variance")}
          {renderTextWithValidation("wall2Variance", "Wall2 Variance", 0)}
        </div>
      </Form>
    </SlideOutModal>
  );
}

export default NormalizeModal;

