import "./Card.scss";
import React, { HTMLAttributes } from "react";

export interface TitlelessCardProps extends React.PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  bodyClassName?: string
}

export function TitlelessCard(props: TitlelessCardProps) {
  return (
    <div className={`card w-100 ${props.className ?? ""}`}>
      <div className={`card-body ${props.bodyClassName ?? ""}`}>{props.children}</div>
    </div>
  );
}

export default TitlelessCard;
