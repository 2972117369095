import React, { FormEvent, useEffect, useState } from "react";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import Toggle from "../../components/form/toggle/Toggle";
import BlockButton from "../../components/form/buttons/BlockButton";
import Form from "react-bootstrap/Form";
import PasswordWithValidation from "../../components/form/input/PasswordWithValidation";
import UserService from "../../services/UserService";
import { router, routes } from "../../app/routes";
import UrlService from "../../services/UrlService";
import { Input } from "@progress/kendo-react-inputs";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const formElement = React.useRef<HTMLFormElement>(null);

  useEffect(() => {}, []);

  const signInButtonClick = () => {
    signIn();
  }

  const updateValues = (newEmail?: string, newPassword?: string) => {
    if (newEmail !== undefined) setEmail(newEmail);
    if (newPassword !== undefined) setPassword(newPassword);

    const currentEmail = newEmail ?? email ?? "";
    const currentPassword = newPassword ?? password ?? "";

    setButtonEnabled(
      currentEmail !== ""
      && currentPassword !== ""
    );
  };

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signIn();
  };

  const signIn = async () => {
    setShowSpinner(true);
    const userService = new UserService();
    await userService.login(email, password, rememberMe);
    const isSignedIn = UserService.isSignedIn();

    setShowSpinner(false);

    if (!isSignedIn) return;

    const returnUrl = UrlService.getQueryParams("returnUrl");
    const destination = returnUrl ?? routes.home;
    console.debug("Redirecting to: ", destination);
    router.navigate(destination);
  }

  return (
    <>
      <h2>Sign in</h2>
      <section>
        <h4 className="mb-4">Enter your credentials.</h4>
        <Form ref={formElement} onSubmit={onFormSubmit}>
          <EmailWithValidation
            id="email"
            onChangeText={(input) => updateValues(input)}
          />
          <PasswordWithValidation
            id="password"
            onChangeText={(input) => updateValues(undefined, input)}
          />
          <Toggle
            id="rememberMe"
            description="Remember me"
            initialValue={rememberMe}
            onChange={(value) => {
              setRememberMe(value);
            }}
          ></Toggle>
          <Input type="submit" className="d-none" disabled={!isButtonEnabled}></Input>
          <BlockButton
            className="mt-3"
            label="Sign in"
            onClick={signInButtonClick}
            disabled={!isButtonEnabled}
            showSpinner={showSpinner}
          ></BlockButton>
        </Form>
      </section>
    </>
  );
}


export default Login;