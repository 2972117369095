import React, { useEffect, useState } from "react";
import { ConfirmationModal, IconAndText } from "../../../../components";
import sharedProperty from "../../../../app/sharedProperty";
import EditableGrid from "../../../../components/grid/EditableGrid";
import { Button } from "react-bootstrap";
import { GridColumn } from "@progress/kendo-react-grid";
import { NoteService } from "../../../../services";
import { NoteValueModel } from "../../../../data/entities";
import { setWarning, store } from "../../../../app/store/store";
import { isNumber } from "../../../../app/tools";
import InspectionNotesSubmenu from "./InspectionNotesSubmenu";
import { ModalStatesModel } from "./InspectionsNotesModal";
// import { sortValues } from "../../../myInspections/InspectionNoteValues";

interface InspectionNoteEditValuesModelProps {
  modalState: sharedProperty<boolean>;
  noteId: number;
  onSaved?: () => void;
  onStartSaving?: () => void;
  refreshCode: number;
  modalStates?: ModalStatesModel;
}

function InspectionNoteEditValuesModal(
  props: Readonly<InspectionNoteEditValuesModelProps>
) {
  const [originalValues, setOriginalValues] = useState<NoteValueModel[]>([]);
  const [noteValues, setNoteValues] = useState<NoteValueModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const odataValues = await new NoteService().getNoteValues(props.noteId);
      setOriginalValues(() => {
        return odataValues ?? [];
      });
      setNoteValues(odataValues ?? []);
    };
    fetchData();

    return () => {};
  }, [props.noteId, props.refreshCode]);

  

  const saveData = async () => {
    //Delete Values
    const removedValues = originalValues.filter(
      (original) => !noteValues.some((value) => value.id === original.id)
    );
    for (const removedValue of removedValues) {
      await new NoteService().deleteNoteValue(removedValue.id!);
    }

    //Add Values
    const addedValues = noteValues.filter(
      (value) => !isNumber(value.id?.toString() ?? "")
    );
    for (const addedValue of addedValues) {
      await new NoteService().addNoteValue(addedValue);
    }

    //Update Values
    const updatedValues = noteValues.filter((value) =>
      isNumber(value.id?.toString() ?? "")
    );
    for (const updatedValue of updatedValues) {
      await new NoteService().updateNoteValue(updatedValue);
    }
  };

  const ovalityClickHandler = () => {
    const odMin = noteValues.find((value) => value.name === "OD (Min)")?.value;
    const odMax = noteValues.find((value) => value.name === "OD (Max)")?.value;

    if (!odMin || !odMax) {
      store.dispatch(
        setWarning(
          'Unable to calculate Ovality, double check that "OD (Min)" and "OD (Max)" are valid.'
        )
      );
      return;
    }

    const pendingChanges = noteValues.some(
      (value) => !isNumber(value.id?.toString() ?? "")
    );
    if (pendingChanges) {
      props.modalState.setter(false);
      store.dispatch(
        setWarning(
          "Unable to calculate Ovality, please save your changes before calculating Ovality."
        )
      );

      return;
    }

    const calculate = async () => {
      await onConfirmHandler();
      const calculated = await new NoteService().calculateOvality(props.noteId);

      if (!calculated) return;
      const values = calculated.value;
      setOriginalValues(values);
      setNoteValues(values);
      props.onSaved?.();
    };

    calculate();
  };

  const additionalToolbarContent = [
    <Button
      key={"btnOvality"}
      variant="secondary"
      className="ms-auto"
      onClick={ovalityClickHandler}
    >
      <IconAndText iconName="ovality" text="Ovality" />
    </Button>,
  ];

  const itemChangeHandler = (data: NoteValueModel[]) => {
    setNoteValues(data);
  };

  const onConfirmHandler = async () => {
    props.onStartSaving?.();
    await saveData();
    props.modalState.setter(false);
    setOriginalValues(noteValues);
    props.onSaved?.();
  };

  const onCancelHandler = () => {
    //Resets the values to the original values
    setNoteValues(originalValues);
  };

  const getNewItem = (): NoteValueModel => {
    const newItem = {} as NoteValueModel;
    newItem.noteId = props.noteId;
    return newItem;
  };

  return (
    <ConfirmationModal
      title="Values"
      showState={props.modalState}
      onConfirm={onConfirmHandler}
      onCancel={onCancelHandler}
      confirmText="Save"
      submenu={
        <InspectionNotesSubmenu
          initialTab={"Values"}
          onChangeTab={() => {
            console.log("change tab");
          }}
          modalStates={props.modalStates}
        />
      }
    >
      <EditableGrid
        toolbarAdditionalContent={additionalToolbarContent}
        editDataKey="id"
        data={noteValues}
        onItemChange={itemChangeHandler}
        getNewItem={getNewItem}
      >
        <GridColumn title="Name" field="name" editable={true} editor="text" />
        <GridColumn
          title="Value"
          field="value"
          editable={true}
          editor="text"
        />
      </EditableGrid>
    </ConfirmationModal>
  );
}

export default InspectionNoteEditValuesModal;

