
import { ODataResponse, UserProfileModel } from "../entities";
import SecurityModel from "../entities/SecurityModel";
import UserDeleteModel from "../entities/UserDeleteModel";
import UserDetailsModel from "../entities/UserDetailsModel";
import BaseRepository from "./BaseRepository";

export class UserRepo extends BaseRepository {

  async login(email: string, password: string, rememberMe: boolean): Promise<string | null | undefined> {
    const url = "Account/Login";

    const body = {
      "email": email,
      "password": password,
      "rememberMe": rememberMe
    };

    const response = await this.client.post<string>(url, body);
    return this.handleResponse<string>(response);
  }

  async getUserDetails(id: number) {
    const url = `User/${id}`;
    const response = await this.client.get(url);
    return this.handleResponse<UserDetailsModel>(response);
  }

  async forgotPassword(email: string): Promise<boolean | undefined> {
    const url = "Account/ForgotPassword";
    const body = {
      "email": email
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async resetPassword(email: string, password: string,
    confirmation: string, code: string): Promise<boolean | undefined> {
    const url = `Account/ResetPassword`;
    const body = {
      email: email,
      password: password,
      confirmPassword: confirmation,
      code: code
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  };

  async updateUserProfile(model: UserProfileModel): Promise<boolean | undefined> {
    const url = `Manage/`;
    const body = {
      user: {
        id: model.id,
        email: model.email,
        firstName: model.firstName,
        lastName: model.lastName,
        preferredMeasurement: model.preferredMeasurement
      }
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  };

  async changePassword(password: string, newPassword: string,
    confirmation: string): Promise<boolean | undefined> {
    const url = `Manage/ChangePassword`;
    const body = {
      oldPassword: password,
      newPassword: newPassword,
      confirmPassword: confirmation
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  };

  async register(email: string, firstName: string,
    lastName: string, company: string): Promise<boolean | undefined> {
    const url = `Account/RegistrationRequest`;
    const body = {
      email,
      firstName,
      lastName,
      company,
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getUsers(oDataQuery: string): Promise<ODataResponse<UserDetailsModel>> {
    const url = `/OData/Users/?$count=true&${oDataQuery}&expand=Company`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<UserDetailsModel>>(response)!;
  }

  async getUserById(id: number): Promise<UserDetailsModel | null> {
    const url = `/User/${id}`;
    const response = await this.client.get(url);
    return this.handleResponse<UserDetailsModel>(response);
  }

  async updateUserDetails(id: number, model: UserDetailsModel): Promise<boolean> {
    const url = `/User/${id}`;
    const response = await this.client.post(url, model);
    return this.handleResponse<boolean>(response)!;
  }

  async deleteUser(id: number, model: UserDeleteModel): Promise<boolean> {
    const url = `/User/${id}/Delete`;
    const response = await this.client.post(url, model);
    return this.handleResponse<boolean>(response)!;
  }

  async getUserSecurity(id: number): Promise<SecurityModel | null> {
    const url = `/User/${id}/Security`;
    const response = await this.client.get(url);
    return this.handleResponse<SecurityModel>(response);
  }

  async removeUserSecurity(id: number, role: string): Promise<boolean | null> {
    const url = `/User/${id}/RemoveSecurity?role=${role}`;
    const response = await this.client.post(url);
    return this.handleResponse<boolean>(response);
  }

  async addUserSecurity(id: number, role: string): Promise<boolean | null> {
    const url = `/User/${id}/AddSecurity?role=${role}`;
    const response = await this.client.post(url);
    return this.handleResponse<boolean>(response);
  }
  

}

export default UserRepo;
