import TitlelessCard from "../../components/card/TitlelessCard";
import {
  GridCellProps,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import InspectionService, {
  AreaExport,
} from "../../services/InspectionService";
import { router, routes } from "../../app/routes";
import {
  Breakpoints,
  Icon,
  IconDefinitions,
  ODataGrid,
  ResponsiveGridColumn,
} from "../../components";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { toYearMonthDay } from "../../app/dateHelpers";
import { FileExportType } from "../../models/FileExport";
import PageContent from "../PageContent";
import { useCallback} from "react";

export function MyInspectionPage() {
  const getInspections = (oDataQuery: string) => {
    return new InspectionService().getMyInspections(oDataQuery);
  };

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(
          routes.myInspectionsDetails,
          new Map([["id", dataItem.id]])
        );
        break;
      case "opentab":
        router.navigateWithParams(
          routes.myInspectionsDetails,
          new Map([["id", dataItem.id]]),
          true
        );
        break;
    }
  };

  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      routes.myInspectionsDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  const dateCellBuilder = (props: GridCellProps) => {
    return <td>{toYearMonthDay(props.dataItem.inspectionDate)}</td>;
  };

  const fileClickHandler = useCallback((source: GridCellProps) => {
    const inspectionService = new InspectionService();
    const inspectionId = source.dataItem.id;
    const tubing = source.dataItem.tubing.name;
    const inspectionDate = source.dataItem.inspectionDate;
    const pdfFileName = `${tubing}-${new Date(inspectionDate)
      .toISOString()
      .slice(0, 10)}`;

    const urls = inspectionService.getExportUrls(
      inspectionId,
      AreaExport.inspection,
      pdfFileName
    );
    const fileExport = urls.filter((u) => u.type === FileExportType.Report)[0];

    inspectionService.exportFile(fileExport.url).then((file) => {
      if (!file) return;

      const binaryData = [];
      binaryData.push(file.data);
      const blob = new Blob(binaryData, { type: fileExport.contentType });
      const objUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = file.name ?? pdfFileName;
      link.target = "_blank";
      link.href = objUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objUrl);
    });
  }, []);

  const fileCellBuilder = useCallback(
    (props: GridCellProps) => {
      return (
        <td className="text-center">
          <Icon
            onClick={() => fileClickHandler(props)}
            className="text-primary cursor-pointer"
            iconName={IconDefinitions.pdf}
          />
        </td>
      );
    },
    [fileClickHandler]
  );

  // Function to format the address field with multiple data values
  const formatAddress = (dataItem: any) => {
    // Customize this function to format the address as needed
    return `${dataItem.address.city}, ${dataItem.address.province.countryCode}`;
  };

  const addressCellBuilder = useCallback((props: GridCellProps) => {
    return <td>{formatAddress(props.dataItem)}</td>;
  }, []);

  return (
    <PageContent
      footerless
      title="My Inspections"
      breadcrumbs={{ values: [{ label: "My Inspections" }] }}
    >
      <TitlelessCard bodyClassName="p-0">
        <ODataGrid
          getData={getInspections}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={onRowClick}
          sort={[{ field: "inspectionDate", dir: "desc" }]}
          scrollable="scrollable"
          fullHeight={true}
        >
          <ResponsiveGridColumn
            className="column-date"
            title="Date"
            field="inspectionDate"
            sortable={true}
            filter="date"
            cell={dateCellBuilder}
            breakpoint={Breakpoints.xs}
          />
          <ResponsiveGridColumn
            className="column-reference"
            title="Reference"
            field="reference"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.min}
          />
          <ResponsiveGridColumn
            className="column-tubing"
            title="Tubing"
            field="tubing.name"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.md}
          />
          <ResponsiveGridColumn
            className="column-tubing-length"
            title="Length"
            field="tubing.length"
            sortable={true}
            filterable={false}
            width={110}
            breakpoint={Breakpoints.md}
          />
          <ResponsiveGridColumn
            className="column-tubing-size"
            title="Size (inches)"
            field="tubing.sizeDescription"
            sortable={true}
            filterable={false}
            width={110}
            breakpoint={Breakpoints.md}
          />
          <ResponsiveGridColumn
            className="column-address"
            title="Location"
            field="address.city"
            sortable={true}
            filter="text"
            cell={addressCellBuilder}
            breakpoint={Breakpoints.xs}
          />
          <ResponsiveGridColumn
            className="column-company"
            title="Company"
            field="tubing.company.name"
            sortable={true}
            filter="text"
            breakpoint={Breakpoints.lg}
          />
          <ResponsiveGridColumn
            className="column-url"
            field="url"
            title=" "
            sortable={false}
            filterable={false}
            headerCell={() => null}
            cell={fileCellBuilder}
            width={47}
            breakpoint={Breakpoints.xs}
          />
          <MenuItem
            text="Open"
            data={{ action: "open" }}
            icon="hyperlink-open"
          />
          <MenuItem
            text="Open Tab"
            data={{ action: "opentab" }}
            icon="windows"
          />
        </ODataGrid>
      </TitlelessCard>
    </PageContent>
  );
}

export default MyInspectionPage;

