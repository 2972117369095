import { useEffect, useState } from "react";
import { SimpleCard } from "../../components/card/Card";
import TitlelessCard from "../../components/card/TitlelessCard";
import SpinnerDefault from "../../components/spinners/SpinnerDefault";
import InspectionDetails from "../../data/entities/InspectionDetailsModel";
import { NoteModel } from "../../data/entities/NoteModel";
import InspectionNote from "./InspectionNote";
import LinkLooksLike from "../../components/link/LinkLooksLike";

interface InspectionNotesProps extends React.PropsWithChildren {
  pageData: InspectionDetails | undefined | null;
}

export default function InspectionNotes(props: InspectionNotesProps) {
  const [noteList, setNoteList] = useState<NoteModel[] | undefined>(undefined);
  const [noteElementList, setNoteElementList] = useState<JSX.Element[]>([]);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    setNoteList(props.pageData?.notes);

    return () => {};
  }, [props.pageData]);

  useEffect(() => {
    setNoteElementList(
      noteList?.map((note) => (
        <TitlelessCard key={note.id} className="mb-3">
          <InspectionNote note={note}></InspectionNote>
        </TitlelessCard>
      )) ?? []
    );

    return () => {};
  }, [noteList]);

  const isFilterActive = (filterItem: string) => {
    return filterItem === filter ? "active" : "";
  };

  const applyFilter = (filterItem: string) => {
    const processedFilterItem = filterItem.replaceAll(/[-\s]/g, "").toUpperCase();
    setFilter(filterItem);
    if (filterItem === "All") setNoteList(props.pageData?.notes);
    else
      setNoteList(
        props.pageData?.notes?.filter(
          (note) =>
            note.severity?.toUpperCase() === processedFilterItem
        )
      );
  };

  const filterList = ["All", "Comments", "Repairs", "Caution", "Urgent", "Pre-Repair", "Post-Repair", "Compromised OD"].map(
    (filter) => {
      return (
        <LinkLooksLike
          key={filter}
          className={`mx-2 ${isFilterActive(filter)}`}
          onClick={() => applyFilter(filter)}
        >
          {filter}
        </LinkLooksLike>
      );
    }
  );

  if (props.pageData === undefined) return <SpinnerDefault />;
  else if (props.pageData === null)
    return <SimpleCard title="Failed to load data." className="mb-3"></SimpleCard>;
  else {
    return (
      <>
        <h5>Notes</h5>
        <TitlelessCard className="mb-3">
          <div className="">
            <div className="">
              <label className="text-sm font-weight-bold mb-0 me-3">
                Show:{" "}
              </label>
              {filterList}
            </div>
          </div>
        </TitlelessCard>
        {noteElementList}
      </>
    );
  }
}
