import { AdjustModel,
  ProcessMergeModel,
  ProcessSplitModel,
  NormalizeVarianceModel,
  ProcessCopyModel,
  ProcessFLWBaselineModel,
  ProcessPositionsModel,
  ProcessResetModel,
  RandomizeModel 
} from "../entities";

import BaseRepository from "./BaseRepository";

export class ProcessRepo extends BaseRepository {

  async getProcessReset(id: number) {
    const url = `Inspection/${id}/Process/Reset`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessResetModel>(response);
  }

  async postProcessReset(id: number, confirm: string) {
    const url = `Inspection/${id}/Process/Reset`;
    const body = {
      id: id,
      confirm: confirm
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessReversePositions(id: number) {
    const url = `Inspection/${id}/Process/Reverse`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async getProcessSplit(id: number) {
    const url = `Inspection/${id}/Process/Split`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessSplitModel>(response);
  }

  async postProcessSplit(id: number, model: ProcessSplitModel) {
    const url = `Inspection/${id}/Process/Split`;
    const body = {
      id: id,
      dataPointIndexByInspection: model.dataPointIndexByInspection,
      position: model.position
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessMerge(id: number) {
    const url = `Inspection/${id}/Process/Merge`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessMergeModel>(response);
  }

  async postProcessMerge(id: number, model: ProcessMergeModel) {
    const url = `Inspection/${id}/Process/Merge`;
    const body = {
      id: id,
      segmentIndex1: model.segmentIndex1,
      segmentIndex2: model.segmentIndex2
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessPositions(id: number) {
    const url = `Inspection/${id}/Process/Positions`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessPositionsModel>(response);
  }

  async postProcessPositions(id: number, model: ProcessPositionsModel) {
    const url = `Inspection/${id}/Process/Positions`;
    const body = {
      id: id
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessNormalize(id: number) {
    const url = `Inspection/${id}/Process/Normalize`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async postProcessNormalize(id: number, body: NormalizeVarianceModel) {
    const url = `Inspection/${id}/Process/Normalize`;

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessNormalizeVariance(id: number) {
    const url = `Inspection/${id}/Process/NormalizeVariance`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async postProcessNormalizeVariance(id: number) {
    const url = `Inspection/${id}/Process/NormalizeVariance`;
    const body = {

    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessNormalizeConfirm(id: number) {
    const url = `Inspection/${id}/Process/NormalizeConfirm`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async postProcessNormalizeConfirm(id: number) {
    const url = `Inspection/${id}/Process/NormalizeConfirm`;
    const body = {

    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessAdjust(id: number) {
    const url = `Inspection/${id}/Process/Adjust`;

    const response = await this.client.get(url);
    return this.handleResponse<AdjustModel>(response);
  }

  async postProcessAdjust(id: number, body: AdjustModel) {
    const url = `Inspection/${id}/Process/Adjust`;

    const response = await this.client.post(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async getProcessRandomize(id: number) {
    const url = `Inspection/${id}/Process/Randomize`;

    const response = await this.client.get(url);
    return this.handleResponse<RandomizeModel>(response);
  }

  async postProcessRandomize(id: number, body: RandomizeModel) {
    const url = `Inspection/${id}/Process/Randomize`;

    const response = await this.client.post(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async getProcessFLWBaseline(id: number) {
    const url = `Inspection/${id}/Process/FLWBaseline`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessFLWBaselineModel>(response);
  }

  async postProcessFLWBaseline(id: number, model: ProcessFLWBaselineModel) {
    const url = `Inspection/${id}/Process/FLWBaseline`;
    const body = {
      id: id,
      startPosition: model.startPosition,
      endPosition: model.endPosition,
      startBaseline: model.startBaseline,
      endBaseline: model.endBaseline,
      chart: model.chart
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getProcessCopy(id: number) {
    const url = `Inspection/${id}/Process/Copy`;

    const response = await this.client.get(url);
    return this.handleResponse<ProcessCopyModel>(response);
  }

  async postProcessCopy(id: number, model: ProcessCopyModel) {
    const url = `Inspection/${id}/Process/Copy`;
    const body = {
      companyId: model.companyId,
      tubingName: model.tubingName
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async reversePositions(id: number) {
    const url = `Inspection/${id}/Process/ReversePositions`;
    const response = await this.client.post(url);

    return this.handleResponse(response);
  }
}

export default ProcessRepo;
