import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

interface EmailWithValidationProps {
  id: string;
  initialValue?: string;
  label?: string;
  required?: boolean;
  validationFunction?: (input: string) => void;
  onChangeText?: (value: string) => void;
  useDefaultValidation?: boolean;
  validationResult?: (input: string, result: boolean) => void;
}

export function EmailWithValidation({
  initialValue: emailText,
  id,
  label,
  required,
  validationFunction,
  onChangeText,
  useDefaultValidation,
  validationResult,
}: EmailWithValidationProps) {
  const [value, setEmailValue] = useState(emailText ?? "");
  const [isValid, setIsValid] = useState(true);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const changedValue = event.target.value ?? "";
    setEmailValue(changedValue);
    if (onChangeText) onChangeText(changedValue);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
    if (useDefaultValidation) {
      const regex = /^\S+@\S+\.\S+$/;
      const valid = required ? regex.test(value) : regex.test(value) || value === "";
      setIsValid(valid);
      if (validationResult) validationResult(value, valid);
    }
    if (validationFunction) validationFunction(value);
  }

  return (
    <>
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3">
          <Form.Control
            type="email"
            placeholder="name@cti.com"
            required={required}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={!isValid}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">Invalid e-mail.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}

EmailWithValidation.defaultProps = {
  label: "Email",
  required: true,
  useDefaultValidation: true,
}

export default EmailWithValidation;
