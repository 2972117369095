import React, { useImperativeHandle, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

interface PostalCodeWithValidationProps {
  id?: string;
  label?: string;
  required?: boolean;
  postalCodeType?: string;
  initialValue?: string;
  disabled?: boolean;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
}

export const PostalCodeWithValidation = React.forwardRef(
  (props: Readonly<PostalCodeWithValidationProps>, ref) => {
    const {
      initialValue: text,
      id,
      label,
      required,
      postalCodeType,
      disabled,
      validationFunction,
      onChangeText,
      validationResult,
    } = props;

    const [value, setValue] = useState(text ?? "");
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState("");

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      const changedValue = event.target.value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>): boolean {
      let message = `Invalid Postal Code / Zip for the selected country (${postalCodeType})`;
      let result = false;
      let valid = required ? value !== "" : true;

      if (required && value === "")
        message = "Field is required. It cannot be empty";
      else if (value !== "")
        result = postalCodeValidation(
          postalCodeType ?? "Canada",
          valid,
          value,
          required ?? true
        );
      else result = valid;

      setValidationMessage(message);

      if (validationFunction) result = validationFunction(value);
      if (validationResult) validationResult(value, result);

      setIsValid(result);

      return result;
    }

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleBlur({} as React.FocusEvent<HTMLInputElement>);
      },
    }));

    return (
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3">
          <Form.Control
            type={postalCodeType}
            placeholder={label}
            required={required}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={!isValid}
            disabled={disabled}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {validationMessage}.
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    );
  }
);

// Validation functions for each text field type
function postalCodeValidation(
  postalCodeType: string,
  valid: boolean,
  value: string,
  required: boolean
) {
  switch (postalCodeType) {
    case "United States":
      valid = /(\d{5}(?:-\d{4})?)/.test(value);
      break;
    case "Netherlands":
      valid = /((?:NL-)?(\d{4})\s*([A-Z]{2}))/.test(value);
      break;
    default:
      valid = /([a-zA-Z]\d[a-zA-Z]\s?\d[a-zA-Z]\d)/.test(value);
      break;
  }
  if (!required) return valid || value === "";
  else return valid;
}

PostalCodeWithValidation.defaultProps = {
  label: "Postal Code / Zip",
  id: "postalCode",
  required: true,
  postalCodeType: "Canada",
  disabled: false,
};

export default PostalCodeWithValidation;


