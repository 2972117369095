import React from "react";
import { HeadingBar, Icon, IconDefinitions, Link } from "../../components";
import { routes } from "../../app/routes";
import PageContent from "../PageContent";

export function Forbidden() {
  return (
    <PageContent>
      <div className="row">
        <div className="col-auto text-danger" style={{ fontSize: 90 }}>
          <Icon iconName={IconDefinitions.forbidden}></Icon>
        </div>
        <div className="col">
          <HeadingBar h={1} title="Access Forbidden" />
          <p>Sorry, you don't have permission to access this page.</p>
          <p>
            Would you like to go back to the{" "}
            <Link url={routes.home}>homepage</Link>?
          </p>
        </div>
      </div>
    </PageContent>
  );
}

export default Forbidden;
