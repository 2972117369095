import { useEffect, useMemo, useState } from "react";
import { routes } from "../../app/routes";
import {
  Submenu,
  IconDefinitions,
  SubmenuNavigationItem,
} from "../../components";
import { UrlService } from "../../services";

export const InspectionRootSubmenu = () => {
  const returnUrl = UrlService.getQueryParams("returnUrl");
  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Inspections",
        icon: IconDefinitions.search,
        url: routes.inspections,
        activeMatch: routes.inspections,
      },
      {
        label: "Add Inspection",
        icon: IconDefinitions.add,
        url: routes.inspectionsInsert,
        activeMatch: routes.inspectionsInsert,
      },
    ],
    []
  );

  useEffect(() => {
    if (returnUrl) {
      const backItem = {
        label: "Back",
        icon: IconDefinitions.back,
        url: returnUrl ?? "",
      };

      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
};

export default InspectionRootSubmenu;

