import Dashboard from "../../models/Dashboard";
import DashboardItem from "../entities/DashboardItem";
import BaseRepository from "./BaseRepository";

export class DashboardRepo extends BaseRepository {

  async getLastInspectionsData(): Promise<Dashboard | undefined> {
    const url = "Home";

    const response = await this.client.get(url);
    const handled = this.handleResponse<Dashboard>(response);
    if (!handled) return;
    return handled;
  }

  async getMyInspectionHistory(): Promise<DashboardItem[] | undefined> {

    const url = "OData/Dashboard/MyInspectionHistory";

    const response = await this.client.get(url);
    const handled = this.handleResponse<DashboardItem[]>(response);
    if (!handled) return;
    return handled;
  }
}

export default DashboardRepo;
