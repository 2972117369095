import { PropsWithChildren, useState } from "react";
import LinkLooksLike from "../link/LinkLooksLike";
import { InspectionService } from "../../services";
import { FileExport } from "../../models";
import { Spinner } from "react-bootstrap";

interface LinkFileDownloaderProps extends PropsWithChildren {
  inspectionId: number;
  source: FileExport;
  pdfFileName?: string;
  onClick?: () => boolean;
  onDownloaded?: () => void;
}

export function InspectionFileExporter(props: LinkFileDownloaderProps) {
  const { source } = props;

  const [isLoading, setIsLoading] = useState(false);

  const spinner = (
    <Spinner
      animation="border"
      variant="dark"
      role="status"
      aria-hidden="true"
      size="sm"
      className={`d-block`}
    />
  );

  const clickHandler = () => {
    const breakClick = props.onClick?.() ?? false;
    if (breakClick) return;

    setIsLoading(true);

    new InspectionService()
      .exportFile(source.url)
      .then((file) => {
        if (!file) return;

        const binaryData = [];
        binaryData.push(file.data);
        const blob = new Blob(binaryData, { type: source.contentType });
        const objUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download =
          file.name ??
          props.pdfFileName ??
          `${props.inspectionId}.${source.fileExtension}`;
        link.target = "_blank";
        link.href = objUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objUrl);
        props.onDownloaded?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LinkLooksLike onClick={clickHandler}>
      {isLoading ? spinner: props.children}
    </LinkLooksLike>
  );
}

export default InspectionFileExporter;
