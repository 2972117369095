import { ReactElement, useEffect, useState } from "react";
import PageContent from "../PageContent";
import {
  SpinnerDefault
} from "../../components";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { InspectionService } from "../../services";
import {
  setInspectionForManagement,
  setInspectionIdForManagement,
  store,
} from "../../app/store/store";
import { EditInspectionModel } from "../../data/entities";
import InspectionSubmenu from "./InspectionSubmenu";
import GetInspectionBreadcrumbs from "./GetInspectionBreadcrumbs";

export interface InspectionsPageContext {
  title?: string;
  setTitle: (title?: string) => void;
  sidemenu?: ReactElement;
  setSidemenu: (sidemenu?: ReactElement) => void;
}

export function InspectionsPage() {
  const params = useParams();
  const location = useLocation();
  const id = params["id"] ?? "";
  const [title, setTitle] = useState("");
  const [sidemenu, setSidemenu] = useState<ReactElement>();
  const [inspection, setInspection] = useState<EditInspectionModel | null>();

  useEffect(() => {
    const fetchData = async () => {
      const inspection = await new InspectionService().getInspectionViewModel(
        Number(id)
      );
      store.dispatch(setInspectionIdForManagement(Number(id)));
      store.dispatch(setInspectionForManagement(inspection));
      setInspection(inspection);
    };

    fetchData();

    return () => { };
  }, [id]);

  if (inspection === undefined) {
    return <SpinnerDefault />;
  }

  if (id && id !== "" && inspection === null) {
    return <h2>Inspection not found</h2>;
  }

  const setTitleRelay = (title?: string) => {
    setTimeout(() => {
      setTitle(title ?? "");
    }, 100);
  }

  const GetContext = (): InspectionsPageContext => {
    return {
      title: title,
      setTitle: setTitleRelay,
      sidemenu: sidemenu,
      setSidemenu: setSidemenu
    };
  }

  return (
    <PageContent
      title={`${inspection?.reference}: ${title}`}
      breadcrumbs={GetInspectionBreadcrumbs(location, inspection)}
      submenu={<InspectionSubmenu id={id} />}
      sidemenu={sidemenu}
      footerless={true}
    >
      <Outlet context={GetContext()} />
    </PageContent>
  );
}

export default InspectionsPage;

