import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { TubingService } from '../../services';
import { TubingModel } from '../../data/entities';

interface SearchableTubingDropdownProps extends PropsWithChildren{
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
}

export const SearchableTubingDropdown = React.forwardRef(
  (
    props: Readonly<SearchableTubingDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const [options, setOptions] = useState<DropdownOption[]>([]);

    const sort = (data: TubingModel[]): TubingModel[] => {
      // Sort tubings by company name and then by tubing name
      return data.sort((a, b) => {
        const nameA = a.company?.name.toUpperCase();
        const nameB = b.company?.name.toUpperCase();

        if (nameA !== nameB) {
          return nameA! < nameB! ? -1 : 1;
        }

        // Secondary sort by tubing name if company names are equal
        const tubingNameA = a.name!.toUpperCase();
        const tubingNameB = b.name!.toUpperCase();
        return tubingNameA < tubingNameB ? -1 : tubingNameA > tubingNameB ? 1 : 0;
      });
    };

    useEffect(() => {
      const fetchData = async () => {
        let tubings = await new TubingService().getTubingsList();

        tubings = sort(tubings);
        const newOptions = tubings.map<DropdownOption>((tubing: TubingModel) => ({
          value: tubing.id?.toString() ?? "",
          text: `${tubing.company?.name}: ${tubing.name}`,
        }));

        setOptions(newOptions);
      };

      fetchData();
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        label="Tubing"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        isInvalid={props.isInvalid}
      >
        {props.children}
      </SearchableDropdown>
    )
  }
);

export default SearchableTubingDropdown
