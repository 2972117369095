import React, {
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

export interface Option {
  value: string;
  text: string;
}

interface DropdownProps {
  id: string;
  selectedOption?: number | string;
  label: string;
  disabled?: boolean;
  options: Option[] | null;
  onChangeSelected?: (value: SetStateAction<string>) => void;
  required?: boolean;
}

export const Dropdown = React.forwardRef(
  (props: Readonly<DropdownProps>, ref) => {
    const {
      id,
      selectedOption: dropdownId,
      label,
      disabled,
      options,
      onChangeSelected,
      required,
    } = props;

    const [selectedOption, setSelectedOption] = useState(dropdownId ?? "");
    const [customClassString, setCustomClassString] = useState("");
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(e.target.value);
      if (onChangeSelected) onChangeSelected(e.target.value);
    };

    useEffect(() => {
      if (disabled) setCustomClassString("");
      else setCustomClassString("clickable");
    }, [disabled]);

    const checkValid = (): boolean => {
      const valid = required ? selectedOption !== "" : true;
      setIsValid(valid);
      return valid;
    };

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return checkValid();
      },
    }));

    return (
      <>
        <Form.Select
          className={customClassString}
          id={id}
          onChange={handleChange}
          value={selectedOption}
          disabled={disabled}
          aria-label={label}
          title={label}
          isInvalid={isValid !== undefined && !isValid}
          isValid={isValid}
        >
          <option
            value=""
            label="Select an option"
            aria-label="Select an option"
            title="Select an option"
          />
          {options?.map((option: Option, index: number) => (
            <option
              key={`option-${option.value}`}
              value={option.value}
              title={option.text}
              label={option.text}
              aria-label={option.text}
            >
              {option.text}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Invalid {label}.
        </Form.Control.Feedback>
      </>
    );
  }
);

Dropdown.defaultProps = {
  required: false,
  disabled: false,
};

// Country is stored in the Database inside Province as a string.
export function mapCountryToEnum(country: string): number {
  switch (country) {
    case "Canada":
      return 0;
    case "United States":
      return 1;
    case "Netherlands":
      return 2;
    default:
      return 0;
  }
}

export function handlePostalCodeTypeChanges(
  selectedCountry: number | null,
  countryList: Option[] | null
): string {
  let postalType = "Canada"; // Default
  countryList?.forEach((country) => {
    if (selectedCountry?.toString() === country.value)
      postalType = country.text;
  });
  return postalType;
}

export default Dropdown;


