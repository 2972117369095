import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "./IconDefinitions";

interface IconAndTextProps extends React.PropsWithChildren {
  iconName: string;
  text: string | null;
  className?: string;
}

export function IconAndText(props: IconAndTextProps) {
  let i, span;

  if (props.iconName) {
    const iconObject = getFontAwesomeIcon(props.iconName);
    i = <FontAwesomeIcon icon={iconObject} className="fa fa-fw me-1" />;
  }
  if (props.text) {
    span = props.text;
  }

  return (
    <span className={`icon-and-text d-inline-flex align-items-center ${props.className ?? ""}`}>
      {i}
      <span>{span}</span>
    </span>
  );
}

export default IconAndText;

