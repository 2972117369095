import { routes } from "../../app/routes";
import SubmenuSideBar from "../../components/sideBar/SubmenuSideBar";
import UserService from "../../services/UserService";

export function ProfileSubmenuSideBar() {
  const userEmail = UserService.getUserEmail();

  const menuItems = [
    {
      text: "Profile",
      url: routes.manage,
      activeMatchUrl: routes.manage,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: "Password",
      url: routes.changePassword,
      activeMatchUrl: routes.changePassword,
      additionalClass: "submenu-sidebar-nav-item",
    },
  ];

  return <SubmenuSideBar title={userEmail} menuItems={menuItems} />;
}

export default ProfileSubmenuSideBar;
