import { PropsWithChildren, useEffect, useState } from 'react'
import {
    DropDownList,
    DropDownListBlurEvent,
    DropDownListChangeEvent,
    DropDownListFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { FloatingLabel } from 'react-bootstrap';
import { DropdownOption } from './DropdownOption';

interface SearchableDropdownProps extends PropsWithChildren {
  label: string;
  name: string;
  isInvalid?: boolean;
  className?: string;
  options: DropdownOption[];
  defaultItem?: DropdownOption;
  value?: DropdownOption;
  includeAll?: boolean;
  onChange?: (e: DropDownListChangeEvent) => void;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onSelect?: (value: DropdownOption) => void;
}

export function SearchableDropdown(props: Readonly<SearchableDropdownProps>) {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [allData, setAllData] = useState<DropdownOption[]>([]);
  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = allData;
    return filterBy(data, filter);
  };
  const filterChange = (event: DropDownListFilterChangeEvent) => {
    setOptions(filterData(event.filter));
  };

  useEffect(() => {
    setOptions(props.options);
    setAllData(props.options);
  }, [props.options]);

  return (
    <FloatingLabel
      className="mb-3 h-100 floating-label-fixed"
      label={`${props.label}`}
      style={{ "color": "rgba(var(--bs-body-color-rgb), 0.65)" }}>
      <DropDownList
        name={props.name}
        className={`w-100 h-100 bg-transparent ${props.isInvalid ? 'k-invalid is-invalid' : ''} ${props.className}`}
        data={options}
        textField="text"
        dataItemKey="value"
        value={props.value}
        defaultItem={props.defaultItem}
        filterable={true}
        onFilterChange={filterChange}
        onChange={(e) => {
          props.onChange && props.onChange(e);
          props.onSelect && props.onSelect(e.target.value as DropdownOption);
        }}
        onBlur={props.onBlur}
      />
      {props.children}
    </FloatingLabel>
  )
}

export default SearchableDropdown
