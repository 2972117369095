import TubingModel from "../entities/TubingModel";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";

export class TubingRepo extends BaseRepository {
  async getDetails(id: string, oDataQuery: string = ""): Promise<TubingModel> {
    const url = `/OData/Tubings(${id})?expand=Company,Inspections`;
    const response = await this.client.get(url);
    return this.handleResponse<TubingModel>(response)!;
  }

  async getTubings(oDataQuery: string = ""): Promise<ODataResponse<TubingModel>> {
    const url = `/OData/Tubings/?$count=true&${oDataQuery}`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<TubingModel>>(response)!;
  }

  async updateDetails(id: string, data: TubingModel): Promise<boolean | undefined> {
    const url = `/Tubing/${id}`;
    const response = await this.client.post(url, data);
    return this.handleResponse(response);
  }

  async addTubing(data: TubingModel): Promise<boolean | undefined> {
    const url = `/Tubing/Insert`;
    const response = await this.client.post(url, data);
    return this.handleResponse(response);
  }

  async delete(tubingId: number): Promise<boolean> {
    const url = `Tubing/${tubingId}/Delete`;
    const response = await this.client.post(url);
    return response.success;
  }
}

export default TubingRepo;
