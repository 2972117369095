import { AddressModel, CompanyAddModel, TubingModel } from "../data/entities";
import CompanyModel from "../data/entities/CompanyModel";
import ODataResponse from "../data/entities/ODataResponse";
import ProvinceModel from "../data/entities/ProvinceModel";
import { CompanyRepo } from "../data/repo/CompanyRepo";
import LoggedUser from "../models/LoggedUser";
export class CompanyService {

  async getAllCompanies(): Promise<CompanyModel[]> {
    const data = await new CompanyRepo().getCompanies();
    const values = data.value;

    return values;

  }

  async getDetails(id: number): Promise<CompanyModel | null> {
    const response = await new CompanyRepo().getDetails(id);

    const responseDetails = {
      id: response?.id ?? 0,
      name: response?.name ?? "",
      defaultMeasurement: response?.defaultMeasurement ?? 0
    }
    return responseDetails;
  }

  async getAddress(id: number): Promise<AddressModel | null> {
    const response = await new CompanyRepo().getAddress(id);

    const responseDetails = {
        id: response?.id,
        createdDate: response?.address?.createdDate ?? new Date(),
        streetAddress: response?.address?.streetAddress ?? "",
        streetAddress2: response?.address?.streetAddress2,
        city: response?.address?.city ?? "",
        postalCode: response?.address?.postalCode ?? "",
        provinceId: response?.address?.provinceId,
        province: response?.address?.province
    }
    return responseDetails;
  }

  async getUsers(id: number): Promise<ODataResponse<LoggedUser | null>> {
    const response = await new CompanyRepo().getUsers(id);

    return response;
  }

  async updateAddress(id: number, name: string, address: AddressModel) {
    return await new CompanyRepo().updateAddress(id, name, address);
  }

  async addCompany(model: CompanyAddModel) {
    return await new CompanyRepo().addCompany(model);
  }

  async updateCompany(id: number, model: CompanyModel) {
    return await new CompanyRepo().updateCompany(id, model);
  }

  async deleteCompany(id: number) {
    return await new CompanyRepo().deleteCompany(id);
  }

  async getAllCompaniesOData(oDataQuery: string = ""): Promise<ODataResponse<CompanyModel>> {
    const response = await new CompanyRepo().getCompaniesOData(oDataQuery);
    return response;

  }

  async getAllProvinces(): Promise<ProvinceModel | null> {
    const response = await new CompanyRepo().getAllProvinces();

    return response;
  }

  async getTubings(id: number): Promise<TubingModel[]> {
    const response = await new CompanyRepo().getTubings(id);
    
    return response.tubings;
  }
}

export default CompanyService;
