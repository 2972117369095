import { FormEvent, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import sharedProperty from "../../../../app/sharedProperty";
import { HeadingBar, NumberWithValidation, SlideOutModal, SlideOutModalProps } from "../../../../components";
import { DataPointViewModel } from "../../../../data/entities";
import { propsFilter } from "../../../../app/tools";
import * as yup from 'yup';
import { Formik } from 'formik';

export interface InsertEmptySegmentModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<DataPointViewModel>;
  onSubmit: (data: DataPointViewModel) => void;
}

export function EditDataPointViewModal(props: InsertEmptySegmentModalProps) {
  
  const schema = yup.object().shape({
    flw: yup.number().required(),
    flaw: yup.number().required(),
    wall1: yup.number().required(),
    wall2: yup.number().required(),
  });

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);
  const flwInputRef = useRef<HTMLInputElement>(null);

  const patchDataItem = (patch: Object) => {
    props.dataItemState.setter({ ...props.dataItemState.value, ...patch });
  }

  propsFilter(props, ["title", "footer", "onShow"]);

  return (
    <SlideOutModal {...props}
      title={`Edit Data Point ${props.dataItemState.value.dataPointIndexByInspection}`}
      onShow={() => flwInputRef?.current?.focus()}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Save
            </Button>
          </div>
        </div>
      </div>}>

      <HeadingBar h={5} title={`Position: ${props.dataItemState.value.startPosition}m`} />
      <Formik
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit.call(null, props.dataItemState.value);
          setSubmitting(false);
        }}
        initialValues={{
          flw: props.dataItemState.value.flw,
          flaw: props.dataItemState.value.flaw,
          wall1: props.dataItemState.value.wall1,
          wall2: props.dataItemState.value.wall2,
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => {

          // Custom change handler that also patches data item state
          const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;

            handleChange(e);

            const patch: Partial<DataPointViewModel> = {};
            const floatValue = parseFloat(value);
            switch (name) {
              case "flw":
                patch.flw = floatValue;
                break;
              case "flaw":
                patch.flaw = floatValue;
                break;
              case "wall1":
                patch.wall1 = floatValue;
                break;
              case "wall2":
                patch.wall2 = floatValue;
                break;
              default:
                break;
            }

            patchDataItem(patch);
          };

          return (
            <Form 
              ref={formRef} 
              noValidate 
              // validated={validated} 
              onSubmit={handleSubmit}
            >
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="FLW" className="mb-3">
                    <Form.Control
                      autoFocus={true}
                      type="number"
                      name="flw"
                      placeholder="FLW"
                      value={values.flw}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.flw}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flw}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="Flaw" className="mb-3">
                    <Form.Control
                      type="number"
                      name="flaw"
                      placeholder="Flaw"
                      value={values.flaw}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.flaw}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flaw}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="Wall 1" className="mb-3">
                    <Form.Control
                      type="number"
                      name="wall1"
                      placeholder="Wall 1"
                      value={values.wall1}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.wall1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall1}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <FloatingLabel label="Wall 2" className="mb-3">
                    <Form.Control
                      type="number"
                      name="wall2"
                      placeholder="Wall 2"
                      value={values.wall2}
                      onChange={handleCustomChange}
                      isInvalid={!!errors.wall2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flaw}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Button ref={formSubmitRef} type="submit" className="d-none" />
            </Form>
          )
        }}
        </Formik>
      {props.children}
    </SlideOutModal>
  );
}

export default EditDataPointViewModal;

