import React, { PropsWithChildren, useState } from "react";
import { Form } from "react-bootstrap";
import { propsFilter } from "../../app/tools";

interface FormWithValidationProps
  extends React.FormHTMLAttributes<HTMLFormElement>,
    PropsWithChildren {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

function FormWithValidation(props: Readonly<FormWithValidationProps>) {
  const [validated, setValidated] = useState(false);
  const refList: React.MutableRefObject<HTMLElement | undefined | null>[] = [];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    let allValid = true;
    refList.forEach((ref) => {
      if (ref.current && (ref.current as any).validate) {
        const curr = ref.current as any;
        console.debug("curr", curr);
        const validateResult = curr.validate();
        console.debug("validateResult", validateResult);
        allValid = allValid && validateResult;
      }
    });
    const form = e.currentTarget;

    if (allValid && form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, e);
    } else {
      setValidated(false);
    }
  };

  const mapChildren = (children: React.ReactNode): any => {
    return React.Children.map(children ?? [], (child) => {
      if (React.isValidElement(child)) {
        const newRef = React.createRef<HTMLElement>();
        refList.push(newRef);

        let childChildren = null;
        if (child.props?.children)
          childChildren = mapChildren(child.props.children);

        return React.cloneElement(child, {
          ...child.props,
          ref: newRef,
          children: childChildren,
        });
      }
      return child;
    });
  };

  const children = mapChildren(props.children);

  const filteredProps = propsFilter(props, [
    "onSubmit",
    "children",
    "validated",
  ]);

  return (
    <Form
      noValidate
      {...filteredProps}
      validated={validated}
      onSubmit={handleSubmit}
    >
      {children}
    </Form>
  );
}

export default FormWithValidation;

