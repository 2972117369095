export function RegistrationRequestConfirmation() {
  return (
    <>
      <h2 className="mb-4">Access Requested</h2>
      <section>
        <p>
          We've received your registration request. Please give us some time to review your submission,
          and we will contact you as soon as we can.
        </p>
      </section>
    </>
  );
}
export default RegistrationRequestConfirmation;