import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { EditInspectionModel } from "../../../../data/entities";
import {
  CustomDatePicker,
  DirectionPicker,
  HandleDropDownListBlur,
  HandleDropDownListChange,
  MultiLineTextWithValidation,
  SearchableTubingDropdown,
  SpinnerDefault,
  TextWithValidation,
  Toggle,
  TubingPicker,
} from "../../../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { InspectionService } from "../../../../services";
import { showErrorMessage, showSuccessMessage } from "../../../../app/tools";
import { setInspectionForManagement } from "../../../../app/store/inspectionManagementSlice";
import { store } from "../../../../app/store/store";
import { useParams } from "react-router-dom";

export function InspectionSummaryEditor() {
  
  const params = useParams();
  const id = params["id"] ?? "";
  
  const [showSpinner, setShowSpinner] = useState(false);
  const [inspectionDetails, setInspectionDetails] = useState<EditInspectionModel>();

  const fetchInspectionDetails = useCallback(async () => {
    try{
      const inspection = await new InspectionService().getInspectionViewModel(parseInt(id));
      if (inspection)
        setInspectionDetails(inspection);
    }
    catch(e){
      console.error("Error fetching inspection details", e);
    }
  }, [id]);

  useEffect(() => {
    fetchInspectionDetails();
  }, [fetchInspectionDetails])

  const validationSchema = yup.object().shape({
    reference: yup.string().required("Reference is required"),
    invoiceReference: yup.string().required("Invoice is required"),
    inspectionDate: yup.date().required("Date of inspection is required"),
    isReversed: yup.boolean().required("Select the direction of inspection"),
    contact: yup.string().notRequired(),
    specification: yup.string().notRequired(),
    specificationMet: yup.boolean().notRequired(),
    allowedOvalitySpec: yup.string().notRequired(),
    allowedOvalityMet: yup.boolean().notRequired(),
    outsideDiameterSpec: yup.string().notRequired(),
    outsideDiameterMet: yup.boolean().notRequired(),
    summary: yup.string().notRequired()
  });

  const onSubmit = async (values: EditInspectionModel) => {
    setShowSpinner(true);
    console.log("Submitting inspection details form.", values);
    try {
      const ok = await new InspectionService().saveInspection(values);

      if (ok) {
        showSuccessMessage(`Inspection details updated for ${values.reference}`);
        // Update the cached inspection in the store
        store.dispatch(setInspectionForManagement(values));
      }
      else {
        showErrorMessage(`Error save inspection details.`);
      }
    } catch (e) {
      console.error("Error editing inspection details", e);
    }
    setShowSpinner(false);
  };

  if (!inspectionDetails)
    return <SpinnerDefault />;

  return (
    <div className="editor-inspection">
      <Formik
        validationSchema={validationSchema}
        initialValues={inspectionDetails}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
          return showSpinner ? <SpinnerDefault /> : (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="tubingId">
                    <SearchableTubingDropdown
                      name="tubingId"
                      value={values.tubingId}
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      onBlur={(e) => HandleDropDownListBlur(e, setFieldValue)}
                      isInvalid={touched.tubingId && !!errors.tubingId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.tubingId}
                      </Form.Control.Feedback>
                    </SearchableTubingDropdown>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="reference">
                    <FloatingLabel label="Reference" className="mb-3">
                      <Form.Control
                        type="text"
                        name="reference"
                        value={values.reference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reference && !!errors.reference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.reference}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="invoiceReference">
                    <FloatingLabel label="Invoice" className="mb-3">
                      <Form.Control
                        type="text"
                        name="invoiceReference"
                        value={values.invoiceReference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.invoiceReference && !!errors.invoiceReference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceReference}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="inspectionDate">
                    <FloatingLabel className="mb-3 h-100 pb-0 floating-label-fixed" label="Inspection Date" style={{ "color": "rgba(var(--bs-body-color-rgb), 0.65)" }}>
                      <CustomDatePicker
                        label="Inspection Date"
                        value={new Date(values.inspectionDate)}
                        onChangeText={(value: string) => setFieldValue("inspectionDate", new Date(value))}
                        isInvalid={touched.invoiceReference && !!errors.invoiceReference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {`${errors.inspectionDate}`}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="isReversed">
                    <FloatingLabel className="mb-3 h-100 pb-0 floating-label-fixed" label="Direction" style={{ "color": "rgba(var(--bs-body-color-rgb), 0.65)" }}>
                      <DirectionPicker
                        id="isReversed"
                        label="Direction"
                        initialValue={`${values.isReversed}`.toLowerCase()}
                        onSelect={(value: string) => setFieldValue("isReversed", value === "true")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.isReversed}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="contact">
                    <FloatingLabel label="Contact" className="mb-3">
                      <Form.Control
                        type="text"
                        name="contact"
                        value={values.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.contact && !!errors.contact}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contact}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={9} lg={10}>
                  <Form.Group controlId="specification">
                    <FloatingLabel label="Inspection Criteria" className="mb-3">
                      <Form.Control
                        type="text"
                        name="specification"
                        value={values.specification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.specification && !!errors.specification}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.specification}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={3} lg={2} className="d-flex align-items-center">
                  <Toggle
                    id="specificationMet"
                    initialValue={values.specificationMet}
                    className="mb-3"
                    description="Met"
                    onChange={(value: boolean) => setFieldValue("specificationMet", value)}
                  />
                </Col>
              </Row>

              <Row className="my-3">
                <Col xs={9} lg={10}>
                  <Form.Group controlId="allowedOvalitySpec">
                    <FloatingLabel label="Allowed Ovality Specification" className="mb-3">
                      <Form.Control
                        type="text"
                        name="allowedOvalitySpec"
                        value={values.allowedOvalitySpec}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.allowedOvalitySpec && !!errors.allowedOvalitySpec}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.allowedOvalitySpec}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={3} lg={2} className="d-flex align-items-center">
                  <Toggle
                    id="allowedOvalityMet"
                    initialValue={values.allowedOvalityMet}
                    className="mb-3"
                    description="Met"
                    onChange={(value: boolean) => setFieldValue("allowedOvalityMet", value)}
                  />
                </Col>
              </Row>

              <Row className="my-3">
                <Col xs={9} lg={10}>
                  <Form.Group controlId="outsideDiameterSpec">
                    <FloatingLabel label="OD Specification" className="mb-3">
                      <Form.Control
                        type="text"
                        name="outsideDiameterSpec"
                        value={values.outsideDiameterSpec}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.outsideDiameterSpec && !!errors.outsideDiameterSpec}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.outsideDiameterSpec}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={3} lg={2} className="d-flex align-items-center">
                  <Toggle
                    id="outsideDiameterMet"
                    initialValue={values.outsideDiameterMet}
                    className="mb-3"
                    description="Met"
                    onChange={(value: boolean) => setFieldValue("outsideDiameterMet", value)}
                  />
                </Col>
              </Row>

              <Row className="pt-3 border-top">
                <Col>
                  <Form.Group controlId="summary">
                    <FloatingLabel label="Summary" className="mb-3">
                      <TextareaAutosize
                        name="summary"
                        className={`form-control ${touched.summary && !!errors.summary ? 'is-invalid' : ''}`}
                        value={values.summary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.summary}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" className="btn btn-primary btn-lg w-100 w-lg-auto">Save</Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default InspectionSummaryEditor;

