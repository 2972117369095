import React, { useEffect, useState } from "react";
import { InspectionsPageContext } from "../InspectionsPage";
import { useOutletContext } from "react-router-dom";
import {
  HeadingBar,
  SimpleCard,
  SpinnerDefault,
} from "../../../components";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { formFieldStateSetter, showSuccessMessage } from "../../../app/tools";
import { useSelector } from "react-redux";
import { EditInspectionModel, ScaleModel } from "../../../data/entities";
import { InspectionService } from "../../../services";
import * as yup from 'yup';
import { Formik } from "formik";

export function InspectionsScale() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Scale");

  const [scale, setScale] = useState<ScaleModel | undefined | null>();
  const inspection = useSelector((state: any) => state.inspectionManagement.inspection as EditInspectionModel);

  const schema = yup.object().shape({
    flawBaseline: yup.number().required("Flaw Baseline is required."),
    flawMultiplier: yup.number().required("Flaw Multiplier is required."),
    wall1Baseline: yup.number().required("Wall1 Baseline is required."),
    wall1Multiplier: yup.number().required("Wall1 Multiplier is required."),
    wall2Baseline: yup.number().required("Wall2 Baseline is required."),
    wall2Multiplier: yup.number().required("Wall2 Multiplier is required."),
    flwLowValue: yup.number().required("FLW Low Value is required."),
    flwLowConversion: yup.number().required("FLW Low Conversion is required."),
    flwMidValue: yup.number().notRequired(),
    flwMidConversion: yup.number().notRequired(),
    flwHighValue: yup.number().required("FLW High Value is required."),
    flwHighConversion: yup.number().required("FLW High Conversion is required."),
    ovalityLowRange: yup.number().notRequired(),
    ovalityHighRange: yup.number().notRequired(),
    overallThreshold: yup.number().notRequired(),
    ovalityThreshold: yup.number().notRequired(),
  });

  
  useEffect(() => {
    const fetchData = async () => {
      const scale = await new InspectionService().getScale(inspection.id);
      console.log(scale);

      if (!inspection) return;

      setScale(scale);
    };

    fetchData();

    return () => {};
  }, [inspection]);

  if (scale === undefined) return <SpinnerDefault />;

  return (
    <SimpleCard title="Update inspection scale.">
      <Formik
        initialValues={{
          flawBaseline: scale?.flawBaseline?.toFixed(6),
          flawMultiplier: scale?.flawMultiplier?.toFixed(3),
          wall1Baseline: scale?.wall1Baseline?.toFixed(6),
          wall1Multiplier: scale?.wall1Multiplier?.toFixed(3),
          wall2Baseline: scale?.wall2Baseline?.toFixed(6),
          wall2Multiplier: scale?.wall2Multiplier?.toFixed(3),
          flwLowValue: scale?.flwLowValue?.toFixed(3),
          flwLowConversion: scale?.flwLowConversion?.toFixed(3),
          flwMidValue: scale?.flwMidValue?.toFixed(3),
          flwMidConversion: scale?.flwMidConversion?.toFixed(3),  
          flwHighValue: scale?.flwHighValue?.toFixed(3),
          flwHighConversion: scale?.flwHighConversion?.toFixed(3),
          ovalityLowRange: scale?.ovalityLowRange?.toFixed(3),
          ovalityHighRange: scale?.ovalityHighRange?.toFixed(3),
          overallThreshold: scale?.overallThreshold?.toFixed(3),
          ovalityThreshold: scale?.ovalityThreshold?.toFixed(3),
        }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const updatedScale = {
            ...scale,
            ...values
          } as ScaleModel;
          const saved = await new InspectionService().saveScale(
            inspection.id,
            updatedScale!
          );
          setSubmitting(false);
          if (!saved) return;
      
          showSuccessMessage("Inspection Scale saved.");
        }}
       > 
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, isSubmitting}) => {
          console.log("Errorrs: ", errors);
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel label="Flaw Baseline" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flawBaseline"
                      value={values.flawBaseline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flawBaseline && !!errors.flawBaseline}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flawBaseline}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Flaw Multiplier" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flawMultiplier"
                      value={values.flawMultiplier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flawMultiplier && !!errors.flawMultiplier}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flawMultiplier}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="Wall1 Baseline" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="wall1Baseline"
                      value={values.wall1Baseline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.wall1Baseline && !!errors.wall1Baseline}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall1Baseline}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Wall1 Multiplier" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="wall1Multiplier"
                      value={values.wall1Multiplier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.wall1Multiplier && !!errors.wall1Multiplier}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall1Multiplier}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="Wall2 Baseline" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="wall2Baseline"
                      value={values.wall2Baseline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.wall2Baseline && !!errors.wall2Baseline}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall2Baseline}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Wall2 Multiplier" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="wall2Multiplier"
                      value={values.wall2Multiplier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.wall2Multiplier && !!errors.wall2Multiplier}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall2Multiplier}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <HeadingBar h={4} title="FLW Conversions" />
              <Row>
                <Col>
                  <FloatingLabel label="FLW Low Value" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwLowValue"
                      value={values.flwLowValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwLowValue && !!errors.flwLowValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwLowValue}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="FLW Low Conversion" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwLowConversion"
                      value={values.flwLowConversion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwLowConversion && !!errors.flwLowConversion}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwLowConversion}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="FLW Mid Value (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwMidValue"
                      value={values.flwMidValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwMidValue && !!errors.flwMidValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwMidValue}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="FLW Mid Conversion (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwMidConversion"
                      value={values.flwMidConversion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwMidConversion && !!errors.flwMidConversion}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwMidConversion}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="FLW High Value" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwHighValue"
                      value={values.flwHighValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwHighValue && !!errors.flwHighValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwHighValue}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="FLW High Conversion" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="flwHighConversion"
                      value={values.flwHighConversion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.flwHighConversion && !!errors.flwHighConversion}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.flwHighConversion}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <HeadingBar h={4} title="OD Tolerances" />
              <Row>
                <Col>
                  <FloatingLabel label="Low Range (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="ovalityLowRange"
                      value={values.ovalityLowRange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.ovalityLowRange && !!errors.ovalityLowRange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ovalityLowRange}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="High Range (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="ovalityHighRange"
                      value={values.ovalityHighRange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.ovalityHighRange && !!errors.ovalityHighRange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.wall1Multiplier}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <HeadingBar h={4} title="Thresholds" />
              <Row>
                <Col>
                  <FloatingLabel label="Amplitude Threshold (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="overallThreshold"
                      value={values.overallThreshold}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.overallThreshold && !!errors.overallThreshold}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.overallThreshold}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="Ovality Threshold (optional)" className="mb-3">
                    <Form.Control 
                      type="number"
                      name="ovalityThreshold"
                      value={values.ovalityThreshold}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.ovalityThreshold && !!errors.ovalityThreshold}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ovalityThreshold}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit">Save </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </SimpleCard>
  );
}

export default InspectionsScale;

