import { useEffect, useState } from "react";
import {
  SpinnerDefault,
  SearchableCompanyDropdown,
  HandleDropDownListBlur,
  HandleDropDownListChange
} from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { router, routes } from "../../app/routes";
import { RegisterModel } from "../../data/entities";
import { PasswordWithValidation } from "../../components";
import AccountService from "../../services/AccountService";
import { useParams } from "react-router-dom";
import { UrlService } from "../../services";
import { showErrorMessage } from "../../app/tools";

export function RegisterPage() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const key = UrlService.getParamValue("code", urlParams);

  const [showSpinner, setShowSpinner] = useState(false);
  const initialValues: RegisterModel = {
    email: "",
    password: "",
    confirmPassword: ""
  };

  const validationSchema = yup.object({
    email: yup.string().email().required("Enter your email address"),
    password: yup.string().required("Set a password"),
    confirmPassword: yup.string().required("Confirm your password")
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      })
  });

  const onSubmit = async (values: RegisterModel) => {
    setShowSpinner(true);
    try {
      const ok = await new AccountService().register(id, key ?? "", values);

      ok ?
        router.navigate(routes.registerConfirmation) :
        showErrorMessage(`Error inserting invite.`);
    } catch (e) {
      console.error("Error registering", e);
    }
    setShowSpinner(false);
  };
  
  return (
    <>
      <h2>Register</h2>
      <section>
        <h4 className="mb-4">Create your new account.</h4>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
            return showSpinner ? <SpinnerDefault /> : (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <FloatingLabel label="Email" className="mb-3">
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="password">
                      <FloatingLabel label="Password" className="mb-3">
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="confirmPassword">
                      <FloatingLabel label="Confirm Password" className="mb-3">
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="submit" className="btn btn-primary btn-lg w-100">Register</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </section>
    </>
  );
}

export default RegisterPage;
