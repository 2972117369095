import { useOutletContext, useParams } from "react-router-dom";
import {
  BlockButton,
  Option,
  Dropdown,
  SimpleCard,
  TextWithValidation,
} from "../../../components";
import { useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import { CompanyService } from "../../../services";
import ProcessCopyModel from "../../../data/entities/ProcessCopyModel";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsProcessCopy() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Copy");

  const params = useParams();
  const id = params["id"] ?? "";

  const [copyModel, setCopyModel] = useState<ProcessCopyModel | null>(null);

  const [companyDropdown, setCompanyList] = useState<Option[] | null>(null);

  const [showSpinner, setShowSpinner] = useState(false);

  async function copyDataButtonClick() {
    if (copyModel === null) return;

    setShowSpinner(true);
    const ok = await new ProcessService().postProcessCopy(
      parseInt(id),
      copyModel
    );
    setShowSpinner(false);

    if (!ok) return;

    showSuccessMessage("Inspection successfully copied.");
    // Reload data after copying inspection.
    loadData(parseInt(id));
  }

  const loadData = async(id: number) => {

    let companyList: Option[] = [];

    new CompanyService()
    .getAllCompanies()
    .then((data) => {
      data.forEach((company) => {
        let companyInfo = {
          value: company?.id?.toString() ?? "",
          text: company.name ?? "",
        };
        companyList.push(companyInfo);
      });
      setCompanyList(companyList);
    })
    .catch((error) => {
      console.error("Error loading Companies list: ", error);
    });

    new ProcessService()
      .getProcessCopy(id)
      .then((data) => {
        setCopyModel(data);
      })
      .catch((error) => {
        console.error("Failed to fetch process copy data: ", error);
      });
  }

  useEffect(() => {
    loadData(parseInt(id));
  }, [id]);

  const handleChange = (field: string, input: string | number) => {
    setCopyModel((prevState) => ({
      ...(prevState as ProcessCopyModel),
      [field]: input,
    }));
  };

  return (
    <>
      <SimpleCard className="mb-3 p-0" title="Copy the entire inspection.">
        <div className="row">
          <div className="col-12">
            <p>
              This will duplicate everything in this inspection, and create a
              copy under a new company and tubing.
            </p>
            <p className="fst-italic">
              WARNING! This duplicates a lot of data for every inspection that
              is copied. Please remember to maintain the database after each
              copy is made.
            </p>

            <div className="row">
              <div className="col-12">
                <Dropdown
                  label="Company"
                  selectedOption={0}
                  id=""
                  options={companyDropdown}
                  disabled={false}
                  onChangeSelected={(input) => {
                    handleChange("companyId", parseInt(input.toString()));
                  }}
                ></Dropdown>
              </div>
              <div className="col-12">
                <TextWithValidation
                  label="Tubing Name"
                  id=""
                  required={false}
                  onChangeText={(input) => {
                    handleChange("tubingName", input);
                  }}
                ></TextWithValidation>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
                <BlockButton
                  label="Copy Inspection"
                  onClick={copyDataButtonClick}
                  disabled={false}
                  showSpinner={showSpinner}
                ></BlockButton>
              </div>
            </div>
          </div>
        </div>
      </SimpleCard>
      <SimpleCard className="mb-3 p-0" title="Copy history.">
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {copyModel?.processes.map((processDataHistory) => (
              <tr className="clickable" key={getUuid()}>
                <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
                <td>{processDataHistory.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </SimpleCard>
    </>
  );
}

export default InspectionsProcessCopy;

