import InspectionDetails from "../entities/InspectionDetailsModel";
import { EditInspectionModel, InspectionModel, InspectionOvalityViewModel, ScaleModel } from "../entities";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";
import { FileExportType, FileStructure } from "../../models/FileExport";
import { CalibrationModel } from "../entities/CalibrationModel";
import { NormalizeDataPointsModel } from "../entities/NormalizeDataPointsModel";
import CompanyAccessModel from "../entities/CompanyAccessModel";

export class InspectionRepo extends BaseRepository {
  private reportUrl = process.env.REACT_APP_REPORT_URL;

  async queryInspections(oDataQuery: string = ""): Promise<ODataResponse<InspectionModel>> {
    const url = `OData/Inspections/?$count=true&${oDataQuery}&expand=Tubing($expand=Company)&expand=Address($expand=Province)`;

    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<InspectionModel>>(response)!;
  }

  async getMyInspections(oDataQuery: string = ""): Promise<ODataResponse<InspectionModel>> {
    const url = `OData/Inspections/Default.MyInspections?$count=true&${oDataQuery}&expand=Tubing($expand=Company)&expand=Address($expand=Province)`;

    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<InspectionModel>>(response)!;
  }

  async getNormalizeDataPoints(inspectionId: number): Promise<ODataResponse<NormalizeDataPointsModel>> {
    const url = `OData/Inspections(${inspectionId})/DataPointViews?%24select=Id,DataPointIndexByInspection,StartPosition,FLW,Flaw,Wall1,Wall2`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<NormalizeDataPointsModel>>(response)!;
  }

  async getMyInspectionDetails(id: string, compareTo?: string): Promise<InspectionDetails | null> {
    const url = `home/MyInspectionDetails/${id}${compareTo ? "?compareToId=" + compareTo : ""}`;

    const response = await this.client.get(url);
    return this.handleResponse<InspectionDetails>(response);
  }

  async getMyInspectionOvality(id: string): Promise<InspectionOvalityViewModel | null> {
    const url = `home/MyInspectionOvality/${id}`;

    const response = await this.client.get(url);
    return this.handleResponse<InspectionOvalityViewModel>(response);
  }

  geMyInspectionExportUrls(id: number): Map<FileExportType, string> {
    const map = new Map<FileExportType, string>();
    const serverUrl = this.client.baseUrl;

    map.set(FileExportType.csv, `${serverUrl}inspection/${id}/export/csv`);
    map.set(FileExportType.excel, `${serverUrl}inspection/${id}/export/excel`);
    map.set(FileExportType.Report, `${this.reportUrl}export/inspection/${id}.pdf`);

    return map;
  }

  geMyInspectionOvalityExportUrls(id: number): Map<FileExportType, string> {
    const map = new Map<FileExportType, string>();
    const serverUrl = this.client.baseUrl;

    map.set(FileExportType.csv, `${serverUrl}ovality/${id}/export/csv`);
    map.set(FileExportType.excel, `${serverUrl}ovality/${id}/export/excel`);
    map.set(FileExportType.Report, `${this.reportUrl}export/inspection/${id}.pdf`);

    return map;
  }

  async getExportFile(url: string): Promise<FileStructure | null> {
    const response = await this.client.get(url,
      {
        responseType: 'blob',
      });

    if (!response.success) return this.handleAlert(response);

    const disposition = response.headers['content-disposition'] as string;
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
    const match = RegExp(regex).exec(disposition);
    const filename = match ? match[1].replace(/['"]/g, '') : null;
    const file = {
      name: filename,
      data: response.data,
    } as FileStructure;
    return file;
  }

  async createReferencePoint(inspectionId: number, position: number, pointIndex: number, ProcessPositions: boolean): Promise<boolean> {
    const url = `OData/inspections(${inspectionId})/Reference`;

    const body = {
      "DataPointIndex": pointIndex,
      "Position": position,
      "ProcessPositions": ProcessPositions
    };
    const response = await this.client.post(url, body);
    if (!response.success) {
      this.handleAlert(response);
      return false;
    }

    return response.success;
  }

  async addInspection(model: InspectionModel): Promise<number | null> {
    const url = `Inspection/Insert`;
    const body = {
      id: model.id,
      inspectionDate: model.inspectionDate,
      reference: model.reference,
      needsProcessing: model.needsProcessing,
      isReversed: model.isReversed,
      tubingId: model.tubingId,
      contact: model.contact,
      invoiceReference: model.invoiceReference,
      summary: model.summary,
      equipmentCalibrations: model.equipmentCalibrations,
      apI5ST: model.api5st,
      allowedOvalitySpec: model.allowedOvalitySpec,
      allowedOvalityMet: model.allowedOvalityMet,
      address: {
        streetAddress: model.address?.streetAddress,
        streetAddress2: model?.address?.streetAddress2,
        city: model.address?.city,
        postalCode: model.address?.postalCode,
        provinceId: model.address?.provinceId,
      }
    };
    const response = await this.client.post<number>(url, body);
    const handled = this.handleResponse(response);
    return handled;
  }

  async publish(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/publish`;

    const response = await this.client.post(url);
    return response.success;
  }

  async delete(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/delete`;

    const response = await this.client.post(url);
    return response.success;
  }

  async getInspectionDetailsViewModel(id: number): Promise<EditInspectionModel | null> {
    const url = `Inspection/${id}`;

    const response = await this.client.get(url);
    return this.handleResponse<EditInspectionModel>(response);
  }

  async saveInspection(inspection: EditInspectionModel) {
    const url = `Inspection/${inspection.id}`;

    const response = await this.client.post(url, inspection);
    this.handleResponse(response);
    return response.success;
  }

  async unpublish(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/unpublish`;

    const response = await this.client.post(url);
    return response.success;
  }

  async requestApproval(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/requestApproval`;

    const response = await this.client.post(url);
    return response.success;
  }

  async revokeApproval(inspectionId: number): Promise<boolean> {
    const url = `inspection/${inspectionId}/revokeApproval`;

    const response = await this.client.post(url);
    return response.success;
  }

  async getCalibrationData(inspectionId: number): Promise<CalibrationModel> {
    const url = `inspection/${inspectionId}/calibration`;
    const response = await this.client.get(url);
    return this.handleResponse(response)
  }

  async updateCalibrationData(inspectionId: number, calibration: CalibrationModel): Promise<boolean> {
    const url = `inspection/${inspectionId}/calibration`;
    console.log(calibration);
    const response = await this.client.post(url, calibration);
    return this.handleResponse(response);
  }

  async getInspectors(id: number) {
    const url = `Inspection/${id}/Inspectors`;

    const response = await this.client.get(url);
    return this.handleResponse<InspectionModel>(response);
  }

  async addInspector(id: number, userId: number) {
    const url = `Inspection/${id}/AddInspector?userId=${userId}`;
    const body = {
      id: id,
      userId: userId
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async removeInspector(id: number, userId: number) {
    const url = `Inspection/${id}/RemoveInspector?userId=${userId}`;
    const body = {
      id: id,
      userId: userId
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getAccess(id: number) {
    const url = `/Inspection/${id}/Access`;
    console.log(url);
    const response = await this.client.get(url);
    console.log("response: ", response);
    return this.handleResponse<CompanyAccessModel>(response);
  }

  async addCompanyAccess(id: number, companyId: number) {
    const url = `Inspection/${id}/AddCompany?companyId=${companyId}`;

    const response = await this.client.post(url);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async removeCompanyAccess(id: number, companyId: number) {
    const url = `Inspection/${id}/RemoveCompany?companyId=${companyId}`;

    const response = await this.client.post(url);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async getScale(inspectionId: number): Promise<ScaleModel | null> {
    const url = `inspection/${inspectionId}/scale`;

    const response = await this.client.get(url);
    return this.handleResponse<ScaleModel>(response);
  }

  async saveScale(inspectionId: number, scale: ScaleModel): Promise<boolean> {
    const url = `inspection/${inspectionId}/scale`;

    const response = await this.client.post(url, scale);
    return response.success;
  }
}

export default InspectionRepo;
