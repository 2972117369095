import { useEffect, useState } from "react";
import { SimpleCard } from "../../components/card/Card";
import CompanyService from "../../services/CompanyService";
import { router, routes } from "../../app/routes";
import {
  IconAndText,
  IconDefinitions,
  Link,
  SpinnerDefault,
} from "../../components";
import { useParams, useLocation } from "react-router-dom";
import UrlService from "../../services/UrlService";
import { usePageActions } from "../PageContent";

export function CompanyUsersCard() {
  const urlParams = useParams();
  const location = useLocation();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [users, setUsers] = useState<{ id: number; email: string }[] | null>(
    null
  );

  usePageActions(
    <>
      <Link className="btn btn-primary" url={`${routes.userInsertInvitation}?returnUrl=${location.pathname}&?companyId=${companyId}`}>
        <IconAndText iconName={IconDefinitions.add} text={"Invite New User"} />{" "}
      </Link>
    </>
  );

  useEffect(() => {
    let userList: { id: number; email: string }[] = [];

    new CompanyService()
      .getUsers(companyId)
      .then((data) => {
        data.value.forEach((user) => {
          const id = user?.id ?? 0;
          const email = user?.email ?? "";
          const userInfo = { id: id, email: email };
          userList.push(userInfo);
        });
        setUsers(userList);
      })
      .catch((error) => {
        console.error("Error fetching company user data:", error);
      });
  }, [companyId]);

  async function userRowClick(id: number) {
    router.navigate(`/User/${id}?returnUrl=%2FCompany%2F${companyId}%2FUsers`);
  }

  if (users === null) return <SpinnerDefault />;

  return (
    <SimpleCard title="View company accounts.">
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, index) => (
            <tr
              className="clickable"
              key={item.id}
              onClick={() => userRowClick(item.id)}
            >
              <td>{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </SimpleCard>
  );
}

export default CompanyUsersCard;

