import { useOutletContext, useParams } from "react-router-dom";
import { BlockButton, SimpleCard, SpinnerDefault, TextWithValidation } from "../../../components";
import { useEffect, useState } from "react";
import { router, routes } from "../../../app/routes";
import ProcessService from "../../../services/ProcessService";
import ProcessMergeModel from "../../../data/entities/ProcessMergeModel";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsProcessMerge() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Merge Segments");

  const params = useParams();
  const id = params["id"] ?? "";

  const [mergeModel, setMergeModel] = useState<ProcessMergeModel | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  

  async function mergeDataButtonClick() {

    if(mergeModel === null) return;

    setShowSpinner(true);
    const ok = await new ProcessService().postProcessMerge(parseInt(id), mergeModel);
    setShowSpinner(false);

    if(!ok) return;

    showSuccessMessage("Inspection data successfully merged.");
    router.navigateWithParams(routes.inspectionsDataSegment, new Map([["id", id]]));
  }

  useEffect(() => {
    new ProcessService()
    .getProcessMerge(parseInt(id))
    .then((data) => {
      setMergeModel(data);
      setIsDataLoaded(true);
    })
    .catch((error) => {
      console.error("Failed to fetch process merge data: ", error);
    });
  }, [id])

  const handleChange = (field: string, input: string) => {
    setMergeModel((prevState) => ({
      ...prevState as ProcessMergeModel,
      [field]: input,
    }));
  }

  if (!isDataLoaded) return <SpinnerDefault />;

  return (
    <>
    <SimpleCard className="mb-3 p-0" title="Merge two data segments together.">
      <div className="row">
        <div className="col-12">
              <p>This data process merges two sequential segments together into a single data segment. This is useful for undoing a split, so you can merge the segments back together and then re-split them at a different reference point if need be.</p>
              <p>After you merge the data, you may want to recalculate the "Positions" because the merge may or may not affect how the positions are calculated.</p>

          <div className="row">
            <div className="col-12 col-md-6">
            <TextWithValidation
              label="Segment Index 1"
              id=""
              required={false}
              initialValue={mergeModel?.segmentIndex1.toString()}
              onChangeText={(input) => {
                handleChange("segmentIndex1", input);
              }}
              type="number"
            ></TextWithValidation>
            </div>
            <div className="col-12 col-md-6">
            <TextWithValidation
              label="Segment Index 2"
              id=""
              required={false}
              initialValue={mergeModel?.segmentIndex2.toString()}
              onChangeText={(input) => {
                handleChange("segmentIndex2", input);
              }}
              type="number"
            ></TextWithValidation>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
              <BlockButton
                label="Merge Data"
                onClick={mergeDataButtonClick}
                disabled={false}
                showSpinner={showSpinner}
              ></BlockButton>
            </div>
          </div>
        </div>
      </div>
    </SimpleCard>
    <SimpleCard className="mb-3 p-0" title="Segment merging history.">
    <table className="table hover-row mt-3">
        <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
        {mergeModel?.processes.map((processDataHistory) => (
            <tr className="clickable" key={getUuid()}>
              <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
              <td>{processDataHistory.description}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </SimpleCard>
    </>
  );
}

export default InspectionsProcessMerge;

