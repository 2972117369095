import {
  GridCellProps,
  GridColumn,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import {
  ConfirmationModal,
  ResponsiveGridColumn,
  Icon,
  IconDefinitions,
  ODataGrid,
  SpinnerDefault,
  TitlelessCard,
  Breakpoints,
} from "../../components";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { InspectionModel, ODataResponse } from "../../data/entities";
import { InspectionService } from "../../services";
import { toYearMonthDay } from "../../app/dateHelpers";
import { useRef, useState, useCallback } from "react";
import { router, routes } from "../../app/routes";
import PageContent, { usePageActions } from "../PageContent";
import InspectionRootSubmenu from "./InspectionRootSubmenu";
import { useSharedState } from "../../app/sharedProperty";

export function InspectionsList() {
  const gridRef = useRef<ODataGrid>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const publishItemState = useSharedState({} as InspectionModel);
  const showPublishInspectionState = useSharedState(false);

  const deleteItemState = useSharedState({} as InspectionModel);
  const showDeleteInspectionState = useSharedState(false);

  usePageActions(
    <>
      <a href={routes.inspectionsInsert} className="btn btn-primary">
        <Icon iconName="plus" />
        Add Inspection
      </a>
    </>
  );

  const getInspections = (oDataQuery: string): Promise<ODataResponse<any>> => {
    return new InspectionService().queryInspections(oDataQuery);
  };

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;

    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(
          routes.inspectionsDetails,
          new Map([["id", dataItem.id]])
        );
        break;
      case "opentab":
        router.navigateWithParams(
          routes.inspectionsDetails,
          new Map([["id", dataItem.id]]),
          true
        );
        break;
      case "publish":
        showPublishInspection(dataItem);
        break;
      case "delete":
        showDeleteInspection(dataItem);
        break;
    }
  };

  const onRowClick = (e: GridRowClickEvent) => {
    if (e.dataItem.isDeleted) return;

    router.navigateWithParams(
      routes.inspectionsDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  const dateCellBuilder = (props: GridCellProps) => {
    return <td>{toYearMonthDay(props.dataItem.inspectionDate)}</td>;
  };

  const showPublishInspection = useCallback(
    (dataItem: InspectionModel) => {
      // Save the data item to publish, and show modal
      publishItemState.setter(dataItem);
      showPublishInspectionState.setter(true);
    },
    [publishItemState, showPublishInspectionState]
  );

  const showDeleteInspection = useCallback(
    (dataItem: InspectionModel) => {
      // Save the data item to delete, and show modal
      deleteItemState.setter(dataItem);
      showDeleteInspectionState.setter(true);
    },
    [deleteItemState, showDeleteInspectionState]
  );

  const publishInspection = async (dataItem?: InspectionModel) => {
    if (!dataItem) return;

    setShowSpinner(true);
    try {
      const isPublished = await new InspectionService().publishInspection(
        dataItem.id!
      );
      if (!isPublished) throw Error("Value returned was 'not published'.");
      gridRef.current?.refreshData();
    } catch (err) {
      throw Error("Unable to publish the inspection. " + err);
    } finally {
      setShowSpinner(false);
    }
  };

  const deleteInspection = async (dataItem?: InspectionModel) => {
    if (!dataItem) return;

    setShowSpinner(true);
    try {
      const isDeleted = await new InspectionService().deleteInspection(
        dataItem.id!
      );
      if (!isDeleted) throw Error("Value returned was 'not deleted'.");
      gridRef.current?.refreshData();
    } catch (err) {
      throw Error("Unable to delete the inspection. " + err);
    } finally {
      setShowSpinner(false);
    }
  };

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-end">
        {props.dataItem.isPublished ? null : (
          <Icon
            iconName={IconDefinitions.publish}
            className="mr-2 text-primary"
            onClick={() => showPublishInspection(props.dataItem)}
          />
        )}
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDeleteInspection(props.dataItem)}
        />
      </td>
    ),
    [showDeleteInspection, showPublishInspection]
  );

  // Function to format the address field with multiple data values
  const formatAddress = (dataItem: any) => {
    // Customize this function to format the address as needed
    return `${dataItem.address.city}, ${dataItem.address.province.countryCode}`;
  };

  const addressCellBuilder = useCallback((props: GridCellProps) => {
    return <td>{formatAddress(props.dataItem)}</td>;
  }, []);

  return (
    <>
      <PageContent
        title="Inspections"
        breadcrumbs={{ values: [{ label: "Inspections" }] }}
        submenu={<InspectionRootSubmenu />}
        footerless={true}
      >
        <SpinnerDefault show={showSpinner} fullscreen={true} />
        <TitlelessCard bodyClassName="p-0">
          <ODataGrid
            ref={gridRef}
            getData={getInspections}
            onRowMenuSelect={onRowMenuSelect}
            onRowClick={onRowClick}
            sort={[{ field: "inspectionDate", dir: "desc" }]}
            scrollable="scrollable"
            fullHeight={true}
          >
            <ResponsiveGridColumn
              className="column-date"
              title="Date"
              field="inspectionDate"
              sortable={true}
              filter="date"
              cell={dateCellBuilder}
              breakpoint={Breakpoints.sm}
            />
            <GridColumn
              className="column-reference"
              title="Reference"
              field="reference"
              sortable={true}
              filter="text"
            />
            <ResponsiveGridColumn
              className="column-tubing"
              title="Tubing"
              field="tubing.name"
              sortable={true}
              filter="text"
              breakpoint={Breakpoints.md}
            />
            <ResponsiveGridColumn
              className="column-tubing-length"
              title="Length (m)"
              field="tubing.length"
              sortable={true}
              filterable={false}
              width={110}
              breakpoint={Breakpoints.md}
            />
            <ResponsiveGridColumn
              className="column-tubing-size"
              title="Size (inches)"
              field="tubing.sizeDescription"
              sortable={true}
              filterable={false}
              width={110}
              breakpoint={Breakpoints.md}
            />
            <ResponsiveGridColumn
              className="column-address"
              title="Inspection Location"
              field="address.city"
              sortable={true}
              filter="text"
              cell={addressCellBuilder}
              breakpoint={Breakpoints.sm}
            />
            <ResponsiveGridColumn
              className="column-company"
              title="Company"
              field="tubing.company.name"
              sortable={true}
              filter="text"
              breakpoint={Breakpoints.xxl}
            />
            <ResponsiveGridColumn
              className="column-url"
              field="url"
              title=" "
              sortable={false}
              filterable={false}
              headerCell={() => null}
              cell={commandsCell}
              width={70}
              breakpoint={Breakpoints.sm}
            />
            <MenuItem
              text="Open"
              data={{ action: "open" }}
              icon="hyperlink-open"
            />
            <MenuItem
              text="Open Tab"
              data={{ action: "opentab" }}
              icon="windows"
            />
            <MenuItem
              text="Publish"
              data={{ action: "publish" }}
              icon={IconDefinitions.publish}
            />
            <MenuItem
              text="Delete"
              data={{ action: "delete" }}
              icon={IconDefinitions.delete}
            />
          </ODataGrid>
        </TitlelessCard>
      </PageContent>
      <ConfirmationModal
        showState={showPublishInspectionState}
        title={`Publish Inspection ${publishItemState.value?.reference}`}
        message={`You are about to publish this inspection so it will be visible to other users. Are you sure you want to publish inspection ${publishItemState.value?.reference}?`}
        confirmText="Publish"
        onConfirm={() => publishInspection(publishItemState.value)}
      />
      <ConfirmationModal
        showState={showDeleteInspectionState}
        title={`Delete Inspection ${deleteItemState.value?.reference}`}
        message={`You are about to delete this inspection, it cannot be undone. Are you sure you want to delete inspection ${publishItemState.value?.reference}?`}
        confirmText="Delete"
        onConfirm={() => deleteInspection(deleteItemState.value)}
      />
    </>
  );
}

export default InspectionsList;
