import { routes } from "../../app/routes";
import { Submenu, IconDefinitions, SubmenuNavigationItem } from "../../components";

export const CompanyRootSubmenu = () => {

  const submenus: SubmenuNavigationItem[] = [
    {
      label: "Companies",
      icon: IconDefinitions.company,
      url: routes.company,
      activeMatch: routes.company,
    },
    {
      label: "Add Company",
      icon: IconDefinitions.add,
      url: routes.companyInsert,
      activeMatch: routes.companyInsert,
    },
  ];

  return <Submenu items={submenus}></Submenu>;
};

export default CompanyRootSubmenu;
