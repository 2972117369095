import { routes } from "../../app/routes";
import Submenu from "../../components/submenu/Submenu";


export function SettingsSubmenu() {
    const submenus = [
        { label: "Settings", icon: "", url: routes.settings, activeMatch: routes.settings, activeMatchExact: true },
        { label: "Accounts", icon: "", url: routes.settingsAccount, activeMatch: routes.settingsAccount, activeMatchExact: true },
        { label: "Scale", icon: "", url: routes.settingsScale, activeMatch: routes.settingsScale, activeMatchExact: true },
    ]

  return (
    <Submenu items={submenus}></Submenu>
  )
}

export default SettingsSubmenu
