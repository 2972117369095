import { useParams } from "react-router-dom";
import { router, routes } from "../../app/routes";
import SubmenuSideBar from "../../components/sideBar/SubmenuSideBar";
import UrlService from "../../services/UrlService";
import { useSharedState } from "../../app/sharedProperty";
import { SlideOutModal } from "../../components/modals/SlideOutModal";
import { Button } from "react-bootstrap";
import CompanyService from "../../services/CompanyService";
import { showErrorMessage, showSuccessMessage } from "../../app/tools";

interface CompanySubmenuSideBarProps {
  title: string,
};

export function CompanySubmenuSideBar(props: CompanySubmenuSideBarProps) {

  const urlParams = useParams();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const map = new Map<string, string>();
  const urlId = map.set('id', companyId.toString());

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await new CompanyService().deleteCompany(companyId);
      if(!ok) return;
      showSuccessMessage("Company: " + props.title + " was successfully deleted.");
      router.navigate(routes.company)
      .catch((error) => {
        showErrorMessage("Unable to delete company: " + props.title + ", an error occurred: " + error);
      });
  };
  
  const menuItems = [
    {
      text: 'Details',
      url: router.getUrl(routes.companyDetails, urlId),
      activeMatchUrl: routes.companyDetails,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Address',
      url: router.getUrl(routes.companyAddress, urlId),
      activeMatchUrl: routes.companyAddress,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Users',
      url: router.getUrl(routes.companyUsers, urlId),
      activeMatchUrl: routes.companyUsers,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Tubings',
      url: router.getUrl(routes.companyTubings, urlId),
      activeMatchUrl: routes.companyTubings,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Delete',
      url: routes.company,
      additionalClass: "submenu-sidebar-nav-item",
      clickHandler: () => showSelectModalState.setter(true)
    },
  ];

  return (
    <>
      <SubmenuSideBar menuItems={menuItems} />
      <SlideOutModal
          showState={showSelectModalState}
          title={"Delete Company"}
          footer={<>
            <Button onClick={selectModalCancelClick} variant="primary" className="w-100">
              Close
            </Button>
            <Button onClick={deleteSelectClick} variant="secondary" className="w-100">
              Delete
            </Button>
          </>}
        >
          Are you sure you want to delete this company?
        </SlideOutModal>
    </>
  );
}

export default CompanySubmenuSideBar;
