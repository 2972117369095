import { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { PageBreadcrumbProps, PageContent } from "../PageContent"
import UsersSubmenu from "./UsersSubmenu";
import { UrlService, UserService } from "../../services";
import { ODataResponse, UserDetailsModel } from "../../data/entities";
import { generateBreadcrumbs, BreadcrumbProps } from "./UsersBreadcrumbs";

export interface UsersPageContext {
  title?: string;
  setTitle: (title?: string) => void;
  sidemenu?: ReactElement;
  setSidemenu: (sidemenu?: ReactElement) => void;
}

export function UsersPage() {

  const location = useLocation();
  const urlParams = useParams();
  const userId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [title, setTitle] = useState("");
  const [sidemenu, setSidemenu] = useState<ReactElement>();
  const [userName, setUserName] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();

  const setTitleRelay = (title?: string) => {
    setTimeout(() => {
      setTitle(title ?? "");
    }, 100);
  }

  /**
   * When the user id changes, set the new user's name
   */
  useEffect(() => {
    async function getUserById(){
      const user = await new UserService().getUsers(`filter=id eq ${userId}`);
      return user;
    };

    if(userId){
      getUserById().then((response: ODataResponse<UserDetailsModel>) => {
        setUserName(response.value[0].userName);
      });
    }
  }, [userId]);

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    const newBreadcrumbs: BreadcrumbProps = generateBreadcrumbs(location.pathname, userName, userId);
    setBreadcrumbs(newBreadcrumbs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const GetContext = (): UsersPageContext => {
    return {
      title: title,
      setTitle: setTitleRelay,
      sidemenu: sidemenu,
      setSidemenu: setSidemenu
    };
  }

  return (
    <PageContent
      title={title ?? "Manage user accounts"}
      breadcrumbs={breadcrumbs}
      submenu={<UsersSubmenu />}
      sidemenu={sidemenu}
      footerless>
      <Outlet context={GetContext()} />
    </PageContent>
  )
}

export default UsersPage
