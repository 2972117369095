import { SetStateAction, useEffect, useState } from "react";
import { router, routes } from "../../app/routes";
import { Button } from "react-bootstrap";
import { showSuccessMessage } from "../../app/tools";
import { SystemOfMeasurement, UserProfileModel } from "../../data/entities";
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import { SimpleCard } from "../../components/card/Card";
import TextWithValidation from "../../components/form/input/TextWithValidation";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import { Dropdown, Option } from "../../components/form/dropdown/Dropdown";
import SpinnerDefault from "../../components/spinners/SpinnerDefault";

export function ProfilePage() {
  // Fields that contain the updated User Profile info.
  const [email, setEmail] = useState(UserService.getUserEmail());
  const [company, setCompany] = useState(UserService.getCompanyId());
  const [firstName, setFirstName] = useState(UserService.getFirstName());
  const [lastName, setLastName] = useState(UserService.getLastName());
  const [preferredMeasurement, setPreferredMeasurement] = useState(
    UserService.getPreferredMeasurement()
  );

  // User Profile values onload are valid by default.
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isValid, setIsValid] = useState(
    isEmailValid && isFirstNameValid && isLastNameValid
  );

  const [companyDropdown, setCompanyList] = useState<Option[] | null>(null);
  const [measurementDropdown, setMeasurementList] = useState<Option[] | null>(
    null
  );

  enum fields {
    email,
    company,
    firstName,
    lastName,
    preferredMeasurement,
  }

  const handleProfileChanges = (
    field: fields,
    input: SetStateAction<string>,
    result?: boolean
  ) => {
    switch (field) {
      case fields.email:
        setEmail(input);
        setIsEmailValid(result ?? false);
        break;
      case fields.firstName:
        setFirstName(input);
        setIsFirstNameValid(result ?? false);
        break;
      case fields.lastName:
        setLastName(input);
        setIsLastNameValid(result ?? false);
        break;
      case fields.preferredMeasurement:
        setPreferredMeasurement(parseInt(input.toString()));
        break;
      case fields.company:
        setCompany(parseInt(input.toString()));
        break;
    }
  };

  useEffect(() => {
    let companyList: Option[] = [];

    new CompanyService()
      .getAllCompanies()
      .then((data) => {
        data.forEach((company) => {
          let companyInfo = {
            value: company?.id?.toString() ?? "",
            text: company.name ?? "",
          };
          companyList.push(companyInfo);
        });
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.error("Error loading Companies list: ", error);
      });

    let preferredMeasurementList: Option[] = [];

    const enumValues = Object.values(SystemOfMeasurement).filter(
      (value) => typeof SystemOfMeasurement[value as any] === "number"
    ); // Filter is to remove the reverse mappings as entries
    let enumCount = 0;
    enumValues.forEach((value) => {
      const id = enumCount.toString();
      const measurementType = value;
      const measurementInfo = { value: id, text: measurementType.toString() };
      preferredMeasurementList.push(measurementInfo);
      enumCount++;
    });
    setMeasurementList(preferredMeasurementList);
  }, []);

  useEffect(() => {
    setIsValid(isEmailValid && isFirstNameValid && isLastNameValid);
  }, [isEmailValid, isFirstNameValid, isLastNameValid]);

  async function updateProfileButtonClick() {
    if (!isValid) return;
    let updatedProfileModel: UserProfileModel = {
      id: parseInt(UserService.getId()),
      email: email,
      firstName: firstName,
      lastName: lastName,
      preferredMeasurement: preferredMeasurement,
    };

    const ok = await new UserService().updateProfile(updatedProfileModel);

    if (!ok) return;

    showSuccessMessage("Your profile was successfully updated.");
    router.navigate(routes.manage);
  }

  if (companyDropdown === null) return <SpinnerDefault />;

  return (
    <SimpleCard title="Update your profile.">
      <TextWithValidation
        label="UserName"
        initialValue={UserService.getDisplayName()}
        id=""
        disabled={true}
      ></TextWithValidation>
      <div className="row">
        <div className="col-12 col-md-6">
          <EmailWithValidation
            label="Email"
            initialValue={email}
            required={true}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.email, input, result);
            }}
          ></EmailWithValidation>
        </div>
        <div className="col-12 col-md-6">
          <Dropdown
            label="Company"
            selectedOption={company}
            id=""
            options={companyDropdown}
            disabled={true}
            onChangeSelected={(input) => {
              handleProfileChanges(fields.company, input);
            }}
          ></Dropdown>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <TextWithValidation
            label="First Name"
            initialValue={firstName}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.firstName, input, result);
            }}
          ></TextWithValidation>
        </div>
        <div className="col-12 col-md-6">
          <TextWithValidation
            label="Last Name"
            initialValue={lastName}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.lastName, input, result);
            }}
          ></TextWithValidation>
        </div>
      </div>
      <div className="row mb-3 flat-card">
        <div className="col-12 card-body">
          <h5>Preferred System of Measurement</h5>
          <p>
            Choose how you would like measurements to be displayed for you. This
            will affect the interactive website, but the downloadable inspection
            reports will be processed using the measurement preferences of the
            company.
          </p>
          <Dropdown
            label="My Preferred System"
            selectedOption={preferredMeasurement}
            id=""
            options={measurementDropdown}
            disabled={false}
            onChangeSelected={(input) => {
              handleProfileChanges(fields.preferredMeasurement, input);
            }}
          ></Dropdown>
        </div>
      </div>
      <div className="col-1">
        <Button onClick={updateProfileButtonClick} disabled={false}>Save</Button>
      </div>
    </SimpleCard>
  );
}

export default ProfilePage;

