import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { HeadingBar, SlideOutModal, SlideOutModalProps, Toggle } from "../../../../components";
import { OvalityInsertModel } from "../../../../data/entities";
import { Upload } from "@progress/kendo-react-upload";
import { UserService } from "../../../../services";

export interface InsertOvalityModalProps extends SlideOutModalProps {
  inspectionId: number,
  onSubmit: (data: OvalityInsertModel) => void;
}

export function InsertOvalityModal(props: InsertOvalityModalProps) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    compress: true,
    metric: false,
    file: null
  } as OvalityInsertModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
    } else {
      setValidated(false);
    }
  };

  if (props.title !== undefined) delete props.title;
  if (props.footer !== undefined) delete props.footer;

  return (
    <SlideOutModal {...props}
      title="Insert Empty Ovality"
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Insert
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <p>
          <span className="text-danger">Warning</span>: When uploading new ovality data, any existing data will be erased! If you already have ovality data uploaded for this inspection, be absolutely sure you want to replace it before you import any new data.
        </p>
        <Upload
          batch={false}
          multiple={false}
          defaultFiles={[]}
          withCredentials={false}
          autoUpload={true}
          saveHeaders={{
            Authorization: `Bearer ${UserService.getUserToken()}`
          }}
          removeHeaders={{
            Authorization: `Bearer ${UserService.getUserToken()}`
          }}
          saveUrl={`${process.env.REACT_APP_API_URL}OvalityFile/${props.inspectionId}/Save`}
          removeUrl={`${process.env.REACT_APP_API_URL}OvalityFile/${props.inspectionId}/Remove`}
          onStatusChange={(e) => {
            if (!e.newState) return;
            const file = e.newState.length ? e.newState[0] : null;
            setValues({ ...values, file: file });
          }}
          onRemove={(e) => {
            setValues({ ...values, file: null });
          }}
        />
        <div className="row mt-2">
          <div className="col-12">
            <HeadingBar h={5} title="Compression" />
            <p>
              When uploading data, compression will take extra data points and combine them together to keep the outliers while reducing the overall data file size.
            </p>
            <Toggle
              description="Compress"
              initialValue={values.compress}
              onChange={(v) => setValues({ ...values, compress: v })}
            />
          </div>
          <div className="col-12">
            <HeadingBar h={5} title="Metric Data" />
            <p>
              The system needs to know if the data file contains metric measurements or not so it can compare to nominal, and so that it knows how to convert values for customers in different regions based on their preferred measurement system.
            </p>
            <Toggle
              description="Is Metric"
              initialValue={values.metric}
              onLabel="Yes"
              offLabel="No"
              onChange={(v) => setValues({ ...values, metric: v })}
            />
          </div>
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default InsertOvalityModal;

