import "./Tubing.scss";
import { GridColumn, GridRowClickEvent, } from "@progress/kendo-react-grid";
import PageContent, { PageBreadcrumbProps } from "../PageContent"
import { router, routes } from "../../app/routes";
import { ODataGrid, TitlelessCard } from "../../components";
import { MenuSelectEvent } from "@progress/kendo-react-layout";
import { TubingService } from "../../services";
import { TubingSubmenu } from "./";

export function TubingPage() {
  const title: string = "Tubings";

  const breadcrumbs: PageBreadcrumbProps = {
    showHome: true,
    values: [{ label: title }],
  };

  const tubingService = new TubingService();

  const getTubings = (oDataQuery: string) =>
    tubingService.getAllTubings(`$expand=company&${oDataQuery}`);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(routes.tubingDetails, new Map([
          ['id', dataItem.id]
        ]));
        break;
      case "opentab":
        router.navigateWithParams(routes.tubingDetails, new Map([
          ['id', dataItem.id]
        ]), true);
        break;
    }
  };

  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      routes.tubingDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  return (
    <PageContent submenu={<TubingSubmenu />} breadcrumbs={breadcrumbs} footerless title="Tubings">
      <TitlelessCard className="p-0" bodyClassName="p-0">
        <ODataGrid
          getData={getTubings}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={onRowClick}
          sort={[{ field: "name", dir: "asc" }]}
          scrollable="scrollable"
          pageSize={100}
          fullHeight={true}
        >
          <GridColumn title="Name" field="name" sortable={true} filter="text" />
          <GridColumn title="Company" field="company.name" sortable={true} filter="text" />
        </ODataGrid>
      </TitlelessCard>
    </PageContent >
  );
}


export default TubingPage;
