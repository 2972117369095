import React, { useImperativeHandle, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";

interface MultiLineTextWithValidationProps {
  id: string;
  label: string;
  required?: boolean;
  initialValue?: string;
  disabled?: boolean;
  validationFunction?: (input: string) => void;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
  rows?: number;
}

export const MultiLineTextWithValidation = React.forwardRef(
  (props: MultiLineTextWithValidationProps, ref) => {
    const {
      id,
      label,
      required,
      disabled,
      initialValue,
      validationFunction,
      onChangeText,
      validationResult,
    } = props;

    const [value, setValue] = useState(initialValue ?? "");
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      const changedValue = event.target.value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    function handleBlur(event: React.FocusEvent<HTMLTextAreaElement>): boolean {
      const result = required ? value !== "" : true;
      setIsValid(result);
      if (validationResult) validationResult(value, result);
      if (validationFunction) validationFunction(value);

      return result;
    }

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleBlur({} as React.FocusEvent<HTMLTextAreaElement>);
      },
    }));

    const getValidationClass = () => {
      if (isValid === undefined) return "";
      return isValid ? "is-valid" : "is-invalid";
    };

    return (
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3 autosize">
          <TextareaAutosize
            className={`form-control autosize has-input ${getValidationClass()}`}
            style={{ resize: "none" }}
            rows={props.rows}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={disabled ?? false}
            required={required}
            placeholder={label}
            aria-invalid={!isValid}
            ref={ref as any}
          />
          <Form.Control.Feedback type="invalid">
            Invalid {label}.
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    );
  }
);

MultiLineTextWithValidation.defaultProps = {
  required: false,
  disabled: false,
  rows: 3,
};

export default MultiLineTextWithValidation;


