import { createSlice } from "@reduxjs/toolkit";
import { EditInspectionModel, NoteModel } from "../../data/entities";

interface InspectionManagementSlice {
  inspectionId: number | undefined;
  inspection: EditInspectionModel | undefined;
  note: NoteModel | undefined;
}

const initialState: InspectionManagementSlice = {
  inspectionId: undefined,
  inspection: undefined,
  note: undefined,
};

const inspectionManagementSlice = createSlice({
  name: "inspectionManagement",
  initialState,
  reducers: {
    setInspectionIdForManagement: (state, action) => {
      state.inspectionId = Number.parseInt(action.payload);
    },
    setInspectionForManagement: (state, action) => {
      state.inspection = action.payload;
    },
    setNoteForManagement: (state, action) => {
      state.note = action.payload;
    },
  },
});

export const { setInspectionIdForManagement, setInspectionForManagement, setNoteForManagement, } = inspectionManagementSlice.actions;
export default inspectionManagementSlice.reducer;