import {
  SlideOutModal,
  SlideOutModalProps,
  TextWithValidation,
  Toggle,
} from "../../../../components";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import ProcessService from "../../../../services/ProcessService";
import {
  getUuid,
  propsFilter,
  showErrorMessage,
  showSuccessMessage,
} from "../../../../app/tools";
import { RandomizeModel } from "../../../../data/entities";

export interface RandomizeModalProps extends SlideOutModalProps {
  message?: string;
  confirmText?: string;
  range: { startPosition: number; endPosition: number };
  inspectionId: number;
  onRandomizeComplete: () => void;
}

export function RandomizeModal(props: Readonly<RandomizeModalProps>) {
  const emptyRandomizeModel: RandomizeModel = {
    startPosition: props.range.startPosition,
    endPosition: props.range.endPosition,
    randomizeFlaw: false,
    randomizeFlw: false,
    randomizeWall1: false,
    randomizeWall2: false,
    flwVariance: undefined,
    flawVariance: undefined,
    wall1Variance: undefined,
    wall2Variance: undefined,
    flwRandomize: undefined,
    flawRandomize: undefined,
    wall1Randomize: undefined,
    wall2Randomize: undefined,
  };

  const [formData, setFormData] = useState<RandomizeModel>(emptyRandomizeModel);

  useEffect(() => {
    setFormData((current) => {
      return {
        ...current,
        startPosition: props.range.startPosition,
        endPosition: props.range.endPosition,
      };
    });

    return () => {};
  }, [props.range]);

  const onCancel = () => {
    props.showState.setter(false);
  };

  const onConfirm = (e: any) => {
    if (
      !window.confirm(
        "Are you sure you want to execute this randomize process?"
      )
    )
      return;

    props.showState.setter(false);
    const randomizeData = async () => {
      const result = await new ProcessService().postProcessRandomize(
        props.inspectionId,
        formData
      );
      if (!result) {
        showErrorMessage(`Error randomizing data.`);
        return;
      }

      showSuccessMessage(
        `Randomized data between ${formData.startPosition} and ${formData.endPosition}`
      );
      setFormData(emptyRandomizeModel);
      props.onRandomizeComplete();
    };

    randomizeData();
  };

  const renderFooter = () => (
    <div className="container p-0">
      <div className="row g-0">
        <div className="col-6 pe-2">
          <Button onClick={onCancel} variant="cancel" className="w-100">
            Cancel
          </Button>
        </div>
        <div className="col-6 ps-2">
          <Button onClick={onConfirm} variant="primary" className="w-100">
            {props.confirmText ?? "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );

  const renderToggle = (
    description: string,
    propertyName: keyof RandomizeModel
  ) => (
    <div className="col d-flex align-items-center">
      <Toggle
        description={description}
        id={propertyName}
        className="mb-3"
        initialValue={formData[propertyName] as boolean}
        onChange={(value) => {
          setFormData((current) => {
            return {
              ...current,
              [propertyName]: value,
            };
          });
        }}
      />
    </div>
  );

  const renderTextWithValidation = (
    propertyName: string,
    label: string,
    initialValue: number | undefined,
    targetName: keyof RandomizeModel
  ) => (
    <div
      className={`col ${(formData[targetName] as boolean) ? "" : "invisible"}`}
    >
      <TextWithValidation
        id={getUuid()}
        label={label}
        type="number"
        initialValue={initialValue}
        onChangeText={(value) => {
          setFormData((current) => {
            return {
              ...current,
              [propertyName]: Number(value),
            };
          });
        }}
      />
    </div>
  );

  const filteredProps = propsFilter(props, [
    "setProcesses",
    "confirmText",
    "inspectionId",
    "onRandomizeComplete",
  ]) as SlideOutModalProps;

  return (
    <SlideOutModal {...filteredProps} footer={renderFooter()}>
      <p>
        The next step is to pick a variance range to fluctuate the data
        randomly. This data processor will use the baseline settings for the
        inspection to normalize the data between points{" "}
        {props.range.startPosition} and {props.range.endPosition}, varying
        randomly by whatever settings you choose below.
      </p>
      <table className="table">
        <tbody>
          <tr>
            <td>Range: </td>
            <td>
              {props.range.startPosition} to {props.range.endPosition}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Length: </td>
            <td>{props.range.endPosition - props.range.startPosition}</td>
          </tr>
        </tfoot>
      </table>

      {/* FLW Variance */}
      <div className="row pt-3 bg-light">
        {renderToggle("FLW", "randomizeFlw")}
        {renderTextWithValidation(
          "flwVariance",
          "FLW Variance",
          formData.flwVariance,
          "randomizeFlw"
        )}
        {renderTextWithValidation(
          "flwRandomize",
          "FLW Randomize",
          formData.flwRandomize,
          "randomizeFlw"
        )}
      </div>
      {/* Flaw Variance */}
      <div className="row pt-3">
        {renderToggle("Flaw", "randomizeFlaw")}
        {renderTextWithValidation(
          "flawVariance",
          "Flaw Variance",
          formData.flawVariance,
          "randomizeFlaw"
        )}
        {renderTextWithValidation(
          "flawRandomize",
          "Flaw Randomize",
          formData.flawRandomize,
          "randomizeFlaw"
        )}
      </div>
      {/* Wall1 Variance */}
      <div className="row pt-3 bg-light">
        {renderToggle("Wall1", "randomizeWall1")}
        {renderTextWithValidation(
          "wall1Variance",
          "Wall1 Variance",
          formData.wall1Variance,
          "randomizeWall1"
        )}
        {renderTextWithValidation(
          "wall1Randomize",
          "Wall1 Randomize",
          formData.wall1Randomize,
          "randomizeWall1"
        )}
      </div>
      {/* Wall2 Variance */}
      <div className="row pt-3">
        {renderToggle("Wall2", "randomizeWall2")}
        {renderTextWithValidation(
          "wall2Variance",
          "Wall2 Variance",
          formData.wall2Variance,
          "randomizeWall2"
        )}
        {renderTextWithValidation(
          "wall2Randomize",
          "Wall2 Randomize",
          formData.wall2Randomize,
          "randomizeWall2"
        )}
      </div>
    </SlideOutModal>
  );
}

export default RandomizeModal;


