import { NavigationItem, IconDefinitions } from "../../components";
import { routes } from "../../app/routes";
import { UserService } from "../../services";

export function AccountSideBar() {
  const getDashboardNavigation = () => { 
    if (UserService.isSignedIn()) {
      return (
        <NavigationItem
          text="Dashboard"
          url={routes.home}
          icon={IconDefinitions.home}
          activeMatchUrl={routes.home}
        ></NavigationItem>
      );
    }
  };
  return (
    <>
      {getDashboardNavigation()}
      <NavigationItem
        text="Sign in"
        url={routes.login}
        icon={IconDefinitions.signIn}
        activeMatchUrl={[routes.login, routes.account]}
      ></NavigationItem>
      <NavigationItem
        text="Forgot password"
        url={routes.forgotPassword}
        icon={IconDefinitions.password}
        activeMatchUrl={[
          routes.forgotPassword,
          routes.forgotPasswordConfirmation,
          routes.resetPassword,
          routes.resetPasswordConfirmation,
        ]}
      ></NavigationItem>
      <NavigationItem
        text="Request Access"
        url={routes.requestAccess}
        icon={IconDefinitions.clipboard}
        activeMatchUrl={[routes.requestAccess, routes.requestAccessConfirmation]}
      ></NavigationItem>
    </>
  );
}


export default AccountSideBar;