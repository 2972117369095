import jwt_decode from 'jwt-decode';
import TokenData from '../models/TokenData';
import { SystemOfMeasurement } from '../data/entities';
import { store } from '../app/store/store';

interface ApiPayload {
  primarysid: string;
  name: string;// returns the email
  role: string;
  aud: string[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  systemOfMeasurement: string;
}

export class TokenService {

  static getTokenData(token: string): TokenData {
    const decoded = jwt_decode<ApiPayload>(token);
    const id = Number.parseInt(decoded.primarysid);
    const roles = this.getRoles(decoded.role);
    const notBefore = new Date(decoded.nbf * 1000);
    const expires = new Date(decoded.exp * 1000);
    const issuedAt = new Date(decoded.iat * 1000);
    const systemOfMeasurement = decoded.systemOfMeasurement;
    const measurement = systemOfMeasurement.toLowerCase() === "metric" ? SystemOfMeasurement.metric : SystemOfMeasurement.US;

    return {
      userId: id,
      email: decoded.name,
      roles: roles,
      audience: decoded.aud,
      notBefore: notBefore.toISOString(),
      expires: expires.toISOString(),
      issuedAt: issuedAt.toISOString(),
      issuer: decoded.iss,
      systemOfMeasurement: measurement
    };
  }

  private static getRoles(roles: string | string[]): string[] {
    if (typeof roles === "string") {
      return roles.split(",");
    }
    return roles;
  }

  static isTokenExpired(): boolean {
    const tokenData = store.getState().auth.tokenData;
    if (!tokenData) return true;
    const expires = new Date(tokenData.expires);
    const now = new Date();
    return expires < now;
  }


}

export default TokenService;
