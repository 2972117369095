import React from 'react'
import PageLayout from '../PageLayout';
import PageContent from '../PageContent';
import { HeadingBar, Icon, IconDefinitions, Link } from '../../components';
import { routes } from '../../app/routes';

export function NotFound() {
  return (
    <PageLayout>
      <PageContent>
        <div className="row">
          <div className="col-auto text-danger" style={{ fontSize: 90 }}>
            <Icon iconName={IconDefinitions.notFound}></Icon>
          </div>
          <div className="col">
            <HeadingBar h={1} title="Page not found" />
            <p>Sorry, the page you are trying to access does not exists.</p>
            <p>
              Would you like to go back to the{" "}
              <Link url={routes.home}>homepage</Link>?
            </p>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
}

export default NotFound