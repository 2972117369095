import React from "react";
import { TopBar } from "../components/topBar/TopBar";
import SideBar from "../components/sideBar/SideBar";
import { Outlet, useLocation } from "react-router-dom";

export interface PageLayoutProps extends React.PropsWithChildren {
  sidebar?: React.ReactElement;
}

export function PageLayout(props: PageLayoutProps) {
  const [isMenuExpanded, setMenuExpanded] = React.useState(false);
  const location = useLocation();

  // Reset side menu when location changes
  React.useEffect(() => {
    setMenuExpanded(false);
  }, [location]);

  const onMenuToggle = () => {
    setMenuExpanded(!isMenuExpanded);
  };

  return (
    <div id="main-wrapper" className="position-fixed d-flex flex-column w-100 h-100">
      <div id="main-topbar" className="position-relative flex-shrink-1">
        <TopBar showToggle={true} onToggle={onMenuToggle}></TopBar>
      </div>
      <div className="position-relative flex-grow-1">
        <div className="position-absolute d-flex flex-row w-100 h-100">
          <nav id="main-menu" className={`h-100 mh-100 ${isMenuExpanded ? "expanded" : ""}`} style={{"width": isMenuExpanded ? '13rem' : ''}}>
            <SideBar showToggle={true} onToggle={onMenuToggle}>
              {props.sidebar}
            </SideBar>
          </nav>
          <div id="main-menu-overlay" className={`d-lg-none ${isMenuExpanded ? "show" : ""}`} onClick={onMenuToggle}></div>
          <main id="main-content" role="main" className="flex-grow-1 h-100 mh-100 overflow-auto">
            {props.children ?? <Outlet />}
          </main>
        </div>
      </div>
    </div>
  );
}

PageLayout.defaultProps = {
  footerless: false,
};

export default PageLayout;
