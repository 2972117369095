import BaseRepository from "./BaseRepository";

export class InspectorRepo extends BaseRepository {

  async getInspectors() {
    const url = `Inspector`;

    const response = await this.client.get(url);
    return this.handleResponse<string[][]>(response);
  }
}

export default InspectorRepo;
