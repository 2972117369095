import { PropsWithChildren } from "react";
import { Button, Spinner } from "react-bootstrap";
import "../FormStyle.scss";

interface BlockButtonProps {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
  showSpinner?: boolean;
  className?: string;
}

export function BlockButton(props: PropsWithChildren<BlockButtonProps>): JSX.Element {
  const { label, onClick, disabled, showSpinner, className } = props;

  const content = showSpinner ? (
    <Spinner animation="border" variant="light" as="span" role="status" aria-hidden="true" />
  ) : (
    label ?? props.children
  );
  const click = showSpinner ? () => {} : onClick;

  return (
    <div className="d-grid gap-2">
      <Button variant="primary" size="lg" className={className} onClick={click} disabled={disabled}>
        {content}
      </Button>
    </div>
  );
}

BlockButton.defaultProps = {
  showSpinner: false,
  disabled: false,
};

export default BlockButton;
