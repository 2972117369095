import React, { useState } from "react";
import "./SubmenuItem.scss";
import IconAndText from "../icons/IconAndText";
import UrlService from "../../services/UrlService";
import { Link } from "..";
import { v4 as uuidv4 } from "uuid";
export interface SubmenuNavigationItem {
  label: string;
  url: string;
  params?: Map<string, string>;
  icon?: string;
  activeMatch?: string | string[];
  activeMatchExact?: boolean;
  ifActive?: string;
  innerItems?: Array<SubmenuNavigationItem>;
  clickHandler?: () => void;
}

export interface SubmenuItemProps extends React.PropsWithChildren {
  item: SubmenuNavigationItem;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export function SubmenuItem(props: SubmenuItemProps) {
  const {
    label,
    url,
    params,
    icon,
    activeMatch,
    activeMatchExact,
    innerItems,
    clickHandler,
    ifActive,
  } = props.item;

  const [showSubMenu, setShowSubMenu] = useState(false);

  let extraClasses = "";
  if (activeMatch) {
    extraClasses += UrlService.isUrlActive(
      activeMatch,
      activeMatchExact ?? true
    )
      ? "active"
      : "";
  }

  let rootComponent: React.JSX.Element;

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    //The timeout is needed to allow the navigation event to fire first
    setTimeout(() => {
      setShowSubMenu(false);
    }, 200);
  };

  if (innerItems) {
    extraClasses += " p-0 ";
    rootComponent = (
      <div onBlur={handleBlur}>
        <button
          className="btn dropdown-toggle"
          type="button"
          id={uuidv4()}
          title={label}
          onClick={(e) => {
            e.preventDefault();
            setShowSubMenu(!showSubMenu);
          }}
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          <IconAndText iconName={icon ?? ""} text={label}></IconAndText>
        </button>
        <ul
          className={`dropdown-menu bg-light border-darken border ${
            showSubMenu ? "d-block" : ""
          }`}
        >
          {innerItems.map((item) => (
            <SubmenuItem key={uuidv4()} item={item}></SubmenuItem>
          ))}
        </ul>
      </div>
    );
  } else {
    rootComponent = (
      <Link
        url={!clickHandler ? url : "#"}
        params={params}
        className="btn"
      >
        <IconAndText iconName={icon ?? ""} text={label}></IconAndText>
      </Link>
    );
  }

  return (
    <li
      className={`submenuitem nav-item list-group-item rounded-0 ${extraClasses} ${ifActive}`} onClick={clickHandler}
    >
      {rootComponent}
    </li>
  );
}

export default SubmenuItem;

