import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { HeadingBar, NumberWithValidation, SlideOutModal, SlideOutModalProps } from "../../../../components";
import SegmentInsertModel from "../../../../data/entities/SegmentInsertModel";
import { Upload } from "@progress/kendo-react-upload";
import { UserService } from "../../../../services";

export interface InsertSegmentModalProps extends SlideOutModalProps {
  inspectionId: number,
  onSubmit: (data: SegmentInsertModel) => void;
}

export function InsertSegmentModal(props: InsertSegmentModalProps) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    length: 0,
    dataPointsPerSecond: 0,
    groupSize: 1,
    file: null
  } as SegmentInsertModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
      setValues({
        length: 0,
        dataPointsPerSecond: 0,
        groupSize: 1,
        file: null
      } as SegmentInsertModel)
    } else {
      setValidated(false);
    }
  };

  if (props.title !== undefined) delete props.title;
  if (props.footer !== undefined) delete props.footer;

  return (
    <SlideOutModal {...props}
      title="Upload Segment"
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Insert
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <Upload
          batch={false}
          multiple={false}
          defaultFiles={[]}
          withCredentials={false}
          autoUpload={true}
          saveHeaders={{
            Authorization: `Bearer ${UserService.getUserToken()}`
          }}
          removeHeaders={{
            Authorization: `Bearer ${UserService.getUserToken()}`
          }}
          saveUrl={`${process.env.REACT_APP_API_URL}SegmentFile/${props.inspectionId}/Save`}
          removeUrl={`${process.env.REACT_APP_API_URL}SegmentFile/${props.inspectionId}/Remove`}
          onStatusChange={(e) => {
            if (!e.newState) return;
            const file = e.newState.length ? e.newState[0] : null;
            setValues({ ...values, file: file });
          }}
          onRemove={(e) => {
            setValues({ ...values, file: null });
          }}
        />
        <div className="row mt-2">
          <div className="col-12">
            <HeadingBar h={5} title="Data File Settings" />
          </div>
          <div className="col-12 col-md-6">
            <NumberWithValidation
              autoFocus={true}
              id="length"
              required={true}
              label="Length (m)"
              initialValue={`${values.length}`}
              onChangeText={(v) => setValues({ ...values, length: parseInt(v) })}
            />
          </div>
          <div className="col-12 col-md-6">
            <NumberWithValidation
              id="dataPointsPerSecond"
              required={true}
              label="Data Points Per Second"
              initialValue={`${values.dataPointsPerSecond}`}
              onChangeText={(v) => setValues({ ...values, dataPointsPerSecond: parseInt(v) })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <HeadingBar h={5} title="Group Data Points Together" />
            <p>
              You can group data points together by specifying a group size larger than 1.
              For example, if you want to take a data file that has '8 points per second' and compress it, you can set the group size to '8' and the system will group 8 points together into a single point (making this data segment 1/8th of its original size).
            </p>
          </div>
          <div className="col-12">
            
            <NumberWithValidation
              id="groupSize"
              required={true}
              label="Group Size"
              initialValue={`${values.groupSize}`}
              onChangeText={(v) => setValues({ ...values, groupSize: parseInt(v) })}
            />
          </div>
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default InsertSegmentModal;

