import { useOutletContext, useParams } from "react-router-dom";
import { InspectionsPageContext } from "../InspectionsPage";
import { useSharedState } from "../../../app/sharedProperty";
import { ConfirmationModal, IconDefinitions, ODataGrid, SpinnerDefault, TitlelessCard } from "../../../components";
import { SegmentService } from "../../../services";
import { SegmentModel } from "../../../data/entities";
import { GridColumn } from "@progress/kendo-react-grid";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { useRef, useState } from "react";
import { removeItemFromArray } from "../../../app/tools";
import { Button } from "react-bootstrap";
import InsertSegmentModal from "./components/InsertSegmentModal";
import InsertEmptySegmentModal from "./components/InsertEmptySegmentModal";
import SegmentInsertEmptyModel from "../../../data/entities/SegmentInsertEmptyModel";
import SegmentInsertModel from "../../../data/entities/SegmentInsertModel";
import { usePageActions } from "../..";

export function InspectionsDataSegments() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Data Segments");

  const { id } = useParams();
  const inspectionId = Number(id);
  const gridData = useSharedState<any>([]);
  const gridRef = useRef<ODataGrid>(null);
  const [selectedItem, setSelectedItem] = useState<SegmentModel | null>(null);

  const showDeleteState = useSharedState(false);
  const showInsertState = useSharedState(false);
  const showInsertEmptyState = useSharedState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const segmentService = new SegmentService();

  const getSegments = async (oDataQuery: string) => 
    await segmentService.getAllSegments(inspectionId, oDataQuery);

  const onRowMenuSelect = async (e: MenuSelectEvent, dataItem: any) => {
    // Reset menu actions
    setSelectedItem(dataItem);
    if (showDeleteState.value) showDeleteState.setter(false);

    switch (e.item.data.action) {
      case "delete":
        showDeleteState.setter(true);
        break;
    }
  };

  const deleteSegment = async () => {
    if (selectedItem == null) {
      throw new Error('Unable to delete, unknown segment');
    }
    try {
      setShowSpinner(true);
      await segmentService.deleteSegment(inspectionId, Number(selectedItem.id));
      removeItemFromArray(gridData.value, 'id', selectedItem.id);
      gridRef.current?.refreshData();
    } catch (err) {
      throw new Error(`Unable to delete segment, ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  const insertSegment = async (seg: SegmentInsertModel) => {
    try {
      showInsertState.setter(false);
      setShowSpinner(true);
      await segmentService.addSegment(inspectionId, seg);
      gridRef.current?.refreshData();
    } catch (err) {
      throw new Error(`Unable to insert new segment, ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  const insertEmptySegment = async (seg: SegmentInsertEmptyModel) => {
    try {
      showInsertEmptyState.setter(false);
      setShowSpinner(true);
      await segmentService.addEmptySegment(inspectionId, seg.length);
      gridRef.current?.refreshData();
    } catch (err) {
      throw new Error(`Unable to insert empty segment, ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  usePageActions(
    <>
      <Button onClick={() => showInsertState.setter(true)} variant="primary" className="me-2">
        Upload
      </Button>
      <Button onClick={() => showInsertEmptyState.setter(true)} variant="cancel">
        Add Empty Segment
      </Button>
    </>
  );

  return (
    <>
      <SpinnerDefault show={showSpinner} fullscreen={true} />
      <TitlelessCard className="mb-3 p-0" bodyClassName="p-0">
        <ODataGrid
          ref={gridRef}
          getData={getSegments}
          dataState={gridData}
          onRowMenuSelect={onRowMenuSelect}
          sort={[{ field: "segmentIndex", dir: "asc" }]}
          sortable={false}
          filterable={false}
          pageable={false}
        >
          <GridColumn title="Index" field="segmentIndex" />
          <GridColumn title="Length (m)" field="length" />
          <MenuItem text="Delete" data={{ action: "delete" }} icon={IconDefinitions.delete} />
        </ODataGrid>
      </TitlelessCard>
      <ConfirmationModal
        onConfirm={deleteSegment}
        showState={showDeleteState}
        title={`Delete Segment ${selectedItem?.segmentIndex}`}
        message={`Are you sure you want to delete segment ${selectedItem?.segmentIndex} of ${selectedItem?.length}m?`}
        confirmText="Delete" />
      <InsertSegmentModal
        showState={showInsertState}
        onSubmit={insertSegment}
        inspectionId={inspectionId}
        size="lg"
      />
      <InsertEmptySegmentModal
        showState={showInsertEmptyState}
        onSubmit={insertEmptySegment}
      />
    </>
  );
}

export default InspectionsDataSegments;
