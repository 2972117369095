import "./Card.scss";
import React, { Children } from "react";
import { CardTitle, CardTitleBasic, CardTitleBasicProps } from "./CardTitle";

export interface SimpleCardProps
  extends React.PropsWithChildren,
    CardTitleBasicProps {
  className?: string;
}

export function SimpleCard(props: SimpleCardProps) {
  return (
    <div className={`card w-100 ${props.className}`}>
      {props.title ? (
        <CardTitleBasic
          title={props.title}
          textRight={props.textRight}
        ></CardTitleBasic>
      ) : (
        <></>
      )}
      <div className="card-body">{props.children}</div>
    </div>
  );
}

export interface AdvancedCardProps extends React.PropsWithChildren {
  className?: string;
}
export function Card(props: AdvancedCardProps) {
  const { children } = props;

  let title = null;
  let body = null;

  // Iterate over the children
  Children.map(children, (child) => {
    // Check if the child is a valid React element
    if (React.isValidElement(child)) {
      // Determine the type of the child
      if (child.type === CardTitle) {
        title = child;
      } else if (child.type === CardBody) {
        body = child;
      }
    }
  });

  return (
    <div className={`card w-100 ${props.className ?? ""}`}>
      {title}
      {body}
    </div>
  );
}

export interface CardBodyProps extends React.PropsWithChildren {}
export function CardBody(props: CardBodyProps) {
  return <div className="card-body">{props.children}</div>;
}

export default Card;

