import { RegisterModel } from "../entities";
import BaseRepository from "./BaseRepository";

export class AccountRepo extends BaseRepository {

  async register(id: number, key: string, body: RegisterModel): Promise<boolean | null> {
    console.log("Body: ", body.password === body.confirmPassword)
    const url = `/Account/Register/${id}/${key}`;
    const response = await this.client.post(url, body);
    return this.handleResponse<boolean>(response);
  }

}
