import { GridColumn, GridColumnProps } from "@progress/kendo-react-grid";
import React from "react";
import { Breakpoints } from "./Breakpoints";

interface ResponsiveGridColumnProps
  extends GridColumnProps,
    React.FunctionComponent {
  breakpoint?: Breakpoints;
}

export const ResponsiveGridColumn: React.FunctionComponent<
  Readonly<ResponsiveGridColumnProps>
> = (props: Readonly<ResponsiveGridColumnProps>) => {
  return <GridColumn {...props} />;
};

export default ResponsiveGridColumn;
