import TitlelessCard from "../../components/card/TitlelessCard";
import InspectionDetails from "../../data/entities/InspectionDetailsModel";

interface InspectionSummaryProps extends React.PropsWithChildren {
  pageData: InspectionDetails | undefined | null;
}
export default function InspectionSummary(props: InspectionSummaryProps) { 

  return (
    <>
      <h5>Inspection Summary</h5>
      <TitlelessCard className="mb-3 p-0">{props.pageData?.summary }</TitlelessCard>
    </>
  );
}