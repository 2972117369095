import { useState } from 'react'
import { SimpleCard } from '../../components/card/Card';
import Form from "react-bootstrap/Form";
import { BlockButton, TextWithValidation } from '../../components';
import MaintenanceService from '../../services/MaintenanceService';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

export default function CompressImagesCard() {

  const [olderThanDays, setOlderThanDays] = useState(30);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleChange = (input: string) => {
    setOlderThanDays(parseInt(input));
  }

  async function compressImagesButtonClick() {
    setShowSpinner(true);
    const ok = new MaintenanceService().compressImages(olderThanDays).then(() => {});
    setShowSpinner(false);
    if(!ok) {
      showErrorMessage("Image compression was not successfully started.");
      return;
    }
    showSuccessMessage("Images compression started.");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to "auto" for an instant scroll
    });
  }

  return (
    <SimpleCard className="mb-3 p-0" title="Compress Old Images">
    <Form>
      <div className="row">
        <div className="col-12">
          <p>This process will take old images stored in the database to compress them if they are larger than a certain size, and stores them back into the database after the images have been updated.</p>
          <p>The process of compressing the images will help save some storage space from being used up by unnecessarily large images.</p>
        </div>
        <div className="col-12 mt-3">
          <TextWithValidation
            label="Older than this many days"
            id=""
            required={false}
            initialValue={olderThanDays.toString()}
            onChangeText={(input) => {
              handleChange(input);
           }}
           type="number"
          ></TextWithValidation>
        </div>
        <div className="col-xs-12 col-md-6 col-lg-5 col-xl-3 mt-3">
          <BlockButton
            label="Compress Old Images"
            onClick={compressImagesButtonClick}
            disabled={false}
            showSpinner={showSpinner}
          ></BlockButton>
        </div>
      </div>
    </Form>
  </SimpleCard>
  );
}
