
import { useEffect, useState } from "react";
import { Dropdown, Option } from "..";
import { CompanyService } from "../../../services";

export interface CompanyPickerProps {
  id: string;
  label: string;
  onSelect: (value: string) => void;
  initialValue?: string;
}

export function CompanyPicker (props: CompanyPickerProps) {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let companyList: { value: string, text: string }[] = [];
        const data = await new CompanyService().getAllCompanies();
        data.forEach((company) => {
          const map = new Map(Object.entries(company));
          const id = map.get("id");
          const name = map.get("name");
          let companyInfo = { value: id, text: name };
          companyList.push(companyInfo);
        })
        setOptions(companyList);
      } catch (e) {
        console.error("Error loading companies", e);
      }
    };

    fetchCompanies();

  }, []);

  return (
    <Dropdown
      id={props.id}
      label={props.label}
      options={options}
      selectedOption={props.initialValue}
      onChangeSelected={(value: React.SetStateAction<string>) => {
        props.onSelect(value.toString());
      }}
    />
  )
}

export default CompanyPicker
