import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Dropdown, Option, SlideOutModal, SlideOutModalProps, SpinnerDefault } from "../../../../components";
import InspectorService from "../../../../services/InspectorService";

export interface InsertInspectorModalProps extends SlideOutModalProps {
  inspectionId: number,
  onSubmit: (id: number, userId: number) => void;
}

export function InsertInspectorModal(props: InsertInspectorModalProps) {
  const [validated, setValidated] = useState(false);
  const [userId, setUserId] = useState(0);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [usersDropdown, setUsersDropdown] = useState<Option[] | null>(null);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, props.inspectionId, userId);
    } else {
      setValidated(false);
    }
  };

  if (props.title !== undefined) delete props.title;
  if (props.footer !== undefined) delete props.footer;

  useEffect(() => {
    const userList : Option[] = [];

    new InspectorService()
    .getInspectors()
    .then((data) => {
      data?.forEach((user) => {
        // user contains a filtered string list of [profileId, displayName]
        userList.push({ value: (user.at(0))!, text: (user.at(1))! });
      })
      setUsersDropdown(userList);
      setIsDataLoaded(true);
    })
    .catch((error) => {
      console.error("Error fetching inspectors data: ", error);
    });
  }, []);

  if(!isDataLoaded) return <SpinnerDefault/>

  return (
    <SlideOutModal {...props}
      title="Add Inspector"
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Insert
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <Dropdown 
          label="Users"
          id=""
          options={usersDropdown} 
          disabled={false}
          onChangeSelected={(input => {
            setUserId(parseInt(input.toString()));
          })}
        ></Dropdown>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default InsertInspectorModal;

