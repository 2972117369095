import { ImageRepo } from "../data/repo/ImageRepo";
import { Buffer } from 'buffer';

export class ImageService {

  private imageRepo = new ImageRepo(); 

  getImageThumbnailUrl(inspectionId: number, imageId: number, noteId: number,): string {
    return this.imageRepo.getThumbnailUrl(inspectionId, imageId, noteId,);
  }

  async getImageThumbnailBase64(inspectionId: number, imageId: number, noteId: number, type: string): Promise<string | null | undefined> {
    const thumb =  await new ImageRepo().getThumbnail(inspectionId, imageId, noteId,);
    const dataArray = new Uint8Array(thumb);
    const base64 = Buffer.from(dataArray).toString('base64');    
    const base64Data = `data:image/${type.replaceAll('.', '').toLowerCase()};base64,${base64}`;
    return base64Data;
  }

  async getImageLargeBase64(inspectionId: number, imageId: number, noteId: number, type: string): Promise<string | null | undefined> {
    const image = await new ImageRepo().getImage(inspectionId, imageId, noteId,);
    const dataArray = new Uint8Array(image);
    const base64 = Buffer.from(dataArray).toString('base64');
    const base64Data = `data:image/${type.replaceAll('.', '').toLowerCase()};base64,${base64}`;
    return base64Data;
  }

  async getCalibrationImageThumbnail(inspectionId: number, imageId: number, type: string): Promise<string | null | undefined> {
    const thumb = await new ImageRepo().getCalibrationImageThumbnail(inspectionId, imageId);
    const dataArray = new Uint8Array(thumb);
    const base64 = Buffer.from(dataArray).toString('base64');
    const base64Data = `data:image/${type.replaceAll('.', '').toLowerCase()};base64,${base64}`;
    return base64Data;
  }

  async deleteCalibrationImage(imageId: number): Promise<boolean> {
    return await new ImageRepo().removeCalibrationImage(imageId);
  }
  
  async updateCalibrationImage(imageId: number, caption: string): Promise<boolean> {
    return await new ImageRepo().updateCalibrationImage(imageId, caption);
  }
}

export default ImageService;
