import BaseRepository from "./BaseRepository";

export class MaintenanceRepo extends BaseRepository {

  async getSpaceUsed() {
    const url = `Maintenance`

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async emptyFileCache() {
    const url = `Maintenance/EmptyFileCache`

    const response = await this.client.get(url);
    return this.handleResponse<boolean>(response);
  }

  async defragmentDatabase() {
    const url = `Maintenance/DefragmentDatabase`

    const response = await this.client.get(url);
    return this.handleResponse<boolean>(response);
  }

  async cleanUpValues(olderThanDays: number): Promise<boolean | undefined> {
    const url = `Maintenance/CleanUpValues?olderThanDays=${olderThanDays}`;
    const body = {
      olderThanDays: olderThanDays
    }

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async compressInspections(olderThanDays: number, ratio: number): Promise<boolean | undefined> {
    const url = `Maintenance/CompressInspections?olderThanDays=${olderThanDays}&ratio=${ratio}`;
    const body = {
      olderThanDays: olderThanDays,
      ratio: ratio
    }

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async compressImages(compressImagesOlderThanDays: number): Promise<boolean | undefined> {
    const url = `Maintenance/CompressImages?compressImagesOlderThanDays=${compressImagesOlderThanDays}`;
    const body = {
      compressImagesOlderThanDays: compressImagesOlderThanDays
    }

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }
}

export default MaintenanceRepo;
