import { dateString, timeAgoString } from "../../app/dateHelpers";
import { NoteModel } from "../../data/entities/NoteModel";
import { InspectionImages, Measurement } from "../../components";
import InspectionNoteDescription from "./InspectionNoteDescription";
import InspectionNoteValues from "./InspectionNoteValues";
import { MeasurementUnit } from "../../data/entities";

interface InspectionNoteProps {
  note: NoteModel;
}

export default function InspectionNote(props: InspectionNoteProps) {
  const { note } = props;

  return (
    <>
      <div className="row" id={note.id?.toString()}>
        <div className="col">
          <div className="note-author">
            <h6 className="mb-0">{note.user?.displayName}</h6>
          </div>
          <div className="note-date small">
            <span>{dateString(new Date(note.createdDate))}</span>
            <span className="d-none d-md-inline">
              {" "}
              ({timeAgoString(new Date(note.createdDate))})
            </span>
          </div>
          <div className="note-position small">
            <span>
              <b>Distance from core:</b>&nbsp;
              {
                note.endPosition === note.startPosition ? 
                <>
                  <Measurement
                    value={note.startPosition}
                    unit={MeasurementUnit.distanceLarge}
                  />
                </> : (
                <>
                  <Measurement
                    value={note.startPosition}
                    unit={MeasurementUnit.distanceLarge}
                  />
                  <span> - </span>
                  <Measurement
                    value={note.endPosition!}
                    unit={MeasurementUnit.distanceLarge}
                  />
                </>
              )
              }
            </span>
          </div>
          <div className="note-description">
            <InspectionNoteDescription
              severity={props.note.severity as string}
              description={props.note.description}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InspectionNoteValues values={props.note.values} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InspectionImages note={props.note} images={props.note.images ?? []} />
        </div>
      </div>
    </>
  );
}

