import ODataResponse from "../data/entities/ODataResponse";
import { OvalityDataPointViewModel, OvalityInsertModel } from "../data/entities";
import { OvalityDataPointViewRepo } from "../data/repo";

export class OvalityDataPointViewService {
  async getAll(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<OvalityDataPointViewModel>> {
    const response = await new OvalityDataPointViewRepo().getOvalityDataPointViews(inspectionId, oDataQuery);
    return response;
  }

  async get(id: string): Promise<OvalityDataPointViewModel> {
    const response = await new OvalityDataPointViewRepo().getDetails(id);
    return response;
  }

  async update(model: OvalityDataPointViewModel): Promise<OvalityDataPointViewModel> {
    const updated = await new OvalityDataPointViewRepo().update(model);
    return updated;
  }

  async delete(id: number): Promise<boolean> {
    const deleted = await new OvalityDataPointViewRepo().delete(id);
    return deleted;
  }

  async reset(id: number): Promise<OvalityDataPointViewModel> {
    const resetItem = await new OvalityDataPointViewRepo().reset(id);
    return resetItem;
  }

  async insert(inspectionId: number, data: OvalityInsertModel): Promise<boolean | undefined> {
    return await new OvalityDataPointViewRepo().insert(inspectionId, data);
  }

  async getList(inspectionId?: number): Promise<OvalityDataPointViewModel[]> {
    const oDataQuery = await this.getAll(inspectionId);
    if (!oDataQuery) return [];
    const count = oDataQuery["@odata.count"] ?? 0;
    return count > 0 ? oDataQuery.value : [];
  }
}

export default OvalityDataPointViewService;
