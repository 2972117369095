import { useEffect, useState } from "react";
import { router, routes } from "../../app/routes";
import CompanyRootSubmenu from "./CompanyRootSubmenu";
import { CompanyAddModel, ProvinceModel } from "../../data/entities";
import { AddressService, CompanyService } from "../../services";
import {
  Breadcrumbs,
  Dropdown,
  PostalCodeWithValidation,
  SimpleCard,
  TextWithValidation,
  handlePostalCodeTypeChanges,
  mapCountryToEnum,
  Option,
} from "../../components";
import { formFieldStateSetter, showSuccessMessage } from "../../app/tools";
import PageContent from "../PageContent";
import FormWithValidation from "../../components/form/FormWithValidation";
import { Button } from "react-bootstrap";

export function CompanyInsertPage() {
  const defaultCountry = 0; // Canada
  const defaultRegion = 2; // Alberta ProvinceId

  const submenus = <CompanyRootSubmenu />;

  const breadcrumbs = [
    { label: "Home", url: "/" },
    { label: "Companies", url: routes.company },
    { label: "Add Company", url: null },
  ];

  const [newCompany, setNewCompany] = useState<
    CompanyAddModel | undefined | null
  >(null);

  // This ProvinceModel list contains ALL regions.
  const [allRegions, setAllRegions] = useState<ProvinceModel[] | null>(null);

  // Used to toggle region on change.
  const [selectedCountry, setSelectedCountry] =
    useState<number>(defaultCountry);

  const [countryDropdown, setCountryList] = useState<Option[] | null>(null);
  const [regionDropdown, setRegionList] = useState<Option[] | null>(null);

  useEffect(() => {
    // Get all Regions
    new AddressService()
      .getAllProvinces()
      .then((data) => {
        setAllRegions(data);
      })
      .catch((error) => {
        console.error("Error fetching region data: ", error);
      });

    setNewCompany((prevState) => ({
      ...(prevState as CompanyAddModel),
      provinceId: defaultRegion,
    }));
  }, []);

  useEffect(() => {
    // Populate Country dropdown list.
    let countryList: Option[] = [];
    let countryCount = 0;
    allRegions?.forEach((province) => {
      if (
        !countryList.some((option) => option.text.includes(province.country))
      ) {
        countryList.push({
          value: countryCount.toString(),
          text: province.country,
        });
        countryCount++;
      }
    });
    setCountryList(countryList);
  }, [allRegions]);

  useEffect(() => {
    // Populate Region dropdown list.
    let regionList: Option[] = [];
    allRegions?.forEach((province) => {
      if (mapCountryToEnum(province.country) === selectedCountry) {
        regionList.push({
          value: province.id?.toString()!,
          text: province.name,
        });
      }
    });
    setRegionList(regionList);
  }, [selectedCountry, allRegions]);

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const newId = await new CompanyService().addCompany(newCompany!);

    if (newId === null) return;

    showSuccessMessage("Company address successfully added.");

    router.navigateWithParams(
      routes.companyDetails,
      new Map([["id", newId.toString()]])
    );
  };

  const valueChange = (field: keyof CompanyAddModel, value: any) => {
    const newCompanyObject = newCompany ?? ({} as CompanyAddModel);

    formFieldStateSetter(field, value, newCompanyObject, setNewCompany);
  };

  // Used to customize the regex for the text form being validated.
  const customValidationBuilder = (input: string, regex: RegExp) => {
    const isValid = regex.test(input) && input !== "";
    return isValid;
  };

  return (
    <PageContent submenu={submenus}>
      <h1>Manage companies</h1>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="row">
        <div className="col-12 col-lg-12">
          <SimpleCard title="Create a new company.">
            <FormWithValidation onSubmit={formSubmit}>
              <TextWithValidation
                label="Company Name"
                required={true}
                id="name"
                validationFunction={(input) => {
                  const regex = /^[a-z0-9 ,.'-]*$/i;
                  const isValid = customValidationBuilder(input, regex);
                  return isValid;
                }}
                onChangeText={(input) => {
                  valueChange("name", input);
                }}
                type="companyName"
              ></TextWithValidation>
              <TextWithValidation
                label="Street Address"
                required={true}
                id="streetAddress"
                validationFunction={(input) => {
                  const regex = /^[a-z0-9 ,.'#-]*$/i;
                  const isValid = customValidationBuilder(input, regex);
                  return isValid;
                }}
                onChangeText={(input) => {
                  valueChange("streetAddress", input);
                }}
                type="streetAddress"
              ></TextWithValidation>
              <TextWithValidation
                label="Apt / Unit"
                required={false}
                id="streetAddress2"
                onChangeText={(input) => {
                  valueChange("streetAddress2", input);
                }}
                type="streetAddress2"
              ></TextWithValidation>
              <TextWithValidation
                label="Town / Locality"
                required={true}
                id="city"
                validationResult={(input) => {
                  valueChange("city", input);
                }}
              ></TextWithValidation>
              <Dropdown
                label="Country"
                selectedOption={selectedCountry ?? ""}
                id="country"
                options={countryDropdown}
                disabled={false}
                onChangeSelected={(input) => {
                  setSelectedCountry(parseInt(input.toString()));
                }}
              ></Dropdown>
              <Dropdown
                label="Region"
                selectedOption={defaultRegion}
                id="region"
                options={regionDropdown}
                disabled={false}
                onChangeSelected={(input) => {
                  valueChange("provinceId", input);
                }}
              ></Dropdown>
              <PostalCodeWithValidation
                validationResult={(input) => {
                  valueChange("postalCode", input);
                }}
                postalCodeType={handlePostalCodeTypeChanges(
                  selectedCountry,
                  countryDropdown
                )}
              ></PostalCodeWithValidation>
              <div className="col-1">
                <Button disabled={false} type="submit">
                  Add
                </Button>
              </div>
            </FormWithValidation>
          </SimpleCard>
        </div>
      </div>
    </PageContent>
  );
}

export default CompanyInsertPage;

