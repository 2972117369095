import { useEffect, useState } from "react";
import { InspectionsPageContext } from "../InspectionsPage";
import { useOutletContext, useParams } from "react-router-dom";
import {
  Icon,
  IconDefinitions,
  SimpleCard,
  SpinnerDefault,
} from "../../../components";
import { CompanyAccessModel } from "../../../data/entities";
import { InspectionService } from "../../../services";
import "../../../components/inspections/InspectorList.scss";
import { useSharedState } from "../../../app/sharedProperty";
import { usePageActions } from "../../PageContent";
import { Button } from "react-bootstrap";
import InsertCompanyAccessModal from "./components/InsertCompanyAccessModal";
import {
  getUuid,
  showErrorMessage,
  showSuccessMessage,
} from "../../../app/tools";

export function InspectionsAccess() {
  const params = useParams();
  const id = params["id"] ?? "";
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Access");

  const [companiesWithAccess, setCompanyAccessList] =
    useState<CompanyAccessModel | null>(null);
  const showInsertState = useSharedState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const insertCompany = async (companyId: number) => {
    try {
      showInsertState.setter(false);
      setShowSpinner(true);
      const inspectionId = parseInt(id);
      const ok = await new InspectionService().addCompanyAccess(inspectionId, companyId);

      if (!ok) return;

      showSuccessMessage(
        "Company successfully given access to this inspection."
      );
      // Reload data after giving company access
      loadData(inspectionId);
    } catch (error) {
      showErrorMessage("Error occurred while giving company access.");
      throw new Error(`Unable to give company access, ${error}`);
    } finally {
      setShowSpinner(false);
    }
  };

  async function deleteHandler(companyId: number): Promise<void> {
    const ok = await new InspectionService().removeCompanyAccess(
      parseInt(id),
      companyId
    );

    if (!ok) return;

    showSuccessMessage("Company access successfully removed.");
    // Reload data after deleting an inspector
    loadData(parseInt(id));
  }

  const loadData = async (id: number) => {
    new InspectionService()
      .getAccess(id)
      .then((data) => {
        setCompanyAccessList(data);
      })
      .catch((error) => {
        console.error("Error loading Companies list: ", error);
      });
  };

  useEffect(() => {
    loadData(parseInt(id));
  }, [id]);

  const content = (
    <>
      {companiesWithAccess?.companies?.map((company) => {
        const companyName = company.name;

        return (
          <div
            className="row d-flex align-items-center flex-nowrap py-2"
            key={getUuid()}
          >
            <div className="col line-height-1">
              <div>{companyName}</div>
            </div>
            <div className="col-auto text-end">
              <Icon
                className="text-danger cursor-pointer"
                iconName={IconDefinitions.delete}
                onClick={() => deleteHandler(company.id!)}
              />
            </div>
          </div>
        );
      })}
    </>
  );

  usePageActions(
    <>
      <Button onClick={() => showInsertState.setter(true)}>
        <Icon iconName="plus" />
        Add Company
      </Button>
    </>
  );

  return (
    <>
      <SpinnerDefault show={showSpinner} fullscreen={true} />
      <SimpleCard title="Modify company access on this inspection.">
        {content}
      </SimpleCard>
      <InsertCompanyAccessModal
        showState={showInsertState}
        onSubmit={insertCompany}
      />
    </>
  );
}

export default InspectionsAccess;

