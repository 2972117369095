import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsH,
  faArrowsRotate,
  faClipboard,
  faKey,
  faX,
  faWrench,
  faHistory,
  faCogs,
  faUsers,
  faCircleNotch,
  faPlus,
  faUser,
  faSignOut,
  faSignIn,
  faBuilding,
  faSearch,
  faHome,
  faArrowLeft,
  faEllipsisVertical,
  faBars,
  faTimes,
  faEdit,
  faList,
  faImages,
  faTrash,
  faCheck,
  faTimesCircle,
  faFilePdf,
  faFileAlt,
  faFileExcel,
  faUpload,
  faExternalLink,
  faChartLineUp,
  faCircleInfo,
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faFloppyDisk,
  faUserPoliceTie,
  faCompass
} from "@fortawesome/pro-duotone-svg-icons";

export class IconDefinitions {
  static close = "close";
  static delete = "delete";
  static signIn = "sign-in";
  static signOut = "sign-out";
  static company = "company";
  static edit = "edit";
  static search = "search";
  static home = "home";
  static tubing = "tubing";
  static add = "add";
  static insert = "insert";
  static plus = "plus";
  static rowMenu = "rowmenu";
  static users = "users";
  static settings = "settings";
  static logs = "logs";
  static maintenance = "maintenance";
  static normalize = "normalize";
  static user = "user";
  static password = "password";
  static publish = "publish";
  static key = "key";
  static copy = "copy";
  static clipboard = "clipboard";
  static back = "back";
  static arrowLeft = "arrow-left";
  static menu = "menu";
  static images = "images";
  static refresh = "refresh";
  static list = "list";
  static check = "check";
  static cancel = "cancel";
  static ellipsisVertical = "ellipsis-v";
  static pdf = "pdf";
  static fileAlt = "file-alt";
  static excel = "excel";
  static externalLink = "external-link";
  static chartLineUp = "chart-line-up";
  static circleInfo = "circle-info";
  static circleCheck = "circle-check";
  static circleExclamation = "circle-exclamation";
  static triangleExclamation = "triangle-exclamation";
  static angleRight = "angle-right";
  static angleUp = "angle-up";
  static angleDown = "angle-down";
  static save = "save";
  static forbidden = "forbidden";
  static notFound = "not-found";
}

export function getFontAwesomeIcon(iconName: string): IconDefinition {
  switch (iconName) {
    case IconDefinitions.close: return faTimes;
    case IconDefinitions.delete: return faTrash;
    case IconDefinitions.signIn: return faSignIn;
    case IconDefinitions.signOut: return faSignOut;
    case IconDefinitions.company: case "fa-building": return faBuilding;
    case IconDefinitions.search: return faSearch;
    case IconDefinitions.home: return faHome;
    case "ovality":
    case "tubing":
    case IconDefinitions.tubing: return faCircleNotch;
    case IconDefinitions.add:
    case IconDefinitions.insert:
    case IconDefinitions.plus: return faPlus;
    case IconDefinitions.users: return faUsers;
    case IconDefinitions.settings: return faCogs;
    case IconDefinitions.logs: return faHistory;
    case IconDefinitions.maintenance: return faWrench;
    case IconDefinitions.normalize: return faArrowsH;
    case IconDefinitions.user: case "fa-user": return faUser;
    case IconDefinitions.password:
    case IconDefinitions.key: return faKey;
    case IconDefinitions.publish: return faUpload;
    case IconDefinitions.copy:
    case IconDefinitions.clipboard: return faClipboard;
    case IconDefinitions.back:
    case IconDefinitions.arrowLeft: return faArrowLeft;
    case IconDefinitions.menu: return faBars;
    case IconDefinitions.edit: case "fa-edit": return faEdit;
    case IconDefinitions.refresh: return faArrowsRotate;
    case IconDefinitions.list: case "fa-list": case "list": return faList;
    case IconDefinitions.images: case "fa-images": case "images": return faImages;
    case "delete": case "times-circle": case "fa-times-circle": return faTimesCircle;
    case "ellipsis-v":
    case "fa-ellipsis-v":
    case IconDefinitions.ellipsisVertical:
    case IconDefinitions.rowMenu: return faEllipsisVertical;
    case "check":
    case "fa-check":
    case IconDefinitions.check: return faCheck;
    case "report":
    case "pdf":
    case "fa-file-pdf":
    case IconDefinitions.pdf: case "fa fa-file-pdf": return faFilePdf;
    case IconDefinitions.fileAlt: case "fa fa-file-alt": return faFileAlt;
    case IconDefinitions.excel: case "fa fa-file-excel": return faFileExcel;
    case "cancel":
    case "fa-cancel":
    case "times":
    case "fa-times":
    case "x":
    case "X":
    case IconDefinitions.cancel: return faTimes;
    case IconDefinitions.externalLink: return faExternalLink;
    case IconDefinitions.chartLineUp: return faChartLineUp;
    case IconDefinitions.circleInfo: return faCircleInfo;
    case IconDefinitions.circleCheck: return faCircleCheck;
    case IconDefinitions.circleExclamation: return faCircleExclamation;
    case IconDefinitions.triangleExclamation: return faTriangleExclamation;
    case IconDefinitions.angleRight: return faAngleRight;
    case IconDefinitions.angleUp: return faAngleUp;
    case IconDefinitions.angleDown: return faAngleDown;
    case "save":
    case "fa-save":
    case IconDefinitions.save: return faFloppyDisk;
    case IconDefinitions.forbidden: return faUserPoliceTie;
    case IconDefinitions.notFound: return faCompass;
    default:
      console.debug(`Icon ${iconName} not found. Using faX instead.`);
      return faX;
  }
};

export default IconDefinitions;
