import { useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { InspectionsPageContext } from "../InspectionsPage";
import InspectionProcessSideBar from "./components/InspectionProcessSideBar";

export function InspectionsProcessPage(props: any) {
  const params = useParams();
  const id = params["id"] ?? "";
  const context: InspectionsPageContext = useOutletContext();

  useEffect(() => {
    context.setSidemenu(<InspectionProcessSideBar inspectionId={Number(id)} />);
  }, [id]);

  return (
    <Outlet context={context} />
  );
}

export default InspectionsProcessPage;

