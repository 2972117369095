import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Icon,
  IconAndText,
  Link,
  IconDefinitions,
  SimpleCard,
  SpinnerDefault,
} from "../../components";
import InviteService from "../../services/InviteService";
import InvitationsModel from "../../data/entities/InvitationsModel";
import { UserInviteModel } from "../../data/entities";
import { router, routes } from "../../app/routes";
import { forceLogout, showSuccessMessage } from "../../app/tools";
import { usePageActions } from "../PageContent";

export function UserInvitationsCard() {
  const [data, setData] = useState<InvitationsModel>();

  usePageActions(
    <Link url={routes.userInsertInvitation} className="btn btn-primary">
      <IconAndText iconName="plus" text="New Invitation" />
    </Link>
  );

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new InviteService().getInvites();
        setData(loadedData);
      } catch (e) {
        console.error("Error fetching invites data.", e);
      }
    };
    fetchData();
  }, []);

  /**
   * Deletes an invitation.
   * @param id Id of the invitation to delete
   * @returns
   */
  const deleteInvitationHandler = async (id: number) => {
    try {
      const ok = await new InviteService().deleteInvite(id);
      if (!ok) {
        return;
      } else {
        showSuccessMessage(`Invitation deleted.`);
      }
      const loadedData = await new InviteService().getInvites();
      setData(loadedData);
    } catch (e) {
      console.error("Error deleting invitation", e);
    }
  };
  /**
   *  Redirects to the register page.
   * @param invitation Invitation to redirect to register page
   */
  const redirectToRegister = (invitation: UserInviteModel) => {
    forceLogout();

    router.navigateWithParams(
      routes.register,
      new Map([
        ["id", invitation.id!.toString()],
        ["code", invitation.key!],
      ])
    );
  };

  /**
   * Gets the expiry date of an invitation.
   * @param date  Date to check
   * @returns
   */
  const getExpiry = (date: Date) => {
    if (new Date(date) < new Date()) {
      return "Expired";
    } else {
      const diff = Math.abs(new Date(date).getTime() - new Date().getTime());
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      return `${days} Days ${hours} Hours`;
    }
  };

  if (data === undefined) return <SpinnerDefault />

  if (data === null || data.invitations === null) {
    return (
      <SimpleCard title="Manage account invitations.">
        <div>
          <em>No Pending Invitations.</em>
        </div>
      </SimpleCard>
    );
  }

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Manage account invitations.</h6>
      </CardTitle>
      <CardBody>
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Company</th>
              <th scope="col">Expires</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.invitations!.map((invitation) => {
              return (
                <tr key={invitation.id}>
                  <td>{invitation.email}</td>
                  <td>{invitation.company!.name}</td>
                  <td>{getExpiry(invitation.expiryDate!)}</td>
                  <td>
                    <Icon onClick={() => redirectToRegister(invitation)} 
                        className="mx-2 text-primary cursor-pointer"
                        iconName={IconDefinitions.externalLink}/>
                    <Icon onClick={() => deleteInvitationHandler(invitation.id!)} 
                      className="mx-2 text-danger cursor-pointer"
                      iconName={IconDefinitions.delete}/>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default UserInvitationsCard;

