import React from "react";
import "./Submenu.scss";
import SubmenuItem, { SubmenuNavigationItem } from "./SubmenuItem";
import { getUuid } from "../../app/tools";

interface SubmenuProps extends React.PropsWithChildren {
  items?: SubmenuNavigationItem[];
}

export function Submenu(props: SubmenuProps) {
  const itemsList = [...(props.items ?? [])];

  return (
    <ul className="topbar submenu nav list-group list-group-horizontal">
      {itemsList.map((item) => (
        <SubmenuItem key={getUuid()} item={item}></SubmenuItem>
      ))}
    </ul>
  );
}

export default Submenu;

