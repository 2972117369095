import { Alert, Col, Row } from "react-bootstrap";
import { Icon, IconDefinitions } from "../../components";
import { Severity, getSeverityDescriptor } from "../../models";

interface InspectionNoteDescriptionProps {
  severity: string | undefined;
  description: string;
}

export default function InspectionNoteDescription(
  props: InspectionNoteDescriptionProps
) {
  const { severity, description } = props;

  switch ((severity ?? "").toUpperCase()) {
    case "URGENT":
      return (
        <Alert variant="danger p-2">
          <div className="d-flex flex-row align-items-center">
            <div className="flex-shrink-1">
              <Icon
                iconName={IconDefinitions.circleExclamation}
                className="text-danger fa-2x"
              />
            </div>
            <div className="flex-grow-1">
              <div className="fw-bold mb-0">{getSeverityDescriptor(severity as Severity)}</div>
              <span>{description}</span>
            </div>
          </div>
        </Alert>
      );
    case "COMPROMISEDOD":
    case "CAUTION":
    case "PREREPAIR":
      return (
        <Alert variant="warning p-2">
          <div className="d-flex flex-row align-items-center">
            <div className="flex-shrink-1">
              <Icon
                iconName={IconDefinitions.triangleExclamation}
                className="text-warning fa-2x"
              />
            </div>
            <div className="flex-grow-1">
              <div className="fw-bold mb-0">{getSeverityDescriptor(severity as Severity)}</div>
              <span>{description}</span>
            </div>
          </div>
        </Alert>
      );
    case "REPAIRED":
    case "POSTREPAIR":
      return (
        <Alert variant="success p-2">
          <div className="d-flex flex-row align-items-center">
            <div className="flex-shrink-1">
              <Icon
                iconName={IconDefinitions.check}
                className="text-success fa-2x"
              />
            </div>
            <div className="flex-grow-1">
              <div className="fw-bold mb-0">{getSeverityDescriptor(severity as Severity)}</div>
              <span>{description}</span>
            </div>
          </div>
        </Alert>
      );
    case "COMMENT":
    default:
      return <p>{description}</p>;
  }
}



