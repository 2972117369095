import React from "react";
import Footer from "../../components/footer/Footer";
import AlertMessage from "../../components/interactions/AlertMessage";
import { Outlet } from "react-router-dom";
import PageLayout from "../PageLayout";

import AccountSideBar from "./AccountSideBar";

export function AccountPageLayout(props: React.PropsWithChildren) {
  return (
    <PageLayout sidebar={<AccountSideBar />}>
      <AlertMessage></AlertMessage>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6 pt-5">
            <Outlet />
            <Footer></Footer>
          </div> 
        </div>
      </div>
    </PageLayout>
  );
}

export default AccountPageLayout;
