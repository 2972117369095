import React, { useState } from "react";
import {
  IconAndText,
  IconDefinitions,
  InspectionImageThumbnail,
  LinkLooksLike,
  TextWithValidation,
  TitlelessCard,
} from "../../../../components";
import { NoteImageModel, NoteModel } from "../../../../data/entities";
import { NoteService } from "../../../../services";

interface InspectionNoteImageCardProps {
  image: NoteImageModel;
  note: NoteModel;
  onSaved?: () => void;
  onDeleted: (imageId: number) => void;
}

enum ImageEditionMode {
  display,
  edit,
  saving,
}

function InspectionNoteImageCard(props: InspectionNoteImageCardProps) {
  const [mode, setMode] = useState(ImageEditionMode.display);
  const [imageData, setImageData] = useState(props.image);
  const [captionBackup, setCaptionBackup] = useState(props.image.caption);

  const editClickHandler = () => {
    setMode(ImageEditionMode.edit);
  };

  const deleteClickHandler = async () => {
    const deleted = await new NoteService().deleteNoteImage(imageData.id!);
    if (!deleted) return;
    props.onDeleted(imageData.id!);
  };

  const saveClickHandler = async () => {
    const saved = await new NoteService().updateNoteImage(imageData);
    if (!saved) return;

    setCaptionBackup(imageData.caption);
    props.onSaved?.();
    setMode(ImageEditionMode.display);
  };

  const cancelClickHandler = () => {
    setImageData({ ...imageData, caption: captionBackup });
    setMode(ImageEditionMode.display);
  };

  const onTextCaptionChanged = (text: string) => {
    setImageData({ ...imageData, caption: text });
  };

  const displayMode = (
    <div className="col d-flex justify-content-start">
      <LinkLooksLike onClick={editClickHandler}>
        <IconAndText iconName={IconDefinitions.edit} text="Edit" />
      </LinkLooksLike>
      <em className="ms-3">{imageData.caption}</em>
    </div>
  );

  const editMode = (
    <div className="col">
      <div className="row">
        <div className="col">
          <TextWithValidation
            label={"Caption"}
            id={"txtCaption"}
            initialValue={imageData.caption!}
            onChangeText={onTextCaptionChanged}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <LinkLooksLike onClick={cancelClickHandler}>
            <IconAndText iconName={IconDefinitions.cancel} text="Cancel" />
          </LinkLooksLike>
          <LinkLooksLike onClick={saveClickHandler} className="ms-3">
            <IconAndText iconName={IconDefinitions.check} text="Save" />
          </LinkLooksLike>
        </div>
      </div>
    </div>
  );

  return (
    <TitlelessCard className="mb-3">
      <div className="row mb-1">
        <div className="col d-flex justify-content-end">
          <LinkLooksLike onClick={deleteClickHandler}>
            <IconAndText iconName={IconDefinitions.delete} text="Delete" />
          </LinkLooksLike>
        </div>
      </div>
      <InspectionImageThumbnail
        image={imageData}
        note={props.note}
      />
      <div className="row">
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        {mode === ImageEditionMode.display ? displayMode : editMode}
      </div>
    </TitlelessCard>
  );
}

export default InspectionNoteImageCard;
