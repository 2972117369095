import { TubingForm, TubingSubmenu } from './';
import { PageBreadcrumbProps, PageContent } from '../PageContent';
import { routes, useQuery } from '../../app/routes';

export function TubingInsertPage() {

  // Instantiate page data
  const title = "Add Tubing";
  const breadcrumbs: PageBreadcrumbProps = {
    showHome: true,
    values: [{ label: "Tubings", url: routes.tubings }, { label: title }]
  };

  const initialCompany = useQuery().get("companyId");
  
  return (
    <PageContent title="Manage coiled tubings" breadcrumbs={breadcrumbs} submenu={<TubingSubmenu />}>
      <TubingForm
        title={"Add a string of coiled tubing."}
        action={"insert"}
        formSubmitLabel='Add'
        dropdownSelectedOption={initialCompany ? parseInt(initialCompany) : undefined}
      />
    </PageContent>
  )
}

export default TubingInsertPage;

