// import React, { useState } from 'react'
// import { FloatingLabel, Form } from 'react-bootstrap';
// import "../FormStyle.scss";

// interface NumberWithValidationProps {
//   id?: string;
//   initialValue?: string;
//   label?: string;
//   required?: boolean;
//   validationFunction?: (input: string) => void;
//   onChangeText?: (value: string) => void;
//   validationResult?: (input: string, result: boolean) => void;
//   validationType: "integer" | "double";
//   minLength?: number;
//   maxLength?: number;
// }

// export const NumberWithValidation = React.forwardRef(({
//   initialValue: emailText,
//   id,
//   label,
//   required,
//   onChangeText,
//   validationResult,
//   validationType,
//   minLength,
//   maxLength,
// }: NumberWithValidationProps, ref: React.ForwardedRef<HTMLInputElement | null>) => {

//   const [isValid, setIsValid] = useState(true);
//   const [value, setEmailValue] = useState(emailText ?? "");

//   function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
//     const changedValue = event.target.value ?? "";
//     setEmailValue(changedValue);
//     if (onChangeText) onChangeText(changedValue);
//   }

//   function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
//     let regex = null;
//     let valid: boolean | null = null;

//     if (validationType === "integer") {
//       regex = /^\d\d{0,3}$/;
      
//       valid = regex.test(e.target.value);
//       setIsValid(
//         valid &&
//         parseInt(e.target.value) >= minLength! &&
//         parseInt(e.target.value) <= maxLength!
//       );
//       if (validationResult) validationResult(value, valid);
//       return;
//     }
//     if (validationType === "double") {
//       regex = /^(?:\d\d{0,3})(?:\.\d{1,2})?$/;
//       valid = regex.test(e.target.value);
//       setIsValid(
//         valid &&
//         parseFloat(e.target.value) >= minLength! &&
//         parseInt(e.target.value) <= maxLength!
//       );
//       if (validationResult) validationResult(value, valid);
//     }
//   }

//   return (
//     <Form.Group controlId={id}>
//       <FloatingLabel label={label} className="mb-3">
//         <Form.Control
//           ref={ref}
//           type="number"
//           required={required}
//           value={value}
//           onChange={handleInputChange}
//           onBlur={handleBlur}
//           isInvalid={!isValid}
//         ></Form.Control>
//         <Form.Control.Feedback type="invalid">
//           Invalid {label}.
//         </Form.Control.Feedback>
//       </FloatingLabel>
//     </Form.Group>
//   );
// });

// NumberWithValidation.defaultProps = {
//   minLength: 0,
//   maxLength: 100000,
//   required: true,
// };

// export default NumberWithValidation;

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";
import { FormControlProps, InputGroup } from "react-bootstrap";
import { propsFilter } from "../../../app/tools";

interface NumberWithValidationProps extends FormControlProps {
  id: string;
  label: string;
  required?: boolean;
  initialValue?: any;
  disabled?: boolean;
  type?: string;
  autoFocus?: boolean;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
  formatFunction?: (input: string) => string;
}

export const NumberWithValidation = forwardRef(
  (props: NumberWithValidationProps, ref) => {
    const {
      initialValue,
      id,
      label,
      disabled,
      required,
      validationFunction,
      onChangeText,
      validationResult,
      formatFunction,
    } = props;

    const startValue = (): string => {
      if (initialValue === undefined) return "";
      const asString = initialValue?.toString() ?? "";
      if (!formatFunction) return asString;
      return formatFunction(asString);
    };

    const [value, setValue] = useState(startValue());
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [validationMessage, setValidationMessage] = useState("");

    React.useEffect(() => {
      handleValidation();
    }, [value]);

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      let changedValue = event.target.value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      let changedValue = event.target.value ?? "";
      setValue(changedValue);
    }

    function handleValidation(): boolean {
      let message = `Invalid ${label}`;
      let result = false;
      let valid = required ? (value !== "" && !isNaN(Number(value))) : true;

      if (required && value === "")
        message = "Field is required. It cannot be empty";
      else result = valid;

      setValidationMessage(message);

      if (validationFunction) result = validationFunction(value);
      if (validationResult) validationResult(value, result);

      setIsValid(result);

      if (!isNaN(Number(value))){
        const numberValue = value;
        if (numberValue !== value){
          setValue(numberValue);
        }
      }

      // if (formatFunction) {
      //   const formattedValue = formatFunction(value);
      //   if (formattedValue !== value) {
      //     setValue(formattedValue);
      //   }
      // }

      console.debug(`Valid (${result}): ${label}. ${value}`);
      return result;
    }

    const filteredProps = propsFilter(props, [
      "id",
      "label",
      "required",
      "initialValue",
      "disabled",
      "type",
      "validationFunction",
      "onChangeText",
      "validationResult",
      "formatFunction",
    ]);

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleValidation();
      },
    }));

    return (
      <Form.Group controlId={id}>
          <InputGroup hasValidation>
          <FloatingLabel label={label} className="mb-3">
            <Form.Control
              autoFocus={props.autoFocus}
              placeholder={label}
              required={required}
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur}
              isInvalid={isValid === undefined || !isValid}
              isValid={isValid}
              disabled={disabled ?? false}
              {...filteredProps}
            />
            <Form.Control.Feedback type="invalid">
              {validationMessage}.
            </Form.Control.Feedback>
        </FloatingLabel>
      
          </InputGroup>
      </Form.Group>
    );
  }
);

NumberWithValidation.defaultProps = {
  required: false,
};

export default NumberWithValidation;

