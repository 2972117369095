import { FormEvent, SetStateAction, useEffect, useState } from "react";
import {
  AddressModel,
  InspectionModel,
  ProvinceModel,
} from "../../data/entities";
import {
  CustomDatePicker,
  DirectionPicker,
  SimpleCard,
  ButtonWithSpinner,
  TextWithValidation,
  SearchableTubingDropdown,
  HandleDropDownListChange,
  HandleDropDownListBlur,
  HandleDatePickerChange,
  
} from "../../components";
import InspectionsManageWrapper from "./InspectionsManageWrapper";
import PostalCodeWithValidation from "../../components/form/input/PostalCodeWithValidation";
import {
  Dropdown,
  Option,
  handlePostalCodeTypeChanges,
  mapCountryToEnum,
} from "../../components/form/dropdown/Dropdown";
import AddressService from "../../services/AddressService";
import InspectionService from "../../services/InspectionService";
import { router, routes, useQuery } from "../../app/routes";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from "formik";
import { UrlService, UserService } from "../../services";
import { showSuccessMessage } from "../../app/tools";

export function InspectionsInsert() {
  const predefinedTubing = useQuery().get("tubingId");
  const defaultCountry = 0; // Canada
  const defaultRegion = 2; // Alberta ProvinceId
  const initialInspectionState: InspectionModel = {
    tubingId: predefinedTubing ? parseInt(predefinedTubing) : undefined,
  } as InspectionModel;
  const [newInspectionState, setNewInspectionState] = useState<InspectionModel | null>(initialInspectionState);
  const [allRegions, setAllRegions] = useState<ProvinceModel[] | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<number>(defaultCountry);
  const [countryList, setCountryList] = useState<Option[] | null>(null);
  const [regionList, setRegionList] = useState<Option[] | null>(null);

  const schema = yup.object({
    tubingId: yup.number().required('Tubing is required'),
    reference: yup.string().matches(/^[a-z0-9 ,.'-]*$/i, 'Invalid reference').required('Reference is required'),
    invoiceReference: yup.string().required('Invoice is required.').matches(/^[a-z0-9 ,.'-]*$/i, 'Invalid invoice'),
    inspectionDate: yup.date().required('Inspection date is required'),
    isReversed: yup.boolean(),
    contact: yup.string(),
    streetAddress: yup.string().required('Street address is required').matches(/^[a-z0-9 ,.'#-]*$/i, 'Invalid street address'),
    streetAddress2: yup.string(),
    city: yup.string().required('City is required'),
    country: yup.number().required('Country is required'),
    provinceId: yup.string().required('Province is required'),
    postalCode: yup.string().required('Postal code is required').matches(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$|^\d{5}(-\d{4})?$/, 'Invalid postal code'),
  });

  useEffect(() => {
    // These fields are passed as null if not set.
    setNewInspectionState((prevState) => ({
      ...(prevState as InspectionModel),
      contact: "",
      invoiceReference: "",
      inspectionDate: new Date(),
      isReversed: false,
      allowedOvalityMet: false,
    }));
    // Get all Regions
    new AddressService()
      .getAllProvinces()
      .then((data) => {
        setAllRegions(data);
      })
      .catch((error) => {
        console.error("Error fetching region data: ", error);
      });
    setNewInspectionState((prevState) => ({
      ...(prevState as InspectionModel),
      address: {
        ...(prevState?.address as AddressModel),
        provinceId: defaultRegion,
      },
    }));
  }, []);

  useEffect(() => {
    // Populate Country dropdown list.
    let countryList: Option[] = [];
    let countryCount = 0;
    allRegions?.forEach((province) => {
      if (
        !countryList.some((option) => option.text.includes(province.country))
      ) {
        countryList.push({
          value: countryCount.toString(),
          text: province.country,
        });
        countryCount++;
      }
    });
    setCountryList(countryList);
  }, [allRegions]);

  useEffect(() => {
    // Populate Region dropdown list.
    let regionList: Option[] = [];
    allRegions?.forEach((province) => {
      if (mapCountryToEnum(province.country) === selectedCountry) {
        regionList.push({
          value: province.id?.toString()!,
          text: province.name,
        });
      }
    });
    setRegionList(regionList);
  }, [selectedCountry, allRegions]);

  const handleNewInspectionChanges = (
    field: string,
    input: SetStateAction<string | boolean | Date | undefined>
  ) => {
    setNewInspectionState((prevState) => ({
      ...(prevState as InspectionModel),
      [field]: input,
    }));
  };

  const handleAddressChanges = (
    field: string,
    input: SetStateAction<string>
  ) => {
    if (field === "country") setSelectedCountry(parseInt(input.toString()));

    // Set the Address with the changes from the form field.
    setNewInspectionState((prevState) => ({
      ...(prevState as InspectionModel),
      address: {
        ...(prevState?.address as AddressModel),
        [field]: input,
      },
    }));
  };
  
  return (
    <InspectionsManageWrapper>
      <SimpleCard title="Add an inspection.">
        <Formik
          initialValues={{
            tubingId: predefinedTubing ? parseInt(predefinedTubing) : '',
            reference: '',
            invoiceReference: '',
            inspectionDate: new Date().toISOString(),
            isReversed: false,
            contact: '',
            streetAddress: '',
            streetAddress2: '',
            city: '',
            country: defaultCountry.toString(),
            provinceId: defaultRegion.toString(),
            postalCode: '',
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            const newId = await new InspectionService().addInspection(
              {
                tubingId: values.tubingId,
                reference: values.reference,
                invoiceReference: values.invoiceReference,
                inspectionDate: new Date(values.inspectionDate),
                isReversed: values.isReversed,
                contact: values.contact,
                address: {
                  streetAddress: values.streetAddress,
                  streetAddress2: values.streetAddress2,
                  city: values.city,
                  provinceId: parseInt(values.provinceId),
                  postalCode: values.postalCode,
                },
              } as InspectionModel
            )

            if (newId === null) return;
            router.navigateWithParams(
              routes.inspectionsDetails,
              new Map([["id", newId.toString()]])
            );

            setSubmitting(false);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, isSubmitting }) => {
            return (
            <Form noValidate onSubmit={handleSubmit}> 
              <Row className="mb-3">
                <Col lg={12}>
                  <Form.Group controlId="tubingId">
                    <SearchableTubingDropdown
                      name="tubingId"
                      value={values.tubingId}
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      onBlur={(e) => HandleDropDownListBlur(e, setFieldValue)}
                      isInvalid={touched.tubingId && !!errors.tubingId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.tubingId}
                      </Form.Control.Feedback>
                    </SearchableTubingDropdown>
                  </Form.Group>
                </Col>
              </Row> 
              <Row>
                <Col lg={6}>
                  <Form.Group as={Col} controlId="reference">
                    <FloatingLabel label="Reference" className="mb-3">
                      <Form.Control 
                        type="text"
                        name="reference"
                        value={values.reference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reference && !!errors.reference}
                        isValid={touched.reference && !errors.reference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.reference}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} controlId="invoiceReference">
                    <FloatingLabel label="Invoice" className="mb-3">
                      <Form.Control 
                        type="text"
                        name="invoiceReference"
                        value={values.invoiceReference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.invoiceReference && !!errors.invoiceReference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceReference}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-3">
                  <Form.Group controlId="tubingId">
                    <FloatingLabel className="h-100 pb-0 floating-label-fixed" label="Inspection date" style={{"color": "rgba(var(--bs-body-color-rgb), 0.65)"}}>
                      <CustomDatePicker
                        name="inspectionDate"
                        value={new Date(values.inspectionDate)}
                        onChange={(e) => HandleDatePickerChange(e, setFieldValue)}
                        isInvalid={touched.inspectionDate && !!errors.inspectionDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.inspectionDate}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    controlId={"cmbDirection"}
                    label={"Direction"}
                    className="mb-3"
                    aria-label={"Direction"}
                    title={"Direction"}
                  >
                    <DirectionPicker
                      id="cmbDirection"
                      label="Direction"
                      initialValue={values.isReversed.toString()}
                      onSelect={(value: string) => {
                        setFieldValue("isReversed", value === "true");
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="contact">
                  <FloatingLabel label="Contact" className="mb-3">
                    <Form.Control 
                      type="text"
                      name="contact"
                      value={values.contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.contact && !!errors.contact}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contact}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <hr />


              <Row>
                <Form.Group as={Col} controlId="streetAddress">
                  <FloatingLabel label="Street Address" className="mb-3">
                    <Form.Control 
                      type="text"
                      name="streetAddress"
                      value={values.streetAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.streetAddress && !!errors.streetAddress}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streetAddress}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="streetAddress2">
                  <FloatingLabel label="Apt / Unit" className="mb-3">
                    <Form.Control 
                      type="text"
                      name="streetAddress2"
                      value={values.streetAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.streetAddress2 && !!errors.streetAddress2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streetAddress2}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="city">
                  <FloatingLabel label="City" className="mb-3">
                    <Form.Control 
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.city && !!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row>
                <Col lg={12}>
                  <FloatingLabel
                    controlId={"country"}
                    label={"Country"}
                    className="mb-3"
                    aria-label={"Country"}
                    title={"Country"}
                  >
                  <Dropdown
                      label="Country"
                      selectedOption={selectedCountry ?? ""}
                      id="country"
                      options={countryList}
                      disabled={false}
                      onChangeSelected={(input) => {
                        handleChange(input);
                        handleAddressChanges("country", input);
                        setFieldValue("country", input);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <FloatingLabel
                    controlId={"region"}
                    label={"Region"}
                    className="mb-3"
                    aria-label={"Region"}
                    title={"Region"}
                  >
                    <Dropdown
                      label="Region"
                      selectedOption={defaultRegion}
                      id="region"
                      options={regionList}
                      disabled={false}
                      onChangeSelected={(input) => {
                        handleChange(input);
                        handleAddressChanges("provinceId", input);
                        setFieldValue("provinceId", input);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="postalCode">
                  <FloatingLabel label="Postal Code / Zip" className="mb-3">
                    <Form.Control 
                      type="text"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.postalCode && !!errors.postalCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.postalCode}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              
              <Button type="submit" className="btn btn-primary">Save</Button>
            </Form>
            );
          }}
        </Formik>
      </SimpleCard>
    </InspectionsManageWrapper>
  );
}

export default InspectionsInsert;

