import { routes } from "../../app/routes";
import { Link } from "../../components";

export function ResetPasswordConfirmation() {
  return (
    <>
      <h2 className="mb-4">Reset password confirmation</h2>
      <section>
        <p>
          Your password has been reset. Please{" "}
          <Link url={routes.login}>click here to log in</Link>.
        </p>
      </section>
    </>
  );
}
export default ResetPasswordConfirmation;

