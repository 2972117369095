import { useRef, useState } from "react";
import {
  SpinnerDefault,
  SearchableCompanyDropdown,
  HandleDropDownListBlur,
  HandleDropDownListChange,
  SlideOutModalProps,
  SlideOutModal
} from "../../../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, FloatingLabel, Form } from "react-bootstrap";

import "./InsertCompanyAccessModal.scss";

export interface InsertCompanyAccessModalProps extends SlideOutModalProps {
  onSubmit: (companyId: number) => void;
}

export function InsertCompanyAccessModal(props: InsertCompanyAccessModalProps) {
  const [showSpinner, setShowSpinner] = useState(false);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    companyId: yup.number().required("You must specify a company")
  });

  const onSubmit = async (values: any) => {
    setShowSpinner(true);
    console.log("Submitting add company access.", values);
    try {
      props.onSubmit.call(null, values?.companyId);
    } catch (e) {
      console.error("Error creating invitation", e);
    }
    setShowSpinner(false);
  };

  return (
    <SlideOutModal {...props}
      title="Add Company"
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Insert
            </Button>
          </div>
        </div>
      </div>}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ companyId: undefined }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
          return showSpinner ? <SpinnerDefault /> : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="companyId">
                <SearchableCompanyDropdown
                  name="companyId"
                  value={values.companyId}
                  onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                  onBlur={(e) => HandleDropDownListBlur(e, setFieldValue)}
                  isInvalid={touched.companyId && !!errors.companyId}
                >
                  <Form.Control.Feedback type="invalid">
                    {errors.companyId}
                  </Form.Control.Feedback>
                </SearchableCompanyDropdown>
              </Form.Group>
              <Button ref={formSubmitRef} type="submit" className="d-none" />
              {props.children}
            </Form>
          );
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default InsertCompanyAccessModal;

