import InspectorRepo from "../data/repo/InspectorRepo";

export class InspectorService {
  async getInspectors() {
    return await new InspectorRepo().getInspectors();
  }
}



export default InspectorService;
