import { routes } from "../../app/routes";
import Submenu from "../../components/submenu/Submenu";
import PageContent from "../PageContent";
import ArchiveCompresscard from "./ArchiveCompressCard";
import CleanUpOldValuesCard from "./CleanUpOldValuesCard";
import CompressImagesCard from "./CompressImagesCard";
import DefragmentDatabaseCard from "./DefragmentDatabaseCard";
import UploadedFilesCard from "./UploadedFilesCard";

export function MaintenancePage() {
  const title: string = "Maintenance";

  const submenus = [
    {
      label: "Maintenance",
      url: routes.maintenance,
      activeMatch: routes.maintenance,
      activeMatchExact: true,
    },
  ];

  return (
    <PageContent title={title} submenu={<Submenu items={submenus} />}>
      <p>
        This section is going to be where I'll put maintenance stuff that can be
        run from time-to-time to keep the site running fast and fresh. Things
        like cleaning out logs, re-indexing the data points, maybe even database
        backups.
      </p>
      <UploadedFilesCard />
      <DefragmentDatabaseCard />
      <CleanUpOldValuesCard />
      <ArchiveCompresscard />
      <CompressImagesCard />
    </PageContent>
  );
}

export default MaintenancePage;
