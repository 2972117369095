import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { routes } from "../../app/routes";
import PageContent from "../PageContent";
import {ProfileSubmenuSideBar} from "./";

interface Breadcrumb {
  label: string;
  url: string | null;
}

export function ManageAccountPage() {
  const location = useLocation();
  const breadcrumbs: Array<Breadcrumb> = [];

  useEffect(() => {
    return () => {};
  }, [location]);

  if (location.pathname === routes.manage) {
    breadcrumbs.push({ label: "Profile", url: null });
  } else {
    breadcrumbs.push({ label: "Profile", url: routes.manage });
    breadcrumbs.push({ label: "Change Password", url: null });
  }

  return (
    <PageContent
      title={"Manage your account"}
      breadcrumbs={{ values: breadcrumbs }}
    >
      <div className="row">
        <div className="col-12 col-lg-3">
          <ProfileSubmenuSideBar></ProfileSubmenuSideBar>
        </div>
        <div className="col-12 col-lg-9 position-relative">
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}

export default ManageAccountPage;

