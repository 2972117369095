import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NumberWithValidation, SlideOutModal, SlideOutModalProps } from "../../../../components";
import SegmentInsertEmptyModel from "../../../../data/entities/SegmentInsertEmptyModel";

export interface InsertEmptySegmentModalProps extends SlideOutModalProps {
  onSubmit: (data: SegmentInsertEmptyModel) => void;
}

export function InsertEmptySegmentModal(props: InsertEmptySegmentModalProps) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    length: 0
  } as SegmentInsertEmptyModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
    } else {
      setValidated(false);
    }
  };

  if (props.title !== undefined) delete props.title;
  if (props.footer !== undefined) delete props.footer;

  return (
    <SlideOutModal {...props}
      title="Insert Empty Segment"
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Insert
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <NumberWithValidation
          autoFocus={true}
          id="length"
          required={true}
          label="Length (m)"
          initialValue={`${values.length}`}
          onChangeText={(v) => setValues({ ...values, length: parseInt(v) })}
        />
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default InsertEmptySegmentModal;

