import ODataResponse from "../data/entities/ODataResponse";
import TubingModel from "../data/entities/TubingModel";
import TubingRepo from "../data/repo/TubingRepo";

export class TubingService {
  async getAllTubings(oDataQuery: string = ""): Promise<ODataResponse<TubingModel>> {
    const response = await new TubingRepo().getTubings(oDataQuery);
    return response;
  }

  async getTubingDetails(id: string): Promise<TubingModel> {
    const response = await new TubingRepo().getDetails(id);
    return response;
  }

  async updateTubingDetails(id: string, data: TubingModel): Promise<boolean | undefined> {
    return await new TubingRepo().updateDetails(id, data);
  }

  async addTubing(data: TubingModel): Promise<boolean | undefined> {
    return await new TubingRepo().addTubing(data);
  }

  async deleteTubing(id: number): Promise<boolean> {
    const deleted = await new TubingRepo().delete(id);
    return deleted;
  }

  async getTubingsList(): Promise<TubingModel[]> {
    const oDataQuery = await this.getAllTubings("expand=Company");
    if (!oDataQuery) return [];
    const count = oDataQuery["@odata.count"] ?? 0;
    return count > 0 ? oDataQuery.value : [];
  }
}

export default TubingService;
