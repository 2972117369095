import React, { useEffect, useState } from "react";
import { BlockButton, SlideOutModal } from "../../../../components";
import sharedProperty from "../../../../app/sharedProperty";
import { UserService } from "../../../../services";
import { NoteImageModel, NoteModel } from "../../../../data/entities";
import InspectionNoteImageCard from "./InspectionNoteImageCard";
import { getUuid } from "../../../../app/tools";
import {
  Upload,
  UploadOnAddEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import { ModalStatesModel } from "./InspectionsNotesModal";
import InspectionNotesSubmenu from "./InspectionNotesSubmenu";

interface InspectionNoteImagesModalProps {
  inspectionId: number;
  note: NoteModel;
  images: Array<NoteImageModel>;
  modalState: sharedProperty<boolean>;
  onSaved?: () => void;
  onStartSaving?: () => void;
  modalStates?: ModalStatesModel;
}

function InspectionNoteImagesModal(props: InspectionNoteImagesModalProps) {
  const [imageList, setImageList] = useState(props.images);
  const [uploadControl, setUploadControl] = useState({
    fileCount: 0,
    successUploadCount: 0,
  });

  useEffect(() => {
    setImageList(props.images);
  }, [props.images]);

  const footer = (
    <BlockButton
      onClick={() => {
        props.onSaved?.();
        props.modalState.setter(false);
      }}
    >
      Close
    </BlockButton>
  );

  const onDeleted = (imageId: number) => {
    const filtered = imageList.filter((image) => image.id !== imageId);
    setImageList(filtered);
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setUploadControl((current) => {
      return { ...current, fileCount: event.newState.length };
    });
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.response?.status === 201) {
      setUploadControl((current) => {
        return {
          ...current,
          successUploadCount: current.successUploadCount + 1,
        };
      });
    }
  };

  useEffect(() => {
    if (
      uploadControl.fileCount > 0 &&
      uploadControl.fileCount === uploadControl.successUploadCount
    ) {
      setUploadControl({ fileCount: 0, successUploadCount: 0 });
      props.onSaved?.();
    }
  }, [uploadControl, props]);

  return (
    <SlideOutModal
      showState={props.modalState}
      title="Images"
      footer={footer}
      onExit={() => props.onSaved?.()}
      submenu={
        <InspectionNotesSubmenu
          initialTab={"Images"}
          onChangeTab={() => {
            console.log("change tab");
          }}
          modalStates={props.modalStates}
        />
      }
    >
      <div className="row mb-2">
        <div className="col">
          <Upload
            showFileList={false}
            batch={false}
            multiple={true}
            defaultFiles={[]}
            withCredentials={false}
            autoUpload={true}
            saveHeaders={{
              Authorization: `Bearer ${UserService.getUserToken()}`,
            }}
            saveUrl={`${process.env.REACT_APP_API_URL}inspection/${props.inspectionId}/note/${props.note.id}/noteImage/addImages`}
            onAdd={onAdd}
            onStatusChange={onStatusChange}
          />
        </div>
      </div>

      {imageList.map((image) => {
        return (
          <InspectionNoteImageCard
            key={getUuid()}
            image={image}
            note={props.note}
            onSaved={props.onSaved}
            onDeleted={onDeleted}
          />
        );
      })}
    </SlideOutModal>
  );
}

export default InspectionNoteImagesModal;
