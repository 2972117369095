import React, { Dispatch, SetStateAction } from "react";
import sharedProperty from "../../../../app/sharedProperty";
import { Button } from "react-bootstrap";
import { SlideOutModal } from "../../../../components";
import { NoteService } from "../../../../services";

interface InspectionsNotesReverseModalProps {
  modalState: sharedProperty<boolean>;
  inspectionId: number;
  onSubmit: () => void;
  setShowSpinner: Dispatch<SetStateAction<boolean>>;
}

function InspectionsNotesReverseModal(
  props: InspectionsNotesReverseModalProps
) {
  const closeClickHandler = () => {
    props.modalState.setter(false);
  };
  const reverseClickHandler = async () => {
    try {
      props.setShowSpinner(true);
      let noteService = new NoteService();
      await noteService.reversePositions(props.inspectionId);
      props.modalState.setter(false);
      props.setShowSpinner(false);
      props.onSubmit();
    }
    catch (error) {
      console.error(error);
    }
  };

  const commands = (
    <>
      <Button variant="secondary" onClick={closeClickHandler}>
        Close
      </Button>
      <Button variant="primary" onClick={reverseClickHandler}>
        Reverse
      </Button>
    </>
  );

  return (
    <SlideOutModal
      showState={props.modalState}
      title="Reverse Note Positions"
      footer={commands}

    >
      <p>
        If you click the 'Reverse' button then this will take all of the note
        positions and flip them.
      </p>
      <p>
        So if your note positions are all entered as "distance from tail" then
        you can click the button to reverse them all to "distance from core" and
        vice versa.
      </p>
    </SlideOutModal>
  );
}

export default InspectionsNotesReverseModal;
