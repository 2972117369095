export function subtractDate(dateInFuture: Date, dateInPast: Date): Date {
  return new Date(dateInFuture.getTime() - dateInPast.getTime());
}

export function timeAgoString(date: Date): string {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();
  const millisecondsInSecond = 1000;
  const millisecondsInMinute = millisecondsInSecond * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;

  const daysAgo = Math.floor(diffInMilliseconds / millisecondsInDay);
  const hoursAgo = Math.floor((diffInMilliseconds % millisecondsInDay) / millisecondsInHour);

  const daysString = daysAgo === 1 ? 'Day' : 'Days';
  const hoursString = hoursAgo === 1 ? 'Hour' : 'Hours';

  return `${daysAgo} ${daysString}, ${hoursAgo} ${hoursString} ago`;
}



export function dateString(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return date.toLocaleDateString('en-US', options);
}

export function toYearMonthDay(dateString: string, separator: string = "-"): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}${separator}${month.toString().padStart(2, "0")}${separator}${day.toString().padStart(2, "0")}`;
}

export function toYearMonthDayTime(dateString: string, separator: string = "-", space: string = " "): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${year}${separator}${month.toString()}${separator}${day.toString().padStart(2, "0")}${space}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}${space}${ampm}`;
}
