import React from "react";
import TitlelessCard from "../../components/card/TitlelessCard";
import InspectionDetails from "../../data/entities/InspectionDetailsModel";
import metSource from '../../images/done-48.png';
import notMetSource from '../../images/close-48.png';

interface InspectionCriteriaProps {
  pageData: InspectionDetails | undefined | null;
}

const CriterionRow: React.FC<{ label: string, value: string, met?: boolean }> = ({ label, value, met }) => {
  return (
    <tr className="align-items-center">
      <td className="fw-bold p-3 align-middle">{label}</td>
      <td className="p-3 align-middle">{value}</td>
      <td className="p-3">
        {
          met ? 
            <img src={metSource} alt="Met" width={"30px"}/> : 
            <img src={notMetSource} alt="Not Met" width={"30px"} />
        }
      </td>
    </tr>
  );
};

export default function InspectionCriteria(props: InspectionCriteriaProps) {
  const { pageData } = props;
  
  return (
    <>
      <h5>Inspection Criteria</h5>
      <TitlelessCard className="mb-3 p-0">
        <div className="custom-table-container">
          <table className="table table-borderless m-0" style={{"width": "auto"}}>
            <tbody>
              {pageData?.specification && (
                <CriterionRow
                  label="Specification"
                  value={pageData.specification}
                  met={pageData.specificationMet}
                />
              )}
              {pageData?.allowedOvalitySpec && (
                <CriterionRow
                  label="Allowed Ovality"
                  value={`Within ${pageData.allowedOvalitySpec}`}
                  met={pageData.allowedOvalitySpecMet}
                />
              )}
              {pageData?.outsideDiameterSpec && (
                <CriterionRow
                  label="Outside Diameter"
                  value={pageData.outsideDiameterSpec}
                  met={pageData.outsideDiameterSpecMet}
                />
              )}
            </tbody>
          </table>
        </div>
      </TitlelessCard>
    </>
  );
}
