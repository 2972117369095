import { Outlet, createBrowserRouter, useLocation } from "react-router-dom";
import React from "react";
import Root from "../root";
import * as Pages from "../pages";
import { Roles } from "../models";

const routes = {
  home: "/",
  account: "/account",
  signOut: `/route2`,
  manage: `/manage`,
  inspection: "/inspection",
  company: "/company",
  companyInsert: "/company/insert",
  companyDetails: "/company/:Id/Details",
  companyAddress: "/company/:Id/Address",
  companyUsers: "/company/:Id/Users",
  companyTubings: "/company/:Id/Tubings",
  companyDelete: "/company/:Id/Delete",
  settings: "/settings",
  settingsAccount: "/settings/account",
  settingsScale: "/settings/scale",
  log: "/log",
  maintenance: "/maintenance",
  register: "/account/register/:id/:code",
  registerConfirmation: "/account/registerConfirmation",
  login: "/account/login",
  forgotPassword: "/account/forgotPassword",
  forgotPasswordConfirmation: "/account/forgotPasswordConfirmation",
  resetPassword: "/account/resetPassword",
  resetPasswordConfirmation: "/account/resetPasswordConfirmation",
  requestAccess: "/account/registrationRequest",
  requestAccessConfirmation: "/account/registrationRequestConfirmation",
  user: "/user",
  userDetails: "/user/:id",
  userSecurity: "/user/:id/security",
  userDelete: "/user/:id/delete",
  userInvitations: "/user/invite",
  userInsertInvitation: "/user/invite/insert",
  changePassword: "/manage/changePassword",
  tubings: "/tubings",
  tubingsInsert: "/tubings/insert",
  tubingDetails: "/tubings/:id/details",
  tubingInspections: "/tubings/:id/inspections",
  myInspections: "/home/myInspections",
  myInspectionsDetails: "/home/myInspections/:id/details",
  myInspectionsOvality: "/home/myInspections/:id/ovality",
  myInspectionsOD: "/home/myInspections/:id/od",
  inspections: "/inspection",
  inspectionsInsert: "/inspection/insert",
  inspectionsDetailsBase: "/inspection/:id",
  inspectionsDetails: "/inspection/:id/details",
  inspectionsNote: "/inspection/:id/notes",
  inspectionsNoteValues: "/inspection/:id/notes/:noteId/noteValue",
  inspectionsNoteImages: "/inspection/:id/notes/:noteId/noteImage",
  inspectionsInspectors: "/inspection/:id/inspectors",
  inspectionsCalibration: "/inspection/:id/calibration",
  inspectionsAccess: "/inspection/:id/access",
  inspectionsScale: "/inspection/:id/scale",
  inspectionsData: "/inspection/:id/data",
  inspectionsDataSegment: "/inspection/:id/data/segments",
  inspectionsDataPoint: "/inspection/:id/data/data-points",
  inspectionsDataOvality: "/inspection/:id/data/ovality",
  inspectionsProcess: "/inspection/:id/process",
  inspectionsProcessPositions: "/inspection/:id/process/positions",
  inspectionsProcessNormalize: "/inspection/:id/process/normalize",
  inspectionsProcessAdjust: "/inspection/:id/process/adjust",
  inspectionsProcessRandomize: "/inspection/:id/process/randomize",
  inspectionsProcessSplit: "/inspection/:id/process/split",
  inspectionsProcessMerge: "/inspection/:id/process/merge",
  inspectionsProcessFlwBaseline: "/inspection/:id/process/flw-baseline",
  inspectionsProcessCopy: "/inspection/:id/process/copy",
  inspectionsProcessReset: "/inspection/:id/process/reset",
  inspectionsProcessReversePositions: "/inspection/:id/process/reverse-positions",
};

const baseRouter = createBrowserRouter([
  {
    path: "*",
    element: <Pages.NotFound />,
  },
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <Pages.ErrorPage />,
    children: [
      // Empty path
      {
        path: "",
        element: <Pages.HomePage />,
      },
      //Home and My Inspections
      {
        path: "home",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Pages.MyInspectionPage />,
          },
          {
            path: "myInspections",
            element: <Pages.MyInspectionPage />,
          },
          {
            path: "myInspections/:id/details",
            element: <Pages.MyInspectionDetailsPage />,
          },
          {
            path: "myInspections/:id/ovality",
            element: <Pages.MyInspectionOvalityPage />,
          },
          {
            path: "myInspections/:id/od",
            element: <Pages.MyInspectionODPage />
          }
        ],
      },
      //Manage
      {
        path: "manage",
        element: <Pages.ManageAccountPage />,
        children: [
          {
            path: "",
            element: <Pages.ProfilePage />,
          },
          {
            path: "changePassword",
            element: <Pages.ChangePasswordPage />,
          },
        ],
      },
      // Tubings
      {
        path: "tubings",
        element: <Pages.SecurityOutlet roles={[Roles.admin, Roles.staff]} />,
        children: [
          {
            path: "",
            element: <Pages.TubingPage />,
          },
          {
            path: "insert",
            element: <Pages.TubingInsertPage />,
          },
          {
            path: ":id",
            element: <Pages.TubingDetailsPage />,
            children: [
              {
                path: "details",
                element: <Pages.TubingDetailsCard />,
              },
              {
                path: "inspections",
                element: <Pages.TubingInspectionsCard />,
              },
            ],
          },
        ],
      },
      // Inspections
      {
        path: "inspection",
        element: <Pages.SecurityOutlet roles={[Roles.admin, Roles.staff]} />,
        children: [
          {
            path: "",
            element: <Pages.InspectionsList />,
          },
          {
            path: "insert",
            element: <Pages.InspectionsInsert />,
          },
          {
            path: ":id",
            element: <Pages.InspectionsPage />,
            children: [
              {
                path: "",
                element: <Pages.InspectionsDetailsPage />,
                children: [
                  {
                    path: "",
                    element: <Pages.InspectionSummary />,
                  },
                  {
                    path: "details",
                    element: <Pages.InspectionSummary />,
                  },
                  {
                    path: "notes",
                    children: [
                      {
                        path: "",
                        element: <Pages.InspectionsNotes />,
                      },
                    ],
                  },
                  {
                    path: "inspectors",
                    element: <Pages.InspectionsInspectors />,
                  },
                  {
                    path: "calibration",
                    element: <Pages.InspectionsCalibration />,
                  },
                  {
                    path: "scale",
                    element: <Pages.InspectionsScale />,
                  },
                  {
                    path: "access",
                    element: <Pages.InspectionsAccess />,
                  },
                ],
              },
              {
                path: "data",
                element: <Pages.InspectionsDataPage />,
                children: [
                  {
                    path: "",
                    element: <Pages.InspectionsDataPoints />,
                  },
                  {
                    path: "segments",
                    element: <Pages.InspectionsDataSegments />,
                  },
                  {
                    path: "data-points",
                    element: <Pages.InspectionsDataPoints />,
                  },
                  {
                    path: "ovality",
                    element: <Pages.InspectionsDataOvality />,
                  },
                ],
              },
              {
                path: "process",
                element: <Pages.InspectionsProcessPage />,
                children: [
                  {
                    path: "positions",
                    element: <Pages.InspectionsProcessPositions />,
                  },
                  {
                    path: "normalize",
                    element: <Pages.InspectionsProcessNormalize />,
                  },
                  {
                    path: "adjust",
                    element: <Pages.InspectionsProcessAdjust />,
                  },
                  {
                    path: "randomize",
                    element: <Pages.InspectionsProcessRandomize />,
                  },
                  {
                    path: "split",
                    element: <Pages.InspectionsProcessSplit />,
                  },
                  {
                    path: "merge",
                    element: <Pages.InspectionsProcessMerge />,
                  },
                  {
                    path: "flw-baseline",
                    element: <Pages.InspectionsProcessFlwBaseline />,
                  },
                  {
                    path: "copy",
                    element: <Pages.InspectionsProcessCopy />,
                  },
                  {
                    path: "Reset",
                    element: <Pages.InspectionsProcessReset />,
                  },
                  {
                    path: "reverse-positions",
                    element: <Pages.InspectionsProcessReversePositions />,
                  },
                ],
              },
            ],
          },
        ],
      },
      // User
      {
        path: "user",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]}>
            <Pages.UsersPage />
          </Pages.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Pages.UsersGrid />,
          },
          {
            path: "invite",
            element: <Pages.UserInvitePage />,
            children: [
              {
                path: "",
                element: <Pages.UserInvitationsCard />,
              },
              {
                path: "insert",
                element: <Pages.UserInsertInviteCard />,
              },
              {
                path: `insert?returnUrl=:returnUrl`,
                element: <Pages.UserInsertInviteCard />,
              },
            ],
          },
          {
            path: ":id",
            element: <Pages.UserDetailsPage />,
            children: [
              {
                path: "",
                element: <Pages.UserProfileCard />,
              },
              {
                path: "security",
                element: <Pages.UserSecurityCard />,
              },
              {
                path: "delete",
                element: <Pages.UserDeleteCard />,
              },
            ],
          },
        ],
      },
      // Maintenance
      {
        path: "maintenance",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]}>
            <Pages.MaintenancePage />
          </Pages.SecurityOutlet>
        ),
      },
      // Company
      {
        path: "company",
        element: <Pages.SecurityOutlet roles={[Roles.admin]} />,
        children: [
          {
            path: "",
            element: <Pages.CompanyPage />,
          },
          {
            path: "insert",
            element: <Pages.CompanyInsertPage />,
          },
          {
            path: ":id",
            element: <Pages.CompanySubpagesWrapper />,
            children: [
              {
                path: "details",
                element: <Pages.CompanyDetailsCard />,
              },
              {
                path: "address",
                element: <Pages.CompanyAddressCard />,
              },
              {
                path: "users",
                element: <Pages.CompanyUsersCard />,
              },
              {
                path: "tubings",
                element: <Pages.CompanyTubingsCard />,
              },
            ],
          },
        ],
      },
      // Settings
      {
        path: "settings",
        element: <Pages.SecurityOutlet roles={[Roles.admin]} />,
        children: [
          {
            path: "",
            element: <Pages.SettingsGeneralCard />,
          },
          {
            path: "account",
            element: <Pages.SettingsAccountCard />,
          },
          {
            path: "scale",
            element: <Pages.SettingsScalePage />,
          },
        ],
      },
      // Log
      {
        path: "log",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]}>
            <Pages.LogPage />
          </Pages.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <h1>Log</h1>,
          },
        ],
      },
    ],
  },
  //Account
  {
    path: "account",
    element: <Pages.AccountPageLayout />,
    errorElement: <Pages.ErrorPage />,
    children: [
      {
        path: "",
        element: <Pages.Login />,
      },
      {
        path: "register/:id/:code",
        element: <Pages.RegisterPage />,
      },
      {
        path: "registerConfirmation",
        element: <Pages.RegisterConfirmationPage />,
      },
      {
        path: "login",
        element: <Pages.Login />,
      },
      {
        path: `login?returnUrl=:returnUrl`,
        element: <Pages.Login />,
      },
      {
        path: "forgotPassword",
        element: <Pages.ForgotPassword />,
      },
      {
        path: "forgotPasswordConfirmation",
        element: <Pages.ForgotPasswordConfirmation />,
      },
      {
        path: "resetPassword",
        element: <Pages.ResetPassword />,
      },
      {
        path: "resetPasswordConfirmation",
        element: <Pages.ResetPasswordConfirmation />,
      },
      {
        path: "registrationRequest",
        element: <Pages.RegistrationRequest />,
      },
      {
        path: "registrationRequestConfirmation",
        element: <Pages.RegistrationRequestConfirmation />,
      },
    ],
  },
]);

const router = Object.assign(baseRouter, {
  getUrl: (url: string, params: Map<string, string> | undefined) => {
    let lowerUrl = url.toLocaleLowerCase();
    let replacedUrl: string = lowerUrl;
    params?.forEach((value, key) => {
      replacedUrl = lowerUrl.replace(`:${key.toLocaleLowerCase()}`, value);
      lowerUrl = replacedUrl;
    });
    return lowerUrl;
  },
  navigateWithParams: (
    url: string,
    params: Map<string, string> | undefined,
    newTab: boolean = false
  ) => {
    const processedUrl = router.getUrl(url, params);
    if (newTab) {
      window.open(processedUrl, "_blank");
      return;
    }
    baseRouter.navigate(processedUrl).catch((error) => {
      return <Pages.ErrorPage />;
    });
  },
});

// This should be kept private to this file
class CustomURLSearchParams extends URLSearchParams {
  get(name: string): string | null {
    const value =
      super.get(name) ??
      super.get(name.toLowerCase()) ??
      super.get(name.toUpperCase());
    return value;
  }
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new CustomURLSearchParams(search), [search]);
}

function getRouteParams(route: string, path: string): Map<string, string> {
  const routeParts = route.split("/");
  const pathParts = path.split("/");
  const params = new Map<string, string>();
  for (let index = 0; index < routeParts.length; index++) {
    const routePart = routeParts[index];
    const pathPart = pathParts[index];
    if (routePart.startsWith(":")) {
      const key = routePart.substring(1);
      params.set(key, pathPart);
    }
  }
  return params;
}

function getQueryValues(query: string): Map<string, string> {
  const params = new Map<string, string>();
  query = query.replace("?", "");
  const queryParts = query.split("&");
  queryParts.forEach((part) => {
    const keyValue = part.split("=");
    params.set(keyValue[0], keyValue[1]);
  });
  return params;
}

function getQueryValue(query: string, key: string): string | null {
  const params = getQueryValues(query);
  const value =
    params.get(key) ??
    params.get(key.toLowerCase()) ??
    params.get(key.toUpperCase());
  return value ?? null;
}

export {
  router,
  routes,
  useQuery,
  getRouteParams,
  getQueryValues,
  getQueryValue,
};

