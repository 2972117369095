import { AdjustModel, ProcessMergeModel, ProcessResetModel, ProcessSplitModel, RandomizeModel } from "../data/entities";
import ProcessRepo from "../data/repo/ProcessRepo";
import ProcessCopyModel from "../data/entities/ProcessCopyModel";
import ProcessPositionsModel from "../data/entities/ProcessPositionsModel";
import ProcessFLWBaselineModel from "../data/entities/ProcessFLWBaselineModel";
import { NormalizeVarianceModel } from "../data/entities/NormalizeVarianceModel";
export class ProcessService {

  async getProcessReset(id: number): Promise<ProcessResetModel | null> {
    const response = await new ProcessRepo().getProcessReset(id);
    return response;
  }

  async getProcessReversePositions(id: number): Promise<object> {
    const response = await new ProcessRepo().getProcessReversePositions(id);
    return response;
  }

  async postProcessReset(id: number, confirm: string) {
    return await new ProcessRepo().postProcessReset(id, confirm);
  }

  async getProcessSplit(id: number): Promise<ProcessSplitModel | null> {
    const response = await new ProcessRepo().getProcessSplit(id);
    return response;
  }

  async postProcessSplit(id: number, model: ProcessSplitModel) {
    return await new ProcessRepo().postProcessSplit(id, model);
  }

  async getProcessMerge(id: number): Promise<ProcessMergeModel | null> {
    const response = await new ProcessRepo().getProcessMerge(id);
    return response;
  }

  async postProcessMerge(id: number, model: ProcessMergeModel) {
    return await new ProcessRepo().postProcessMerge(id, model);
  }

  async getProcessPositions(id: number): Promise<ProcessPositionsModel | null> {
    const response = await new ProcessRepo().getProcessPositions(id);
    return response;
  }

  async postProcessPositions(id: number, model: ProcessPositionsModel) {
    return await new ProcessRepo().postProcessPositions(id, model);
  }

  async getProcessNormalize(id: number): Promise<object> {
    const response = await new ProcessRepo().getProcessNormalize(id);
    return response;
  }

  async postProcessNormalize(id: number, body: NormalizeVarianceModel) {
    return await new ProcessRepo().postProcessNormalize(id, body);
  }

  async getProcessNormalizeVariance(id: number): Promise<object> {
    const response = await new ProcessRepo().getProcessNormalizeVariance(id);
    return response;
  }

  async postProcessNormalizeVariance(id: number) {
    return await new ProcessRepo().postProcessNormalizeVariance(id);
  }

  async getProcessNormalizeConfirm(id: number): Promise<object> {
    const response = await new ProcessRepo().getProcessNormalizeConfirm(id);
    return response;
  }

  async postProcessNormalizeConfirm(id: number) {
    return await new ProcessRepo().postProcessNormalizeConfirm(id);
  }

  async getProcessAdjust(id: number): Promise<AdjustModel | null> {
    const response = await new ProcessRepo().getProcessAdjust(id);
    return response;
  }

  async postProcessAdjust(id: number, body: AdjustModel) {
    return await new ProcessRepo().postProcessAdjust(id, body);
  }

  async getProcessRandomize(id: number): Promise<RandomizeModel | null> {
    const response = await new ProcessRepo().getProcessRandomize(id);
    return response;
  }

  async postProcessRandomize(id: number, body: RandomizeModel) {
    return await new ProcessRepo().postProcessRandomize(id, body);
  }

  async getProcessFLWBaseline(id: number): Promise<ProcessFLWBaselineModel | null> {
    const response = await new ProcessRepo().getProcessFLWBaseline(id);
    return response;
  }

  async postProcessFLWBaseline(id: number, model: ProcessFLWBaselineModel) {
    return await new ProcessRepo().postProcessFLWBaseline(id, model);
  }

  async getProcessCopy(id: number): Promise<ProcessCopyModel | null> {
    const response = await new ProcessRepo().getProcessCopy(id);
    return response;
  }

  async postProcessCopy(id: number, model: ProcessCopyModel) {
    return await new ProcessRepo().postProcessCopy(id, model);
  }

  async reversePositions(id: number) {
    return await new ProcessRepo().reversePositions(id);
  }
}

export default ProcessService;
