import { useState } from 'react'
import { SimpleCard } from '../../components/card/Card';
import Form from "react-bootstrap/Form";
import { BlockButton, TextWithValidation } from '../../components';
import MaintenanceService from '../../services/MaintenanceService';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

export default function ArchiveCompresscard() {

  const [olderThanDays, setOlderThanDays] = useState(30);
  const [ratio, setRatio] = useState(10);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleChange = (field: string, input: string) => {
    switch(field) {
      case "olderThanDays":
        setOlderThanDays(parseInt(input));
        break;
      case "ratio":
        setRatio(parseInt(input));
        break;
    }
  }

  async function archiveCompressButtonClick() {
    setShowSpinner(true);
    const ok = new MaintenanceService().compressInspections(olderThanDays, ratio).then(() => {});
    setShowSpinner(false);
    if(!ok) {
      showErrorMessage("Inspection archive and compression failed to execute.");
      return;
    }
    showSuccessMessage("Inspections archive and compression started, check logs for progress.");
  }

  return (
    <SimpleCard className="mb-3 p-0" title="Archive and Compress">
      <Form>
        <div className="row">
          <div className="col-12">
            <p>This process will take all inspections older than the number of days that you set, and it will archive and compress them.</p>
            <p>The "Archiving" step will start by taking the inspection and copy all of the data points over to the archive database for safe keeping. The "Compress" step will combine the data points together to save space and increase performance in the live database.</p>
            <p>Browsing around the site, the "Compressed" data will be used, which is much faster. But, when viewing a single inspection's detail page chart will load the data points from the "Archive" so that the client always sees all of the data points.</p>
            <strong>Moving and compressing the data can fragment the database, so be sure to always run the "Defragment Database" after you do this cleanup.</strong>
          </div>
          <div className="col-12 mt-3">
            <TextWithValidation
              label="Older than this many days"
              id=""
              required={false}
              initialValue={olderThanDays.toString()}
              onChangeText={(input) => {
                handleChange("olderThanDays", input);
            }}
            type="number"
            ></TextWithValidation>
          </div>
          <div className="col-12 mt-3">
            <TextWithValidation
              label="Compression Ratio (10 means 10:1)"
              id=""
              required={false}
              initialValue={ratio.toString()}
              onChangeText={(input) => {
                handleChange("ratio", input);
            }}
            type="number"
            ></TextWithValidation>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-5 col-xl-3 mt-3">
            <BlockButton
              label="Archive and Compress"
              onClick={archiveCompressButtonClick}
              disabled={false}
              showSpinner={showSpinner}
            ></BlockButton>
          </div>
        </div>
    </Form>
  </SimpleCard>
  );
}
