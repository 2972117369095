import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";

export const HandleDropDownListChange = (e: DropDownListChangeEvent, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any) => {
  const eventProps = {
    name: e.target.props.name ?? '',
    value: (e as any).value?.value ?? null
  };
  return setFieldValue(eventProps.name, eventProps.value, true);
};

export const HandleDropDownListBlur = (e: DropDownListBlurEvent, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any) => {
  const eventProps = {
    name: e.target.props.name ?? '',
    value: (e as any).value?.value ?? null
  };
  return setFieldValue(eventProps.name, eventProps.value, true);
};
