import { PropsWithChildren } from "react";
import PageContent, { PageBreadcrumbProps } from "../PageContent";
import { routes } from "../../app/routes";
import { useLocation } from "react-router-dom";
import InspectionRootSubmenu from "./InspectionRootSubmenu";

interface InspectionManageWrapperProps extends PropsWithChildren {
  footerless? : boolean;
}

export function InspectionsManageWrapper(
  props: InspectionManageWrapperProps
) {
  const location = useLocation();

  const breadcrumbs: PageBreadcrumbProps = {
    values: [],
  };

  if (location.pathname === routes.inspections) {
    breadcrumbs.values.push({ label: "Inspections", url: null });
  } else {
    breadcrumbs.values.push({ label: "Inspections", url: routes.inspections });
    breadcrumbs.values.push({ label: "Add Inspection", url: null });
  }

  const submenu = <InspectionRootSubmenu/>;

  return (
    <PageContent 
      title="Manage Inspections"
      breadcrumbs={breadcrumbs}
      submenu={submenu}
      footerless={props.footerless}
    >
      {props.children}
    </PageContent>
  );
}

InspectionsManageWrapper.defaultProps = {
  footerless: false,
};

export default InspectionsManageWrapper;
