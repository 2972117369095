import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SpinnerDefault, TextWithValidation } from "../../components";
import { InspectionService } from "../../services";

interface CreateReferencePointProps {
  pointData?: {
    inspectionId: number;
    categoryPosition: number;
    pointIndex: number;
  };
  show: boolean;
  handleClose: (created: boolean) => void;
}

export default function CreateReferencePoint(props: CreateReferencePointProps) {
  const { pointData, show, handleClose } = props;
  const [isPositionValid, setIsPositionValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [newPosition, setNewPosition] = useState(
    pointData?.categoryPosition ?? 0
  );

  const onCreated = () => {
    if (!pointData) return;

    setIsProcessing(true);

    const service = new InspectionService();
    service
      .createReferencePoint(
        pointData.inspectionId,
        newPosition,
        pointData.pointIndex
      )
      .then((value) => {
        handleClose(value);
      })
      .catch((error) => {
        handleClose(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const content = pointData ? (
    <TextWithValidation
      id={"txtPosition"}
      label={"Position"}
      initialValue={pointData.categoryPosition.toString()}
      type="number"
      required={true}
      validationResult={(input, result) => {
        setNewPosition(parseFloat(input));
        setIsPositionValid(result);
      }}
    />
  ) : (
    <em>Failed to get point.</em>
  );

  const footerContent = (
    <>
      <Button variant="secondary" onClick={() => handleClose(false)}>
        Close
      </Button>
      <Button variant="primary" onClick={onCreated} disabled={!isPositionValid}>
        Create
      </Button>
    </>
  );

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>Create Reference Point</Modal.Title>
      </Modal.Header>
      <Modal.Body>{isProcessing ? <SpinnerDefault /> : content}</Modal.Body>
      <Modal.Footer>
        {isProcessing ? <em>Processing...</em> : footerContent}
      </Modal.Footer>
    </Modal>
  );
}
