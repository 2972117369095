import BaseRepository from "./BaseRepository";

export class ImageRepo extends BaseRepository {

  async getImage(inspectionId: number, imageId: number, noteId: number,): Promise<any> {
    const url = `/Inspection/${inspectionId}/Note/${noteId}/NoteImage/${imageId}`;

    const response = await this.client.get(url, {
      responseType: 'arraybuffer',
    });
    return response.data;
  }

  async getThumbnail(inspectionId: number, imageId: number, noteId: number,): Promise<any> {
    const url = `/Inspection/${inspectionId}/Note/${noteId}/NoteImage/${imageId}/thumbnail`;

    const response = await this.client.get(url,
      {
        responseType: 'arraybuffer',
      });
    return response.data;
  }

  getThumbnailUrl(inspectionId: number, imageId: number, noteId: number,): string {
    return `${this.client.baseUrl}Inspection/${inspectionId}/Note/${noteId}/NoteImage/${imageId}/thumbnail`;
  }

  async getCalibrationImageThumbnail(inspectionId: number, imageId: number): Promise<any> {
    const url = `/Inspection/${inspectionId}/Calibration/${imageId}/thumbnail`;
    const response = await this.client.get(url,
      {
        responseType: 'arraybuffer',
      })
    return response.data;
  }

  async removeCalibrationImage(imageId: number): Promise<any> {
    const url = `OData/CalibrationImages(${imageId})`;
    const response = await this.client.delete(url);
    this.handleResponse(response);
    return response.success;
  }

  async updateCalibrationImage(imageId: number, caption: string): Promise<boolean> {
    const url= `OData/CalibrationImages(${imageId})`;
    const body = {
      id: imageId,
      caption: caption,
    };
    const response = await this.client.put(url, body);
    this.handleResponse(response);
    return response.success;
  }

}

export default ImageRepo;
