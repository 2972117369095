import { useState, useEffect } from "react";
import { router, routes } from "../../app/routes";
import {
  Card,
  CardBody,
  SpinnerDefault,
  TextWithValidation,
  Toggle,
} from "../../components";
import { CardTitle } from "../../components/card/CardTitle";
import { Form } from "react-bootstrap";
import SettingsSubmenu from "./SettingsSubmenu";
import SettingsModel from "../../data/entities/SettingsModel";
import SettingsService from "../../services/SettingsService";
import BlockButton from "../../components/form/buttons/BlockButton";
import { PageContent, PageBreadcrumbProps } from "../PageContent";
import { showSuccessMessage } from "../../app/tools";

export interface AccountSettingsValidationModel {
  expiryHours: boolean;
}

export function SettingsAccountCard() {
  const title: string = "Account Settings";
  const breadcrumbs: PageBreadcrumbProps = {
    showHome: true,
    values: [
      { label: "Settings", url: routes.settings },
      { label: "Accounts" },
    ],
  };

  const [pageData, setPageData] = useState<SettingsModel | undefined | null>(
    undefined
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [isFormValid, setIsFormValid] =
    useState<AccountSettingsValidationModel>();

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    setIsFormValid({
      expiryHours: true,
    });

    const fetchData = async () => {
      try {
        const loadedData = await new SettingsService().getAllAsync();
        setPageData(loadedData);
      } catch (e) {
        console.error("Error fetching settings: ", e);
      }
    };
    fetchData();
  }, []);

  /**
   * Handles changes to the input fields by updating the page data.
   * @param field - The name of the field to update.
   * @param value - The new value of the field.
   */
  const handleInputChange = (field: string, value: any) => {
    setPageData((prevPageData) => {
      if (!prevPageData) return null;
      const newPageData = { ...prevPageData } as SettingsModel;
      if (field in newPageData.invites) {
        (newPageData.invites as any)[field] = value;
      }
      return newPageData;
    });
  };

  /**
   * Handles the submission of the form by saving the updated site settings.
   */
  async function handleSaveSettings() {
    setShowSpinner(true);
    if (pageData) {
      const invites = pageData.invites;
      const ok: boolean | undefined =
        await new SettingsService().changeAccountsAsync(invites);
      console.log(ok);
      if (!ok) {
        console.error("Error changing accounts settings");
        return;
      }
    }
    setShowSpinner(false);
    showSuccessMessage("Account settings updated.");
    router.navigate(routes.settingsAccount);
  }

  /**
   * Checks if every field in the form is valid.
   * @returns boolean
   */
  function isButtonEnabled() {
    if (isFormValid) {
      const result = Object.values(isFormValid).every(
        (value) => value === true
      );
      return result;
    }
  }

  if (pageData === undefined) {
    return (
      <PageContent
        title={title}
        breadcrumbs={breadcrumbs}
        submenu={<SettingsSubmenu />}
      >
        <div className="position-relative">
          <SpinnerDefault />
        </div>
      </PageContent>
    );
  }

  return (
    <>
      <PageContent
        title={title}
        breadcrumbs={breadcrumbs}
        submenu={<SettingsSubmenu />}
      >
        <Card>
          <CardTitle>
            <h6 className="text-light m-0">
              Modify account invitation settings.
            </h6>
          </CardTitle>
          <CardBody>
            <Form>
              <div className="row mt-2">
                <div className="col-12">
                  <TextWithValidation
                    id="expiryHours"
                    type="number"
                    required={true}
                    label="Expiry (hours)"
                    initialValue={pageData?.invites.expiryHours.toString()}
                    onChangeText={(value) => {
                      handleInputChange("expiryHours", value);
                    }}
                    validationResult={(input, result) => {
                      setIsFormValid((current) => ({
                        ...current,
                        expiryHours: result,
                      }));
                    }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <Toggle
                    id="requireExactEmail"
                    description="Require Exact Email"
                    initialValue={pageData?.invites?.requireExactEmail}
                    onChange={(value) => {
                      handleInputChange("requireExactEmail", value);
                    }}
                  />
                </div>
                <div className="col col-1">
                  <BlockButton
                    label="Save"
                    onClick={handleSaveSettings}
                    disabled={!isButtonEnabled()}
                    showSpinner={showSpinner}
                  />
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
}

export default SettingsAccountCard;

