import { useDispatch, useSelector } from "react-redux";
import { setInspectionForManagement } from "../../../../app/store/inspectionManagementSlice";
import { EditInspectionModel } from "../../../../data/entities";
import { BlockButton, LinkLooksLike } from "../../../../components";
import { InspectionService, UserService } from "../../../../services";
import { FileExportType, Roles } from "../../../../models";
import { Button } from "react-bootstrap";
import { showSuccessMessage } from "../../../../app/tools";
import { toYearMonthDayTime } from "../../../../app/dateHelpers";
import React, { useState } from "react";
import { store } from "../../../../app/store/store";

export default function InspectionSummaryCache() {
  const isAdmin = UserService.isInRole(Roles.admin);
  const [showSpinner, setShowSpinner] = useState(false);
  const inspection = useSelector(
    (state: any) => state.inspectionManagement.inspection as EditInspectionModel
  );
  let cachedMessage: React.JSX.Element | null = null;

  const downloadPdf = async (url: string) => {
    const inspectionService = new InspectionService();

    try {
      await inspectionService
        .exportFile(url)
        .then((file) => {
          if (!file) return;
  
          const binaryData = [];
          binaryData.push(file.data);
          const blob = new Blob(binaryData, { type: FileExportType.Report.toString() });
          const objUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download =
            file.name ??
            `${inspection.id}.pdf`;
          link.target = "_blank";
          link.href = objUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(objUrl);
        });
    } catch (e) {
      console.error(e);
    }
  };

  if (!inspection.exportReportUrl)
    cachedMessage = (
      <>
        <h4 className="alert-heading mt-0">Report Not Cached</h4>
        <p>This report has not been cached.</p>
      </>
    );
  else {

    const url = inspection.exportReportUrl;

    cachedMessage = (
      <>
        <h4 className="alert-heading mt-0">Cached Report</h4>
        <p>
          <span>Date: </span>
          <span>
            {toYearMonthDayTime(new Date(inspection.exportFileInfo?.lastWriteTime!).toString() ?? "")}
          </span>
          <br />
          <span>Size: </span>
          <span>{`${inspection.exportFileInfo ? Math.floor(inspection.exportFileInfo.length / 1024) : 0}kB`}</span>
          <br />
          <LinkLooksLike onClick={() => downloadPdf(url)}>
            Download Cached Report
          </LinkLooksLike>
        </p>
      </>
    );
  }

  const handleCacheClicked = async () => {
    setShowSpinner(true);
    showSuccessMessage(
      "The report is being cached. Please wait a few minutes and refresh the page."
    );
    const requested = await new InspectionService().cacheReportInspection(
      inspection.id
    );
    if (requested) {
      setShowSpinner(false);
    }
  };

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-12 col-md-9">{cachedMessage}</div>
        <div className="col-12 col-md-3 text-md-end">
          {isAdmin && <BlockButton
            className="btn-small"
            onClick={handleCacheClicked}
            showSpinner={showSpinner}
          >
            Cache Now
          </BlockButton>}
        </div>
      </div>
    </div>
  );
}

