export function ForgotPasswordConfirmation() {
  return (
    <>
      <h2 className="mb-4">Forgot password confirmation</h2>
      <section>
        <p>
          Please check your email to reset your password. If you don't receive the email within a few
          minutes, please check your spam folder before trying again.
        </p>
      </section>
    </>
  );
}

export default ForgotPasswordConfirmation;