import LogModel from "../data/entities/LogModel";
import ODataResponse from "../data/entities/ODataResponse";
import LogRepo from "../data/repo/LogRepo";
export class LogService {

  async getEventLogsOData(oDataQuery: string = ""): Promise<ODataResponse<LogModel>> {
    const response = await new LogRepo().getEventLogsOData(oDataQuery);
    return response;
  }

  async insertEventLog(eventLog: LogModel): Promise<boolean | undefined> {
    const response = await new LogRepo().insertEventLog(eventLog);
    return response;
  }
}

export default LogService;
