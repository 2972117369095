import { RegisterModel } from "../data/entities";
import { AccountRepo } from "../data/repo/AccountRepo";

export class AccountService {

  async register(id: number, key: string, body: RegisterModel): Promise<boolean | null> {
    const response = await new AccountRepo().register(id, key, body);
    return response;
  }
} 

export default AccountService;
