import React, { useEffect, useState } from "react";
import { Dropdown, Option } from "..";

interface DirectionPickerProps {
  id: string;
  label: string;
  onSelect: (value: string) => void;
  initialValue?: string;
}

export const DirectionPicker = React.forwardRef(
  (
    props: DirectionPickerProps,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        const newOptions = [
          {
            value: "false",
            text: "Core to Tail",
          },
          {
            value: "true",
            text: "Tail to Core",
          },
        ] as Option[];

        setOptions(newOptions);
      };

      fetchData();

      return () => {};
    }, []);

    return (
      <Dropdown
        id={props.id}
        label={props.label}
        options={options}
        selectedOption={props.initialValue}
        onChangeSelected={(value: React.SetStateAction<string>) => {
          props.onSelect(value.toString());
        }}
        ref={ref}
      />
    );
  }
);

export default DirectionPicker;

