import "./TopBar.scss";
import logo from "../../images/layout/brandname.png";
import { routes, router } from "../../app/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/store";

import UserService from "../../services/UserService";

import {
  NavigationItem,
  IconDefinitions,
  IconAndText,
  Icon,
  LinkLooksLike,
} from "../";
import Gravatar from "react-gravatar";
import { Dropdown } from "react-bootstrap";

interface TopBarProps extends React.PropsWithChildren {
  showToggle: boolean;
  onToggle?: () => void;
}

export function TopBar(props: TopBarProps) {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isSignedIn);

  const menuToggle = (e: any) => {
    props.onToggle?.call(null);
  };

  const logoClick = () => {
    router.navigate(routes.home);
  };

  const profileClick = () => {
    router.navigate(routes.manage);
  };

  const changePasswordClick = () => {
    router.navigate(routes.changePassword);
  };

  const signOutClick = () => {
    UserService.logout();
  };

  return (
    <nav
      id="top-bar"
      className="navbar navbar-expand-lg navbar-dark bg-dark py-0 px-3 justify-content-between"
    >
      <LinkLooksLike
        className="d-lg-none text-lg text-light"
        onClick={menuToggle}
      >
        <Icon iconName={IconDefinitions.menu} />
      </LinkLooksLike>
      <span className="navbar-brand" onClick={logoClick}>
        <img src={logo} alt="CTI logo" />
      </span>
      <ul className="navbar-nav">
        {isLoggedIn ? (
          <Dropdown>
            <Dropdown.Toggle as="div" className="cursor-pointer">
              <Gravatar
                className="navbar-avatar"
                email={UserService.getUserEmail()}
                size={96}
                default="identicon"
                alt={UserService.getDisplayName()}
                title={UserService.getDisplayName()}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="border-bottom line-height-1 px-3 pt-1 mb-2">
                <div>{UserService.getDisplayName()}</div>
                <div className="text-secondary fw-bold small mb-2">
                  {UserService.getCompanyName()}
                </div>
              </div>
              <Dropdown.Item href="#" onClick={profileClick}>
                <IconAndText
                  iconName={IconDefinitions.user}
                  text="My Profile"
                />
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={changePasswordClick}>
                <IconAndText
                  iconName={IconDefinitions.key}
                  text="Change Password"
                />
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={signOutClick}>
                <IconAndText
                  iconName={IconDefinitions.signOut}
                  text="Sign out"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <NavigationItem
            text="Sign in"
            url={routes.login}
            icon="sign-in"
            additionalClass="text-nowrap"
          ></NavigationItem>
        )}
      </ul>
    </nav>
  );
}

export default TopBar;

