import { ScaleModel } from "../entities";
import InviteModel from "../entities/InviteModel";
import SettingsModel from "../entities/SettingsModel";
import SiteInfoModel from "../entities/SiteInfoModel";
import BaseRepository from "./BaseRepository";

export class SettingsRepo extends BaseRepository {

  async getSettingsAsync(): Promise<SettingsModel | null> {
    const url = "Settings";
    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  async changeSiteInfoAsync(siteInfo: SiteInfoModel): Promise<boolean | undefined> {
    const url = "Settings/SiteInfo";
    const response = await this.client.post(url, siteInfo);
    return this.handleResponse(response);
  }

  async changeAccountsAsync(accounts: InviteModel): Promise<boolean | undefined> {
    const url = "Settings/Accounts";
    const response = await this.client.post(url, accounts);
    return this.handleResponse(response);
  }

  async changeScaleAsync(scale: ScaleModel): Promise<boolean | undefined> {
    const url = "Settings/Scale";
    const response = await this.client.post(url, scale);
    return this.handleResponse(response);
  }

}

export default SettingsRepo;
