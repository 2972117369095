import "./OvalityChart.scss";
import { InspectionOvalityChartModel, SystemOfMeasurement } from "../../data/entities";
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  SelectEndEvent,
  SharedTooltipContext,
  TooltipContext,
  TooltipPoint,
} from "@progress/kendo-react-charts";
import { MeasurementConverter } from "../../app/measurementConverter";
import { MeasurementUnit } from "../../data/entities/MeasurementUnit";
import { v4 as uuidv4 } from "uuid";
import { Measurement } from "../../data/entities/Measurement";
import { OvalityService, UserService } from "../../services";
import { useState } from "react";
import * as Measure from "../../components/inspections/Measurement";

interface OvalityChartProps {
  chart: InspectionOvalityChartModel;
  threshold?: number;
  odLow?: number;
  odHigh?: number;
}

export default function OvalityChart(props: Readonly<OvalityChartProps>) {
  // 2022-08-17: This was changed to be a static amount
  // instead of using ovality allowance percentage.
  // The allowance sets the green area of the chart.
  // 0.25mm = 0.01in
  let allowance = 0.25;
  let nominal = props.chart.nominal;
  const converterService = new MeasurementConverter();

  const preferredMeasurement = UserService.getPreferredMeasurement();
  if (preferredMeasurement === SystemOfMeasurement.US) {
    nominal = Measure.millimetersToInches(nominal);
    allowance = Measure.millimetersToInches(allowance);
  }

  const points = props.chart.points.map((point) => {
    return {
      ...point,
      nominal: nominal,
      ovalityThreshold: props.threshold!/100,
      allowance: allowance,
    };
  });

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(points.length);
  const [chartTitle, setChartTitle] = useState<string | undefined>(undefined);

  const tooltipRender = function (
    props: TooltipContext | SharedTooltipContext,
    isOvality: boolean
  ) {
    const data = props as any;
    const points = data.points;
    if (!points) return null;


    const legendMap = {
      Min: "OD Min",
      Max: "OD Max",
      Avg: "OD Avg",
      Nominal: "Nominal",
      Ovality: "Ovality",
      Threshold: "Threshold"
    };

    // const ctiOvality = () => {
    //   const ODMin = points.find((p: TooltipPoint) => p.series.name === "Min");
    //   const ODMax = points.find((p: TooltipPoint) => p.series.name === "Max");
    //   const nominal = points.find(
    //     (p: TooltipPoint) => p.series.name === "Nominal"
    //   );

    //   if (!ODMin || !ODMax || !nominal) return null;

    //   const ctiOvalityPercent = new OvalityService().calculateCtiOvalityPercent(
    //     ODMax.value,
    //     ODMin.value,
    //     nominal.value
    //   );
    //   return (
    //     <div key={uuidv4()}>
    //       <span>CTI Ovality</span>: {`${(ctiOvalityPercent * 100).toPrecision(4)}%`}
    //     </div>
    //   );
    // };

    const tooltip = (
      <div>
        <b>
          <span>Position</span>:{" "}
          {converterService.toFriendlyString({
            value: points[0].category,
            unit: MeasurementUnit.distanceLarge,
            system: UserService.getPreferredMeasurement(),
          } as Measurement)}
        </b>
        {points.map((point: TooltipPoint, index: number) => {
          const name = point.series.name as
            | "Min"
            | "Max"
            | "Avg"
            | "Nominal"
            | "Ovality"
            | "Threshold"
          point.series.axis = "percent";
          if (isOvality && (name === "Nominal" || name === "Min" || name === "Max")) {
            return null;
          }
          return (
            <div key={uuidv4()}>
              <span>{legendMap[`${name}`] || point.series.name}</span>:{" "}
              {point.series.axis === "percent"
                ? `${(point.value * 100).toPrecision(4)}%`
                : converterService.toFriendlyString({
                    value: point.value,
                    unit: MeasurementUnit.distanceTiny,
                    system: UserService.getPreferredMeasurement(),
                  } as Measurement)}
            </div>
          );
        })}
        {/* 2024-02-22 CTI requested to remove CTI Ovality from tooltip.
          Leaving code in here just */}
        {/* {isOvality && ctiOvality()} */}
      </div>
    );
    return tooltip;
  };

  const onSelectEnd = (e: SelectEndEvent) => {
    const fromIndex = e.from;
    const toIndex = e.to;

    const fromDistance = Math.round(points[fromIndex].startPosition);
    const toDistance = Math.round(points[toIndex-1].startPosition);
    setMin(e.from);
    setMax(e.to);

    if (e.from === 0 && e.to === points.length) {
      setChartTitle(undefined);
      return;
    }

    let measurement = UserService.getPreferredMeasurement();
    let unit = measurement == SystemOfMeasurement.metric ? "m" : "ft";


    const start = e.from === 0 ? "Start" : `${fromDistance}${unit}`;
    const end = e.to === points.length ? "End" : `${toDistance}${unit}`;
    setChartTitle(`${start} - ${end}`);
  };

  const threshold = points.map((p) => {
    return props.threshold;
  })

  return (
    <div className="">

      {/* Ovality Chart */} 
      <Chart>
        <ChartLegend visible={true} position="top" />        
        <ChartTitle text={chartTitle}></ChartTitle>
        <ChartSeries
        >
          <ChartSeriesItem
            name="Nominal"
            type="line"
            visibleInLegend={false}
            field="nominal"
            categoryField="startPosition"
            color={"#00cc00"}
            width={1}
            markers={{ visible: false }}
            labels={{ visible: false }}
            data={points}
          />
          <ChartSeriesItem
            name="Min"
            type="line"
            visibleInLegend={false}
            field="diameterMinimum"
            categoryField="startPosition"
            color={"#00000033"}
            width={1}
            markers={{ visible: false }}
            labels={{ visible: false }}
            data={points}
          />
          <ChartSeriesItem
            name="Max"
            type="line"
            visibleInLegend={false}
            field="diameterMaximum"
            categoryField="startPosition"
            color={"#00000033"}
            width={1}
            markers={{ visible: false }}
            labels={{ visible: false }}
            data={points}
          />
          <ChartSeriesItem
            name="Threshold"
            visible={!!props.threshold}
            type="line"
            field="ovalityThreshold"
            categoryField="startPosition"
            color={"#00cc00"}
            width={1}
            markers={{ visible: false }}
            labels={{ visible: false }}
            tooltip={{ visible: true }}
            data={points}
          >
          </ChartSeriesItem>
          <ChartSeriesItem
            name="Ovality"
            type="line"
            field="ovalityPercent"
            categoryField="startPosition"
            color={"#0D698B"}
            axis="percent"
            width={1}
            markers={{ visible: false }}
            labels={{ visible: false }}
            tooltip={{ visible: true }}
            data={points}
          />,
        </ChartSeries>
        <ChartValueAxis>
          <ChartValueAxisItem
            name="percent"
            title={{ text: "Ovality %" }}
            min={0}
            max={0.1}
            visible={true}
            labels={{
              visible: true,
              format: "{0:P1}",
              margin: {left: 25}
            }}
            line={{ visible: false }}
            majorGridLines={{ visible: true }}
            minorGridLines={{ visible: false }}
            majorTicks={{ visible: false }}
            minorTicks={{ visible: false }}
            axisCrossingValue={0}
          />
          <ChartValueAxisItem
            visible={false}
            max={10}
            labels={{visible: false}}
            line={{ visible: false }}
            plotBands={[
              {
                from: 0,
                to: props.threshold,
                color: "#0c0",
                opacity: 0.07,
              },
            ]}
            majorGridLines={{ visible: true,step: 2,}}
            minorGridLines={{ visible: false }}
            majorTicks={{ visible: false }}
            minorTicks={{ visible: false }}
            axisCrossingValue={0}
          />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            min={min}
            max={max}
            axisCrossingValue={[0, 999999]}
            majorGridLines={{ visible: false }}
            majorTicks={{ visible: false }}
            minorTicks={{ visible: false }}
            labels={{ visible: false }}
            crosshair={{ visible: true, color: "#ff6f00" }}
          />
        </ChartCategoryAxis>
        <ChartTooltip render={(props) => tooltipRender(props, true)} opacity={0.7} shared={true} />
      </Chart>

      {/* Navigator */}
      <Chart
        transitions={false}
        onSelectEnd={onSelectEnd}
        style={{ maxHeight: "110px" }}
      >
        <ChartLegend visible={false} />
        <ChartArea height={110} margin={{ left: 65, right:0 }} />
        <ChartSeries>
          <ChartSeriesItem
            name="Ovality Navigation"
            type="line"
            field="ovalityPercent"
            categoryField="startPosition"
            color={"#505050"}
            width={1}
            markers={{ visible: false }}
            labels={{
              visible: false }}
            tooltip={{ visible: false }}
            data={points}
          />
        </ChartSeries>
        <ChartValueAxis>
          <ChartValueAxisItem
            name="navigatorValueAxis"
            visible={false}
            labels={{ visible: false }}
            line={{ visible: false }}
            majorGridLines={{ visible: false }}
            minorGridLines={{ visible: false }}
            majorTicks={{ visible: false }}
            minorTicks={{ visible: false }}
            axisCrossingValue={0}
          />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            name="navigatorCategoryAxis"
            title={{ text: preferredMeasurement ==  SystemOfMeasurement.metric ? "Meters (m)" : "Feet (ft)"}}
            majorGridLines={{ visible: false }}
            majorTicks={{
              visible: true,
              step: Math.round(points.length / 40),
            }}
            minorTicks={{ visible: false }}
            labels={{
              visible: true,
              step: Math.round(points.length / 10),
              format: "#",
              margin: { top: 15 },
            }}
            select={{ from: min, to: max }}
          />
        </ChartCategoryAxis>
      </Chart>
    </div>
  );
}

