import { setLoggedUser, setToken, setTokenData, setIsLoggedIn } from "../app/store/authSlice";
import { UserRepo } from "../data/repo/UserRepo";
import LoggedUser from "../models/LoggedUser";
import { store } from '../app/store/store';
import TokenService from "./TokenService";
import { CompanyRepo } from "../data/repo/CompanyRepo";

import { SystemOfMeasurement } from "../data/entities/SystemOfMeasurement";
import { ODataResponse } from "../data/entities";
import UserDetailsModel from "../data/entities/UserDetailsModel";
import { Roles } from "../models";
import UserProfileModel from "../data/entities/UserProfileModel";
import SecurityModel from "../data/entities/SecurityModel";
import UserDeleteModel from "../data/entities/UserDeleteModel";
import { forceLogout } from "../app/tools";

const TOKEN_STORAGE = 'userToken';
export class UserService {

  static getDisplayName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.firstName ?? loggedUser.email} ${loggedUser.lastName ?? ""}`;
  }

  static getId() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.id ?? 0}`;
  }

  static getFirstName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.firstName ?? ""}`;
  }

  static getLastName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser.lastName ?? ""}`;
  }

  static getCompanyName() {
    const loggedUser = store.getState().auth.loggedUser;
    return loggedUser?.company?.name ?? "";
  }

  static getCompanyId() {
    const loggedUser = store.getState().auth.loggedUser;
    return loggedUser?.company?.id ?? 0;
  }

  static getUserEmail() {
    const loggedUser = store.getState().auth.loggedUser;
    return loggedUser?.email ?? "";
  }

  static isInRole(roleName: Roles): boolean {
    const tokenData = store.getState().auth.tokenData;
    return tokenData?.roles?.includes(roleName) ?? false;
  }

  static getDefaultMeasurement(): SystemOfMeasurement {
    return store.getState().auth.tokenData?.systemOfMeasurement ?? SystemOfMeasurement.metric;
  }

  static getPreferredMeasurement(): SystemOfMeasurement {
    const loggedUser = store.getState().auth.loggedUser;
    return loggedUser?.preferredMeasurement ?? SystemOfMeasurement.metric;
  }

  static isSignedIn(): boolean {
    const isIn = store.getState().auth.isSignedIn;
    if (!isIn) return false;

    const token = store.getState().auth.userToken;
    if (!token) return false;

    const expired = TokenService.isTokenExpired();
    return !expired;
  }

  async login(email: string, password: string, rememberMe: boolean) {
    const userToken = await new UserRepo().login(email, password, rememberMe);
    if (!userToken) return;

    // Setting token to the store allows all repos to access it.
    store.dispatch(setToken(userToken));

    const tokenData = TokenService.getTokenData(userToken);
    const loggedUser = await this.getUser(tokenData.userId);
    if (!loggedUser) return;

    const company = await new CompanyRepo().getDetails(loggedUser.company!.id);
    if (!company) return;

    loggedUser.company = { id: company?.id ?? 0, name: company.name, defaultMeasurement: company?.defaultMeasurement ?? 0 };

    if (rememberMe) {
      localStorage.setItem(TOKEN_STORAGE, userToken);
    }

    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setTokenData(tokenData));
    store.dispatch(setLoggedUser(loggedUser));
  }

  static getUserToken(): string | null {
    return store.getState().auth.userToken;
  }

  async forgotPassword(email: string) {
    return await new UserRepo().forgotPassword(email);
  }

  async resetPassword(email: string, password: string, confirmation: string, code: string) {
    return await new UserRepo().resetPassword(email, password, confirmation, code);
  }

  async updateProfile(model: UserProfileModel) {
    const ok = await new UserRepo().updateUserProfile(model);
    const loggedUser = await this.getUser(model?.id ?? 0);
    store.dispatch(setLoggedUser(loggedUser));
    return ok;
  }

  async changePassword(password: string, newPassword: string, confirmation: string) {
    return await new UserRepo().changePassword(password, newPassword, confirmation);
  }

  async register(
    email: string,
    firstName: string,
    lastName: string,
    company: string) {
    return await new UserRepo().register(email, firstName, lastName, company);
  }

  static logout() {
    forceLogout();
  }

  private async getUser(id: number): Promise<LoggedUser | undefined> {
    const repo = new UserRepo();
    const details = await repo.getUserDetails(id);
    if (!details) return undefined;

    const company = await new CompanyRepo().getDetails(details.user.companyId ?? 0);
    if (!company) return undefined;

    const loggedUser = {
      id: details.user.id ?? 0,
      userName: details.user.userName ?? "",
      email: details.user.email,
      firstName: details.user.firstName,
      lastName: details.user.lastName,
      preferredMeasurement: details.user.preferredMeasurement,
      company: {
        id: company?.id ?? 0,
        name: company.name,
        defaultMeasurement: company?.defaultMeasurement ?? 0,
      },
    };

    return loggedUser;
  }

  async getUsers(oDataQuery: string): Promise<ODataResponse<UserDetailsModel>> {
    const response = await new UserRepo().getUsers(oDataQuery);
    return response;
  }

  async getUserById(id: number): Promise<UserDetailsModel | null> {
    const response = await new UserRepo().getUserById(id);
    return response;
  }

  async updateUserDetails(id: number, model: UserDetailsModel): Promise<boolean> {
    const response = await new UserRepo().updateUserDetails(id, model);
    return response;
  }

  async deleteUser(id: number, model: UserDeleteModel): Promise<boolean> {
    const response = await new UserRepo().deleteUser(id, model);
    return response;
  }

  async getUserSecurity(id: number): Promise<SecurityModel | null> {
    const response = await new UserRepo().getUserSecurity(id);
    return response;
  }

  async removeUserSecurity(id: number, role: string): Promise<boolean | null> {
    const response = await new UserRepo().removeUserSecurity(id, role);
    return response;
  }

  async addUserSecurity(id: number, role: string): Promise<boolean | null> {
    const response = await new UserRepo().addUserSecurity(id, role);
    return response;
  }

}

export default UserService;
