import ImageService from "../../services/ImageService";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { NoteModel } from "../../data/entities/NoteModel";
import { NoteImageModel } from "../../data/entities";
import { SpinnerDefault } from "../../components";

export function InspectionImageThumbnail(props: {
  image: NoteImageModel;
  note: NoteModel;
  onClick?: MouseEventHandler<HTMLImageElement> | undefined;
}) {
  const { image, note } = props;
  const [src, setSrc] = useState<string>();

  const fetchThumbnail = useCallback(async () => {
    const src = await new ImageService().getImageThumbnailBase64(note.inspectionId!, image.id!, note.id!, image.imageType!);
    setSrc(src ?? "");
  }, [image.id, image.imageType, note.inspectionId, note.id])

  useEffect(() => {
    fetchThumbnail();
  }, [fetchThumbnail]);

  return (
    <div className="card ms-2">
      {!src ? <div className="card px-5 py-0" style={{ opacity: "0.2" }}><SpinnerDefault /></div> :
        <img
          className="image-hover"
          src={src!}
          alt={image.name ?? "Inspection image"}
          onClick={props.onClick}
        />
      }
    </div>
  );
}

export default InspectionImageThumbnail;
