import ImageService from "../../services/ImageService";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NoteModel } from "../../data/entities/NoteModel";
import Measurement from "../../components/inspections/Measurement";
import { MeasurementUnit } from "../../data/entities/MeasurementUnit";
import { ScrollView } from "@progress/kendo-react-scrollview";
import { NoteImageModel } from "../../data/entities";

interface InspectionImageItem {
  data: NoteImageModel,
  src: string
}

export function InspectionImagesModal(props: {
  imageId: number;
  images: Array<NoteImageModel>;
  note: NoteModel;
  onHide?: any;
  show: boolean;
}) {
  const getActiveIndex = useCallback((list: Array<NoteImageModel>, activeId: number) => {
    const foundIndex = list.findIndex(x => x.id === activeId);
    return foundIndex + 1;
  }, []);

  const { imageId, images, note } = props;
  const [items, setItems] = useState<InspectionImageItem[]>([]);
  const [activeImageIndex, setActiveImageIndex] = useState(getActiveIndex(props.images, props.imageId));

  const fetchImages = useCallback(async () => {
    const imageService = new ImageService();
    const results = await Promise.all(
      images.map(async (image) => {
        try {
          const src = await imageService.getImageLargeBase64(note.inspectionId!, image.id!, note.id!, image.imageType!);
          return {
            data: image,
            src: src
          } as InspectionImageItem;
        } catch (e) {
          console.error('Failed to load image', e);
          return null;
        }
      })
    );

    const nonNullResults = results.filter(x => x !== null && x.src) as InspectionImageItem[];
    setItems(nonNullResults);
  }, [images, note]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  useEffect(() => {
    if (props.show) {
      const newIndex = getActiveIndex(items.map(x => x.data), imageId);
      setActiveImageIndex(newIndex);
    }
  }, [getActiveIndex, items, imageId, props.show]);

  useEffect(() => {
    if (!props.show) {
      // Zero out the active index to destroy the
      // modal and ScrollView when closed.
      setActiveImageIndex(0);
    }
  }, [props.show]);

  /* The ScrollView needs to have the activeImageIndex
     set on initial load, it does not re-render when 
     the value changes properly (it is always lagging in value).
     So by destroying the modal and recreating it, it
     will have the active index on load */
  if (activeImageIndex <= 0) return <></>;

  return (
    <Modal
      size="xl"
      aria-labelledby={`modal-${imageId}`}
      centered
      show={props.show}
      className="note-image-modal"
    >
      <Modal.Header closeButton onHide={props.onHide}>
        <Modal.Title id={`modal-${imageId}`}>
          <Measurement
            value={props.note.startPosition}
            unit={MeasurementUnit.distanceLarge}
          ></Measurement>
          <span> - </span>
          <Measurement
            value={props.note.endPosition!}
            unit={MeasurementUnit.distanceLarge}
          ></Measurement>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ScrollView
          automaticViewChange={false}
          style={{
            height: 'calc(95vh - 5rem)',
            textAlign: 'center',
          }}
          className="note-image-scrollview"
          pagerOverlay="dark"
          activeView={activeImageIndex}
        >
          {items.map((item, index) => {
            return (
              <div className="note-image-full" key={index + 1}>
                <img
                  src={item?.src ?? ""}
                  alt="Inspection"
                />
              </div>
            )
          })}
        </ScrollView>
      </Modal.Body>
    </Modal>
  );
}

export default InspectionImagesModal;
