import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { TubingModel } from "../../data/entities";
import { TubingService, UrlService } from "../../services";
import { PageBreadcrumbProps, PageContent } from "../PageContent";
import { routes } from "../../app/routes";
import { TubingSidebar, TubingDetailsSubmenu } from "./";

export function TubingDetailsPage() {
  // Get the id from the url
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  // Instantiate page data
  const [pageData, setPageData] = useState<TubingModel | undefined | null>();
  const title: string | null | undefined = pageData?.name;
  const breadcrumbs: PageBreadcrumbProps = {
    showHome: true,
    values: [{ label: "Tubings", url: routes.tubings }, { label: title ?? "" }],
  };

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    // Instantiate services
    const tubingService = new TubingService();
    const fetchData = async () => {
      try {
        const loadedData = await tubingService.getTubingDetails(id!);
        setPageData(loadedData);
      } catch (e) {
        console.error("Error loading tubing details", e);
      }
    };
    fetchData();
  }, [id]);

  return (
    <PageContent
      title={title ?? ""}
      breadcrumbs={breadcrumbs}
      submenu={<TubingDetailsSubmenu />}
      sidemenu={<TubingSidebar />}
    >
      <Outlet />
    </PageContent>
  );
}

export default TubingDetailsPage;

