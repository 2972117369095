import { useEffect, useState } from 'react';
import CalibrationImageModel from '../../../data/entities/CalibrationImageModel';
import { ImageService } from '../../../services';
import { LinkLooksLike } from '../../../components';
import { useSharedState } from '../../../app/sharedProperty';
import EditCaptionModal from './components/EditCaptionModal';

interface InspectionCalibrationImageProps {
  onChange: () => void;
  image: CalibrationImageModel;
}

export function InspectionCalibrationImage(props: InspectionCalibrationImageProps) {
  const { image, onChange } = props;

  return (
    <ImageCard
      onChange={onChange}
      image={image}
    />
  );
}

interface ImageCardProps {
  onChange: () => void;
  image: CalibrationImageModel;
}

export function ImageCard(props: ImageCardProps) {
  const { image, onChange } = props;
  const { caption, inspectionId, id, imageType } = image;
  const [imageElement, setImageElement] = useState<JSX.Element | null>(null);
  const [imageCaption, setImageCaption] = useState<JSX.Element | null>(null);
  const showEditCaptionState = useSharedState(false);

  useEffect(() => {
    const imageService = new ImageService();
    imageService
      .getCalibrationImageThumbnail(inspectionId, id, imageType!)
      .then((data) => {
        setImageElement(
          <img src={data!} alt="" />
        );
      });

    setImageCaption(
      caption ? <div className="mx-2 my-2">{caption}</div> : null
    );
  }, [inspectionId, id, imageType, caption]);

  const handleDeleteImage = async () => {
    const confirm = window.confirm("Are you sure you want to delete this image?");
    if (!confirm) return;

    const imageService = new ImageService();
    await imageService.deleteCalibrationImage(id);
    onChange();
  }

  return (
    <div className="card ms-2">
      {imageElement}
      <div className="image-card-actions">
        <ul className="nav small border-bottom">
          <li className="nav-item">
            <LinkLooksLike className="nav-link pl-2 pr-0" onClick={() => showEditCaptionState.setter(true)}>
              <span className="fa fa-edit"></span> Edit
            </LinkLooksLike>
            <EditCaptionModal
              onConfirm={onChange}
              modalState={showEditCaptionState}
              title="Edit Caption"
              caption={caption ?? ""}
              imageId={id}
            />
          </li>
          <li className="nav-item">
            <LinkLooksLike className="nav-link pl-2 pr-0" onClick={handleDeleteImage}>
              <span className="fa fa-times-circle"></span> Delete
            </LinkLooksLike>
          </li>
        </ul>
      </div>
      {imageCaption}
    </div>
  );
}

export default InspectionCalibrationImage;
