import { router, routes } from "../../../../app/routes";
import SubmenuSideBarNavItemModel from "../../../../models/SubmenuSideBarNavItemModel";
import { SubmenuSideBar } from "../../../../components";

interface InspectionDetailsSideBarProps {
  inspectionId: number;
}

export function InspectionDetailsSideBarItems(
  inspectionId: number
): Array<SubmenuSideBarNavItemModel> {
  const params = new Map<string, string>([["id", inspectionId.toString()]]);

  const menu: SubmenuSideBarNavItemModel[] = [
    {
      text: "Details",
      url: router.getUrl(routes.inspectionsDetailsBase, params),
      activeMatchUrl: [
        routes.inspectionsDetailsBase,
        routes.inspectionsDetails,
      ],
    },
    {
      text: "Notes",
      url: router.getUrl(routes.inspectionsNote, params),
    },
    {
      text: "Inspectors",
      url: router.getUrl(routes.inspectionsInspectors, params),
    },
    {
      text: "Scale",
      url: router.getUrl(routes.inspectionsScale, params),
    },
    {
      text: "Calibration",
      url: router.getUrl(routes.inspectionsCalibration, params)
    },
    {
      text: "Access",
      url: router.getUrl(routes.inspectionsAccess, params),
    },
    {
      text: "Chart",
      url: "#",
      clickHandler: () => {
        window.open(router.getUrl(routes.myInspectionsDetails, params), "_blank");
      }

    },
  ];

  return menu;
}

export function InspectionDetailsSideBar(props: InspectionDetailsSideBarProps) {
  const menuItems = InspectionDetailsSideBarItems(props.inspectionId);

  return <SubmenuSideBar menuItems={menuItems} />;
}

export default InspectionDetailsSideBar;

