export enum Severity {
  Comment = "Comment",
  Caution = "Caution",
  Urgent = "Urgent",
  PreRepair = "PreRepair",
  PostRepair = "PostRepair",
  CompromisedOD = "CompromisedOD",
  // Repaired was removed by client request.
}

export function getSeverityDescriptor(severity: Severity | string): string {

  let tester = severity.toUpperCase();

  switch (tester) {

    case Severity.Caution.toUpperCase():
      return "Caution";
    case Severity.Urgent.toUpperCase():
      return "Urgent";
    case Severity.PreRepair.toUpperCase():
      return "Pre-Repair";
    case Severity.PostRepair.toUpperCase():
      return "Post-Repair";
    case Severity.CompromisedOD.toUpperCase():
      return "Compromised OD";
    case "REPAIRED":
      return "Repaired"; // Kept for backwards compatibility
    case Severity.Comment.toUpperCase():
    default:
      return "Comment";
  }
}

export default Severity;
