import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { TubingModel } from "../../data/entities";
import { routes, router } from "../../app/routes";
import {
  Card,
  CardBody,
  CardTitle,
  HandleDropDownListChange,
  HandleDropDownListBlur,
} from "../../components";
import { TubingService } from "../../services";
import { showSuccessMessage } from "../../app/tools";
import SearchableCompanyDropdown from "../../components/dropdown/SearchableCompanyDropdown";
import { Formik } from "formik";
import * as yup from "yup";

export interface TubingFormModel {
  name: string;
  grade: string;
  length: number;
  companyId: number;
  size: number;
  sizeDescription: string;
  wallThickness: number;
  wallThicknessDescription: string;
}

interface TubingFormProps {
  title: string;
  tubingId?: number;
  action: "insert" | "update";
  data?: TubingModel | null;
  dropdownSelectedOption?: number;
  formSubmitLabel: "Add" | "Save";
}

export function TubingForm(props: TubingFormProps) {
  const tubingService = new TubingService();
  const schema = yup.object().shape({
    name: yup.string().required("Name is required."),
    grade: yup.string().required("Grade is required."),
    length: yup.number().required("Length is required."),
    companyId: yup.number().required("Company is required."),
    size: yup.number().required("Size is required."),
    sizeDescription: yup.string().notRequired(),
    wallThickness: yup.number().required("Wall thickness is required."),
    wallThicknessDescription: yup.string().notRequired(),
  })
  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">{props.title}</h6>
      </CardTitle>
      <CardBody>
        <Formik
          validationSchema={schema}
          initialValues={
            {
              name: props.data?.name ?? "",
              grade: props.data?.grade ?? "",
              length: props.data?.length ?? 0,
              companyId: props.dropdownSelectedOption,
              size: props.data?.size ?? 0,
              sizeDescription: props.data?.sizeDescription ?? "",
              wallThickness: props.data?.wallThickness ?? 0,
              wallThicknessDescription: props.data?.wallThicknessDescription ?? "",
            }
          }
          onSubmit={async (values, { setSubmitting }) => {
            const newData = {
              name: values.name,
              grade: values.grade,
              companyId: values.companyId,
              length: values.length,
              size: values.size,
              sizeDescription: values.sizeDescription,
              wallThickness: values.wallThickness,
              wallThicknessDescription: values.wallThicknessDescription,
            } as TubingModel;

            if (props.action === "insert") {
              try {
                const isAdded = await tubingService.addTubing(newData);
                if (isAdded) {
                  showSuccessMessage(`Tubing ${values.name} added`);
                  router.navigateWithParams(
                    routes.tubingDetails,
                    new Map([["id", isAdded.toString()]])
                  );
                }
              } catch (e) {
                console.error("Error updating tubing details", e);
              }
            } else {
                try {
                  const isUpdated = await tubingService.updateTubingDetails(
                    props.tubingId!.toString(),
                    {
                      ...props.data,
                      name: values.name,
                      grade: values.grade,
                      companyId: values.companyId,
                      length: values.length,
                      size: values.size,
                      sizeDescription: values.sizeDescription,
                      wallThickness: values.wallThickness,
                      wallThicknessDescription: values.wallThicknessDescription
                    } as TubingModel
                  );
                  if (isUpdated) {
                    showSuccessMessage(`Tubing details updated for ${values.name}`);
                  }
                } catch (e) {
                  console.error("Error updating tubing details", e);
                }
                router.navigateWithParams(
                  routes.tubingDetails,
                  new Map([["id", props.tubingId!.toString()]])
                );
            }
            setSubmitting(false);
          
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
            return (
              <Form
                noValidate
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    <Form.Group controlId="companyId">
                      <SearchableCompanyDropdown
                        name="companyId"
                        value={values.companyId}
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        onBlur={(e) => HandleDropDownListBlur(e, setFieldValue)}
                        isInvalid={touched.companyId && !!errors.companyId}                          
                      >
                        <Form.Control.Feedback type="invalid">
                          {errors.companyId}
                        </Form.Control.Feedback>
                      </SearchableCompanyDropdown>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="name">
                      <FloatingLabel label="Name" className="mb-3">
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="grade">
                      <FloatingLabel label="Grade" className="mb-3">
                        <Form.Control
                          type="text"
                          name="grade"
                          value={values.grade}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.grade && !!errors.grade}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.grade}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="length">
                      <FloatingLabel label="Length" className="mb-3">
                        <Form.Control
                          type="number"
                          name="length"
                          value={values.length}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.length && !!errors.length}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.length}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="size">
                      <FloatingLabel label="Size (mm)" className="mb-3">
                        <Form.Control
                          type="number"
                          name="size"
                          value={values.size}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.size && !!errors.size}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.size}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="sizeDescription">
                      <FloatingLabel label="Size (Inches)" className="mb-3">
                        <Form.Control
                          type="text"
                          name="sizeDescription"
                          value={values.sizeDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.sizeDescription && !!errors.sizeDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sizeDescription}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="wallThickness">
                      <FloatingLabel label="Wall Thickness (mm)" className="mb-3">
                        <Form.Control
                          type="number"
                          name="wallThickness"
                          value={values.wallThickness}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.wallThickness && !!errors.wallThickness}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.wallThickness}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="wallThicknessDescription">
                      <FloatingLabel label="Wall Thickness (Inches)" className="mb-3">
                        <Form.Control
                          type="text"
                          name="wallThicknessDescription"
                          value={values.wallThicknessDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.wallThicknessDescription && !!errors.wallThicknessDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.wallThicknessDescription}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Button type="submit" className="btn btn-primary btn-lg w-100">{props.formSubmitLabel}</Button>
              </Form>
            )
          }}
        </Formik>
      </CardBody>
    </Card>
  );
}

export default TubingForm;

