import { useOutletContext, useParams } from "react-router-dom";
import { BlockButton, SimpleCard } from "../../../components";
import { useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";
import { ProcessPositionsModel } from "../../../data/entities";

export function InspectionsProcessReversePositions() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Reverse Positions");

  const params = useParams();
  const id = params["id"] ?? "";

  const [reversePositionsModel, setReversePositionsModel] = useState<ProcessPositionsModel | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadData = async (id: number) => {
    await new ProcessService()
    .getProcessReversePositions(id)
    .then((data) => {
      setReversePositionsModel(data as ProcessPositionsModel);
    })
    .catch((error) => {
      console.error("Failed to fetch process reverse positions: ", error);
    });
  }

  useEffect(() => {
    loadData(parseInt(id));
  }, [id])

  async function reversePositionsButtonClick() {
    setShowSpinner(true);
    const ok = await new ProcessService().reversePositions(parseInt(id));
    setShowSpinner(false);

    if(!ok) return;
    
    showSuccessMessage("Positions reversed successfully.");
    // Reload the reset history data.
    loadData(parseInt(id));
  }

  return (
    <>
    {/* Reverse Points */}
    <SimpleCard className="mb-3 p-0" title="Reverse all data point values.">
      <div className="row">
        <div className="col-12">
          <p>This will reverse positions of all data points in this process. </p>
        </div>
      <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
        <BlockButton
          label="Reverse Positions"
          onClick={reversePositionsButtonClick}
          disabled={false}
          showSpinner={showSpinner}
        ></BlockButton>
      </div>
    </div>
    </SimpleCard>

    {/* Reverse History */}
    <SimpleCard className="mb-3 p-0" title="Data reset history.">
    <table className="table hover-row mt-3">
        <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
          {reversePositionsModel?.processes.map((processDataHistory) => (
            <tr className="clickable" key={getUuid()}>
              <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
              <td>{processDataHistory.description}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </SimpleCard>
    </>
    
  );
}

export default InspectionsProcessReversePositions;

