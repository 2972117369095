import { Params } from "react-router-dom";

export class UrlService {
  static getQueryParams<T extends string | string[]>(param: T): T | null {

    const searchParams = new URLSearchParams(window.location.search);

    if (Array.isArray(param)) {
      let paramValues: string[] = [];
      param.forEach((p) => {
        const paramValue = searchParams.get(p);
        if (paramValue) paramValues.push(paramValue);
      });
      return paramValues as T;
    } else {
      const paramValue = searchParams.get(param);
      return paramValue as T;
    }
  }

  static getParamValue(paramName: string, urlParams: Readonly<Params<string>>): string | undefined {
    const caseInsensitiveParamName = Object.keys(urlParams).find(
      (key) => key.toLowerCase() === paramName.toLowerCase()
    );
    return caseInsensitiveParamName ? urlParams[caseInsensitiveParamName] : undefined;
  } 

  static isUrlActive(searchString: string | string[], activeMatchExact?: boolean): boolean {
    let match = false;
    let url = window.location.pathname.toLowerCase();
    if (url.endsWith("/")) url = url.slice(0, -1);

    if (Array.isArray(searchString)) {
      match = searchString
        .map((s) => s.toLowerCase())
        .some((r) => {
          const pattern = new RegExp("^" + r.replace(":id", "(\\d+)")
            + ((activeMatchExact || activeMatchExact === undefined) ? "$" : ""));
          return pattern.test(url);
        });
    } else {
      const pattern = new RegExp("^" + searchString.toLocaleLowerCase().replace(":id", "(\\d+)")
        + ((activeMatchExact || activeMatchExact === undefined) ? "$" : ""));
      match = pattern.test(url);
    }
    return match;
  }
}

export default UrlService;
