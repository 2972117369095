import React, { PropsWithChildren } from "react";
import { Button, Spinner } from "react-bootstrap";

interface ButtonWithSpinnerProps extends PropsWithChildren {
  disabled?: boolean;
  showSpinner?: boolean;
  className?: string;
  variant?: string;
  size?: "lg" | "sm";
  type?: "submit" | "reset" | "button";
}

export const ButtonWithSpinner = React.forwardRef(
  (props: Readonly<ButtonWithSpinnerProps>, ref): JSX.Element => {
    const content = props.showSpinner ? (
      <Spinner
        animation="border"
        variant="light"
        as="span"
        aria-hidden="true"
      />
    ) : (
      props.children
    );

    return (
      <Button
        type={props.type}
        variant={props.variant}
        size={props.size}
        className={props.className}
        disabled={props.disabled}
      >
        {content}
      </Button>
    );
  }
);

ButtonWithSpinner.defaultProps = {
  showSpinner: false,
  disabled: false,
  variant: "primary",
  size: "lg",
  type: "submit",
};

export default ButtonWithSpinner;
