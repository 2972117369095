import React from "react";
import { router } from "../../app/routes";

export interface CardTitleBasicProps {
  title?: string;
  textRight?: {
    text: string;
    redirectUrl?: string;
  };
}

export function CardTitleBasic(props: CardTitleBasicProps) {
  const textRight = props.textRight ? (
    <div className="col-12 col-md-6 text-end">
      <span
        className={`pe-auto fw-bold text-light ${props.textRight.redirectUrl ? "clickable" : ""}`}
        onClick={
          props.textRight.redirectUrl
            ? () => {
                router.navigate(props.textRight!.redirectUrl!);
              }
            : undefined
        }
      >
        {props.textRight.text}
      </span>
    </div>
  ) : (
    <></>
  );

  return (
    <div className="card-title bg-secondary-dark p-3 m-0">
      <div className="row">
        <div className="col-12 col-md-6 text-left">
          <h6 className="text-light m-0">{props.title}</h6>
        </div>
        {textRight}
      </div>
    </div>
  );
}

export interface CartTitleProps extends React.PropsWithChildren {}
export function CardTitle(props: CartTitleProps) {
  const children = React.Children.toArray(props.children) as React.ReactElement[];
  const left = <div className="col-12 col-md-6 text-start">{children[0]}</div>;
  const right = children.length > 1 ? <div className="col-12 col-md-6 text-end">{children[1]}</div> : <></>;
  return (
    <div className="card-title bg-secondary-dark p-3  m-0">
      <div className="row">
        {left}
        {right}
      </div>
    </div>
  );
}

export default CardTitle;
