import { InviteRepo } from "../data/repo/InviteRepo";
import InvitationsModel from "../data/entities/InvitationsModel";
import InviteInsertModel from "../data/entities/InviteInsertModel";

export class InviteService {

  async getInvites(): Promise<InvitationsModel> {
    const response = await new InviteRepo().getInvites();
    return response;
  }

  async deleteInvite(id: number): Promise<boolean> {
    console.log("deleteInvite service", id);
    const response = await new InviteRepo().deleteInvite(id);
    return response;
  }

  async insertInvite(invite: InviteInsertModel): Promise<boolean | undefined> {
    const response = await new InviteRepo().insertInvite(invite);
    return response;
  }

}

export default InviteService;
