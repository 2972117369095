import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import { SimpleCard } from "../../components/card/Card";
import UserService from "../../services/UserService";
import UrlService from "../../services/UrlService";
import { showSuccessMessage } from "../../app/tools";
import { router, routes } from "../../app/routes";

export function ChangePasswordPage() {

  const schema = yup.object().shape({
    currentPassword: yup.string().required("Current password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmNewPassword: yup.string()
      .oneOf([yup.ref('newPassword'), undefined], "Passwords must match")
      .required("Please confirm your new password"),
  });

  return (
    <SimpleCard title="Pick a new password.">
      <Formik
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          async function updatePassword() {
            const ok = await new UserService().changePassword(
              values.currentPassword,
              values.newPassword,
              values.confirmNewPassword
            );

            if (ok) {
              showSuccessMessage("Your password was successfully updated.");
              const returnUrl = UrlService.getQueryParams("returnUrl");
              const destination = returnUrl ?? routes.resetPasswordConfirmation;
              router.navigate(destination);
            }

            setSubmitting(false);
          }

          updatePassword();
        }}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {/* Current Password Field */}
            <Row>
              <Form.Group as={Col} controlId="currentPassword">
                <FloatingLabel label="Current Password" className="mb-3">
                  <Form.Control
                    type="password"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.currentPassword && !!errors.currentPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.currentPassword}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            {/* New Password Field */}
            <Row>
              <Form.Group as={Col} controlId="newPassword">
                <FloatingLabel label="New Password" className="mb-3">
                  <Form.Control 
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && !!errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            {/* Confirm New Password Field */}
            <Row>
              <Form.Group as={Col} controlId="confirmNewPassword">
                <FloatingLabel label="Confirm New Password" className="mb-3">
                  <Form.Control
                    type="password"
                    name="confirmNewPassword"
                    value={values.confirmNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.confirmNewPassword && !!errors.confirmNewPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmNewPassword}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            {/* Save Button */}
            <Button type="submit" disabled={isSubmitting}>Save</Button>
          </Form>
        )}
      </Formik>
    </SimpleCard>
  );
}

export default ChangePasswordPage;
