import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridRowProps,
  GridToolbar,
} from "@progress/kendo-react-grid";
import React, {
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { Icon, IconAndText, IconDefinitions } from "../icons";
import { LinkLooksLike } from "../link";
import { v4 as uuidv4 } from "uuid";

interface ODataEditableGridProps extends PropsWithChildren {
  toolbarAdditionalContent?: React.ReactNode[];
  editDataKey: string;
  data: any[];
  onAddClick?: () => boolean;
  onEditStart?: () => void;
  onEditEnd?: () => void;
  onItemChange?: (event: any[]) => void;
  showDeleteButton?: boolean;
  getNewItem: () => any;
}

function EditableGrid(props: ODataEditableGridProps) {
  const [editID, setEditID] = useState<number | null>(null);
  const [data, setData] = useState<any[]>(props.data);

  const addClickHandler = () => {
    if (props.onAddClick?.() ?? true) {
      const newItem = props.getNewItem();
      setData((current) => [
        ...current,
        {
          ...newItem,
          [props.editDataKey]: uuidv4(),
          inEdit: true,
        },
      ]);
    }
  };

  const editStartHandler = () => {
    props.onEditStart?.();
  };

  const rowClickHandler = (event: GridRowClickEvent) => {
    setEditID(event.dataItem[props.editDataKey]);
  };

  const deleteClickHandler = (e: GridCellProps) => {
    const filteredData = data.filter((item) => {
      if (item[props.editDataKey] !== e.dataItem[props.editDataKey])
        return item;
      return null;
    });
    setData(filteredData);
    props.onItemChange?.(filteredData);
  };

  const itemChangeHandler = (e: GridItemChangeEvent) => {
    const identifier = e.dataItem[props.editDataKey];
    const field = e.field as string;
    const value = e.value;
    const newData = data.map((item) => {
      if (item[props.editDataKey] === identifier)
        return { ...item, [field]: value };

      return item;
    });

    setData(newData);
    props.onItemChange?.(newData);
  };

  const commandCell = (e: GridCellProps) => (
    <td className="d-flex justify-content-center">
      <LinkLooksLike onClick={() => deleteClickHandler(e)}>
        <Icon iconName={IconDefinitions.delete} />
      </LinkLooksLike>
    </td>
  );

  const editableData = data.map((item) => {
    return item[props.editDataKey] === editID
      ? { ...item, inEdit: true }
      : { ...item, inEdit: false };
  });

  const inputFinder = (
    element: React.ReactElement<any> | null
  ): ReactElement | null => {
    if (!element) return null;

    if (element.type === "input") return element;

    if (element.props?.children ?? false) {
      if (Array.isArray(element.props.children)) {
        // Handle arrays of children
        for (const child of element.props.children) {
          const found = inputFinder(child);
          if (found) {
            return found;
          }
        }
      } else {
        // Handle a single child
        return inputFinder(element.props.children);
      }
    }

    return null;
  };

  const cellRender = (
    tdElement: React.ReactElement<HTMLTableCellElement> | null,
    props: GridCellProps
  ) => {
    const input = inputFinder(tdElement);

    if (input) {
      const ref = React.createRef<HTMLInputElement>();
      const inputClone = React.cloneElement(input, {
        ...input.props,
        ref: ref,
      });
      const newElement = React.cloneElement(tdElement as ReactElement, {
        ...tdElement?.props,
        children: inputClone,
      });

      return newElement;
    }
    return tdElement;
  };

  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement> | null,
    props: GridRowProps
  ) => {
    const newElement = React.cloneElement(trElement as ReactElement, {
      ...trElement?.props,
      onFocus: (e: SyntheticEvent) => {
        editStartHandler();
      },
    });
    return newElement;
  };

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const editEndHandler = () => {
      setEditID(null);
      props.onEditEnd?.();
    };

    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        editEndHandler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, props]);

  return (
    <div ref={divRef}>
      <Grid
        filterable={false}
        sortable={false}
        pageable={false}
        onItemChange={itemChangeHandler}
        data={editableData}
        editField="inEdit"
        cellRender={cellRender}
        rowRender={rowRender}
        onRowClick={rowClickHandler}
      >
        <GridToolbar className="d-flex ">
          <Button variant="secondary" className="" onClick={addClickHandler}>
            <IconAndText iconName="add" text="Add" />
          </Button>
          {props.toolbarAdditionalContent}
        </GridToolbar>
        {props.children}
        {props.showDeleteButton && <GridColumn cell={commandCell} width={50} />}
      </Grid>
    </div>
  );
}

EditableGrid.defaultProps = {
  showDeleteButton: true,
};

export default EditableGrid;
