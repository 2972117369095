import DataPointViewModel from "../entities/DataPointViewModel";
import ODataResponse from "../entities/ODataResponse";
import BaseRepository from "./BaseRepository";

export class DataPointViewRepo extends BaseRepository {
  async getDetails(id: string, oDataQuery: string = ""): Promise<DataPointViewModel> {
    const url = `${this.getBaseUri()}(${id})`;
    const response = await this.client.get(url);
    return this.handleResponse<DataPointViewModel>(response)!;
  }

  async getDataPointViews(inspectionId?: number, oDataQuery: string = ""): Promise<ODataResponse<DataPointViewModel>> {
    const url = `${this.getBaseUri(inspectionId)}?$count=true&${oDataQuery}`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<DataPointViewModel>>(response)!;
  }

  async update(model: DataPointViewModel): Promise<DataPointViewModel> {
    const url = `${this.getBaseUri()}(${model.id})`;
    const response = await this.client.put(url, model);
    return this.handleResponse<DataPointViewModel>(response) || model;
  }

  async delete(id: number): Promise<boolean> {
    const url = `${this.getBaseUri()}(${id})`;
    const response = await this.client.delete(url);
    return response.success;
  }

  async reset(id: number): Promise<DataPointViewModel> {
    const url = `${this.getBaseUri()}(${id})/Default.ResetCurrentValue`;
    const response = await this.client.post(url);
    return this.handleResponse<DataPointViewModel>(response)!;
  }

  private getBaseUri(inspectionId?: number): string {
    if (inspectionId !== undefined && inspectionId != null) {
      return `/OData/Inspections(${inspectionId})/DataPointViews`;
    }
    return '/OData/DataPointViews';
  }
}

export default DataPointViewRepo;
