import { router, routes } from "../../app/routes";
import { isNumber, toTitleCase } from "../../app/tools";
import { CompanyModel } from "../../data/entities";
import { PageBreadcrumbProps } from "../PageContent";

export const GetCompanyBreadcrumbs = (
  location: any,
  company: CompanyModel | undefined | null
): PageBreadcrumbProps => {
  const breadcrumbs: PageBreadcrumbProps = {
    values: [{ label: "Companies", url: routes.company }],
  }

  const currentArea = location.pathname.split("/").pop()!;
  if (isNumber(currentArea) || currentArea === "details") {
    // Just the inspection root
    breadcrumbs.values.push({
      label: company?.name ?? "--",
      url: null,
    });
  } else {
    const id = company?.id ?? 0;

    // Make the inspection id a link
    breadcrumbs.values.push({
      label: company?.name ?? "--",
      url: router.getUrl(
        routes.companyDetails,
        new Map([["id", `${id}`]])
      ),
    });

    // The last page name
    breadcrumbs.values.push({
      label: toTitleCase(currentArea) ?? "--",
      url: null,
    });
  }

  return breadcrumbs;
};

export default GetCompanyBreadcrumbs;
