import { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  SpinnerDefault,
  SearchableCompanyDropdown,
  HandleDropDownListBlur,
  HandleDropDownListChange
} from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import InviteInsertModel from "../../data/entities/InviteInsertModel";
import InviteService from "../../services/InviteService";
import { showErrorMessage, showSuccessMessage } from "../../app/tools";
import { useQuery } from "../../app/routes";

export function UserInsertInviteCard() {
  const [showSpinner, setShowSpinner] = useState(false);
  const initialValues: InviteInsertModel = {
    email: "",
    companyId: useQuery().get("companyId") ?? undefined,
    message: ""
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    companyId: yup.number().required("You must specify a company"),
    message: yup.string().notRequired()
  });

  const onSubmit = async (values: InviteInsertModel) => {
    setShowSpinner(true);
    console.log("Submitting invitation form.", values);
    try {
      const ok = await new InviteService().insertInvite(values);

      ok ?
        showSuccessMessage(`Invitation to ${values?.email ?? '[Unknown]'} sent.`) : 
        showErrorMessage(`Error inserting invite.`);
    } catch (e) {
      console.error("Error creating invitation", e);
    }
    setShowSpinner(false);
  };

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Invite a new user.</h6>
      </CardTitle>
      <CardBody>
        <p>
          Users accounts can only be created with an invitation. To send a new
          invitation to someone, enter their email address below and they will
          receive a link in their inbox for them to register.
        </p>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
            return showSpinner ? <SpinnerDefault /> : (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="email">
                      <FloatingLabel label="Email" className="mb-3">
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="companyId">
                      <SearchableCompanyDropdown
                        name="companyId"
                        value={values.companyId}
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        onBlur={(e) => HandleDropDownListBlur(e, setFieldValue)}
                        isInvalid={touched.companyId && !!errors.companyId}
                      >
                        <Form.Control.Feedback type="invalid">
                          {errors.companyId}
                        </Form.Control.Feedback>
                      </SearchableCompanyDropdown>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="message">
                      <FloatingLabel label="Message" className="mb-3">
                        <TextareaAutosize
                          name="message"
                          className={`form-control ${touched.message && !!errors.message ? 'is-invalid' : ''}`}
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={5} xl={3}>
                    <Button type="submit" className="btn btn-primary">Send Invitation</Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
}

export default UserInsertInviteCard;

