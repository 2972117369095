import React, { useEffect, useState } from "react";
import InspectionListItem from "./InspectionListItem";
import InspectionCard from "../../models/InspectionCard";
import SpinnerDefault from "../spinners/SpinnerDefault";
import "./InspectionList.scss";
import DashboardService from "../../services/DashboardService";
import { getUuid } from "../../app/tools";

export function InspectionList() {
  const [cards, setCards] = useState<InspectionCard[] | undefined | null>(
    undefined
  );

  useEffect(() => {
    // Requests the dashboard data from server
    new DashboardService().getLastInspectionsData().then((dashboardData) => {
      setCards(dashboardData?.cards ?? null);
    });

    return () => {};
  }, []);

  if (cards === undefined) {
    return <SpinnerDefault />;
  } else if (cards === null) {
    return (
      <div id="inspections-list-empty" className="row cards-container">
        <p>No inspections to display.</p>
      </div>
    );
  } else {
    return (
      <div id="inspections-list" className="row cards-container">
        {cards.slice(0, 3).map((card, index) => (
          <InspectionListItem model={cards?.at(index)} key={getUuid()} />
        ))}
      </div>
    );
  }
}

export default InspectionList;
