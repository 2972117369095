import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

interface PasswordWithValidationProps {
  id: string;
  initialValue?: string;
  label?: string;
  required?: boolean;
  validationFunction?: (input: string) => void;
  onChangeText?: (value: string) => void;
  useDefaultValidation?: boolean;
  validationResult?: (input: string, result: boolean) => void;
}

export function PasswordWithValidation({
  initialValue,
  id,
  label,
  required,
  validationFunction,
  onChangeText,
  useDefaultValidation,
  validationResult,
}: PasswordWithValidationProps) {
  const [value, setValue] = useState(initialValue ?? "");
  const [isValid, setIsValid] = useState(true);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const changedValue = event.target.value ?? "";
    setValue(changedValue);
    if (onChangeText) onChangeText(changedValue);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
    if (useDefaultValidation) {
      const valid = value.length >= 6;
      setIsValid(required ? required && valid : true);
      if (validationResult) validationResult(value, valid);
    }
    if (validationFunction) validationFunction(value);
  }

  return (
    <>
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3">
          <Form.Control
            type="password"
            placeholder="*****"
            required={required}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={!isValid}
            autoComplete="true"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">Invalid password.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}

PasswordWithValidation.defaultProps = {
  label: "Password",
  required: true,
  useDefaultValidation: true,
};

export default PasswordWithValidation;
