import { useState } from "react";
import { Button } from "react-bootstrap";
import { MultiLineTextWithValidation, SlideOutModal } from "../../../../components";
import sharedProperty from "../../../../app/sharedProperty";
import { ImageService } from "../../../../services";

interface EditCaptionModalProps {
  modalState: sharedProperty<boolean>;
  caption?: string;
  onConfirm?: () => void;
  title: string;
  imageId: number;
}

export function EditCaptionModal({
  modalState,
  caption,
  onConfirm,
  title,
  imageId,
}: EditCaptionModalProps) {
  const [captionValue, setCaptionValue] = useState(caption);

  const closeClickHandler = () => modalState.setter(false);

  const saveClickHandler = async () => {
    const imageService = new ImageService();
    await imageService.updateCalibrationImage(imageId, captionValue ?? "");
    modalState.setter(false);
    onConfirm?.();
  };

  const commands = (
    <>
      <Button variant="secondary" onClick={closeClickHandler}>
        Close
      </Button>
      <Button variant="primary" onClick={saveClickHandler}>
        Save
      </Button>
    </>
  );

  return (
    <SlideOutModal showState={modalState} title={title} footer={commands}>
      <div className="row">
        <div className="col-12">
          <MultiLineTextWithValidation
            id="caption"
            label="Caption"
            initialValue={caption}
            required={false}
            onChangeText={(value) => setCaptionValue(value)}
          />
        </div>
      </div>
    </SlideOutModal>
  );
}

export default EditCaptionModal;
