import "./SpinnerDefault.scss";

export interface SpinnerDefaultProps {
  show?: boolean
  fullscreen?: boolean
}

export function SpinnerDefault(props: SpinnerDefaultProps) {
  if (props.show != null && props.show === false)
    return <></>;

  return (
    <div className={`spinner w-100 d-flex justify-content-center my-3 ${props.fullscreen ? "fullscreen" : ""}`}>
      <div className="spinner-cti"><span className="spinner-cti-inner"></span></div>
    </div>
  )
}

export default SpinnerDefault;
