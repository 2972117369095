import { useOutletContext, useParams } from "react-router-dom";
import { InspectionsPageContext } from "../InspectionsPage";
import { BlockButton, ODataGrid, SimpleCard, TextWithValidation } from "../../../components";
import { useCallback, useEffect, useRef, useState } from "react";
import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { InspectionProcess } from "../../../data/entities";
import { useSharedState } from "../../../app/sharedProperty";
import AdjustModal from "./components/AdjustModal";
import ProcessRepo from "../../../data/repo/ProcessRepo";
import { GridColumn } from "@progress/kendo-react-grid";
import { InspectionService } from "../../../services";

export function InspectionsProcessAdjust() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Process Adjust");

  const params = useParams();
  const id = params["id"] ?? "";
  const getPoints = (oDataQuery: string) =>
    new InspectionService().getNormalizeDataPoints(Number(id));
  const [processes, setProcesses] = useState<InspectionProcess[] | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const loadedData = await new ProcessRepo().getProcessAdjust(
        Number(id)
      );
      setProcesses(loadedData?.processes!);
    } catch (e) {
      console.error("Error loading data points", e);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
      { field: "startPosition", operator: 'gte', value: 0 },
      { field: "startPosition", operator: 'lte', value: 0 }
    ],
  };

  const gridData = useSharedState<any>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showModalState = useSharedState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [range, setRange] = useState(() => {
    return {
      startPosition: 0,
      endPosition: 0
    }
  });

  return (
    <>

      <SimpleCard className="mb-3 p-0" title="Adjust data points.">
        <div className="row">
          <div className="col-12">
            <p>This data process allows external interference to be adjusted in the inspection results.</p>
            <p>Get started by picking a "Start" and "End" position to adjust. This requires the positions to be calculated already!</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <TextWithValidation
              id="startPosition"
              type="number"
              label="Start Position (m)"
              initialValue={range.startPosition.toString()}
              onChangeText={(value) => {
                setRange({
                  ...range,
                  startPosition: Number(value)
                })
              }}
              validationResult={(value) => {
                setFilter({
                  ...filter,
                  filters: [
                    { field: "startPosition", operator: 'gte', value: Number(value) },
                    { field: "startPosition", operator: 'lte', value: range.endPosition }
                  ]
                })
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <TextWithValidation
              id="endPosition"
              type="number"
              label="End Position (m)"
              initialValue={range.endPosition.toString()}
              onChangeText={(value) => {
                setRange({
                  ...range,
                  endPosition: Number(value)
                })
              }}
              validationResult={(value) => {
                setFilter({
                  logic: 'and',
                  filters: [
                    { field: "startPosition", operator: 'gte', value: range.startPosition },
                    { field: "startPosition", operator: 'lte', value: Number(value) }
                  ],
                })
              }}
            />
          </div>
          <div className="row">
          <div className="col col-12">
            <ODataGrid
              ref={gridRef}
              getData={getPoints}
              data={filterBy(gridData.value, filter)}
              dataState={gridData}
              sort={[{ field: "dataPointIndexByInspection", dir: "asc" }]}
              sortable={true}
              filterable={false}
              pageable={false}
              style={{ height: "350px" }}
            >
              <GridColumn title="Index" field="dataPointIndexByInspection" />
              <GridColumn title="Pos" field="startPosition" />
              <GridColumn title="FLW" field="flw" />
              <GridColumn title="Flaw" field="flaw" />
              <GridColumn title="Wall1" field="wall1" />
              <GridColumn title="Wall2" field="wall2" />
            </ODataGrid>
          </div>
        </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-5 col-xl-4 col-xxl-3">
            <BlockButton
              label="Adjust"
              onClick={() => {
                showModalState.setter(true);
              }}
              disabled={false}
            />
            <AdjustModal
              showState={showModalState}
              onAdjustmentComplete={() => fetchData()}
              size="lg"
              title={`Set adjustment variance`}
              range={range}
              confirmText="Submit" 
              inspectionId={Number(id)}/>

          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">Data Point Count: {filterBy(gridData.value, filter).length}</div>
        </div>
      </SimpleCard>

      <SimpleCard className="mb-3 p-0" title="Adjustment history.">
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {processes?.map((process) => {
                const createdDate = process?.createdDate
                  ? new Date(process.createdDate)
                  : null;
                const formattedDate = createdDate
                  ? `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`
                  : 'N/A';
                return (
                  <tr key={`${process.id}`}>
                    <td style={{width: "180px"}}>
                      <span>{formattedDate}</span>
                    </td>
                    <td>
                      <span>{process.description}</span>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </SimpleCard>

    </>
  );
}

export default InspectionsProcessAdjust;

