import { v4 as uuidv4 } from "uuid";
import { router, routes } from "../app/routes";
import { clearLoggedUser, clearToken, clearTokenData, setIsLoggedIn } from "../app/store/authSlice";
import { setError, setInformation, setSuccess, setWarning, store } from "./store/store";

export function isDate(value: string | Date): boolean {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

export function toCamelCase(str: string): string {

  let result;
  //check if the string contains any lowercase letters
  const match = RegExp(/[a-z]/).exec(str);

  if (match) {
    // When str contains lowercase letters, it can be converted to camelCase
    if (match.index === 0) {
      // it means that the first letter is lowercase. It's already camelCase
      result = str;
    } else if (match.index === 1) {
      // it means that the second letter is lowercase
      // so we need to convert the first letter to lowercase as well
      const firstCharLower = str.charAt(0).toLowerCase();
      result = firstCharLower + str.slice(1);
    } else {
      // it means that the first letters are uppercase
      let index = match.index - 1;
      const firstPart = str.slice(0, index);
      const rest = str.slice(index);
      result = firstPart.toLowerCase() + rest;
    }
  } else {
    // if no lowercase letters, return the string as lowercase
    result = str.toLowerCase();
  }

  return result;
}

export function toYearMonthDayString(date: Date): string {
  return date.toISOString().split('T')[0];
}

export function toMonthYearStringFromISOString(isoDateString: string): string {
  const date = new Date(isoDateString);
  const monthYear = date.toLocaleString("en-US", {
    month: "short",
    year: "2-digit",
  });

  const formattedDate = `${monthYear.split(" ")[0]} '${monthYear.split(" ")[1]}`;
  return formattedDate;
}

export function getInspectionReportUrl(id: number, fileName?: string) {
  return "TODO:GetRealUrl.ca";
}

export function isNumber(value: string): boolean {
  return !isNaN(Number(value));
}

export function toTitleCase(str: string) {
  return str
    .replace('-', ' ')
    .replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
}

export function removeItemFromArray(source: Array<any>, prop: string, value: any): any {
  const index = source.findIndex(x => x[prop] === value);
  if (index < 0) return null;
  return source.splice(index, 1);
}

const defaultChartColors: Array<string> = [
  "#FF7F11",
  "#0D698B",
  "#006B5E",
  "#8B1D00",
  "#0b516b",
  "#D9531E",
  "#63BAAB",
  "#B3876C",
  "#d9531e",
  "#6270AA",
  "#EE982B",
  "#364954",
  "#9AAEBB",
  "#ffab63",
  "#737A86"
];
export { defaultChartColors };

export function propsFilter(propsObject: any, filterList: string[]) {
  const propsArray = Object.entries(propsObject);
  const filteredPropsArray = propsArray.filter(([key, value]) => {
    // Removes all the keys that are in the filterList
    return !filterList.includes(key);
  });
  const newProps = Object.fromEntries(filteredPropsArray);
  return newProps;
}

export function getUuid(): string {
  const uuid = uuidv4();
  return uuid;
}

export function formFieldStateSetter<T, K extends keyof T>(
  propertyKey: K,
  newValue: T[K],
  currentState: T,
  stateSetter: React.Dispatch<React.SetStateAction<T | undefined | null>>,
): T {
  const newState = { ...currentState };
  newState[propertyKey] = newValue;
  stateSetter(newState);
  return newState;
}

export function forceLogout() {
  store.dispatch(clearToken());
  store.dispatch(clearTokenData());
  store.dispatch(clearLoggedUser());
  store.dispatch(setIsLoggedIn(false));
  localStorage.removeItem('userToken');

  router.navigate(routes.login);
}

export function showErrorMessage(message: string) {
  store.dispatch(setError(message));
}

export function showSuccessMessage(message: string) {
  store.dispatch(setSuccess(message));
}

export function showWarningMessage(message: string) {
  store.dispatch(setWarning(message));
}

export function showInfoMessage(message: string) {
  store.dispatch(setInformation(message));
}