import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlockButton, Card, CardBody, CardTitle, EmailWithValidation, MultiLineTextWithValidation, SimpleCard, SpinnerDefault, TextWithValidation } from '../../components'
import { Form } from 'react-bootstrap'
import { UrlService, UserService } from '../../services';
import { UserDetailsModel } from '../../data/entities';
import CompanyPicker from '../../components/form/dropdown/CompanyPicker';
import SignaturePad from 'react-signature-canvas';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

interface UserProfileFormModel{
  email: string | undefined,
  companyId: number | undefined,
  firstName?: string,
  lastName?: string,
  credentials?: string,
  signatureDataUrl?: string | null
}

export function UserProfileCard() {

  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  let pad = useRef<SignaturePad>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState<UserDetailsModel>();
  const [formData, setFormData] = useState<UserProfileFormModel>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [signatureDataUrl, setSignatureDataUrl] = useState<string | undefined>(data?.signatureDataUrl ?? "");


  const formatIntoPng = () => {
    if (pad.current) {
      const dataURL = pad.current.toDataURL();
      return dataURL;
    }
  }

  /**
   * Load the user details.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new UserService().getUserById(id);
        setData(loadedData!);
        
      } catch (e) {
        console.error("Error loading user details", e);
      }
    };
    fetchData();

  }, [id]);

  /**
   * Set the form data when the data is loaded.
   */
  useEffect(() => {
    setFormData({
      email: data?.user.email,
      companyId: data?.user.companyId,
      firstName: data?.user.firstName ?? "",
      lastName: data?.user.lastName ?? "",
      credentials: data?.credentials ?? "",
      signatureDataUrl: data?.signatureDataUrl === "" ? null : data?.signatureDataUrl
    });
  }, [data]);

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (fieldName: string, value: any) => {
    setFormData((current) => ({
      ...current as UserProfileFormModel,
      [fieldName]: value,
    }));
  };

  /**
   * Handle the update user details button click.
   */
  const handleSubmitForm = async () => {
    setShowSpinner(true);
    try {
      const newData : UserDetailsModel = {
        ...data!,
        user: {
          ...data?.user!,
          email: formData?.email!,
          companyId: formData?.companyId!,
          firstName: formData?.firstName!,
          lastName: formData?.lastName!
        },
        credentials: formData?.credentials!,
        signatureDataUrl: formData?.signatureDataUrl!
      }
      const isUpdated = await new UserService().updateUserDetails(id, newData);
      if(isUpdated){
        showSuccessMessage(`User profile for ${data?.userName} updated successfully.`);
      } else {
        showErrorMessage(`Error updating user profile for ${data?.userName}`);
      }
    } catch (e){
      console.error("Error updating user profile", e);
    }
    setShowSpinner(false);
  }

  if (data === undefined) return <SpinnerDefault />
  
  if (data === null) {
    return (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>No Profile data for this User.</em>
        </div>
      </SimpleCard>
    );
  }

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Update user profile details</h6>
      </CardTitle>
      <CardBody>
        <Form>
          <div className="row">
            <div className='col col-12'>
              <TextWithValidation
                id="userName"
                label="Username"
                initialValue={data.userName}
                disabled={true}
                onChangeText={(value: string) => {}}
              />
            </div>
          </div>
          <div className="row">
            <div className='col col-12 col-lg-6'>
              <EmailWithValidation
                required={true}
                id="email"
                label="Email"
                initialValue={data.user.email}
                onChangeText={(value) => handleFieldChange("email", value)}
                validationResult={(input, result) => {
                  setIsEmailValid( input !== "" && result );
                }}
              />
            </div>
            <div className='col col-12 col-lg-6'>
              <CompanyPicker
                id="companyId"
                label="Company"
                initialValue={data.user.companyId?.toString()}
                onSelect={(value) => handleFieldChange("companyId", value)}
              />
            </div>
          </div>
          <div className="row">
            <div className='col col-12 col-lg-6'>
              <TextWithValidation
                id="firstName"
                label="First Name"
                initialValue={data.user.firstName}
                onChangeText={(value) => handleFieldChange("firstName", value)}
              />
            </div>
            <div className='col col-12 col-lg-6'>
              <TextWithValidation
                id="lastName"
                label="Last Name"
                initialValue={data.user.lastName}
                onChangeText={(value) => handleFieldChange("lastName", value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MultiLineTextWithValidation
                id="credentials"
                label="Credentials"
                initialValue={data.credentials}
                onChangeText={(value) => handleFieldChange("credentials", value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div id="signature-existing" style={!data.signatureDataUrl ? {display: "none"} : {}}>
                <img
                  width="500"
                  height="250"
                  id="signature-preview"
                  className="d-block mb-1"
                  style={{ border: "1px solid #c3d4da", backgroundColor: "#e3e3e3" }}
                  src={data.signatureDataUrl}
                  alt="Signature Preview"
                />
                <div className="row mt-2">
                  <div className="col-2">
                    <BlockButton
                      label="Change Signature"
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        document.getElementById("signature-existing")?.setAttribute("style", "display: none");
                        document.getElementById("signature-new")?.setAttribute("style", "");
                        pad.current?.on();
                      }}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
              <div id="signature-new" style={data.signatureDataUrl ? {display: "none"} : {}}>
                <SignaturePad
                  ref={pad as any}
                  onEnd={()=>{
                    setSignatureDataUrl(formatIntoPng());
                  }}
                  penColor="#d9531e"
                  canvasProps={{
                    width: 500,
                    height: 250,
                    style: {border: "1px solid #c3d4da" }
                  }}
                />
                <div className="row mt-2">
                  <div className="col-1">
                    <BlockButton
                      label="Accept"
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        document.getElementById('signature-preview')!.setAttribute('src', formatIntoPng()!);
                        document.getElementById("signature-new")?.setAttribute("style", "display: none");
                        document.getElementById("signature-existing")?.setAttribute("style", "");
                        handleFieldChange("signatureDataUrl", signatureDataUrl)
                        pad.current?.off();
                      }}
                      disabled={false}
                    />
                  </div>
                  <div className="col-1">
                    <BlockButton
                      label="Clear"
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        pad.current?.clear();
                      }}
                      disabled={false}
                    />
                  </div>
                  <div className="col-1">
                    <BlockButton
                      label="Cancel"
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        document.getElementById("signature-new")?.setAttribute("style", "display: none");
                        document.getElementById("signature-existing")?.setAttribute("style", "");
                        pad.current?.off();
                      }}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-1">
              <BlockButton
                label="Save"
                onClick={handleSubmitForm}
                showSpinner={showSpinner}
                disabled={!isEmailValid}
              />
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default UserProfileCard
