import { Outlet } from "react-router-dom";
import { UsersSidebar } from "./";

export function UserDetailsPage() {
  return (
    <div className="row">
      <div className="col-12 col-lg-3">
        <UsersSidebar />
      </div>
      <div className="col-12 col-lg-9 position-relative">
        <Outlet />
      </div>
    </div>
  )
}

export default UserDetailsPage
