import React from "react";
import { NavigationItem } from "./";
import { routes } from "../../app/routes";
import UserService from "../../services/UserService";
import "./SideBar.scss";
import ActionItem from "../actionItem/ActionItem";
import { IconAndText, IconDefinitions } from "../icons";
import { Roles } from "../../models";

interface SideBarProps extends React.PropsWithChildren {
  showToggle: boolean;
  onToggle?: () => void;
}

export function SideBar(props: SideBarProps) {
  const menuToggle = (e: any) => {
    props.onToggle?.call(null);
  };

  return (
    <div className="sidebar ">
      <ul className="nav flex-column">
        {!props.showToggle ? <></> :
          <li 
            className="nav-item sidebar-toggle">
            <span onClick={menuToggle} className="nav-link">
              <IconAndText iconName={IconDefinitions.menu} text="Main Menu"></IconAndText>
            </span>
          </li>
        }
        {props.children ?? <>
          <NavigationItem
            text="Dashboard"
            url={routes.home}
            icon={IconDefinitions.home}
            activeMatchUrl={['']}
            activeMatchExact={true}
          ></NavigationItem>
          <NavigationItem
            text="My Inspections"
            url={routes.myInspections}
            icon={IconDefinitions.search}
            activeMatchUrl={[
              routes.myInspections,
              routes.myInspectionsDetails,
              routes.myInspectionsOvality,
            ]}
          ></NavigationItem>
          <NavigationItem
            text="My Profile"
            url={routes.manage}
            icon={IconDefinitions.user}
            activeMatchUrl={[routes.manage, routes.changePassword]}
          ></NavigationItem>
          <ActionItem
            text="Sign out"
            icon={IconDefinitions.signOut}
            additionalClass="text-nowrap"
            onClick={() => {
              UserService.logout();
            }}
          ></ActionItem>
          </>}
      </ul>
      {props.children ? <></> : <>{UserService.isInRole(Roles.staff) ||
        UserService.isInRole(Roles.admin) ? (
          <ul className="nav flex-column mb-2 mt-auto">
            <NavigationItem
              text="Tubings"
              url={routes.tubings}
              icon={IconDefinitions.tubing}
              activeMatchUrl={[
                routes.tubings,
                routes.tubingsInsert
              ]}
              activeMatchExact={false}
            ></NavigationItem>
            <NavigationItem
              text="Inspections"
              url={routes.inspections}
              icon={IconDefinitions.search}
              activeMatchUrl={[
                  routes.inspections,
                  routes.inspectionsInsert,
                  routes.inspectionsDetails,
                ]}
              activeMatchExact={false}
            ></NavigationItem>
          </ul>
      ) : null}
        {UserService.isInRole(Roles.admin) ? (
          <ul className="nav flex-column mb-2 bg-light border-top">
            <NavigationItem
              text="Users"
              url={routes.user}
              icon={IconDefinitions.users}
              activeMatchUrl={[
                routes.user, 
                routes.userDetails, 
                routes.userSecurity, 
                routes.userDelete, 
                routes.userInvitations, 
                routes.userInsertInvitation
              ]}
            ></NavigationItem>
            <NavigationItem
              text="Companies"
              url={routes.company}
              icon={IconDefinitions.company}
              activeMatchUrl={[routes.company,
                  routes.companyInsert,
                  routes.companyDetails,
                  routes.companyAddress,
                  routes.companyUsers,
                  routes.companyTubings
                ]}
            ></NavigationItem>
            <NavigationItem
              text="Settings"
              url={routes.settings}
              icon={IconDefinitions.settings}
              activeMatchUrl={[
                routes.settings,
                routes.settingsAccount,
                routes.settingsScale
              ]}
            ></NavigationItem>
            <NavigationItem
              text="Activity logs"
              url={routes.log}
              icon={IconDefinitions.logs}
              activeMatchUrl={routes.log}
            ></NavigationItem>
            <NavigationItem
              text="Maintenance"
              url={routes.maintenance}
              icon={IconDefinitions.maintenance}
              activeMatchUrl={routes.maintenance}
            ></NavigationItem>
          </ul>
        ) : null}
      </>}
    </div>
  );
}

export default SideBar;

