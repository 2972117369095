import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddressModel, ProvinceModel } from "../../data/entities";
import AddressService from "../../services/AddressService";
import CompanyService from "../../services/CompanyService";
import UrlService from "../../services/UrlService";
import { SpinnerDefault } from "../../components";
import TextWithValidation from "../../components/form/input/TextWithValidation";
import { SimpleCard } from "../../components/card/Card";
import {
  Dropdown,
  Option,
  mapCountryToEnum,
  handlePostalCodeTypeChanges,
} from "../../components/form/dropdown/Dropdown";
import PostalCodeWithValidation from "../../components/form/input/PostalCodeWithValidation";
import { formFieldStateSetter, showSuccessMessage } from "../../app/tools";
import { usePageActions } from "../PageContent";
import FormWithValidation from "../../components/form/FormWithValidation";
import { Button } from "react-bootstrap";

export function CompanyAddressCard() {
  usePageActions(<></>);

  const urlParams = useParams();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  // This AddressModel is initially populated with the fetched company address data, and is updated with changes to the form.
  const [address, setAddress] = useState<AddressModel | undefined | null>(null);

  // This is needed by the backend to update the address.
  const [companyName, setCompanyName] = useState("");

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // This ProvinceModel list contains ALL regions.
  const [allRegions, setAllRegions] = useState<ProvinceModel[] | null>(null);

  // Used to toggle region on change.
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);

  const [countryDropdown, setCountryList] = useState<Option[] | null>(null);
  const [regionDropdown, setRegionList] = useState<Option[] | null>(null);

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const saved = await new CompanyService().updateAddress(
      companyId,
      companyName,
      address!
    );

    if (!saved) return;

    showSuccessMessage("Company address successfully updated.");
    // Reload the address data.
    loadAddressData(companyId);
  };

  const valueChange = (field: keyof AddressModel, value: any) => {
    const addressObject = address ?? ({} as AddressModel);

    formFieldStateSetter(field, value, addressObject, setAddress);
  };

  const loadAddressData = async (id: number) => {
    new CompanyService()
    .getAddress(id)
    .then((data) => {
      setAddress(data);
      setSelectedCountry(mapCountryToEnum(data?.province?.country ?? ""));
      setIsDataLoaded(true);
    })
    .catch((error) => {
      console.error("Error fetching company address data: ", error);
    });
  }

  useEffect(() => {
    // Get all Regions
    new AddressService()
      .getAllProvinces()
      .then((data) => {
        setAllRegions(data);
      })
      .catch((error) => {
        console.error("Error fetching region data: ", error);
      });

    // Get company name
    new CompanyService()
      .getDetails(companyId)
      .then((data) => {
        setCompanyName(data?.name ?? "");
      })
      .catch((error) => {
        console.error("Error fetching company details: ", error);
      });

    // Get company address
    loadAddressData(companyId);

  }, [companyId]);

  useEffect(() => {
    // Populate Country dropdown list.
    let countryList: Option[] = [];
    let countryCount = 0;
    allRegions?.forEach((province) => {
      if (
        !countryList.some((option) => option.text.includes(province.country))
      ) {
        countryList.push({
          value: countryCount.toString(),
          text: province.country,
        });
        countryCount++;
      }
    });
    setCountryList(countryList);
  }, [allRegions]);

  useEffect(() => {
    // Populate Region dropdown list.
    let regionList: Option[] = [];
    allRegions?.forEach((province) => {
      if (mapCountryToEnum(province.country) === selectedCountry) {
        regionList.push({
          value: province.id?.toString()!,
          text: province.name,
        });
      }
    });
    setRegionList(regionList);
  }, [selectedCountry, allRegions]);

  if (!isDataLoaded) return <SpinnerDefault />;

  return (
    <SimpleCard title="Update this company's address.">
      <FormWithValidation onSubmit={formSubmit}>
        <TextWithValidation
          label="Street Address"
          initialValue={address?.streetAddress ?? ""}
          required={true}
          id="streetAddress"
          validationFunction={(input) => {
            const regex = /^[a-z0-9 ,.'#-]*$/i;
            const valid = (regex.test(input) && input !== "");
            return valid;
          }}
          onChangeText={(input) => {
            valueChange("streetAddress", input);
          }}
          type="streetAddress"
        ></TextWithValidation>
        <TextWithValidation
          label="Apt / Unit"
          initialValue={address?.streetAddress2 ?? ""}
          id="streetAddress2"
          onChangeText={(input) => {
            valueChange("streetAddress2", input);
          }}
          type="streetAddress2"
        ></TextWithValidation>
        <TextWithValidation
          label="Town / Locality"
          initialValue={address?.city ?? ""}
          required={true}
          id="city"
          validationResult={(input) => {
            valueChange("city", input);
          }}
        ></TextWithValidation>
        <Dropdown
          label="Country"
          selectedOption={selectedCountry ?? ""}
          id="country"
          options={countryDropdown}
          disabled={false}
          onChangeSelected={(input) => {
            setSelectedCountry(parseInt(input.toString()));
          }}
        ></Dropdown>
        <Dropdown
          label="Region"
          selectedOption={address?.provinceId ?? 0}
          id="region"
          options={regionDropdown}
          disabled={false}
          onChangeSelected={(input) => {
            valueChange("provinceId", input);
          }}
        ></Dropdown>
        <PostalCodeWithValidation
          initialValue={address?.postalCode ?? ""}
          validationResult={(input) => {
            valueChange("postalCode", input);
          }}
          postalCodeType={handlePostalCodeTypeChanges(
            selectedCountry,
            countryDropdown
          )}
        ></PostalCodeWithValidation>
        <div className="col-1">
          <Button disabled={false} type="submit">Save</Button>
        </div>
      </FormWithValidation>
    </SimpleCard>
  );
}

export default CompanyAddressCard;

