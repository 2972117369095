import React from "react";
import { Card, CardBody, CardTitle } from "../../../components";
import InspectionSummaryCache from "./components/InspectionSummaryCache";
import InspectionSummaryEditor from "./components/InspectionSummaryEditor";
import InspectionSummaryPublish from "./components/InspectionSummaryPublish";
import { useOutletContext } from "react-router-dom";
import { InspectionsPageContext } from "../InspectionsPage";

interface InspectionSummaryProps {}

export function InspectionSummary(props: InspectionSummaryProps) {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Details");

  return (
    <Card>
      <CardTitle>
        <span className="text-white">Update inspection details.</span>
      </CardTitle>
      <CardBody>
        <InspectionSummaryPublish />
        <InspectionSummaryCache />
        <InspectionSummaryEditor />
      </CardBody>
    </Card>
  );
}

export default InspectionSummary;

