import { useState } from "react";
import { Form } from "react-bootstrap";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import BlockButton from "../../components/form/buttons/BlockButton";
import UserService from "../../services/UserService";
import { router, routes } from "../../app/routes";

export function ForgotPassword() {
  const userService = new UserService();

  const [email, setEmail] = useState("");
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  async function buttonClick() {
    setShowSpinner(true);
    const ok = await userService.forgotPassword(email);
    setShowSpinner(false);
    if (ok) router.navigate(routes.forgotPasswordConfirmation);
  }

  return (
    <>
      <h2>Forgot your password?</h2>
      <section>
        <h4 className="mb-4">Enter your email.</h4>
        <Form>
          <EmailWithValidation
            id="email"
            validationResult={(input, result) => {
              setEmail(input);
              setButtonEnabled(input !== "" && result);
            }}
          ></EmailWithValidation>
          <BlockButton
            label="Submit"
            onClick={buttonClick}
            disabled={!isButtonEnabled}
            showSpinner={showSpinner}
          ></BlockButton>
        </Form>
      </section>
    </>
  );
}


export default ForgotPassword;