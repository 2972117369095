import "../../css/slick-slider.scss";
import "./InspectionImages.scss";
import Slider from "react-slick";
import { NoteModel } from "../../data/entities/NoteModel";
import { NoteImageModel } from "../../data/entities";
import { InspectionImageThumbnail, InspectionImagesModal } from "../../components";
import { useState } from "react";

interface InspectionImagesProps {
  note: NoteModel;
  images: Array<NoteImageModel>;
}

export function InspectionImages(props: InspectionImagesProps) {
  const [activeImageId, setActiveImageId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const onThumbnailClick = (image: NoteImageModel) => {
    setShowModal(true);
    setActiveImageId(image?.id ?? 0);
  };

  return (
    <>
      <Slider
        dots={true}
        infinite={false}
        speed={300}
        variableWidth={true}
        className="noteimages-slider">
        {(props.images ?? []).map((image) => (
          <div key={image.id} className="noteimages-slider-item ms-2">
            <InspectionImageThumbnail
              image={image}
              note={props.note}
              onClick={() => onThumbnailClick(image)}
            />
          </div>
        ))}
      </Slider>
      <InspectionImagesModal
        imageId={activeImageId}
        images={props.images}
        note={props.note}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default InspectionImages;


