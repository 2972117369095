import { useEffect, useState } from "react";
import { router, routes } from "../../app/routes";
import { useParams, useLocation } from "react-router-dom";
import { TubingModel } from "../../data/entities";
import CompanyService from "../../services/CompanyService";
import UrlService from "../../services/UrlService";
import {
  IconAndText,
  IconDefinitions,
  Link,
  SpinnerDefault,
} from "../../components";
import { SimpleCard } from "../../components/card/Card";
import { usePageActions } from "../PageContent";

export function CompanyTubingsCard() {
  const urlParams = useParams();
  const location = useLocation();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  usePageActions(
    <>
      <Link className="btn btn-primary" url={`${routes.tubingsInsert}?returnUrl=${location.pathname}&?companyId=${companyId}`}>
        <IconAndText iconName={IconDefinitions.add} text="Add New Tubing" />
      </Link>
    </>
  );

  const [tubings, setTubings] = useState<TubingModel[] | null>(null);

  useEffect(() => {
    const tubingsList: TubingModel[] = [];

    new CompanyService()
      .getTubings(companyId)
      .then((data) => {
        data.forEach((tubing) => {
          const entry = {
            id: tubing.id,
            createdDate: tubing.createdDate ?? new Date(),
            name: tubing.name ?? "",
            length: tubing.length ?? 0,
          };
          tubingsList.push(entry);
        });
        setTubings(tubingsList);
      })
      .catch((error) => {
        console.error("Error fetching company tubings data:", error);
      });
  }, [companyId]);

  async function tubingDetailsUrl(id: number) {
    router.navigateWithParams(
      routes.tubingDetails,
      new Map([["id", id.toString()]])
    );
  }

  if (tubings === null) return <SpinnerDefault />;

  return (
    <SimpleCard title="View company coiled tubings.">
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Length</th>
          </tr>
        </thead>
        <tbody>
          {tubings?.map((tubing, index) => (
            <tr
              className="clickable"
              key={tubing.id}
              onClick={() => tubingDetailsUrl(tubing.id ?? 0)}
            >
              <td>{tubing.name}</td>
              <td>{tubing.length?.toFixed(2) + "m"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </SimpleCard>
  );
}

export default CompanyTubingsCard;

