import { router, routes } from "../../../../app/routes";
import SubmenuSideBarNavItemModel from "../../../../models/SubmenuSideBarNavItemModel";
import { SubmenuSideBar } from "../../../../components";

interface InspectionDataSideMenuProps {
  inspectionId: number;
}

export function InspectionDataSideMenuItems(
  inspectionId: number
): Array<SubmenuSideBarNavItemModel> {
  const params = new Map<string, string>([["id", inspectionId.toString()]]);

  const menu: SubmenuSideBarNavItemModel[] = [
    {
      text: "Segments",
      url: router.getUrl(routes.inspectionsDataSegment, params),
    },
    {
      text: "Data Points",
      url: router.getUrl(routes.inspectionsDataPoint, params),
    },
    {
      text: "Ovality",
      url: router.getUrl(routes.inspectionsDataOvality, params),
    },
  ];

  return menu;
}

export function InspectionDataSideMenu(props: InspectionDataSideMenuProps) {
  const menuItems = InspectionDataSideMenuItems(props.inspectionId);

  return <SubmenuSideBar menuItems={menuItems} />;
}

export default InspectionDataSideMenu;

