
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SubmenuSideBar from "../../components/sideBar/SubmenuSideBar";
import { UrlService, UserService } from "../../services";
import { ODataResponse, UserDetailsModel } from "../../data/entities";
import { routes, router } from "../../app/routes";

export function UsersSidebar() {
  const urlParams = useParams();
  const userId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  

  const [userName, setUserName] = useState("");

  useEffect(() => {

    const getUserById = async () => {
      const user = await new UserService().getUsers(`filter=id eq ${userId}`);
      return user;
    };
    
    getUserById().then((response: ODataResponse<UserDetailsModel>) => {
      setUserName(response.value[0].userName);
    });
  }, [userId]);

  const menuItems = [
    {
      text: "Profile",
      url: router.getUrl(routes.userDetails, new Map([["Id", userId.toString()]])),
      activeMatchUrl: routes.userDetails,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: "Security",
      url: router.getUrl(routes.userSecurity, new Map([["Id", userId.toString()]])),
      activeMatchUrl: routes.userSecurity,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: "Delete",
      url: router.getUrl(routes.userDelete, new Map([["Id", userId.toString()]])),
      activeMatchUrl: routes.userDelete,
      additionalClass: "submenu-sidebar-nav-item",
    },
  ];

  return (
    <>
      <h6>{userName}</h6>
      <SubmenuSideBar menuItems={menuItems} />
    </>
  );
}

export default UsersSidebar;

