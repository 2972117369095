import { useEffect, useState } from 'react'
import { SimpleCard } from '../../components/card/Card';
import Form from "react-bootstrap/Form";
import { BlockButton } from '../../components';
import MaintenanceService from '../../services/MaintenanceService';
import { showErrorMessage, showSuccessMessage } from '../../app/tools';

export default function UploadedFilesCard() {

  const [spaceUsed, setSpaceUsed] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const loadData = async () => {
    new MaintenanceService()
    .getSpaceUsed()
    .then((fileCacheUsedBytes) => {
      setSpaceUsed(fileCacheUsedBytes?.toString() ?? "");
    })
    .catch((error) => {
      console.error("Failed to fetch file cache data: ", error);
    })
  }

  useEffect(() => {
    loadData();
  }, [])

  async function emptyFileCacheButtonClick() {
    setShowSpinner(true);
    const ok = new MaintenanceService().emptyFileCache().then(() => {});
    setShowSpinner(false);
    if(!ok) {
      showErrorMessage("Emptying the file cache was not successful.");
      return;
    }
    showSuccessMessage("File cache emptied successfully.");
    loadData();
  }

  return (
      <SimpleCard className="mb-3 p-0" title="Uploaded Files">
        <Form>
          <div className="row">
            <div className="col-12">
              <p>When files are uploaded, they're stored in a temporary folder. If uploaded files are abandoned, that folder can collect junk over time and take up space.</p>
              <p><strong>Current Space Used: </strong>{spaceUsed} B</p>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-5 col-xl-3 mt-2">
              <BlockButton
                label="Empty File Cache"
                onClick={emptyFileCacheButtonClick}
                disabled={false}
                showSpinner={showSpinner}
              ></BlockButton>
            </div>
          </div>
        </Form>
      </SimpleCard>
  );
}
