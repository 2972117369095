import { useEffect, useState } from "react";
import UserModel from "../../data/entities/UserModel";
import Gravatar from "react-gravatar";
import "./InspectorList.scss";

interface InspectorProps {
  inspectors: UserModel[] | undefined;
}
export function InspectorList(props: InspectorProps) {
  const [inspectors, setInspectors] = useState<UserModel[] | undefined>(props.inspectors);

  useEffect(() => {
    setInspectors(props.inspectors);
  }, [props.inspectors]);

  let content = null;

  if (inspectors === undefined) {
    content = <em>There are no inspectors to display.</em>;
  } else {
    content = (
      <>
        {inspectors.map((inspector) => {
          const displayName = inspector.displayName;
          const credentials =
            inspector.profile?.credentials ?? false ? (
              <p className="my-0">{inspector.profile?.credentials}</p>
            ) : null;
          const email = <a href={`mailto:${inspector.email}`}>{inspector.email}</a>;
          const signature =
            inspector.profile?.signatureDataUri ?? false ? (
              <img
                className="signature"
                src={inspector.profile?.signatureDataUri}
                alt="Inspector Signature"
              />
            ) : null;

          return (
            <div key={inspector.email} className="row inspector my-3 d-flex align-items-center">
              <div className="col-auto">
                <Gravatar
                  email={inspector.email}
                  size={96}
                  default="identicon"
                  alt={`${inspector.userName} Gravatar`}
                  title={inspector.displayName}
                  className="avatar"
                />
              </div>
              <div className="col pl-0">
                <div className="row d-flex align-items-center">
                  <div className="col-auto">
                    <h6 className="mb-0">{displayName}</h6>
                    {credentials}
                    {email}
                  </div>
                  <div className="col d-none d-md-block pl-lg-3">{signature}</div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div id="inspectors" className="my-5">
      <h5>Inspected By</h5>
      <div className="inspectors-list">{content}</div>
    </div>
  );
}

export default InspectorList;
