import HttpClient, { ResponseData } from "../../app/HttpClient";
import { store } from "../../app/store/store";
import { setError, setInformation, setSuccess, setWarning } from "../../app/store/alertSlice";
import { forceLogout, toCamelCase } from "../../app/tools";
import { AlertLevel, AlertType } from "../../models";

export default abstract class BaseRepository {
  protected client = new HttpClient();

  protected handleResponse<T>(response: ResponseData<T>) {
    if (response.success) {
      const parsed = this.parseJsonWithCaseInsensitiveKeys<T>(response.data);
      return parsed;
    }

    return this.handleAlert(response);
  }

  private parseJsonWithCaseInsensitiveKeys<T>(data: any): T | null {
    if (data === undefined) return null;
    const jsonString = JSON.stringify(data);
    const transformedJsonString = jsonString.replace(/"([^"]+)":/g, (match, p1) => `"${toCamelCase(p1)}":`);
    const parsed = JSON.parse(transformedJsonString) as T;
    return parsed;
  }

  protected handleAlert<T>(response: ResponseData<T>) {
    let action;

    switch (response.alertLevel!) {
      case AlertLevel.information:
        action = setInformation(response.exceptionMessage);
        break;
      case AlertLevel.warning:
        action = setWarning(response.exceptionMessage);
        break;
      case AlertLevel.error:
        action = setError(response.exceptionMessage);
        if (window.onerror)
          window.onerror(response.exceptionMessage ?? "Unknown error message.");
        break;
      case AlertLevel.success:
        action = setSuccess(response.exceptionMessage);
        break;
    }
    store.dispatch(action);

    if (response.alertType === AlertType.userAccess) { 
      forceLogout();
    }

    return null;
  }
}
