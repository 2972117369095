import { useOutletContext, useParams } from "react-router-dom";
import { BlockButton, SimpleCard } from "../../../components";
import { router, routes } from "../../../app/routes";
import { useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import ProcessPositionsModel from "../../../data/entities/ProcessPositionsModel";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import { getUuid, showSuccessMessage } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsProcessPositions() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("Process Positions");

  const params = useParams();
  const id = params["id"] ?? "";

  const [positionsModel, setPositionsModel] = useState<ProcessPositionsModel | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  async function calculatePositionButtonClick() {

    if(positionsModel === null) return;

    setShowSpinner(true);
    const ok = await new ProcessService().postProcessPositions(parseInt(id), positionsModel);
    setShowSpinner(false);

    if(!ok) return;
    
    showSuccessMessage("Inspection positions successfully processed.");
    router.navigateWithParams(routes.inspectionsDataPoint, new Map([["id", id]]));
  }

  useEffect(() => {
    new ProcessService()
    .getProcessPositions(parseInt(id))
    .then((data) => {
      setPositionsModel(data);
    })
    .catch((error) => {
      console.error("Failed to fetch process positions data: ", error);
    });
  }, [id])

  return (
    <>
    <SimpleCard className="mb-3 p-0" title="Process data point positions.">
      <div className="row">
        <div className="col-12">
          <p>This data process exists so because the data points are a reference in time, but not position on the coiled tubing. The system will use the amount of time it took to inspect the pipe (based on the data points recorded per second), and use that to estimate the position of each data point in metres.</p>
          <p>Estimating the position based on the time is an inaccurate way to measure the position because it assumes that the speed that the tubing is consistent when it runs through the tool, and it assumes that no positions were recorded multiple times or skipped at all. Use of the tool needs to be extremely accurate to ensure that these calculated positions are equally accurate.</p>
          <p><strong>Warning:</strong> You need to set the direction of the inspection first before processing positions. If the inspection is done in reverse, then the 'position' of each data point is calculated in reverse. This is so that when you compare inspections they are always overlaid in the same direction with the data points matching up.</p>
          <p><strong>Current Direction:</strong> {}</p>
        </div>
      <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
        <BlockButton
          label="Calculate Positions"
          onClick={calculatePositionButtonClick}
          disabled={false}
          showSpinner={showSpinner}
        ></BlockButton>
      </div>
    </div>
    </SimpleCard>
    <SimpleCard className="mb-3 p-0" title="Position processing history.">
    <table className="table hover-row mt-3">
        <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
        {positionsModel?.processes.map((processDataHistory) => (
            <tr className="clickable" key={getUuid()}>
              <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
              <td>{processDataHistory.description}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </SimpleCard>
    </>
    
  );
}

export default InspectionsProcessPositions;

