import React from "react";
import { InspectionService, UserService } from "../../../../services";
import { useSelector } from "react-redux";
import { EditInspectionModel } from "../../../../data/entities";
import { Roles } from "../../../../models";
import { Button } from "react-bootstrap";
import { dateString } from "../../../../app/dateHelpers";
import {
  setInspectionForManagement,
  store,
} from "../../../../app/store/store";
import { Link } from "../../../../components";
import { routes } from "../../../../app/routes";
import { showSuccessMessage } from "../../../../app/tools";

export function InspectionSummaryPublish() {
  const isAdmin = UserService.isInRole(Roles.admin);
  const inspection = useSelector(
    (state: any) => state.inspectionManagement.inspection as EditInspectionModel
  );

  const getStructure = (
    title: string,
    messageContent: React.JSX.Element,
    commands: React.JSX.Element | null
  ) => {
    return (
      <div className="mb-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <h4 className="alert-heading mt-0">{title}</h4>
            {messageContent}
          </div>
          <div className="col-12 col-md-5 text-md-end d-flex flex-column">
            {commands}
          </div>
        </div>
      </div>
    );
  };

  const handleUnpublish = async () => {
    const requested = await new InspectionService().unpublish(inspection.id);
    if (requested) {
      showSuccessMessage(`Unpublished inspection ${inspection.reference}.`);
      const updatedInspection =
        await new InspectionService().getInspectionViewModel(inspection.id);
      store.dispatch(setInspectionForManagement(updatedInspection));
    }
  };

  const unpublishButton = () => {
    return (
      <Button
        className="btn btn-primary btn-small"
        type="button"
        onClick={handleUnpublish}
      >
        Unpublish
      </Button>
    );
  };

  const handlePublish = async () => {
    const requested = await new InspectionService().publishInspection(
      inspection.id
    );
    if (requested) {
      showSuccessMessage(`Published inspection ${inspection.reference}.`);
      const updatedInspection =
        await new InspectionService().getInspectionViewModel(inspection.id);
      store.dispatch(setInspectionForManagement(updatedInspection));
    }
  };

  const publishButton = () => {
    return (
      <Button
        className="btn btn-primary btn-small btn-block mt-1"
        type="submit"
        onClick={handlePublish}
      >
        Publish Now
      </Button>
    );
  };

  const handleRevokeApproval = async () => {
    const requested = await new InspectionService().revokeApproval(
      inspection.id
    );
    if (requested) {
      showSuccessMessage(
        `Revoked approval request for inspection  ${inspection.reference}.`
      );
      const updatedInspection =
        await new InspectionService().getInspectionViewModel(inspection.id);
      store.dispatch(setInspectionForManagement(updatedInspection));
    }
  };

  const revokeApprovalButton = () => {
    return (
      <Button
        className="btn btn-primary btn-small btn-block mt-1"
        type="submit"
        onClick={handleRevokeApproval}
      >
        Revoke Approval Request
      </Button>
    );
  };

  const handleRequestApproval = async () => {
    const requested = await new InspectionService().requestApproval(
      inspection.id
    );
    if (requested) {
      showSuccessMessage(
        `Requested approval for inspection  ${inspection.reference}.`
      );
      const updatedInspection =
        await new InspectionService().getInspectionViewModel(inspection.id);
      store.dispatch(setInspectionForManagement(updatedInspection));
    }
  };

  const requestApprovalButton = () => {
    return (
      <Button
        className="btn btn-primary btn-small btn-block mt-1"
        type="submit"
        onClick={handleRequestApproval}
      >
        Request Approval
      </Button>
    );
  };

  const previewButton = () => {
    const params = new Map<string, string>([["id", inspection.id.toString()]]);
    return (
      <Link
        url={routes.myInspectionsDetails}
        params={params}
        className="btn btn-secondary btn-small btn-block"
        target="_blank"
      >
        Preview
      </Link>
    );
  };

  if (inspection.isPublished) {
    const button = unpublishButton();
    const content = (
      <>
        <label className="font-weight-bold">Publication Date:</label>
        <span>{dateString(new Date(inspection.publishedDate!))}</span>
      </>
    );
    return getStructure("Published", content, button);
  }

  if (inspection.approvalRequested || isAdmin) {
    const publishComponent = isAdmin ? publishButton() : null;
    const revokeComponent = inspection.approvalRequested
      ? revokeApprovalButton()
      : null;
    return getStructure(
      "Not Yet Published",
      <p>This inspection has not yet been published.</p>,
      <>
        {previewButton()}
        {publishComponent}
        {revokeComponent}
      </>
    );
  }

  return getStructure(
    "Request Approval",
    <p>This inspection requires approval to be published.</p>,
    <>
      {previewButton()}
      {requestApprovalButton()}
    </>
  );
}

export default InspectionSummaryPublish;

