import { NoteValueModel } from "../../data/entities/NoteValueModel";

interface InspectionNoteValuesProps {
  values: NoteValueModel[] | undefined | null;
}


export const sortValues = (values: NoteValueModel[]) => {
  return values.sort((a, b) => {
    const nameA = a.name?.toLowerCase() ?? "";
    const nameB = b.name?.toLowerCase() ?? "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

export default function InspectionNoteValues(props: Readonly<InspectionNoteValuesProps>) {


  const rows = props.values?.map((val) => {
    return (
      <tr key={val.id}>
        <td className="py-1 no-border w-25">{val.name}</td>
        <td className="py-1 no-border w-75">{val.friendlyValue}</td>
      </tr>
    );
  });

  return (
    <div className="note-values mt-3 text-sm">
      <table className="table">
        <thead>
          <tr>
            <th colSpan={2} className="py-2 bg-light">
              Measurements
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

