import React, { useImperativeHandle, useState } from "react";
import { NoteContextModelContext, NoteModel } from "../../../../data/entities";
import InspectionNote from "../../../myInspections/InspectionNote";
import {
  Icon,
  IconAndText,
  IconDefinitions,
  LinkLooksLike,
  SlideOutModal,
  SpinnerDefault,
  TitlelessCard,
} from "../../../../components";
import { useSharedState } from "../../../../app/sharedProperty";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { NoteService } from "../../../../services";
import InspectionsNotesModal from "./InspectionsNotesModal";
import InspectionNoteEditValuesModal from "./InspectionNoteEditValuesModal";
import InspectionNoteImagesModal from "./InspectionNoteImagesModal";

interface InspectionsNotesEditCardProps {
  note: NoteModel;
  noteContext: NoteContextModelContext;
  onChanged: () => void;
}

export const InspectionsNotesEditCard = React.forwardRef(
  (props: InspectionsNotesEditCardProps, ref) => {
    const { inspectionId } = props.note;
    const deleteModalState = useSharedState<boolean>(false);
    const editModalState = useSharedState<boolean>(false);
    const editValuesModalState = useSharedState<boolean>(false);
    const editImagesModalState = useSharedState<boolean>(false);
    const [editValuesRefreshCode, setEditValuesRefreshCode] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    const deleteClickHandler = () => {
      document.body.click();
      deleteModalState.setter(true);
    };
    const yesDeleteClickHandler = () => {
      const deleteNote = async () => {
        const isDeleted = await new NoteService().deleteNote(props.note.id!);
        if (isDeleted) {
          props.onChanged();
          deleteModalState.setter(false);
        }
      };
      deleteNote();
    };

    const modalDeleteCommands = (
      <>
        <Button
          variant="secondary"
          onClick={() => deleteModalState.setter(false)}
        >
          No
        </Button>
        <Button variant="primary" onClick={yesDeleteClickHandler}>
          Yes
        </Button>
      </>
    );

    useImperativeHandle(ref, () => ({
      show(): void {
        editValuesModalState.setter(true);
      },
    }));

    const editClickHandler = () => {
      document.body.click();
      editModalState.setter(true);
    };

    const valueClickHandler = () => {
      document.body.click();
      editValuesModalState.setter(true);
    };

    const imageClickHandler = () => {
      document.body.click();
      editImagesModalState.setter(true);
    };

    const popover = (
      <Popover id={`popover-${props.note.id}`}>
        <Popover.Body>
          <div className="d-grid gap-2">
            <LinkLooksLike onClick={editClickHandler}>Edit</LinkLooksLike>
            <LinkLooksLike onClick={valueClickHandler}>Values</LinkLooksLike>
            <LinkLooksLike onClick={imageClickHandler}>Images</LinkLooksLike>
            <LinkLooksLike onClick={deleteClickHandler}>Delete</LinkLooksLike>
          </div>
        </Popover.Body>
      </Popover>
    );

    return (
      <div className="row" id={`note-${props.note.id}`}>
        <div className="col">
          <TitlelessCard className="mb-3">
            <div className="row g-0">
              <div className="col overflow-hidden">
                <InspectionNote note={props.note}></InspectionNote>
              </div>
              <div className="col-auto">
                <OverlayTrigger
                  trigger="click"
                  placement="left-start"
                  overlay={popover}
                  rootClose
                >
                  {({ ref, ...triggerHandler }) => (
                    <LinkLooksLike ref={ref} {...triggerHandler}>
                      <Icon
                        iconName={IconDefinitions.ellipsisVertical}
                        className="clickable"
                      />
                    </LinkLooksLike>
                  )}
                </OverlayTrigger>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex gap-3">
                <LinkLooksLike onClick={editClickHandler}>
                  <IconAndText iconName={"edit"} text="Edit" />
                </LinkLooksLike>
                <LinkLooksLike onClick={valueClickHandler}>
                  <IconAndText iconName={"list"} text="Values" />
                </LinkLooksLike>
                <LinkLooksLike onClick={imageClickHandler}>
                  <IconAndText iconName={"images"} text="Images" />
                </LinkLooksLike>
                <LinkLooksLike onClick={deleteClickHandler}>
                  <IconAndText iconName={"delete"} text="Delete" />
                </LinkLooksLike>
              </div>
            </div>
            {/* Delete Modal */}
            <SlideOutModal
              showState={deleteModalState}
              title="Confirm"
              footer={modalDeleteCommands}
            >
              <p>
                Are you sure you want to delete the note described as "
                {props.note.description}"?
              </p>
            </SlideOutModal>
            {/* Edit Modal */}
            <InspectionsNotesModal
              modalState={editModalState}
              noteContext={props.noteContext}
              inspectionId={inspectionId!}
              note={props.note}
              onSaved={props.onChanged}
              modalStates={{
                detailsState: editModalState,
                valuesState: editValuesModalState,
                imagesState: editImagesModalState,
              
              }}
            />
            <InspectionNoteEditValuesModal
              modalState={editValuesModalState}
              noteId={props.note.id!}
              onSaved={() => {
                setEditValuesRefreshCode(editValuesRefreshCode + 1);
                props.onChanged();
                setIsSaving(false);
              }}
              refreshCode={editValuesRefreshCode}
              onStartSaving={() => setIsSaving(true)}
              modalStates={{
                detailsState: editModalState,
                valuesState: editValuesModalState,
                imagesState: editImagesModalState,
              
              }}
            />
            <InspectionNoteImagesModal
              inspectionId={inspectionId!}
              note={props.note}
              images={props.note.images ?? []}
              modalState={editImagesModalState}
              onSaved={() => {
                setEditValuesRefreshCode(editValuesRefreshCode + 1);
                props.onChanged();
                setIsSaving(false);
              }}
              modalStates={{
                detailsState: editModalState,
                valuesState: editValuesModalState,
                imagesState: editImagesModalState,
              
              }}
            />
            <SpinnerDefault fullscreen={true} show={isSaving} />
          </TitlelessCard>
        </div>
      </div>
    );
  }
);

export default InspectionsNotesEditCard;


