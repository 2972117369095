import React, { useState } from "react";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { FloatingLabel } from "react-bootstrap";

interface DatePickerProps {
  label?: string;
  required?: boolean;
  value?: Date;
  disabled?: boolean;
  name?: string;
  isInvalid?: boolean;
  onChange?: (e: DatePickerChangeEvent) => void;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
}

export const HandleDatePickerChange = (e: DatePickerChangeEvent, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any) => {
  const eventProps = { 
    name: e.target.props.name ?? '',
    value: (e as any).value.toISOString() ?? null
  };
  return setFieldValue(eventProps.name, eventProps.value, true);
};

export const CustomDatePicker = React.forwardRef(
  (
    props: Readonly<DatePickerProps>,
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => {
    const {
      onChange,
      name,
      label,
      required,
      value: initialValue,
      disabled,
      onChangeText,
      isInvalid
    } = props;

    const [value, setValue] = useState<string>(
      initialValue?.toISOString().substring(0, 10) ?? ""
    );

    function handleInputChange(value: string) {
      const changedValue = value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    return (
      <DatePicker
        name={name}
        className="w-100 h-100"
        format={"yyyy-MM-dd"}
        placeholder={label}
        value={new Date(value)}
        disabled={disabled}
        ref={ref}
        onChange={(event) => {
            handleInputChange(event.target.value ? event.target.value.toString() : new Date().toISOString());
            onChange && onChange(event);
          }}
        valid={!isInvalid}
        required={required}
      />
    );
  }
);

DatePicker.defaultProps = {
  required: false,
  disabled: false,
};

export default CustomDatePicker;

