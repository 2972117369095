import { toCapitalCase } from "../../app/stringHelpers";
import { NoteImageModel, NoteModel, NoteValueModel, ODataResponse, ODataSingleResponse } from "../entities";
import { NoteContextModel } from "../entities/NoteContextModel";
import BaseRepository from "./BaseRepository";

export class NoteRepo extends BaseRepository {

  async getNotesByInspectionId(inspectionId: number): Promise<NoteContextModel | null> {
    const url = `inspection/${inspectionId}/note`;

    const response = await this.client.get(url);
    return this.handleResponse<NoteContextModel>(response);
  }

  async addNote(note: NoteModel): Promise<NoteModel | null> {
    const url = `OData/Notes`;
    const body = {
      ...note,
      severity: toCapitalCase(note.severity!),
    };
    const response = await this.client.post(url, body);
    const handled = this.handleResponse<ODataSingleResponse<NoteModel>>(response);

    if (!handled)
      return null;

    return {
      id: handled.id,
      createdDate: handled.createdDate,
      startPosition: handled.startPosition,
      endPosition: handled.endPosition,
      inspectionId: handled.inspectionId,
      userId: handled.userId,
      description: handled.description,
      severity: handled.severity,
    } as NoteModel;
  }

  async editNote(note: NoteModel): Promise<boolean> {
    const url = `OData/Notes(${note.id})`;
    const body = {
      startPosition: note.startPosition,
      endPosition: note.endPosition,
      inspectionId: note.inspectionId,
      userId: note.userId,
      description: note.description,
      severity: toCapitalCase(note.severity!),
      id: note.id,
    };
    const response = await this.client.put(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async deleteNoteById(id: number): Promise<boolean> {
    const url = `OData/Notes(${id})`;
    const response = await this.client.delete(url);
    this.handleResponse(response);
    return response.success;
  }

  async getNoteById(inspectionId: number, id: number): Promise<NoteModel | null> {
    const url = `inspection/${inspectionId}/note/partial/${id}`;
    const response = await this.client.get(url);
    return this.handleResponse<NoteModel>(response);
  }

  async getNoteValues(noteId: number): Promise<ODataResponse<NoteValueModel> | null> {
    const url = `OData/Notes(${noteId})/Values?%24select=Id,NoteId,Name,Value`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<NoteValueModel>>(response)!;
  }

  async updateNoteValue(noteValue: NoteValueModel): Promise<boolean> {
    const url = `OData/NoteValues(${noteValue.id})`;
    const body = {
      id: noteValue.id,
      name: noteValue.name,
      noteId: noteValue.noteId,
      value: noteValue.value?.toString(),
    };
    const response = await this.client.put(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async addNoteValue(noteValue: NoteValueModel): Promise<boolean> {
    const url = `OData/NoteValues`;
    const body = {
      name: noteValue.name,
      noteId: noteValue.noteId,
      value: noteValue.value?.toString(),
    };
    const response = await this.client.post(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async deleteNoteValue(noteValueId: number): Promise<boolean> {
    const url = `OData/NoteValues(${noteValueId})`;
    const response = await this.client.delete(url);
    this.handleResponse(response);
    return response.success;
  }

  async calculateOvality(noteId: number): Promise<ODataResponse<NoteValueModel> | null> {
    const url = `OData/Notes(${noteId})/Default.UpdateOvalityValue`;
    const response = await this.client.post(url);
    return this.handleResponse<ODataResponse<NoteValueModel>>(response);
  }

  async getImages(inspectionId: number, noteId: number): Promise<string[] | null> {
    const url = `inspection/${inspectionId}/note/${noteId}/noteImages`;
    const response = await this.client.get(url);
    return this.handleResponse<string[]>(response);
  }

  async updateNoteImage(noteImage: NoteImageModel): Promise<boolean> {
    const url = `OData/NoteImages(${noteImage.id})`;
    const body = {
      id: noteImage.id,
      name: noteImage.name,
      noteId: noteImage.noteId,
      caption: noteImage.caption,
    };
    const response = await this.client.put(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async deleteNoteImage(noteImageId: number): Promise<boolean> {
    const url = `OData/NoteImages(${noteImageId})`;
    const response = await this.client.delete(url);
    this.handleResponse(response);
    return response.success;
  }

  async reversePositions(id: number): Promise<boolean> {
    const url = `inspection/${id}/note/ReversePositions`;
    const response = await this.client.post(url);
    this.handleResponse(response);
    return response.success;
  }

}
