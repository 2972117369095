import { routes } from "../../app/routes";
import PageContent from "../PageContent";
import {
  HeadingBar,
  InspectionList,
  DashboardInspectionsChart,
  Link,
} from "../../components";
import { UserService } from "../../services";

export function HomePage() {
  // Avoid calling the API if the user is not signed in
  if (!UserService.isSignedIn()) return <></>;

  return (
    <PageContent>
      <HeadingBar title="All Inspections" h={5} />
      <DashboardInspectionsChart />
      <HeadingBar
        title="Recent Inspections"
        h={5}
        right={<Link url={routes.myInspections}>View All</Link>}
      ></HeadingBar>
      <InspectionList></InspectionList>
    </PageContent>
  );
}

export default HomePage;
