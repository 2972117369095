import { routes } from "../../app/routes";
import sharedProperty from "../../app/sharedProperty";
import { Submenu, IconDefinitions, SubmenuNavigationItem } from "../../components";

interface CompanySubmenuProps {
  id: string,
  showSelectModalState: sharedProperty<boolean>
};

export const CompanySubmenu = (props: CompanySubmenuProps) => {
  
  const submenus: SubmenuNavigationItem[] = [
    {
      label: "Back",
      icon: IconDefinitions.arrowLeft,
      url: routes.company,
    },
    {
      label: "Details",
      url: routes.companyDetails,
      params: new Map([["id", props.id]]),
      activeMatch: routes.companyDetails,
      activeMatchExact: true
    },
    {
      label: "Address",
      url: routes.companyAddress,
      params: new Map([["id", props.id]]),
      activeMatch: routes.companyAddress,
      activeMatchExact: true
    },
    {
      label: "Users",
      url: routes.companyUsers,
      params: new Map([["id", props.id]]),
      activeMatch: routes.companyUsers,
      activeMatchExact: true
    },
    {
      label: "Tubings",
      url: routes.companyTubings,
      params: new Map([["id", props.id]]),
      activeMatch: routes.companyTubings,
      activeMatchExact: true
    },
    {
      label: "Delete",
      url: routes.company,
      activeMatch: routes.company,
      activeMatchExact: true,
      clickHandler: () => props.showSelectModalState.setter(true)
    }
  ];

  return <Submenu items={submenus}></Submenu>;
};

export default CompanySubmenu;
