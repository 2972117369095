import SubmenuSideBar from "../../components/sideBar/SubmenuSideBar";
import { routes } from "../../app/routes";

export function InviteSidebar() {

  const menuItems = [
    {
      text: "View Pending",
      url: routes.userInvitations,
      activeMatchUrl: routes.userInvitations,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: "Invite New User",
      url: routes.userInsertInvitation,
      activeMatchUrl: routes.userInsertInvitation,
      additionalClass: "submenu-sidebar-nav-item",
    },
  ];

  return (
    <SubmenuSideBar menuItems={menuItems} />
  );
}

export default InviteSidebar;
