import { router, routes } from "../../../../app/routes";
import SubmenuSideBarNavItemModel from "../../../../models/SubmenuSideBarNavItemModel";
import { SubmenuSideBar } from "../../../../components";

interface InspectionDetailsSideBarProps {
  inspectionId: number;
}

export function InspectionProcessSideBarItems(
  inspectionId: number
): Array<SubmenuSideBarNavItemModel> {
  const params = new Map<string, string>([["id", inspectionId.toString()]]);

  const menu: SubmenuSideBarNavItemModel[] = [
    {
      text: "Positions",
      url: router.getUrl(routes.inspectionsProcessPositions, params),
      activeMatchUrl: [
        routes.inspectionsProcess,
        routes.inspectionsProcessPositions,
      ]
    },
    {
      text: "Normalize",
      url: router.getUrl(routes.inspectionsProcessNormalize, params)
    },
    {
      text: "Adjust",
      url: router.getUrl(routes.inspectionsProcessAdjust, params)
    },
    {
      text: "Randomize",
      url: router.getUrl(routes.inspectionsProcessRandomize, params)
    },
    {
      text: "Split",
      url: router.getUrl(routes.inspectionsProcessSplit, params)
    },
    {
      text: "Merge",
      url: router.getUrl(routes.inspectionsProcessMerge, params)
    },
    {
      text: "FLW Baseline",
      url: router.getUrl(routes.inspectionsProcessFlwBaseline, params)
    },
    {
      text: "Copy",
      url: router.getUrl(routes.inspectionsProcessCopy, params)
    },
    {
      text: "Reset",
      url: router.getUrl(routes.inspectionsProcessReset, params)
    },
    {
      text: "Reverse Positions",
      url: router.getUrl(routes.inspectionsProcessReversePositions, params)
    },
  ];

  return menu;
}

export function InspectionProcessSideBar(props: InspectionDetailsSideBarProps) {
  const menuItems = InspectionProcessSideBarItems(
    props.inspectionId
  ) as SubmenuSideBarNavItemModel[];

  return <SubmenuSideBar menuItems={menuItems } />;
}

export default InspectionProcessSideBar;


