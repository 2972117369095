import { Alert, CloseButton } from "react-bootstrap";
import { store } from "../../app/store/store";
import { clearAlert } from "../../app/store/alertSlice";
import { useAppSelector } from "../../app/hooks";
import { AlertLevel } from "../../models";
import { Icon, IconDefinitions } from "../../components";

export function AlertMessage() {
  const message = useAppSelector((state) => state.alerts.message);
  const showAlert = useAppSelector((state) => state.alerts.show);
  const level = useAppSelector((state) => state.alerts.level);
  const initialTime = 5000;
  const millisecondsPerChar = 100;
  const variants = new Map<AlertLevel, string>();
  variants.set(AlertLevel.information, "info");
  variants.set(AlertLevel.warning, "warning");
  variants.set(AlertLevel.error, "danger");
  variants.set(AlertLevel.success, "success");

  const variant = variants.get(level!);

  const titles = new Map<AlertLevel, string>();
  titles.set(AlertLevel.information, "Info");
  titles.set(AlertLevel.warning, "Warning");
  titles.set(AlertLevel.error, "Error");
  titles.set(AlertLevel.success, "Success");

  const title = titles.get(level!);

  const dismiss = () => {
    store.dispatch(clearAlert());
  };

  const getIconName = (lvl: AlertLevel): string => {
    let iconName;
    switch (level) {
      case AlertLevel.warning:
        iconName = IconDefinitions.triangleExclamation;
        break;
      case AlertLevel.error:
        iconName = IconDefinitions.circleExclamation;
        break;
      case AlertLevel.success:
        iconName = IconDefinitions.circleCheck;
        break;
      default:
        iconName = IconDefinitions.circleInfo;
    }
    return iconName;
  };

  if (showAlert) {
    const waitTime = initialTime + (message?.length ?? 1) * millisecondsPerChar;
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, waitTime);


    return (
      <Alert 
        key={variant}
        variant={variant}
        onClose={dismiss}
        className="w-100 d-flex align-items-center"
        style={{ position: "absolute", zIndex: 999 } }
        dismissible
      >
        <Icon className="fa-3x" iconName={getIconName(level ?? AlertLevel.information)}></Icon>
        <div>
          <Alert.Heading className="mb-0">{title}</Alert.Heading>
          <span>{message}</span>
          <CloseButton onClick={dismiss} />
        </div>
      </Alert>
    );
  }
  return <></>;
}

export default AlertMessage;
