import { useEffect, useState } from 'react'
import { SpinnerDefault } from '../../components'
import { TubingForm } from './'
import { TubingModel } from '../../data/entities'
import { TubingService, UrlService } from '../../services';
import { useParams } from 'react-router-dom'

export function TubingDetailsCard() {

  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [data, setData] = useState<TubingModel | undefined | null>();

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new TubingService().getTubingDetails(id.toString());
        setData(loadedData);
      } catch (e) {
        console.error("Error loading tubing details", e);
      }
    };
    fetchData();
  }, [id]);

  // If the data is undefined, show a spinner.
  if (data === undefined) return <SpinnerDefault />
  
  return (
    <TubingForm
      title="Update tubing details."
      data={data}
      action={'update'}
      tubingId={id}
      dropdownSelectedOption={data?.companyId}
      formSubmitLabel='Save'
    />
  )
}

export default TubingDetailsCard
