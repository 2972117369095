import { GridColumn, GridRowClickEvent } from "@progress/kendo-react-grid";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { router, routes } from "../../app/routes";
import { ODataGrid, TitlelessCard } from "../../components";
import { CompanyService } from "../../services";
import PageContent, { PageBreadcrumbProps } from "../PageContent";
import CompanyRootSubmenu from "./CompanyRootSubmenu";

export function CompanyPage() {

  const map = new Map<string, string>();

  const title: string = "Manage companies";
  const breadcrumbs: PageBreadcrumbProps = {
    showHome: true,
    values: [{ label: title }]
  };

  const submenus = <CompanyRootSubmenu/>;

  const companyService = new CompanyService();

  const getCompanies = (oDataQuery: string) =>
    companyService.getAllCompaniesOData(`${oDataQuery}`);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    const id = map.set('id', dataItem.id);
    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(routes.companyDetails, id);
        break;
      case "opentab":
        router.navigateWithParams(routes.companyDetails, id, true);
        break;
    }
  };

  const onRowClick = (e: GridRowClickEvent) => {
    if (e.dataItem.isDeleted) return;

    router.navigateWithParams(
      routes.companyDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  return (
    <PageContent title={title} breadcrumbs={breadcrumbs} submenu={submenus}>
      <TitlelessCard className="p-0" bodyClassName="p-0">
        <ODataGrid getData={getCompanies} onRowClick={onRowClick} onRowMenuSelect={onRowMenuSelect}>
          <GridColumn title="Company" field="name" sortable={true} filter="text" />
          <MenuItem text="Open" data={{ action: "open" }} icon="hyperlink-open" />
          <MenuItem text="Open Tab" data={{ action: "opentab" }} icon="windows" />
        </ODataGrid>
      </TitlelessCard>
    </PageContent>
  );
}

export default CompanyPage;
