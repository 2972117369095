import React, { useEffect, useState } from "react";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import BlockButton from "../../components/form/buttons/BlockButton";
import Form from "react-bootstrap/Form";
import PasswordWithValidation from "../../components/form/input/PasswordWithValidation";
import UserService from "../../services/UserService";
import { router, routes } from "../../app/routes";
import UrlService from "../../services/UrlService";

export function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmation, setConfirm] = useState("");
  const [isConfirmValid, setIsConfirmValid] = useState(false);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const code = UrlService.getQueryParams<string>("code")!;

  useEffect(() => {
    setButtonEnabled(isEmailValid && isPasswordValid && isConfirmValid);
  }, [isEmailValid, isPasswordValid, isConfirmValid]);

  enum fields {
    email,
    password,
    confirm,
  }

  const validateField = (field: fields, input: string, result: boolean) => {
    switch (field) {
      case fields.email:
        setEmail(input);
        setIsEmailValid(result);
        break;
      case fields.password:
        setPassword(input);
        setIsPasswordValid(result);
        break;
      case fields.confirm:
        setConfirm(input);
        setIsConfirmValid(result);
        break;
    }
  };

  async function onClick() {
    setShowSpinner(true);
    const ok = await new UserService().resetPassword(email, password, confirmation, code);

    setShowSpinner(false);

    if (!ok) return;
    router.navigate(routes.resetPasswordConfirmation);
  }

  return (
    <>
      <h2>Reset Password</h2>
      <section>
        <h4 className="mb-4">Enter a new password.</h4>
        <Form>
          <EmailWithValidation
            id="email"
            validationResult={(input, result) => {
              validateField(fields.email, input, result);
            }}
          ></EmailWithValidation>
          <PasswordWithValidation
            id="password"
            validationResult={(input, result) => {
              validateField(fields.password, input, result);
            }}
          ></PasswordWithValidation>
          <PasswordWithValidation
            id="passwordValidation"
            label="Confirm password"
            validationResult={(input, result) => {
              validateField(fields.confirm, input, result);
            }}
          ></PasswordWithValidation>
          <BlockButton
            label="Save"
            onClick={onClick}
            disabled={!isButtonEnabled}
            showSpinner={showSpinner}
          ></BlockButton>
        </Form>
      </section>
    </>
  );
}

export default ResetPassword;
