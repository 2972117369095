import { useEffect, useMemo, useState } from "react";
import { routes } from "../../app/routes";
import {
  Submenu,
  IconDefinitions,
  SubmenuNavigationItem,
} from "../../components";
import { UrlService } from "../../services";

export const TubingSubmenu = (props: any) => {
  const returnUrl = UrlService.getQueryParams("returnUrl");
  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Tubings",
        icon: IconDefinitions.tubing,
        url: routes.tubings,
        activeMatch: routes.tubings,
        activeMatchExact: true,
      },
      {
        label: "Add Tubing",
        icon: IconDefinitions.add,
        url: routes.tubingsInsert,
        activeMatch: routes.tubingsInsert,
        activeMatchExact: true,
      },
    ],
    []
  );

  useEffect(() => {
    const backItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
};

export default TubingSubmenu;

