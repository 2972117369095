import { SetStateAction, useEffect, useState } from 'react'
import { SimpleCard } from '../../components/card/Card';
import Form from "react-bootstrap/Form";
import TextWithValidation from '../../components/form/input/TextWithValidation';
import {Dropdown, Option} from '../../components/form/dropdown/Dropdown';
import CompanyService from '../../services/CompanyService';
import { CompanyModel, SystemOfMeasurement } from '../../data/entities';
import { SpinnerDefault } from '../../components';
import UrlService from '../../services/UrlService';
import { useParams } from 'react-router-dom';
import { showSuccessMessage } from '../../app/tools';
import { Button } from 'react-bootstrap';

export function CompanyDetailsCard() {

  const urlParams = useParams();
  const companyId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [companyName, setCompanyName] = useState("");

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [defaultMeasurement, setDefaultMeasurement] = useState(company?.defaultMeasurement ?? 0);

  const [measurementDropdown, setMeasurementList] = useState<Option[] | null>(null);

  const loadData = async(id: number) => {
    new CompanyService()
      .getDetails(id)
      .then((data) => {
        setCompany(data);
        setCompanyName(data?.name ?? "");
        setDefaultMeasurement(data?.defaultMeasurement ?? 0);
      })
      .catch((error) => {
        console.error("Error fetching company data: ", error);
      });
  }

  useEffect(() => {
    loadData(companyId);
  },[companyId]);


  useEffect(() => {
    let preferredMeasurementList : Option[] = [];

      const enumValues = Object.values(SystemOfMeasurement)
      .filter(value => typeof SystemOfMeasurement[value as any] === 'number'); // Filter is to remove the reverse mappings as entries
      let enumCount = 0;
      enumValues.forEach(value => {
        const id = enumCount.toString();
        const measurementType = value;
        const measurementInfo = { value: id, text: measurementType.toString() }; 
        preferredMeasurementList.push(measurementInfo); 
        enumCount++;
      })
      setMeasurementList(preferredMeasurementList);

  }, []);

  async function updateCompanyButtonClick() {
    if(company == null || !isCompanyNameValid) return;
    
    const updatedCompanyModel = {id: companyId, name: companyName, defaultMeasurement: defaultMeasurement}
    const ok = await new CompanyService().updateCompany(companyId, updatedCompanyModel);

    if(!ok) return;

    showSuccessMessage("Company details successfully updated.");
    // Reload the company data.
    loadData(companyId);
  };

  const handleChange = (field: string, input: SetStateAction<string>) => {
    switch(field) {
      case "companyName":
        setCompanyName(input);
        break;
      case "defaultMeasurement":
        setDefaultMeasurement(parseInt(input.toString()));
        break;
    }
  };

  if(company === null) return <SpinnerDefault/>

  return (
      <SimpleCard title="Update company details.">
        <Form>
          <TextWithValidation 
            label="Company Name"
            initialValue={company?.name}
            required={true}
            id="Name"
            disabled={false}
            validationFunction={(input) => {
              const regex = /^[a-z0-9 ,.'-]*$/i;
              const valid = (regex.test(input) && input !== "");
              setIsCompanyNameValid(valid)
              return valid;
            }}
            onChangeText={(input) => {
              handleChange("companyName", input);
            }}
            type="companyName"
          ></TextWithValidation>
          <div className="row mb-3 flat-card">
            <div className="col-12 card-body">
              <h5>Default System of Measurement</h5>
              <p>Choose how you would like measurements to be displayed for this company by default. This will affect the default system of measurement for new users under this company, and it will also affect how the pre-cached PDF document is rendered. The PDF reports will always use the company's default system of measurement instead of any individual user settings.</p>
            </div>
            <div className="col-12">
              <Dropdown 
                label="Default Measurement System" 
                selectedOption={company?.defaultMeasurement} 
                id="measurement" 
                options={measurementDropdown} 
                disabled={false} 
                onChangeSelected={(input) => {
                  handleChange("defaultMeasurement", input)
                }}
              ></Dropdown>
            </div>
          </div>
          <div className="col-1">
            <Button onClick={updateCompanyButtonClick} disabled={false}>Save</Button>
          </div>
        </Form>
      </SimpleCard>
  );
}

export default CompanyDetailsCard;
